// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { useAppSelector, useAppDispatch } from "utils/store"
import ZxModal from "ui/ZxModal"
import { ZForm } from "uiZ/zForm"
import {
    horaireMembreCreateSuccess4Table,
    horaireMembreUpdateSuccessV2,
    deleteMembreHoraireSuccess,
} from "features/timetable/utils/timetableReducer"
import {
    IHoraireMembre,
    setFormHoraireMembre,
} from "features/membres/utils/membresReducer"
import {
    zApiHoraireMembreCreate,
    zApiHoraireMembreUpdate,
    zApiHoraireMembreDelete,
} from "features/membres/utils/apiMembres"
import HoraireMembreFormInner from "./HoraireMembreFormInner"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function HoraireMembreForm(/* {
    horaireModeTimetable,
}: {
    horaireModeTimetable: boolean
} */) {
    const dispatch = useAppDispatch()
    const { formHoraireMembre } = useAppSelector((state) => state.membres)
    console.log("formHoraireMembre", formHoraireMembre)

    const customValidate = (values: IHoraireMembre) => {
        let errors = {}

        if (values.heureDebut >= values.heureFin) {
            errors = {
                ...errors,
                heureDebut: "values.heureDebut >= values.heureFin",
            }
        }

        return errors
    }

    if (!formHoraireMembre) {
        return <h1>!formHoraireMembre</h1>
    }

    const createSuccessFunction = (result: any) => {
        console.log("createSuccessFunction", result)
        console.log(result)
        dispatch(
            horaireMembreCreateSuccess4Table({
                horaireMembre: result.horaireMembre,
                membreId: formHoraireMembre.membreId,
            })
        )
    }

    const updateSuccessFunction = (result: any) => {
        console.log("updateSuccessFunction", result)
        dispatch(
            horaireMembreUpdateSuccessV2({
                horaireMembre: result,
                membreId: formHoraireMembre.membreId,
            })
        )
    }
    const deleteSuccessFunction = () => {
        console.clear()
        console.log("deleteSuccessFunction")
        dispatch(
            deleteMembreHoraireSuccess({
                membreHoraireId: formHoraireMembre.id,
                membreId: formHoraireMembre.membreId,
            })
        )
        // dispatch(setFormHoraireMembre(null))
    }

    console.log("formHoraireMembre", formHoraireMembre)

    return (
        <ZxModal styles={{ maxWidth: "460px" }}>
            <ZForm
                initialData={formHoraireMembre}
                customValidate={customValidate}
                closeForm={() => dispatch(setFormHoraireMembre(null))}
                // *** Create:
                createTitle="Ajouter un horaire de travail"
                createFunction={(args: any) => zApiHoraireMembreCreate(args)}
                createSuccessFunction={(result: any) =>
                    createSuccessFunction(result)
                }
                // *** Update:
                updateTitle="Modifier un horaire de travail"
                updateFunction={zApiHoraireMembreUpdate}
                updateSuccessFunction={(result: any) =>
                    updateSuccessFunction(result)
                }
                // *** Delete:
                deleteTitle="Supprimer un horaire de travail"
                deleteConfirmText=""
                deleteFunction={() =>
                    zApiHoraireMembreDelete(formHoraireMembre.id || 0)
                }
                deleteSuccessFunction={(_result: any) =>
                    deleteSuccessFunction()
                }
            >
                <HoraireMembreFormInner />
            </ZForm>
        </ZxModal>
    )
}
