// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
// import React from "react"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export const status = {
    pending: "PENDING",
    canceled: "CANCELED",
    processing: "PROCESSING",
    checking: "CHECKING",
    done: "DONE",
}

export const progressItemsMesEtabs = [
    {
        page: "Mes Etablissements",
        actions: [
            {
                title: "Afficher la liste de mes étabs",
                content: "Aller sur la page de l'étab en cliquant",
                status: status.done,
            },
            {
                title: "Créer un établissement",
                status: status.done,
            },
            {
                title: "Afficher un message si il n'y a aucun établissement",
                status: status.done,
            },
            {
                title: "Bouton: Accepter la demande",
                content:
                    "Quand un CPE a ajouté un personnel avec un mail qui est déja lié à un compte",
                status: status.pending,
            },
            {
                title: "Nombre limite d'établissements",
                status: status.pending,
            },
        ],
    },
]
