/* eslint-disable react/no-unstable-nested-components */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import ZTable from "ui/zTable/ZTable"
import { ZPageSection } from "uiZ/zPage"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

interface Log {
    createdAt: string
    event: string
}
interface User {
    logs: Log[]
}

type Props = {
    users: User[]
}
export default function DashboardUsers({ users }: Props) {
    // const [formEditUserIsOpen, setFormEditUserIsOpen] = useState(false)

    const formatedUsers = users.map((user) => ({
        ...user,
        lastLogAt:
            user.logs.length > 0
                ? user.logs[0].createdAt + " : " + user.logs[0].event
                : "",
    }))

    const tableColumns = [
        {
            name: "edit",
            text: "Edit.",
            // eslint-disable-next-line react/no-unstable-nested-components
            // cellHtml: (eleve: any) => <ButtonEleveEdit eleve={eleve} />,
        },
        { name: "id", text: "ID" },
        { name: "username", text: "username" },
        { name: "email", text: "email" },
        { name: "lastLogAt", text: "lastLogAt" },

        {
            name: "emailVerified",
            text: "email Verified",
            cellClassName: (user: any) => user.emailVerified && "text-success",
            // cellHtml: (eleve: any) => booleanToString(eleve.actif),
        },
        { name: "dateInscription", text: "Inscription" },
        { name: "canUseCmzEdt", text: "Use Edt" },
        { name: "canUseCmzDp", text: "Use Dp" },

        {
            name: "etabs",
            text: "etabs",
            cellHtml: (user: any) =>
                user.personnels &&
                user.personnels.length > 0 && (
                    <ul className="m-0">
                        {user.personnels.map((perso: any) => (
                            <li key={"perso-" + perso.id} className="p-0">
                                {perso.etablissement.nom}
                            </li>
                        ))}
                    </ul>
                ),
        },
        {
            name: "logs",
            text: "Logs",
            cellHtml: (user: any) => user.logs?.length,
        },
        /*
        {
            name: "logs2",
            text: "logs2",
            cellHtml: (user: any) =>
                user.logs &&
                user.logs.length > 0 && (
                    <ul className="m-0">
                        {user.logs.map((log: any) => (
                            <li key={"perso-" + log.id} className="p-0">
                                {log.createdAt} - {log.event}
                            </li>
                        ))}
                    </ul>
                ),
        },
        {
            name: "lastLog",
            text: "lastLog",
            cellHtml: (user: any) =>
                user.logs &&
                user.logs.length > 0 && (
                    <>
                        {user.logs[0].createdAt} - {user.logs[0].event}
                    </>
                ),
        },
        */
    ]

    const funcRowClassName = (item: any) => {
        let className = ""
        if (!item.canUseCmzEdt) {
            className += " bg-secondary "
        }
        return className
    }

    return (
        <ZPageSection>
            <h2>{formatedUsers.length} user(s)</h2>
            <div className="zzAutoScrollX">
                <ZTable
                    className="table table-bordered table-xs table-striped table-hover "
                    columns={tableColumns}
                    data={formatedUsers}
                    funcRowClassName={funcRowClassName}
                />
            </div>
        </ZPageSection>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS
