/* eslint-disable no-unused-vars */ // !!!!!!!!!!
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React, { useState } from "react"
import Button from "react-bootstrap/Button"
import { IcoDelete, IcoFalse, IcoDanger } from "styles/icones"
import ZxModal from "ui/ZxModal"
import { FormContext } from "./ZForm"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function ZDeleteForm() {
    const [confirmCheck, setConfirmCheck] = useState(false)
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    // const navigate = useNavigate()

    return (
        <FormContext.Consumer>
            {({
                setDeleteFormIsOpen,
                handleDelete,
                isSubmitting,
                deleteTitle,
                deleteConfirmText,
            }) => (
                <ZxModal styles={{ width: "500px" }}>
                    <h1 className="text-danger mb-5 text-center">
                        <IcoDanger /> {deleteTitle}
                    </h1>
                    {deleteConfirmText !== "" && (
                        <label>
                            <input
                                type="checkbox"
                                checked={confirmCheck}
                                onChange={() => setConfirmCheck(!confirmCheck)}
                                className="me-1"
                            />
                            {deleteConfirmText}
                        </label>
                    )}

                    {isSubmitting ? (
                        <h2>Loading</h2>
                    ) : (
                        <div className=" col-12 mt-5">
                            <Button
                                variant="secondary"
                                title="Annuler"
                                onClick={() => setDeleteFormIsOpen(false)}
                                disabled={loading}
                            >
                                <IcoFalse /> Annuler
                            </Button>
                            <Button
                                autoFocus
                                variant="danger"
                                className="float-end"
                                title="Supprimer cet établissement"
                                onClick={handleDelete}
                                disabled={
                                    (!confirmCheck &&
                                        deleteConfirmText !== "") ||
                                    loading
                                }
                            >
                                <IcoDelete /> Supprimer
                            </Button>
                        </div>
                    )}
                </ZxModal>
            )}
        </FormContext.Consumer>
    )
}
