// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { ZField } from "uiZ/zForm"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function EdtFormInner() {
    return (
        <div className="row col-12">
            <ZField autoFocus name="pseudo" label="Pseudo" type="text" />
            <ZField
                autoFocus
                name="nbreHeuresHebdo"
                label="Nombre d'heures par semaine"
                type="number"
            />

            <ZField
                name="nbreNuitsHebdo"
                label="Nombre de nuits par semaine"
                type="number"
            />

            <ZField
                name="quotite"
                label="Quotité (à titre indicatif pour l'instant)"
                type="number"
                step={0.01}
            />
            <ZField name="fonction" label="Fonction" type="text" />
            <ZField name="couleur" label="Couleur" type="color" />
        </div>
    )
}
