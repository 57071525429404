/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React, { useEffect } from "react"
import { useAppSelector, useAppDispatch } from "utils/store"
import { NavLink } from "react-router-dom"
import { updateHeureNow } from "features/timetable/utils/timetableReducer"
import TimetableGlobalContainer from "./TimetableGlobalContainer"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
/*
 *  Timetable = Copie de Edt.js
 *  Timetable = Timetable.header + Timetable."""content"""
 *
 *
 *
 */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
export default function Timetable() {
    const { edt, membres, modeHoraires, modeHPS } = useAppSelector(
        (state) => state.timetable
    )

    const dispatch = useAppDispatch()

    useEffect(() => {
        // TODO : modifier - trop de re-render
        // TODO : suelement si today
        dispatch(updateHeureNow())
        const interval = setInterval(() => {
            dispatch(updateHeureNow())
        }, 10000)
        return () => clearInterval(interval)
    }, [])

    console.log("Timetable-render")

    if (!edt || edt.horaireEdts?.length === 0) {
        return (
            <div className="bg-info m-2 p-2">
                <h4>
                    Impossible d'afficher la grille des{" "}
                    {modeHoraires ? "horaires" : "postages"} car:
                </h4>
                <h3>Il n'y a aucun horaire pour cet EDT</h3>
                {edt && (
                    <p>
                        Commencez par ajouter{" "}
                        <NavLink
                            className="card-headerX"
                            to={"/edt/" + edt.id + "/horaires"}
                        >
                            les horaires de cet EDT.
                        </NavLink>
                    </p>
                )}
            </div>
        )
    }

    if (!modeHPS) {
        if (!membres || membres.length === 0) {
            return (
                <div className="bg-info m-2 p-2">
                    <h4>
                        Impossible d'afficher la grille des{" "}
                        {modeHoraires ? "horaires" : "postages"} car:
                    </h4>
                    <h3>Il n'y a aucun personnel pour cet EDT</h3>
                    <p>
                        Commencez par ajouter le personnel qui doit figurer sur
                        cet EDT.
                    </p>
                </div>
            )
        }

        const countPersonnelsWithHoraire = membres.filter(
            (mbr) => mbr.horaires.length > 0
        ).length

        if (!modeHoraires && countPersonnelsWithHoraire === 0) {
            return (
                <div className="bg-info m-2 p-2">
                    <h4>Impossible d'afficher la grille des postages car:</h4>
                    <h3>Le personnel de l'EDT n'a pas d'horaire de travail</h3>
                    <p>
                        Commencez par ajouter des{" "}
                        <NavLink
                            className="card-headerX"
                            to={"/edt/" + edt.id + "/membres"}
                        >
                            horaires de travail
                        </NavLink>{" "}
                        aux membres de l'EDT avant de placer les postages
                    </p>
                </div>
            )
        }
    }
    return <TimetableGlobalContainer />
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS
