/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React, { useState } from "react"
// import { SliderPicker } from "react-color"
import { SketchPicker } from "react-color"
// import './ZFormInput.css';
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function FormField({
    name,
    label,
    hint = null,
    type = "text",
    formik,
    className = "",
    inputProps = null,
    selectOptions = null,
    step = null,
    disabled = null,
    ...props
}) {
    return (
        <div className={className + " row col-12 pb-4"} {...props}>
            <label htmlFor={name}>{label}</label>
            <CustomInput
                id={name}
                name={name}
                type={type}
                formik={formik}
                selectOptions={selectOptions}
                step={step}
                disabled={disabled}
                {...inputProps}
            />
            {hint ? <div className="text-info">{hint}</div> : null}
            {formik.errors[name] ? (
                <div className="text-danger">{formik.errors[name]}</div>
            ) : null}
        </div>
    )
}

function CustomInput({
    name,
    type,
    formik,
    inputProps,
    selectOptions,
    step,
    disabled,
}) {
    if (type === "select") {
        return (
            <select
                id={name}
                name={name}
                type={type}
                onChange={formik.handleChange}
                value={formik.values[name]}
                disabled={disabled}
                {...inputProps}
            >
                {selectOptions.map((opt) => (
                    <option value={opt.value} key={`sel-${name}-${opt.value}`}>
                        {opt.text}
                    </option>
                ))}
            </select>
        )
    }
    if (type === "color") {
        const [displayColorPicker, setDisplayColorPicker] = useState(false)

        const popover = {
            position: "absolute",
            zIndex: "2",
            width: "300px",
        }
        const cover = {
            position: "fixed",
            top: "0px",
            right: "0px",
            bottom: "0px",
            left: "0px",
        }
        return (
            <>
                <div
                    style={{
                        backgroundColor: formik.values[name],
                        width: "50px",
                        height: "30px",
                    }}
                    className="border p-1 mb-2"
                    onClick={() => setDisplayColorPicker(true)}
                >
                    {" "}
                </div>

                {displayColorPicker && (
                    <div style={popover}>
                        <div
                            style={cover}
                            onClick={() => setDisplayColorPicker(false)}
                        />

                        <SketchPicker
                            color={formik.values[name]}
                            disableAlpha
                            onChange={(e) => {
                                formik.setFieldValue(name, e.hex)
                            }}
                            name={name}
                            {...inputProps}
                        />
                    </div>
                )}
            </>
        )
    }

    return (
        <input
            id={name}
            name={name}
            type={type}
            onChange={formik.handleChange}
            // onChange={() => formik.setValue({ ..."aaaaaaaa" })}
            value={formik.values[name]}
            step={step}
            disabled={disabled}
            {...inputProps}
        />
    )
}
