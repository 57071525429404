/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import styled from "styled-components"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function PosteItem({ poste, setSelectedPoste }) {
    return (
        <StyledPoste
            className="poste col-12"
            style={{ backgroundColor: poste.couleur }}
            title={"poste #" + poste.id + " : " + poste.nom}
            onClick={() => setSelectedPoste(poste)}
        >
            <div className="poste-nom">{poste.nom}</div>
            {poste.description && (
                <div className="poste-description">{poste.description}</div>
            )}
        </StyledPoste>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS

const StyledPoste = styled.div`
    border: 1px solid black;
    padding: 3px;
    font-size: 0.9em;
    margin-bottom: 3px;

    &:hover {
        cursor: pointer;
        color: darkorange;
        border-bottom: 1px solid blue;
    }

    .poste-nom {
        // background-color: green;
        // font-size: 1.2em;
    }

    .poste-description {
        background-color: white;
        font-size: 0.7em;
        padding: 2px;
    }
`
