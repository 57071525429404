/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React, { useState } from "react"
import { Button } from "react-bootstrap"
import { IcoEdit } from "styles/icones"
import { useAppSelector, useAppDispatch } from "utils/store"
import { setPostageForm } from "features/timetable/utils/timetableReducer"
// import styled from 'styled-components'
import LayoutItem from "../layouts/LayoutItem"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function ItemPostage({
    postage,
    membre /* , setItemOvered = null */,
}) {
    const { postes, locked, orientation } = useAppSelector(
        (state) => state.timetable
    )
    const dispatch = useAppDispatch()
    // let marginLeft = "5%"
    // let width = "90%"
    // postage.poste ne contient que l'id du poste. On récupère le poste dans le redux
    const poste = postes.filter((p) => p.id === postage.poste.id)[0]

    const [isOver, setIsOver] = useState(false)

    function handleOnClick() {
        const editablePostage = {
            ...postage,
            posteId: postage.poste.id,
            membreId: membre.id,
            userWorkspacePseudo: membre.pseudo,
        }
        // console.log("editablePostage", editablePostage)
        dispatch(setPostageForm(editablePostage))
    }

    function handleOnMouseMove(ev) {
        // console.log("handleOnMouseMove", ev)
        // ev.preventDefault() // ne sert a rien
        ev.stopPropagation() // ne sert a rien
        // setItemOvered(true)
        setIsOver(true)
    }
    /*
    if (postage.semaine === "A") {
        width = "45%"
    } else if (postage.semaine === "B") {
        width = "45%"
        marginLeft = "50%"
    }
*/
    const styles =
        orientation === "HORIZONTAL"
            ? {
                  backgroundColor: poste.couleur,
                  top: postage.semaine === "B" ? "50%" : "5%",
                  height: postage.semaine ? "45%" : "90%",
                  textAlign: "center",
              }
            : {
                  backgroundColor: poste.couleur,
                  marginLeft: postage.semaine === "B" ? "50%" : "5%",
                  width: postage.semaine ? "45%" : "90%",
                  textAlign: "center",
                  // cursor: locked ? "pointer" : "inherit",
                  // cursor: "pointer",
              }

    return (
        <LayoutItem
            key={"postage-" + postage.id || "new"}
            style={{ fontSize: "0.6em" }}
            heureDebut={postage.heureDebut}
            heureFin={postage.heureFin}
            isLoading={postage.isLoading}
            title={postage.heureDebut + " - " + postage.heureFin}
            // onClick={() => handleOnClick()}
            onMouseMove={(ev) => handleOnMouseMove(ev)}
            onMouseLeave={() => setIsOver(false)}
            styles={styles}
        >
            {!locked && isOver ? (
                <Button
                    variant="warning"
                    className="btn-sm "
                    onClick={() => handleOnClick()}
                    title="Modifier/Supprimer ce postage"
                >
                    <IcoEdit />
                </Button>
            ) : (
                <>
                    {poste.slug}{" "}
                    {postage.isLoading && (
                        <div className="loadingText text-danger">...</div>
                    )}
                </>
            )}
        </LayoutItem>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS
