/* eslint-disable no-alert */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

import { createSlice } from "@reduxjs/toolkit"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
/*
 *
 *
 */

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export interface IHoraireMembre {
    id: number | null
    jour: string //             ex : "Mardi"    // Todo : Enum
    semaine: string | null //   "A" | "B"   // Todo : Enum
    heureDebut: string //       ex : "14:55"
    heureFin: string //         ex : "16:10"
    infos: string | null
    membreId: number | null //  seulement pour le form UWHoraire
    uwPseudo: string | null //  seulement pour le form UWHoraire
    fetchStatus: null | "FETCHING" | "SUCCESS" | "ERROR"
}

interface User {
    id: string
    pseudo: string // pseudo = etabUser.pseudo
}

export interface IMembreForm {
    id: number | null
    pseudo: string
    couleur: string
    fonction: string | null
    horaires: Array<IHoraireMembre>
    nbreHeuresHebdo: number
    nbreNuitsHebdo: number
    quotite: number
    fetchStatus: null | "FETCHING" | "SUCCESS" | "ERROR"
    user?: User
}

interface InitialState {
    formMembre: IMembreForm | null
    formHoraireMembre: IHoraireMembre | null

    //  formAddUsersToWorkspace
}

// ■   ■   ■   ■   ■   ■   ■   ■   ■   ■   ■   ■   ■   ■   ■   ■   ■   ■   ■

const initialState: InitialState = {
    formMembre: null,
    formHoraireMembre: null,

    //  formAddUsersToWorkspaceStatus: null,
}

// ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■

const slice = createSlice({
    name: "membres",
    initialState,
    reducers: {
        setFormMembre: (state, action) => {
            state.formMembre = action.payload
        },
        setFormHoraireMembre: (state, action) => {
            state.formHoraireMembre = action.payload
        },
    },
})

export default slice.reducer

// ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■

// *==========================================================

export const { setFormMembre, setFormHoraireMembre } = slice.actions
