/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { NavLink } from "react-router-dom"
import styled from "styled-components"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function MesEtabsListeItem({ etab }) {
    return (
        <StyledContainer className="row col-sm-12 col-md-4 col-lg-3 col-xl-2">
            <div className="etabItem ">
                <NavLink className="card-headerX" to={"/etab/" + etab.id}>
                    <h5>{etab.nom}</h5>
                </NavLink>
            </div>
        </StyledContainer>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS

const StyledContainer = styled.div`
    padding: 5px;

    .etabItem {
        text-decoration: none;
        border: 1px solid #0b5b95;
        min-height: 60px;
        display: flex;
        text-align: center;
        padding: 5px;
        a {
            text-decoration: none;
            align-self: center;
            width: 100%;
        }
        &:hover {
            a {
                color: white;
            }

            background-color: #0b5b95;
        }

        .card-header {
            border-bottom: 1px solid #0b5b95;
        }
    }
`
