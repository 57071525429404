/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { useAppSelector } from "utils/store"
import {
    calcDureeMinutesFromStrHeureDebutToStrHeureFin,
    minutesToStringHeuresMinutes,
} from "utils/fonctions"
// import styled from "styled-components"

import { IcoDanger } from "styles/icones"
import LayoutHeader from "../layouts/LayoutHeader"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function EdtUserHeader({ membre }) {
    const { jour, modeHoraires } = useAppSelector((state) => state.timetable)
    const dureeTotaleMinutes = membre.horaires.reduce((memo, hor) => {
        let duree = calcDureeMinutesFromStrHeureDebutToStrHeureFin(
            hor.heureDebut,
            hor.heureFin
        )
        if (hor.semaine !== null && hor.semaine !== "") {
            duree /= 2
        }
        return memo + duree
    }, 0)
    const dureeTotaleString = minutesToStringHeuresMinutes(
        dureeTotaleMinutes,
        "h",
        true
    )

    return (
        <LayoutHeader couleur={membre.couleur}>
            <div className="pseudo">{membre.pseudo}</div>

            {modeHoraires ? (
                <small className="dureeTotale">
                    {dureeTotaleString}
                    {membre.nbreHeuresHebdo && (
                        <>
                            <span className="nbreHeuresHebdoTheo ">
                                /{membre.nbreHeuresHebdo}
                            </span>
                            {dureeTotaleMinutes !==
                                membre.nbreHeuresHebdo * 60 && (
                                <IcoDanger className="text-danger" />
                            )}
                        </>
                    )}
                </small>
            ) : (
                <div className="horaires">
                    {membre &&
                        membre.horaires &&
                        membre.horaires
                            .filter((hor) => hor.jour === jour)
                            .map((hor) => (
                                <div key={"hor-" + hor.id}>
                                    {hor.heureDebut} / {hor.heureFin}{" "}
                                    {hor.semaine !== null && (
                                        <span
                                            className={
                                                "badge badge-sem badge-sem-" +
                                                hor.semaine
                                            }
                                        >
                                            sem. {hor.semaine}
                                        </span>
                                    )}
                                </div>
                            ))}
                </div>
            )}
        </LayoutHeader>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS
