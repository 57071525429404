// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React, { useState } from "react"
import { useAppSelector, useAppDispatch } from "utils/store"
import styled from "styled-components"

import {
    createOrUpdatePostage,
    deletePostage,
    setPostageForm,
} from "features/timetable/utils/timetableReducer"

import ZxModal from "ui/ZxModal"
import ZForm from "ui/ZForm/ZForm"
import PostageFormPostes from "./PostageFormPostes"
import PostageFormSemaines from "./PostageFormSemaines"
import PostageFormHeures from "./PostageFormHeures"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function PostageForm() {
    const { postageForm, postes } = useAppSelector((state) => state.timetable)
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useAppDispatch()
    // console.log("PostageForm", postage)

    return (
        <ZxModal styles={{ maxWidth: "800px" }}>
            <StyledForm>
                {/* **{postage.jour}** */}
                <ZForm
                    initialValues={postageForm}
                    handleSubmit={handleSubmit}
                    handleCancel={() => dispatch(setPostageForm(null))}
                    handleDelete={handleDelete}
                    title={
                        postageForm.id
                            ? `Modifier le postage #${postageForm.id} de ${postageForm.userWorkspacePseudo} `
                            : "Ajouter un postage"
                    }
                    isLoading={isLoading}
                >
                    {/* 
                    <PostageFormJours />
                    */}
                    <PostageFormHeures />
                    <PostageFormSemaines />
                    {/*                   
                    <ZFormInput label="commentaire" name="commentaire" />
                    */}
                    <PostageFormPostes
                        postes={postes}
                        currentPosteId={postageForm.posteId}
                        // handleSubmit={handleSubmit}
                    />
                </ZForm>
            </StyledForm>
        </ZxModal>
    )

    function handleSubmit(postageFrm) {
        if (!postageFrm.posteId) {
            alert("vous devez choisir un poste")
            return
        }
        if (postageFrm.heureDebut >= postageFrm.heureFin) {
            alert("L'heure de fin doit être supérieure à celle de début")
            return
        }
        setIsLoading(true)

        dispatch(createOrUpdatePostage(postageFrm))
        setIsLoading(false)
        dispatch(setPostageForm(null))
    }

    function handleDelete(postage) {
        if (
            !window.confirm("Voulez-vous définitivement supprimer ce Postage ?")
        ) {
            return
        }
        // alert("TODO - postage/form/PostageForm > handleDelete")
        dispatch(deletePostage(postage.id, postage.membreId))
        dispatch(setPostageForm(null))
    }
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS

const StyledForm = styled.div``
