/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import styled from "styled-components"
import AboutCardProgressItemAction from "./AboutCardProgressItemAction"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function AboutCardProgressItem({ item, className, showDone }) {
    return (
        <StyledItem className={className}>
            <h4>
                <small>page:</small> {item.page}
            </h4>
            <ul>
                {item.actions.map((action, a) => {
                    if (action.status === "DONE" && !showDone) {
                        return null
                    }

                    return (
                        <AboutCardProgressItemAction
                            key={"action" + a}
                            action={action}
                            className="mb-1 border-bottom border-primary "
                        />
                    )
                })}
            </ul>
            <p className="card-text">{item.content}</p>
        </StyledItem>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS

const StyledItem = styled.div`
    // background-color: yellow;
    ul {
        list-style-type: none;
        padding-left: 0px;
    }
`
