// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { Button } from "react-bootstrap"
import { IcoFalse } from "styles/icones"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

type Props = {
    handleCancel: any
}

export default function ZFormButtonCancel({ handleCancel }: Props) {
    return (
        <Button variant="danger" onClick={handleCancel}>
            <IcoFalse /> Annuler
        </Button>
    )
}
