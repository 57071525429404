/* eslint-disable react/no-array-index-key */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React, { useState } from "react"
import { Button } from "react-bootstrap"
import AboutCardProgressItem from "./AboutCardProgressItem"
import { progressItems } from "./data/aboutCardProgressData"
// import styled from "styled-components"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function AboutCardProgress() {
    const [showDone, setShowDone] = useState(false)
    return (
        <div className="card border-primary">
            <h5 className="card-header bg-dark text-white">
                Progress (fonctionnalités)
                <Button
                    onClick={() => setShowDone(!showDone)}
                    variant={showDone ? "secondary" : "light"}
                    className="float-end btn-sm"
                >
                    {showDone ? "Masquer" : "Afficher"} les taches accomplies
                </Button>
            </h5>

            <div className="card-body">
                <div className="card-text row">
                    {progressItems.map((item, i) => (
                        <AboutCardProgressItem
                            key={"progress" + i}
                            item={item}
                            className="card border-primary mb-1 col-4 "
                            showDone={showDone}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS

// const StyledItem = styled.div``
