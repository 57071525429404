/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { IcoTrue, IcoFalse } from "styles/icones"
// import { Button } from "react-bootstrap"

// import { NavLink } from "react-router-dom"
import ButtonPersonnelEdit from "../crud/ButtonPersonnelEdit"
import ButtonEtabUserMail from "../mails/ButtonEtabUserMail"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function PersonnelsTableTr({ personnel, userIsAdminEtab }) {
    return (
        <tr className={personnel.isActif === false ? "bg-secondary" : ""}>
            {userIsAdminEtab && (
                <td>
                    <ButtonPersonnelEdit etabUser={personnel} />
                </td>
            )}
            <td style={{ backgroundColor: personnel.couleur }}>
                {personnel.pseudo}
            </td>
            <td>{personnel.fonction}</td>
            <td>{personnel.isAdminEtablissement ? <ITrue /> : <IFalse />}</td>

            {/*
            {userIsAdminEtab && (
                <td>{personnel.demandeAcceptee && <ITrue />}</td>
            )}
            */}

            {/* <td>{personnel.email}</td> */}
            {userIsAdminEtab && (
                <td>
                    {personnel.user ? (
                        <>
                            {personnel.user.email && (
                                <span className="text-primary">
                                    <ITrue /> {personnel.user.email}
                                </span>
                            )}
                            &nbsp;({personnel.user.username})
                            {personnel.email !== personnel.user.email && (
                                <small className="text-danger">
                                    <br />
                                    L&apos;email ne correspond pas: (
                                    {personnel.email}) #{personnel.id}
                                </small>
                            )}
                        </>
                    ) : personnel.email ? (
                        <>
                            {personnel.invitationSendAt && (
                                <small>
                                    Invitation envoyée le{" "}
                                    {personnel.invitationSendAt} à{" "}
                                    <u>{personnel.email}</u>
                                </small>
                            )}
                            <ButtonEtabUserMail
                                sendAgain={personnel.invitationSendAt !== null}
                                className="btn-sm ms-1 me-1"
                                eu={personnel}
                            />
                        </>
                    ) : (
                        <small>
                            Ajoutez une adresse mail pour inviter cet
                            utilisateur.
                        </small>
                    )}
                </td>
            )}
        </tr>
    )
}

function ITrue() {
    return <IcoTrue className="text-success" />
}
function IFalse() {
    return <IcoFalse className="text-danger" />
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS
