/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"

import { useAppSelector } from "utils/store"
import styled from "styled-components"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function LayoutSection({ styles, children }) {
    const { orientation, hSectionHeight } = useAppSelector(
        (state) => state.timetable
    )
    return (
        <StyledLayoutSection
            className="d-flex"
            style={styles}
            orientation={orientation}
            hSectionHeight={hSectionHeight}
        >
            {children}
        </StyledLayoutSection>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS

const StyledLayoutSection = styled.div`
    margin: 0px;
    margin-top: 0px;
    padding: 4px;
    padding-top: 0px;
    background-color: black;
    border-right: 1px solid black;

    //   background-color: yellow; // **** debugOnly
    //   border: 2px solid blue; // **** debugOnly
    //   padding: 10px; // **** debugOnly
    //    margin: 5px; // **** debugOnly
    /* */
    /* 
        background-color: red !important;
        order: 4px dashed purple;
        padding:10px!important;
        position: relative;
	    overflow-y: auto;
    */

    ${(props) =>
        props.orientation === "HORIZONTAL"
            ? `
                
                flex-direction: row;
                width: 100%;
                height: 50px;
                // height: ${props.hSectionHeight + "px"};
                `
            : `
                /* height:  400px; / * $ {props.containerHeight+"px" }; */
                /* width: $ {props.vSectionWidth +  "px"}; */
                flex-direction: column;
                height: 100%;   
                width: 400px;
        `}
`
