/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React, { useState } from "react"
import { useParams } from "react-router-dom"
import { dev } from "utils/constants"
import { randomHexColorLight } from "utils/fonctions"
import {
    ZPageContent,
    ZPageSection,
    ZPageSectionTodo,
    ZPageSectionHelp,
    ZYoutube,
} from "uiZ/zPage"
import { PagePostesHeader, PosteForm, PostesListe } from "features/postes"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
// TODO importer from.....
/*
type Edt = {
    id: number | null
    nom: String
    groupe: String
    couleur: String
    description: String
    etablissementId: number
    etablissement: { id: number }
    postes: any
}
*/
type Poste = {
    id: null
    nom: string
    groupe: string
    couleur: string
    description: string
    etablissementId: number | undefined
}
type Props = {
    edt: any
    setEdt: any
}

export default function Postes({ edt, setEdt }: Props) {
    const edtId = Number(useParams().id) || 0
    if (edtId <= 0) {
        return <h1 className="text-danger">Erreur url</h1>
    }

    const [selectedPoste, setSelectedPoste] = useState<Poste | null>(null) // Le poste à créer ou éditer

    const addPosteClick = () => {
        console.log("AddPosteClick")
        const newPoste = {
            id: null,
            nom: "",
            groupe: "",
            couleur: randomHexColorLight(),
            description: "",
            etablissementId: edt?.etablissement.id,
        }
        setSelectedPoste(newPoste)
        console.log(newPoste)
    }

    return (
        <>
            {selectedPoste && (
                <PosteForm
                    poste={selectedPoste}
                    workspace={edt}
                    setWorkspace={setEdt}
                    handleCancel={() => setSelectedPoste(null)}
                />
            )}

            <PagePostesHeader addPosteClick={addPosteClick} />

            <ZPageContent>
                {edt && (
                    <ZPageSection>
                        <PostesListe
                            postes={edt.postes}
                            setSelectedPoste={setSelectedPoste}
                        />
                    </ZPageSection>
                )}
                <div className="row col-12">
                    <ZPageSectionHelp className="col-12 col-md-6">
                        <ZYoutube
                            src="https://www.youtube.com/embed/IOYOdFV4sf0"
                            title="cmzEDT: Editez la liste des postes de l'EDT"
                        />
                    </ZPageSectionHelp>

                    <ZPageSectionTodo className="col-12 col-md-6">
                        <h2>Améliorations à venir:</h2>
                        <ul>
                            <li>Meilleur gestion groupes</li>
                            <li>Dupliquer des postes</li>
                            <li>
                                Importer les postes d&apos;un autre EDT ou
                                depuis une liste par defaut.
                            </li>{" "}
                            <li>
                                On ne peut pas supprimer un poste, si des
                                postages de ce poste ont déjà été placés.
                            </li>
                        </ul>

                        {dev && (
                            <>
                                <h2>Dev:</h2>
                                <ul>
                                    <li>Random color pour poste create</li>
                                    <li>xxxxxxxx</li>
                                    <li>xxxxxxxx</li>
                                </ul>
                            </>
                        )}
                    </ZPageSectionTodo>
                </div>
            </ZPageContent>
        </>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS
