/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import Button from "react-bootstrap/Button"
import { useAppDispatch } from "utils/store"
import { setFormMembre } from "features/membres/utils/membresReducer"
import { IcoEdit } from "styles/icones"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function ButtonUWUpdate({ uw, className = "" }) {
    const dispatch = useAppDispatch()

    function editClick() {
        dispatch(setFormMembre(uw))
    }

    return (
        <Button
            className={"btn-sm m-0 w-100 " + className}
            variant="warning"
            title="Modifier cet utilsateur"
            onClick={() => editClick(uw)}
        >
            <IcoEdit />
        </Button>
    )
}
