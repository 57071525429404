/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import Button from "react-bootstrap/Button"
import { useAppDispatch } from "utils/store"
import { randomHexColor } from "utils/fonctions"
import { setFormPersonnel } from "features/etabs/utils/etabShowReducer"
import { FaPlus } from "react-icons/fa"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function ButtonPersonnelCreate({ className = "" }) {
    const dispatch = useAppDispatch()

    // const { workspace } = useAppSelector((state) => state.hPS)

    function handleOnClick() {
        const etabUser = {
            id: null,
            user: null,
            pseudo: "",
            couleur: randomHexColor(),
            fonction: "", // ??????????????????????????????????????
            email: "",
            isAdminEtablissement: false,
            isAdminPostages: false,
            isAdminUsers: false,
            demandeAcceptee: false,
            isActif: true,
        }
        dispatch(setFormPersonnel(etabUser))
    }

    return (
        <Button
            className={`btn-primary btn-sm ${className}`}
            onClick={() => handleOnClick()}
            title="Ajouter un personnel"
        >
            <FaPlus /> Ajouter un personnel
        </Button>
    )
}
