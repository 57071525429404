/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { ZPageSectionTodo } from "uiZ/zPage"
import { dev } from "utils/constants"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function MesEtabsTodo({ className }) {
    return (
        <ZPageSectionTodo className={className}>
            <p>
                Bien que fonctionnel, cmzEDT est en cour de développement.
                <br />
                <br />
                N&apos;hésitez pas à me contacter (<b>cmzedt@zedixi.com</b>) si
                vous rencontrez un problème, un bug ou si vous avez des
                suggestions d&apos;améliorations.
            </p>
            {dev && (
                <>
                    <h2>Dev:</h2>
                    <ul>
                        <li>
                            Bouton: Accepter la demande
                            <br />( Quand un CPE a ajouté un personnel avec un
                            mail qui est déja lié à un compte )
                        </li>
                        <li>Nombre limite d&apos;établissements</li>
                    </ul>
                    <h3>---Global---:</h3>
                    <ul>
                        <li>HeaderMenu fix</li>
                        <li>Préférences utilisateur</li>
                        <li>Yup pour validation</li>
                        <li>
                            Menu communication (Forum/Mail ou message au
                            webmaster)
                        </li>
                        <li>
                            Timeline
                            <ul>
                                <li>afficher heure si pas de semaine</li>
                                <li>
                                    afficher heure si pas de semaine pour la
                                    periode nuit
                                </li>
                            </ul>
                        </li>
                        <li>Personnel inactif: bloquer acces EDT</li>
                        <li>ZField Select OR Precisez</li>
                        <li>EdtShow "du ... au ..."</li>
                        <li>edt.isPublished n'est pas utilisé</li>
                        <li>utiliser le meme navHeader pour le public</li>
                        <li>cacher disabled nuit, BT24h si internat</li>
                        <li>xxxxxxxxxxxx</li>
                        <li>xxxxxxxxxxxx</li>
                    </ul>
                    <h3>---ZForm---:</h3>
                    <ul>
                        <li>Periode (manque api)</li>
                        <li>
                            HoraireMembre (borderl entre reducer timetime & uw)
                        </li>
                        <li>Poste</li>
                        <li>Postage</li>
                    </ul>
                    <h3>---ZPage---:</h3>
                    <ul>
                        <li>PagePostes (useEffect)</li>
                        <li>PageUsersAdmin (useEffect)</li>
                        <li>PageUserShow (useEffect)</li>
                        <li>xxxxxxxxxxxx</li>
                        <li>xxxxxxxxxxxx</li>
                    </ul>
                </>
            )}
        </ZPageSectionTodo>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS
