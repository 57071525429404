// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
// import React from "react"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export const status = {
    pending: "PENDING",
    canceled: "CANCELED",
    processing: "PROCESSING",
    checking: "CHECKING",
    done: "DONE",
}

export const progressItemsEtabShow = [
    {
        page: "Etablissement Show",
        actions: [
            {
                title: "Modifier l'établissement",
                status: status.done,
            },
            {
                title: "Supprimer l'établissement",
                status: status.done,
            },
            {
                title: "Ajouter un nouvel EDT",
                status: status.done,
            },
            {
                title: "Ajouter un personnel (à l'établissement)",
                status: status.done,
            },
            {
                title: "Associer un user à un personnel",
                content: "Envoyer mail d'invition",
                status: status.done,
            },
            {
                title: "Supprimer un personnel",
                status: status.done,
            },
            {
                title: "Modifier un personnel",
                status: status.done,
            },
            {
                title: "Personnel : Demande Acceptee",
                content: "Ca marche ? C'est util ?",
                status: status.checking,
            },
        ],
    },
]
