/* eslint-disable react/no-array-index-key */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
// import styled from "styled-components"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function AboutCardBacklog() {
    const backlogItems = [
        { content: "Carole veut s'inscrire sur l'application." },
        {
            content:
                'Apres l\'inscription, Carole cliquera sur "Créer un 1er établissement" pour ajouter JCVG.',
        },
        {
            content:
                "Si carole supprime un EtablissementUser, il faut aussi supprimer tous les UserWorkspaces correspondant.",
        },
        { content: "Carole veut " },
    ]

    return (
        <div className="card border-primary">
            <h5 className="card-header bg-primary text-white">
                Product backlog <small>(todo list)</small>
            </h5>
            <div className="card-body">
                <div className="card-text">
                    {backlogItems.map((item, i) => (
                        <div
                            key={"backlog" + i}
                            className="card border-primary mb-1"
                        >
                            <div className="card-body p-1">
                                <p className="card-text">{item.content}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS

// const StyledItem = styled.div``
