// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import { createSlice } from "@reduxjs/toolkit"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

interface Etab {
    id: number
    nom: string
    slug: string
    genre: string | null
}

interface InitialState {
    etabs: Array<Etab>
}
const initialState: InitialState = {
    etabs: [],
}

// ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■
// Slice

const slice = createSlice({
    name: "mesEtabs",
    initialState,
    reducers: {
        fetchMesEtabsSuccess: (state, action) => {
            state.etabs = action.payload
        },
    },
})
export default slice.reducer

// ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■

// Actions

const { fetchMesEtabsSuccess } = slice.actions

export const mesEtabsFetchSuccess =
    (etabs: Etab[]) => async (dispatch: any) => {
        dispatch(fetchMesEtabsSuccess(etabs))
    }
