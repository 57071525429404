/* eslint-disable react/no-unstable-nested-components */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import LogsUser from "./LogsUser"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

interface User {
    id: number
    username: string
}

interface Log {
    id: number
    createdAt: string
    event: string
    user?: User
}

type Props = {
    logs: Log[]
    minDate: string
    maxDate: string
    className: string
}
export default function LogsPeriode({
    logs,
    minDate,
    maxDate,
    className,
}: Props) {
    const filteredLogs = logs.filter(
        (log) => log.createdAt >= minDate && log.createdAt <= maxDate // && log.user?.id !== 1 && log.user?.id !== 4
    )

    const differentUsersIds = filteredLogs
        .map((item) => item.user?.id)
        .filter((value, index, self) => index && self.indexOf(value) === index) // "index &&...." pour enlever les logs sans user (loginFail)
    // console.log("differentUsersIds", differentUsersIds)

    return (
        <div className={" border border-primary col-2 " + className}>
            <u>
                <i>{minDate}</i> ... <i>{maxDate}</i>:
            </u>
            {differentUsersIds.map((userId) => {
                const userLogs = filteredLogs.filter(
                    (log) => log.user?.id === userId
                )
                const { user } = userLogs[0]
                if (user && userLogs) {
                    return (
                        <LogsUser
                            key={"log-diff-userId-" + userId}
                            user={user}
                            userLogs={userLogs}
                        />
                    )
                }
                return null
            })}
        </div>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS
