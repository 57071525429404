// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { ZPageSectionTodo } from "uiZ/zPage"
import { dev } from "utils/constants"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function SectionTodoMembres() {
    return (
        <ZPageSectionTodo className="col-12 col-md-6">
            <h2>Améliorations à venir:</h2>
            <ul>
                <li>
                    Meilleur gestion du temps de travail du personnel:
                    <ul>
                        <li>
                            Valeur par defaut du nombre d&apos;heures (temps
                            plein, mi-temps...)
                        </li>
                        <li>
                            Pour l&apos;instant, la quotité et le nombre de nuit
                            ne sont renseignés qu&apos;à titre indicatif.
                        </li>
                    </ul>
                </li>
                <li>
                    Possibilité de placer des contraintes pour les horaires des
                    AED (ex: &quot;Ne peut pas travailler le jeudi ou apres
                    17h&quot;)
                </li>
            </ul>

            {dev && (
                <>
                    <h2>Dev:</h2>
                    <ul>
                        <li>
                            Cloner un membres (cad un personnel de l'EDT) (avec
                            horaires et postages) ??
                        </li>
                        <li>
                            Verifier que les horaires des AED ne dépassent pas
                            des horaires de l'EDT.
                        </li>
                        <li>
                            horaire membre : Mettre des contrainte
                            <br />( ex: travail au lycée le Lundi et le mercredi
                            )
                        </li>
                        <li>Add membres : les boutons sont gris</li>
                        <li>Validate (number "")</li>
                        <li>Masquer nbreNuit si pas internat</li>
                        <li>xxxxxxxx</li>
                        <li>xxxxxxxx</li>
                    </ul>
                </>
            )}
        </ZPageSectionTodo>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS
