/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { ZPageSection } from "uiZ/zPage"
import EdtCard from "features/edts/EdtCard"
import ButtonEdtCreate from "features/edts/crud/ButtonEdtCreate"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function EtabShowSectionEdts({
    etab,
    className,
    userIsAdminEtab,
}) {
    return (
        <ZPageSection className={className}>
            <div className="section-header">
                <h2 className="col-12">Les EDT de l&apos;établissement</h2>
                {etab.edts.length > 0 ? (
                    <p>
                        Séléctionnez l&apos;EDT sur lequel vous voulez
                        travailler.
                    </p>
                ) : (
                    <p>Créer un 1er Emploi du Temps:</p>
                )}
            </div>

            <div className="col-12 row">
                {etab?.edts?.map((wrk) => (
                    <EdtCard
                        key={"wrkxx-" + wrk.id}
                        workspace={wrk}
                        className="row col-sm-12 col-md-4 col-lg-3 col-xl-2"
                    />
                ))}
                {userIsAdminEtab && (
                    <ButtonEdtCreate
                        className="row col-sm-12 col-md-4 col-lg-3 col-xl-2"
                        etabId={etab.id}
                    />
                )}
            </div>
        </ZPageSection>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS
