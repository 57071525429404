/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { useAppSelector, useAppDispatch } from "utils/store"
import { setGlobalContainerHeight } from "features/timetable/utils/timetableReducer"

// import { AiOutlineColumnHeight } from "react-icons/ai"
import ButtonFilter from "./ButtonFilter"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function FilterGlobalContainerHeight() {
    const { globalContainerHeight } = useAppSelector((state) => state.timetable)
    const dispatch = useAppDispatch()
    const minH = 500
    const maxH = 1200

    return (
        <div className="ms-4  no-print" style={{ whiteSpace: "nowrap" }}>
            {/* 
            <label htmlFor="filterVHeight">Hauteur</label>
            */}
            {/*
            {vSrollablePercentHeight + 1}
             */}
            <ButtonFilter
                id="filterVHeight"
                disabled={globalContainerHeight <= minH}
                className="me-2"
                onClick={() => validateNewHeight(globalContainerHeight / 1.2)}
            >
                &nbsp;-&nbsp;
            </ButtonFilter>
            {/*
            <Button
                className="btn btn-sm ml-2 btn-secondary"
                title="cccccccccccccc"
                onClick={() => validateNewHeight(99)}
            >
                Auto
            </Button>
            */}
            <ButtonFilter
                disabled={globalContainerHeight >= maxH}
                className="ml-2"
                onClick={() => validateNewHeight(globalContainerHeight * 1.2)}
            >
                &nbsp;+&nbsp;
            </ButtonFilter>
        </div>
    )

    function validateNewHeight(newH) {
        newH = Math.floor(newH)
        newH = newH < minH ? minH : newH > maxH ? maxH : newH
        dispatch(setGlobalContainerHeight(newH))
    }
}
