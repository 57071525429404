/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { useAppSelector } from "utils/store"
import styled from "styled-components"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function DateHeure() {
    const { jour, jourNow, heureNow, modeHoraires, locked } = useAppSelector(
        (state) => state.timetable
    )
    if (modeHoraires) {
        return null
    }
    if (!heureNow) {
        return <strong>::</strong>
    }

    const isToday = jour === jourNow

    if (!locked) {
        return null
    }

    return (
        <StyledDateHeure isToday={isToday} className="no-print">
            {/* 
            {isToday && locked && <span id="date">{jour}</span>}
            */}
            {!isToday ? (
                <span id="notToday">
                    Ce ne sont pas les postages d&apos;aujoud&apos;hui !
                </span>
            ) : (
                <span id="heure" className="text-primary">
                    <span id="heu">
                        {heureNow.hours < 10 && "0"}
                        {heureNow.hours}
                    </span>
                    <span id="min">
                        :{heureNow.minutes < 10 && "0"}
                        {heureNow.minutes}
                    </span>
                    <span id="sec">
                        :{heureNow.secondes < 10 && "0"}
                        {heureNow.secondes}
                    </span>
                </span>
            )}
        </StyledDateHeure>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS

const StyledDateHeure = styled.div`
    // background-color: cyan;
    margin: 0px;
    margin-left: 10px;
    padding: 0px;
    flex-shrink: 0;
    #date {
        // background-color: orange;
        font-size: 1.2em;
        font-weight: bold;
        padding-right: 0.5em;
        color: ${(props) => (props.isToday ? "black" : "red")};
    }
    #notToday {
        // background-color: lime;
        font-size: 1em;
        color: red;
    }
    #heure {
        // background-color: yellow;
        font-weight: bold;
        #heu {
            // color: red;
            font-size: 1.2em;
        }
        #min {
            // color: blue;
            font-size: 1.2em;
        }
        #sec {
            // color: green;
            font-size: 0.8em;
        }
    }
`
