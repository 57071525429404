/* eslint-disable import/prefer-default-export */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
// import styled from 'styled-components'

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export const theme = {
    colors: {
        c1l5: "#ebf6fe",
        c1l4: "#bee1fa",
        c1l3: "#7cc3f5",
        c1l2: "#3ba5f0",
        c1l1: "#1083d4",

        c1: "#0b5b95",

        c1d1: "#0a5184",
        c1d2: "#094876",
        c1d3: "#083f67",
        c1d4: "#073658",
        c1d5: "#062d4a",

        danger: "#AA0000",
    },
}
