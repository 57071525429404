/* eslint-disable no-nested-ternary */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-unsafe-finally */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import { API_BASE_URL } from "utils/constants"
import { Etab } from "features/etabs/utils/etabFormReducer"
import { requestOptionsHeaders } from "utils/api"
import { errorManager } from "utils/api/functionsApi"

//* ███████████████████████████████████████████████████████████████████████  zAPI - New version with errorManager OK

export async function zApiFetchMesEtabs() {
    // * Renvoi :  etablissements[]  ou zError
    const url = API_BASE_URL + "/etabs/mes_etabs"
    console.group("🟨  zApiFetchMesEtabs")
    console.log("➤➤ url:", url)
    const requestOptions = {
        method: "GET",
        headers: requestOptionsHeaders(),
        // body: JSON.stringify({ })
    }
    try {
        const response = await fetch(url, requestOptions)
        const rep = await response.json()
        if (rep.etablissements) {
            console.log("✔️ SUCCESS: etablissements: ", rep.etablissements)
            console.groupEnd()
            return { etablissements: rep.etablissements }
        }
        console.groupEnd()
        return { zError: errorManager(rep) }
    } catch (err) {
        console.groupEnd()
        return { zError: errorManager(err) }
    }
}

export async function zApiFetchEtab(etabId: number) {
    // * Renvoi :  etablissements[]  ou zError
    const url = API_BASE_URL + "/etabs/" + etabId
    console.group("🟨  zApiFetchEtab")
    console.log("➤➤ url:", url)
    const requestOptions = {
        method: "GET",
        headers: requestOptionsHeaders(),
    }
    try {
        const response = await fetch(url, requestOptions)
        const rep = await response.json()
        if (rep.etablissement) {
            console.log("✔️ SUCCESS: etablissement: ", rep.etablissement)
            console.groupEnd()
            return { etablissement: rep.etablissement }
        }
        console.groupEnd()
        return { zError: errorManager(rep) }
    } catch (err) {
        console.groupEnd()
        return { zError: errorManager(err) }
    }
}

export async function zApiEtabCreate(etab: Etab) {
    // * Renvoi :  etablissement  ou zError
    const url = API_BASE_URL + "/etabs/new"
    console.group("🟨  zApiEtabCreate")
    console.log("➤➤ url:", url)
    const requestOptions = {
        method: "POST",
        headers: requestOptionsHeaders(),
        body: JSON.stringify(etab),
    }
    try {
        const response = await fetch(url, requestOptions)
        const rep = await response.json()
        if (rep.etablissement) {
            console.log("✔️ SUCCESS: etablissement: ", rep.etablissement)
            console.groupEnd()
            return { etablissement: rep.etablissement }
        }
        console.groupEnd()
        return { zError: errorManager(rep) }
    } catch (err) {
        console.groupEnd()
        return { zError: errorManager(err) }
    }
}

export async function zApiEtabUpdate(etab: Etab) {
    // * Renvoi :  etablissement  ou zError
    const url = API_BASE_URL + "/etabs/" + etab.id + "/edit"
    console.group("🟨  zApiEtabUpdate")
    console.log("➤➤ url:", url)
    const requestOptions = {
        method: "POST",
        headers: requestOptionsHeaders(),
        body: JSON.stringify(etab),
    }
    try {
        const response = await fetch(url, requestOptions)
        const rep = await response.json()
        if (rep.etablissement) {
            console.log("✔️ SUCCESS: etablissement: ", rep.etablissement)
            console.groupEnd()
            return { etablissement: rep.etablissement }
        }
        console.groupEnd()
        return { zError: errorManager(rep) }
    } catch (err) {
        console.groupEnd()
        return { zError: errorManager(err) }
    }
}

export async function zApiEtabDelete(etabId: number) {
    // * Renvoi :  "SUCCESS"  ou zError

    const url = API_BASE_URL + "/etabs/" + etabId + "/delete"
    console.group("🟨  zApiEtabDelete")
    console.log("➤➤ url:", url)

    const requestOptions = {
        method: "DELETE",
        headers: requestOptionsHeaders(),
    }

    try {
        const response = await fetch(url, requestOptions)
        const rep = await response.json()
        if (rep.message && rep.message === "SUCCESS") {
            console.log("✔️ SUCCESS: zApiEtabDelete")
            console.groupEnd()
            return "SUCCESS"
        }
        console.groupEnd()
        return { zError: errorManager(rep) }
    } catch (err) {
        console.groupEnd()
        return { zError: errorManager(err) }
    }
}

//* ███████████████████████████████████████████████████████████████████████
