/* eslint-disable no-nested-ternary */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import styled from "styled-components"
import Loading from "ui/Loading"
import { ButtonNavLinkLogin } from "../common/buttonsNavLink"
import { apiRegisterMailValidation } from "../utils/apiAuthentification"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
// ** Quand un User crée un compte, il recpoit un mail avec un lien vers cette page pour valider son mail
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function PageRegisterValidation() {
    const { email, token } = useParams()
    const [success, setSuccess] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [errorMsg, setErrorMsg] = useState("")

    useEffect(() => {
        document.title = "cmzEDT: Postes"
        setIsLoading(true)
        setErrorMsg("")
        if (!email) {
            setErrorMsg("email missing")
        } else if (!token) {
            setErrorMsg("token missing")
        } else {
            apiRegisterMailValidation(email, token).then(
                (result) => {
                    console.log(
                        "useEffect.apiRegisterMailValidation.result",
                        result
                    )
                    if (result.success) {
                        setSuccess(true)
                    } else if (result.error) {
                        setErrorMsg(result.error)
                    } else {
                        setErrorMsg("erreur inconnu")
                    }
                    setIsLoading(false)
                },
                (error) => {
                    console.log("error", error)
                    setErrorMsg(error)
                    setIsLoading(false)
                }
            )
        }
    }, [])

    return (
        <div className="row col-12 m-1">
            <StyledPageLoginXXXXXXXXXX>
                {/*
                userId:{email}
                <br />
                token:{token}
                 */}
                <h1 className="app-title mb-4">cmzEDT.zedixi.com</h1>
                <img
                    className="app-logo mb-4"
                    alt="cmzEDT"
                    src="/img/app-logo/app-logo-512.png"
                />
                <h1 className="mb-2">Validation de l&apos;inscription</h1>

                {isLoading && <Loading />}
                {success && (
                    <div>
                        Votre inscription a été validée avec succés. Vous pouvez
                        desormais vous connecter.
                    </div>
                )}
                {/* 
                <div>
                    <h2>todo</h2>
                    api - send mail et token.
                    <br />
                    back : verifier si token correspond
                    <br />
                    Retoruner à la page de connexion
                </div>
                */}
                {errorMsg && <p className="text-danger">{errorMsg}</p>}

                <ButtonNavLinkLogin />
            </StyledPageLoginXXXXXXXXXX>
        </div>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS

const StyledPageLoginXXXXXXXXXX = styled.div`
    /*color: $C1d1 !important;*/
    text-align: center;
    margin: 0 auto;

    @media all and (min-width: 320px) {
        padding: 60px 0;
        max-width: 320px;
        .app-title {
            font-size: 1.5em;
        }
        .app-logo {
            width: 200px;
        }
    }

    @media all and (max-width: 319px) {
        padding: 10px 0;
        .app-title {
            font-size: 1.1em;
        }
        .app-logo {
            width: 60%;
        }
    }
`
