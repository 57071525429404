/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import Button from "react-bootstrap/Button"
import { ZPageHeader, ZPageTitle } from "uiZ/zPage"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function PagePostesHeader({ addPosteClick }) {
    return (
        <ZPageHeader>
            <ZPageTitle>
                <div className="float-start">Liste des postes</div>

                <Button
                    className="btn btn-warning ms-auto"
                    onClick={() => addPosteClick()}
                    title="Ajouter un poste"
                >
                    + Ajouter un poste
                </Button>
            </ZPageTitle>
            <b>
                Editez la liste des <u>postes</u> de cet EDT (Vie sco, Portail,
                Etude,...)
                <br />
            </b>
            <small>
                Ensuite, vous pourrez créer les <u>postages</u> (ex: Alice est
                en étude le lundi de 8h à 9h05)
            </small>
        </ZPageHeader>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS
