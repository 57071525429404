/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import Button from "react-bootstrap/Button"
import { useAppDispatch } from "utils/store"
import { setformEdtToUpdate } from "features/edts/utils/edtFormReducer"
import { IcoEdit } from "styles/icones"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function ButtonEdtUpdate({ edt, className }) {
    const dispatch = useAppDispatch()

    const handleClick = () => {
        dispatch(setformEdtToUpdate(edt))
    }
    return (
        <Button
            variant="warning"
            className={className + " btn-sm "}
            title="Modifier cet EDT"
            onClick={() => handleClick()}
        >
            <IcoEdit />
        </Button>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS
