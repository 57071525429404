// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { ZPageSectionTodo } from "uiZ/zPage"
import { dev } from "utils/constants"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function HPSSectionTodo() {
    return (
        <ZPageSectionTodo className="col-12 col-md-6">
            <h2>Améliorations à venir:</h2>
            <ul>
                <li>Ajouter des périodes.</li>
                <li>
                    &quot;Peindre&quot; les horaires, sonneries et périodes
                    (comme pour les postages)
                </li>
                <li>
                    Cloner les Horaires, sonneries et périodes d&apos;un autre
                    EDT (ou importer des valeurs par défaut)
                </li>
            </ul>
            {dev && (
                <>
                    <h2>Dev:</h2>
                    <ul>
                        <li>
                            Modifier d&apos;ici les infos de l&apos;EDT
                            (isInternat)
                        </li>
                        <li>
                            Si on supprime un horaire : supprimer les postages
                            de ce jour ?
                        </li>
                        <li>
                            Verifier qu&apos;il n&apos;y ai pas de postages un
                            jour fermé
                        </li>
                        <li>00:00 et pas 23:59 pour les internats</li>
                        <li>Deduire les périodes des sonneries</li>
                        <li>xxxxxxxxxxxxx</li>
                        <li>xxxxxxxxxxxxx</li>
                        <li>xxxxxxxxxxxxx</li>
                        <li>xxxxxxxxxxxxx</li>
                    </ul>
                </>
            )}
        </ZPageSectionTodo>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS
