/* eslint-disable no-nested-ternary */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-unsafe-finally */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import { API_BASE_URL } from "utils/constants"
import { requestOptionsHeaders } from "utils/api"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export async function apiFetchAdminUsers() {
    let users = null
    let error = null
    const url = API_BASE_URL + "/admin/users"
    console.log("url:", url)

    const requestOptions = {
        method: "POST",
        headers: requestOptionsHeaders(),
        // body: JSON.stringify({ })
    }

    try {
        const response = await fetch(url, requestOptions)
        const rep = await response.json()
        if (rep.apixError) {
            error = rep.apixError
        } else if (rep.users) {
            users = rep.users
        } else {
            console.log(rep)
            error = "Erreur de connexion inconnue"
        }
    } catch (err) {
        error = "Catch: " + err
    } finally {
        return {
            users,
            error,
        }
    }
}

// ■■ // ■■ // ■■ // ■■ // ■■ // ■■ // A MODIFIER POUR REDUX TOOLKIT

export async function apiFetchUser(userId: number) {
    let user = null
    let error = null
    const url = API_BASE_URL + "/users/" + userId
    console.log("apiFetchUser-url:", url)
    console.log(
        "apiFetchUser-requestOptionsHeaders():",
        requestOptionsHeaders()
    )

    const requestOptions = {
        method: "POST",
        headers: requestOptionsHeaders(),
        // body: JSON.stringify({ })
    }

    try {
        const response = await fetch(url, requestOptions)
        const rep = await response.json()
        if (rep.apixError) {
            error = rep.apixError
        } else if (rep.user) {
            user = rep.user
        } else {
            console.log(rep)
            error = "Erreur de connexion inconnue"
        }
    } catch (err) {
        error = "Catch: " + err
    } finally {
        // eslint-disable-next-line no-unsafe-finally
        return {
            user,
            error,
        }
    }
}

export async function apiEditUserPassword() {
    alert("apiEditUserPassword: WIP")
}
