/* eslint-disable no-nested-ternary */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-unsafe-finally */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import { API_BASE_URL } from "utils/constants"
import { requestOptionsHeaders } from "utils/api"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

// eslint-disable-next-line import/prefer-default-export
export async function apiFetchDashboard() {
    let etabs = null
    let users = null
    let logs = null
    let error = null
    const url = API_BASE_URL + "/admin/dashboard"

    console.group("<<<>>> apiFetchDashboard")
    console.log("url:", url)

    const requestOptions = {
        method: "GET",
        headers: requestOptionsHeaders(),
    }

    try {
        const response = await fetch(url, requestOptions)
        const rep = await response.json()
        if (rep.etablissements && rep.users && rep.logs) {
            console.log("success: rep: ", rep)
            etabs = rep.etablissements
            users = rep.users
            logs = rep.logs
        } else if (rep.apixError) {
            console.log("apixError: ", rep.apixError)
            error = "Erreur: " + rep.apixError
        } else {
            console.log("rep--------------------:" + rep)
            error = "Erreur de connexion inconnue"
        }
    } catch (err) {
        error = "Catch: " + err
    } finally {
        console.groupEnd()
        return {
            etabs,
            users,
            logs,
            error,
        }
    }
}
