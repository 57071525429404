/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import HoraireItem from "./HoraireItem"
import ButtonHoraireCreate from "./ButtonHoraireCreate"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
export default function HoraireJour({ jour, horairesJour }) {
    // const [selectedHoraire, setSelectedHoraire] = useState(null)

    // function selectionnerHoraire(hor) {         setSelectedHoraire(hor)    }

    return (
        <div className="row col-12 mb-2 horaire-ligne border-bottom">
            <div className="col-4 col-jour">
                <b>{jour}</b>
            </div>

            <div className="row col-7 col-horaires">
                {horairesJour.map((hor) => (
                    <HoraireItem
                        key={"hor--" + hor.id}
                        horaire={hor}
                        jour={jour}
                    />
                ))}
            </div>
            {horairesJour.length === 0 && (
                <div className="col-1 col-bt-add-horaire">
                    <ButtonHoraireCreate jour={jour} />
                </div>
            )}
        </div>
    )
}
