/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import styled from "styled-components"
import { useAppSelector } from "utils/store"

import EdtMembre from "./edtMembre/EdtMembre"
import EdtPeriodes from "./edtPeriodes/EdtPeriodes"
import EdtSonneries from "./edtSonneries/EdtSonneries"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
/*
 *
 *
 *
 *
 *
 */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
export default function TimetableContainer() {
    const {
        membres,
        periodes,
        sonneries,
        orientation,
        vSrollablePercentHeight,
        // rowWidth,
    } = useAppSelector((state) => state.timetable)

    return (
        <StyledTimetableContainer
            className="flex-grow-1"
            orientation={orientation}
            vSrollablePercentHeight={vSrollablePercentHeight}
            // rowWidth={rowWidth}
            // modeHoraires={modeHoraires}
        >
            <StyledTimetableScrollable
                orientation={orientation}
                vSrollablePercentHeight={vSrollablePercentHeight}
            >
                {periodes && <EdtPeriodes periodes={periodes} />}
                {sonneries && (
                    <EdtSonneries sonneries={sonneries} side="LEFT" />
                )}

                {membres?.map((uw) => (
                    <EdtMembre key={"uw-" + uw.id} membre={uw} />
                ))}

                {sonneries && (
                    <EdtSonneries sonneries={sonneries} side="RIGHT" />
                )}
            </StyledTimetableScrollable>
        </StyledTimetableContainer>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS

const StyledTimetableContainer = styled.div<{
    orientation: string
    vSrollablePercentHeight: number
}>`
    // background-color: orange !important;
    padding: 0px;

    ${(props) =>
        props.orientation === "HORIZONTAL"
            ? `
                // color:red;
                // width: 200px !important;
		    `
            : `
                /* height:  $ {props.containerHeight+"px" }; */
                overflow-y: auto;
            `}// background-color: yellow !important; // **** debugOnly
    // padding: 10px !important; // **** debugOnly
    // margin: 10px !important; // **** debugOnly

    // overflow-y: auto;
    // height: 100%;
    //

    /*
    $ {(props) => !props.modeHoraires && "overflow-y: auto;"}
    $ {(props) => props.modeHoraires && "overflow-y: auto;"}
   
    margin-top:10px;
    padding: 0px !important; 
    padding:0px!important;/*TEMP* /
    width:100%;
    border: 4px solid blue;	/*TEMP* /    
    */
`

const StyledTimetableScrollable = styled.div<{
    orientation: string
    vSrollablePercentHeight: number
}>`
    padding: 0px;
    margin: 0px;
    /*border: 2px solid green;	TEMP*/
    background-color: white;
    // background-color: yellow; // debug only
    // padding: 5px !important; // debug only
    min-height: 200px; // ******************** TEMPPPPPPPPPPPPPPPPPP
    // max-width: 10%;

    // background-color: magenta; // **** debugOnly
    //  padding: 20px; // **** debugOnly

    ${(props) =>
        props.orientation === "HORIZONTAL"
            ? `
                flex-direction: column;
                // width:  $ {props.rowWidth + "px"};
                 width: 100% !important;
                // width: 1000px;
		    `
            : `
                width: 100%;
                display: flex;
                flex: 1;
                flex-direction: row;
                height: ${props.vSrollablePercentHeight + "%"}
		    `}
`
