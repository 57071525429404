/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { useAppSelector, useAppDispatch } from "utils/store"
import { setOrientation } from "features/timetable/utils/timetableReducer"

import { IcoSplitVertical, IcoSplitHorizontal } from "styles/icones"
import ButtonFilter from "./ButtonFilter"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function FilterOrientation() {
    const { orientation } = useAppSelector((state) => state.timetable)
    const dispatch = useAppDispatch()

    return (
        <div className="" style={{ whiteSpace: "nowrap" }}>
            {/* <label>Orientation</label> */}
            <ButtonFilter
                actif={orientation === "VERTICAL"}
                className="ml-2"
                title="Affichage Vertical"
                onClick={() => dispatch(setOrientation("VERTICAL"))}
            >
                <IcoSplitHorizontal />
            </ButtonFilter>{" "}
            <ButtonFilter
                actif={orientation === "HORIZONTAL"}
                className="ml-1"
                title="Affichage Horizontal"
                onClick={() => dispatch(setOrientation("HORIZONTAL"))}
            >
                <IcoSplitVertical />
            </ButtonFilter>
        </div>
    )
}
