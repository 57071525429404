// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"

import { ZPageSection } from "uiZ/zPage"
import DashboardLogsLastDays from "./lastDays_1/DashboardLogsLastDays"
// import DashboardLogsByDayAndHour1 from "./byDayAndHour_1/DashboardLogsByDayAndHour1"
import LogsbyHour from "./byHour/LogsbyHour"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

interface User {
    id: number
    username: string
}

interface Edt {
    id: number
    nom: string
}

interface Etab {
    id: number
    nom: string
}
export interface Log {
    id: number
    createdAt: string
    event: string
    user?: User
    etablissement?: Etab
    edt?: Edt
    detail: string
    message?: string
}

type Props = {
    logs: Log[]
}
export default function DashboardLogs({ logs }: Props) {
    return (
        <>
            <ZPageSection>
                <LogsbyHour logs={logs} />
            </ZPageSection>
            <ZPageSection>
                <DashboardLogsLastDays logs={logs} />
            </ZPageSection>
        </>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS
