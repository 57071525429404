/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { useAppSelector, useAppDispatch } from "utils/store"
import { setModeJournee } from "features/timetable/utils/timetableReducer"

import ButtonFilter from "./ButtonFilter"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function FilterModeJournee() {
    const { modeJournee } = useAppSelector((state) => state.timetable)
    const dispatch = useAppDispatch()

    return (
        <div className="" style={{ whiteSpace: "nowrap" }}>
            <ButtonFilter
                actif={modeJournee === "EDT_HORAIRES"}
                className="ml-2"
                title="EDT_HORAIRES"
                onClick={() => dispatch(setModeJournee("EDT_HORAIRES"))}
            >
                Horaires de l'EDT
            </ButtonFilter>{" "}
            <ButtonFilter
                actif={modeJournee === "24H"}
                className="ml-1"
                title="24H"
                onClick={() => dispatch(setModeJournee("24H"))}
            >
                24H (internat)
            </ButtonFilter>
            {/* 
            {" "}
            <ButtonFilter
                actif={modeJournee === "JOURNEE"}
                className="ml-1"
                title="JOURNEE"
                onClick={() => dispatch(setModeJournee("JOURNEE"))}
            >
                Journée (7h-19h)
            </ButtonFilter>
            */}
        </div>
    )
}
