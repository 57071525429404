// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { ZPageSectionTodo } from "uiZ/zPage"
import { dev } from "utils/constants"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function EdtShowSectionTodo() {
    return (
        <ZPageSectionTodo className="col-12 col-md-6">
            <h2>Améliorations à venir:</h2>
            <ul>
                <li>Dupliquer un EDT</li>
            </ul>

            {dev && (
                <>
                    <h2>Dev:</h2>
                    <ul>
                        <li>Mettre le bouton DeleteEDT dans le form update</li>
                        <li>xxxxxxxx</li>
                        <li>xxxxxxxx</li>
                    </ul>
                </>
            )}
        </ZPageSectionTodo>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS
