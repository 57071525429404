// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import { useAppSelector } from "utils/store"
import "bootstrap/dist/css/bootstrap.min.css"
import "styles/global.scss"

import PagePostes from "pages/PagePostes"

import PageHome from "pages/PageHome"
import PageHoraires from "pages/PageHoraires"
import PageEtabShow from "pages/PageEtabShow"
import PageMesEtabs from "pages/PageMesEtabs"
import PageAdminDashboard from "pages/PageAdminDashboard"
import PageUsersAdmin from "pages/PageUsersAdmin"
import PageTodo from "features/admin/todo/PageTodo"

import PageAbout from "pages/PageAbout"
import PageAboutDocs from "pages/PageAboutDocs"
import PageAboutFaq from "pages/PageAboutFaq"
import PageAboutChangeLog from "pages/PageAboutChangeLog"

import PageUserShow from "pages/PageUserShow"

import PageMembres from "pages/PageMembres"
import PageEdtShow from "pages/PageEdtShow"
import PagePostages from "pages/PagePostages"
import PagePostagesExemple from "pages/public/PagePostagesExemple"
import Page404 from "ui/errors/Page404"

import PageErrorAlreadyConnected from "ui/errors/PageErrorAlreadyConnected"

// **** PUBLIC:
import PageHomeLandingPage from "pages/public/PageHomeLandingPage"
import PageLogin from "pages/public/PageLogin"
import PageRegister from "pages/public/PageRegister"
import PageRegisterValidation from "features/authentification/_registerValidation/PageRegisterValidation"
import PagePasswordForgotten from "pages/public/PagePasswordForgotten"
import PagePasswordReset from "features/authentification/_passwordReset/PagePasswordReset"
// import PageValidatePersonnelEmail from "features/authentification/_validatePersonnelEmail/PageValidatePersonnelEmail"
// ****

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function App() {
    console.log("■■■■■ RENDER ■ App")
    const connectedUserToken: string | null = useAppSelector(
        (state) => state.connectedUser.userToken
    )

    if (!connectedUserToken) {
        return (
            <Router>
                <Routes>
                    <Route
                        path="/password_reset/:userId/:token"
                        element={<PagePasswordReset />}
                    />
                    <Route
                        path="/password_forgotten"
                        element={<PagePasswordForgotten />}
                    />
                    <Route path="/register" element={<PageRegister />} />

                    <Route
                        path="/personnel/:id/validate-mail"
                        // element={<PageValidatePersonnelEmail />}
                        element={<PageRegister />}
                    />
                    <Route
                        path="/register/validation/:email/:token"
                        element={<PageRegisterValidation />}
                    />
                    {/* // TODO ???? !!!!! !:::::::::: */}
                    {/* <Route path="/personnel/:id/validate-mail" element={<PageValidatePersonnelEmail />} /> */}
                    <Route path="/docs" element={<PageAboutDocs />} />
                    <Route
                        path="exemple/:id"
                        element={<PagePostagesExemple />}
                    />
                    <Route path="/login" element={<PageLogin />} />
                    <Route path="*" element={<PageHomeLandingPage />} />
                </Routes>
            </Router>
        )
    }

    return (
        <Router>
            <Routes>
                <Route path="/etab/:id" element={<PageEtabShow />} />
                <Route path="/edt/:id" element={<PageEdtShow />} />
                <Route path="/edt/:id/horaires" element={<PageHoraires />} />
                <Route path="/edt/:id/postes" element={<PagePostes />} />
                <Route path="/edt/:id/membres" element={<PageMembres />} />
                <Route path="/edt/:id/postages" element={<PagePostages />} />
                <Route path="/user/:id" element={<PageUserShow />} />
                <Route path="/mes-etabs" element={<PageMesEtabs />} />
                <Route path="/" element={<PageHome />} />
                <Route
                    path="/password_reset/:userId/:token"
                    element={<PageErrorAlreadyConnected />}
                />
                <Route
                    path="/password_forgotten"
                    element={<PageErrorAlreadyConnected />}
                />
                <Route
                    path="/register"
                    element={<PageErrorAlreadyConnected />}
                />
                <Route
                    path="/register/validation/:email/:token"
                    element={<PageErrorAlreadyConnected />}
                />
                <Route
                    path="/personnel/:id/validate-mail"
                    element={<PageErrorAlreadyConnected />}
                />
                <Route path="/about" element={<PageAbout />} />{" "}
                <Route path="/about/docs" element={<PageAboutDocs />} />
                <Route path="/about/faq" element={<PageAboutFaq />} />
                <Route
                    path="/about/changelog"
                    element={<PageAboutChangeLog />}
                />
                <Route
                    path="/about/exemple/:id"
                    element={<PagePostagesExemple />}
                />
                {/* // todo :::     IF ADMIN */}
                <Route path="/admin" element={<PageAdminDashboard />} />
                <Route path="/admin/users" element={<PageUsersAdmin />} />
                <Route path="/admin/todo" element={<PageTodo />} />
                <Route path="*" element={<Page404 />} />
            </Routes>
        </Router>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS
