/* eslint-disable no-unused-vars */ // !!!!!!!!!!!!!!!!
/* eslint-disable react/jsx-no-constructed-context-values */ // !!!!!!!!!!!!!!!!
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React, { useState, useEffect } from "react"
import { ZError, ZErrorInterface } from "uiZ/zError"
import ZFormHeader from "./ZFormHeader"
import ZFormFooter from "./ZFormFooter"
import ZDeleteForm from "./ZDeleteForm"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

type Props = {
    initialData: any
    customValidate: any
    closeForm: any
    children: any
    // *** Create:
    createTitle: string // React.ReactNode
    createFunction?: (_args?: any | null) => any | null
    createSuccessFunction?: (_result: any) => any | null
    // *** Update:
    updateTitle: string
    updateFunction?: (_args?: any | null) => any | null
    updateSuccessFunction?: (_result: any) => any | null
    // *** Delete:
    deleteTitle: string
    deleteConfirmText: string // !!!!!    chaine vide "" pour null car j'arrive pas a mettre string|null
    deleteFunction?: (_args?: any | null) => any | null
    deleteSuccessFunction?: (_result: any) => any | null
}

ZForm.defaultProps = {
    createFunction: null,
    createSuccessFunction: null,
    updateFunction: null,
    updateSuccessFunction: null,
    deleteFunction: null,
    deleteSuccessFunction: null,
}
export const FormContext = React.createContext({
    formData: {},
    handleInputChange: (_ev: any) => {},
    isSubmitting: false,
    handleSubmit: (_ev: any) => {},
    handleDelete: (_ev: any) => {},
    formErrors: {},
    createTitle: "",
    updateTitle: "",
    deleteTitle: "",
    deleteConfirmText: "",
    updateMode: false,
    setDeleteFormIsOpen: (_val: boolean) => {},
})

export default function ZForm({
    initialData,
    customValidate,
    closeForm,
    children,

    createTitle,
    createFunction,
    createSuccessFunction,

    updateTitle,
    updateFunction,
    updateSuccessFunction,

    deleteTitle,
    deleteConfirmText,
    deleteFunction,
    deleteSuccessFunction,
}: Props) {
    const [formData, setFormData] = useState(initialData)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [deleteFormIsOpen, setDeleteFormIsOpen] = useState(false)
    const [formErrors, setFormErrors] = useState({}) // * pour la validation
    const [fetchZError, setFetchZError] = useState<ZErrorInterface | null>(null) // pour le fetch

    useEffect(() => {
        setFormErrors(customValidate(formData))
    }, [formData])

    const handleInputChange = (ev: any) => {
        // console.log("handleInputChange - ev.target.name", ev.target.name)
        // console.log("handleInputChange - ev.target.value", ev.target.value)
        const { name, value } = ev.target
        const newFormData = { ...formData }
        newFormData[name] = value
        setFormData(newFormData)
        // setFormData({ ...formData, nom: value })
    }

    const handleSubmit = (ev: any) => {
        ev.preventDefault()
        setIsSubmitting(true)
        if (formData.id) {
            if (updateFunction && updateSuccessFunction) {
                //   console.log("----updateSuccessFunction----")
                //   console.log(formData)
                updateFunction(formData).then((response: any) => {
                    //  console.log("then")
                    if (response.zError) {
                        //   console.log("then *----errrrrrrrrrrrrrrr")
                        setFetchZError(response.zError)
                    } else {
                        //  console.log("then *----sssssssuccccccc")
                        updateSuccessFunction(response)
                        closeForm()
                    }
                    setIsSubmitting(false)
                })
            }
        } else if (createFunction && createSuccessFunction) {
            //  console.log("----createSuccessFunction----")
            //   console.log(formData)
            createFunction(formData).then((response: any) => {
                //  console.log("then")
                if (response.zError) {
                    setFetchZError(response.zError)
                } else {
                    createSuccessFunction(response)
                    closeForm()
                }
                setIsSubmitting(false)
            })
        }
    }
    const handleDelete = (ev: any) => {
        ev.preventDefault()

        setIsSubmitting(true)
        if (deleteFunction && deleteSuccessFunction) {
            deleteFunction().then((response: any) => {
                if (response.zError) {
                    setFetchZError(response.zError)
                } else {
                    deleteSuccessFunction(response)
                    closeForm()
                }
                setIsSubmitting(false)
            })
        }
    }

    // console.log("initialData", initialData)

    return (
        <FormContext.Provider
            value={{
                formData,
                handleInputChange,
                isSubmitting,
                formErrors,
                handleSubmit,
                handleDelete,
                createTitle,
                updateTitle,
                deleteTitle,
                deleteConfirmText,
                updateMode: !!formData.id,
                setDeleteFormIsOpen,
            }}
        >
            {deleteFormIsOpen ? (
                <ZDeleteForm />
            ) : (
                <form onSubmit={handleSubmit}>
                    <ZFormHeader />

                    {children}
                    {fetchZError && <ZError zError={fetchZError} />}

                    <ZFormFooter closeForm={closeForm} />
                </form>
            )}
        </FormContext.Provider>
    )
}
