/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { useAppSelector, useAppDispatch } from "utils/store"
import { setShowAllUsers } from "features/timetable/utils/timetableReducer"

import { ZFieldCheckbox } from "uiZ/zForm"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function FilterShowAllUsers() {
    const { showAllUsers, modeHoraires } = useAppSelector(
        (state) => state.timetable
    )
    const dispatch = useAppDispatch()

    const hint = modeHoraires
        ? null
        : "Afficher aussi les AED qui ne travaillent pas ce jour"
    const title = modeHoraires
        ? "Afficher aussi les AED qui ne travaillent pas ce jour"
        : undefined

    return (
        <ZFieldCheckbox
            name="showAllUsers"
            label="Tous les AED"
            // type: string
            hint={hint}
            title={title}
            fieldClassName="ms-2 no-print"
            //  inputClassName?: string | null
            value={showAllUsers}
            handleInputChange={() => dispatch(setShowAllUsers(!showAllUsers))}
            error={null}
            fieldStyle="SWITCH"
        />
    )
}
