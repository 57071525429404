/* eslint-disable react/no-array-index-key */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import styled from "styled-components"

import LogsHeure from "./LogsHeure"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

interface User {
    id: number
    username: string
}

interface Log {
    id: number
    createdAt: string
    event: string
    user?: User
}

type Props = {
    logs: Log[]
    jour: string
}
export default function LogsJour({ logs, jour }: Props) {
    const minDate = jour + " - 00:00"
    const maxDate = jour + " - 23:59"

    const filteredLogs = logs.filter(
        (log) => log.createdAt >= minDate && log.createdAt <= maxDate // && log.user?.id !== 1 && log.user?.id !== 4
    )

    const differentUsersIds = filteredLogs
        .map((item) => item.user?.id)
        .filter((value, index, self) => index && self.indexOf(value) === index) // "index &&...." pour enlever les logs sans user (loginFail)
    // console.log("differentUsersIds", differentUsersIds)

    //  const now = new Date()
    // const current = now.getHours() + ":" + now.getMinutes()

    const heures = []
    for (let h = 0; h < 24; h += 1) {
        heures.push({
            minDate: jour + " - " + (h < 10 ? "0" : "") + h + ":00",
            maxDate: jour + " - " + (h < 10 ? "0" : "") + h + ":59",
        })
    }

    return (
        <StyledJour>
            <div className="row col-12">
                <h5 className="col-md-4 col-12">{jour}</h5>

                <div className="col-md-8 col-12">
                    <div className="float-end">
                        <b>{differentUsersIds.length}</b> utilisateurs
                        differents / <b>{filteredLogs.length}</b> logs
                    </div>
                </div>
            </div>
            <div className="hoursContainer ">
                {heures.map((heure, h) => (
                    <LogsHeure
                        key={"qdfsdf" + h}
                        logs={logs}
                        minDate={heure.minDate}
                        maxDate={heure.maxDate}
                        className=""
                    />
                ))}
            </div>
        </StyledJour>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS
const StyledJour = styled.div`
    background-color: lightgrey;
    padding: 5px;
    margin-bottom: 10px;

    .hoursContainer {
        background-color: lime;
        padding-left: 5px;
    }
`
