/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"

import { useAppSelector } from "utils/store"
import LayoutSection from "../layouts/LayoutSection"
import EdtUserHeader from "./EdtUserHeader"
import EdtUserContent from "./EdtUserContent"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function EdtMembre({ membre }) {
    const { jour, showAllUsers, modeHoraires } = useAppSelector(
        (state) => state.timetable
    )
    // console.log(membre)
    if (
        !showAllUsers &&
        (membre.postages?.filter((post) => post.jour === jour).length === 0 ||
            modeHoraires) &&
        membre.horaires.filter((post) => post.jour === jour).length === 0
    ) {
        return null
    }

    return (
        <LayoutSection styles={{ backgroundColor: membre.couleur }}>
            <EdtUserHeader membre={membre} />
            <EdtUserContent membre={membre} />
        </LayoutSection>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS
