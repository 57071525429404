/* eslint-disable no-nested-ternary */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { useParams } from "react-router-dom"
import styled from "styled-components"
import FormPasswordReset from "./FormPasswordReset"
import { ButtonNavLinkLogin } from "../common/buttonsNavLink"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
// ** Quand un User à fait une demande de réinitialisation du password
// ** Il recoit un lien par mail pour arriver sur cette page
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function PagePasswordReset() {
    const { userId, token } = useParams()
    console.log("aaaaaaaaaaaaa")
    return (
        <div className="row col-12 m-1">
            <StyledPageLoginXXXXXXXXXX>
                {/*
                userId:{userId}
                <br />
                token:{token}
                 */}
                <h1 className="app-title mb-4">cmzEDT.zedixi.com</h1>
                <img
                    className="app-logo mb-4"
                    alt="cmzEDT"
                    src="/img/app-logo/app-logo-512.png"
                />
                <h1 className="mb-2">Réinitialiser le mot de passe</h1>
                <FormPasswordReset userId={userId} token={token} />
                <ButtonNavLinkLogin />
            </StyledPageLoginXXXXXXXXXX>
        </div>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS

const StyledPageLoginXXXXXXXXXX = styled.div`
    /*color: $C1d1 !important;*/
    text-align: center;
    margin: 0 auto;

    @media all and (min-width: 320px) {
        padding: 60px 0;
        max-width: 320px;
        .app-title {
            font-size: 1.5em;
        }
        .app-logo {
            width: 200px;
        }
    }

    @media all and (max-width: 319px) {
        padding: 10px 0;
        .app-title {
            font-size: 1.1em;
        }
        .app-logo {
            width: 60%;
        }
    }
`
