import { useAppSelector } from "utils/store"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export function getArrayJoursFromHoraireEdts(horaireEdts) {
    /*    const jours = [
        "Lundi",
        "Mardi",
        "Mercredi",
        "Jeudi",
        "Vendredi",
        "Samedi",
        "Dimanche",
    ]

    */

    let jours = []
    if (horaireEdts.filter((hor) => hor.jour === "Lundi").length > 0) {
        jours = [...jours, "Lundi"]
    }
    if (horaireEdts.filter((hor) => hor.jour === "Mardi").length > 0) {
        jours = [...jours, "Mardi"]
    }
    if (horaireEdts.filter((hor) => hor.jour === "Mercredi").length > 0) {
        jours = [...jours, "Mercredi"]
    }
    if (horaireEdts.filter((hor) => hor.jour === "Jeudi").length > 0) {
        jours = [...jours, "Jeudi"]
    }
    if (horaireEdts.filter((hor) => hor.jour === "Vendredi").length > 0) {
        jours = [...jours, "Vendredi"]
    }
    if (horaireEdts.filter((hor) => hor.jour === "Samedi").length > 0) {
        jours = [...jours, "Samedi"]
    }
    if (horaireEdts.filter((hor) => hor.jour === "Dimanche").length > 0) {
        jours = [...jours, "Dimanche"]
    }
    return jours
}

export function connectedUserIsAdminEtab(etab) {
    const { userId } = useAppSelector((state) => state.connectedUser)

    console.log("aaaaaaaaaaaaaaaaa userId ", userId)

    if (userId === 1 || userId === "1") {
        return true
    }

    if (!etab || !etab.personnels) {
        return null
    }

    const connectedPersonnel = etab.personnels.filter(
        (pers) => pers.user && pers.user.id.toString() === userId.toString()
    )

    if (!connectedPersonnel || connectedPersonnel.length === 0) {
        return null
    }

    return connectedPersonnel[0].isAdminEtablissement
}

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export function randomHexColor() {
    return "#" + Math.floor(Math.random() * 16777215).toString(16)
}
export function randomHexColorLight() {
    const max = 255
    const min = 100

    const red = Math.floor(Math.random() * (max - min + 1)) + min
    const green = Math.floor(Math.random() * (max - min + 1)) + min
    const blue = Math.floor(Math.random() * (max - min + 1)) + min

    return "#" + red.toString(16) + green.toString(16) + blue.toString(16)
}

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
/*

	export function getRouteParamId(match, paramName="id"){
		const paramId = (match && match.params && match.params[paramName]) ? match.params[paramName] : 0

		let id= (isNaN(parseInt(paramId)) ? 0 : parseInt(paramId))
		return id
	}


*/

/*
 *		Renvoi une heure en string à partir du nombre de minutes
 *		8x60+50		==>		"08:50"
 */
export function minutesToStringHeuresMinutes(
    intMinutes,
    hourSeparator = ":",
    hideNullMinutes = false
) {
    const m = intMinutes % 60
    const h = (intMinutes - m) / 60

    const mStr = m > 9 ? m : "0" + m
    const hStr = h > 9 ? h : "0" + h

    const separatorAndMinutes =
        hideNullMinutes && m === 0 ? "" : hourSeparator + mStr

    return hStr + separatorAndMinutes
}

/*
 *		Renvoi la durée en minutes entre 2 string_Heures '12:00'
 *		("08:00", "09:30")		==>		90
 */
export function calcDureeMinutesFromStrHeureDebutToStrHeureFin(
    strHeureDebut,
    strHeureFin
) {
    const dureeMinutes =
        calcMinutesFromMidnight(strHeureFin) -
        calcMinutesFromMidnight(strHeureDebut)

    return dureeMinutes
}

/**
 *	Renvoi le nombre de minutes depuis minuit à partir d'une heure en string
 *	"08:50"		==>		8x60+50
 */
function calcMinutesFromMidnight(heureStr) {
    const h = parseInt(heureStr.split(":")[0], 10)
    const m = parseInt(heureStr.split(":")[1], 10)
    const minutesFromMidnight = h * 60 + m
    return minutesFromMidnight
}

/**
 * @param 	{boolean} 	replaceWeByLundi - si true, renvoie "Lundi" pour le WeekEnd
 * @returns {string} 	le jour d'aujoujourd'hui ("Lundi" à "Dimanche")
 */
export function getDayNameForToday(replaceWeByLundi = true) {
    const jours = replaceWeByLundi
        ? ["Lundi", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Lundi"]
        : [
              "Dimanche",
              "Lundi",
              "Mardi",
              "Mercredi",
              "Jeudi",
              "Vendredi",
              "Samedi",
          ]

    const key = new Date().getDay()
    return jours[key]
}

/**
 * Renvoi les heure MinutesFromMidnith {int, int} d'ouverture et de fermeture de l'etab en fonction du nom du jour
 * @param 	{object[]} 	etabHoraires - horaires[]
 * @param 	{string} 	jourName - "Lundi".
 * @param 	{int} 		marge - xxxxxxxxx.
 * @returns {int} 	{heureDebut: 440, "heureFin": 1100 }
 */
export function getHeuresMFMOuvertureEtab(etabHoraires, jourName, marge = 0) {
    /*
    console.log('■■■■■■■■■■■■■■')
    console.log(etabHoraires)
    console.log("jourName", jourName)
    console.log('■■■■■■■■■■■■■■')
    */
    const { heureDebut, heureFin } = getHeuresStrOuvertureEtab(
        etabHoraires,
        jourName
    )
    return {
        startHourMFM: heureStrToMFM(heureDebut) - marge,
        endHourMFM: heureStrToMFM(heureFin) + marge,
    }
}

/**
 * Renvoie les heures {string, string} de l'EDT en fonction du nom du jour
 * @param 	{object[]} 	horaires - horaires[]
 * @param 	{string} 	jourName - ex: "Lundi"
 * @returns {string[]} 	{heureDebut: "07:30", "heureFin": "18:30" }
 */
function getHeuresStrOuvertureEtab(horaires, jourName) {
    /*
    console.log('■-■-■-■-■-■-■-■-■-■-■-■-■-■')
    console.log(horaires)
    console.log("jourName", jourName)
    console.log('■-■-■-■-■-■-■-■-■-■-■-■-■-■')
	*/
    const horairesSelectedDay = horaires.filter((hor) => hor.jour === jourName)
    let heureDebut = "07:31"
    let heureFin = "18:31"
    if (horairesSelectedDay.length > 0) {
        heureDebut = horairesSelectedDay[0].heureDebut
        heureFin = horairesSelectedDay[0].heureFin
    }

    return {
        heureDebut,
        heureFin,
    }
}
/*
 function getStartEndDurationMFM(
    etabHoraires,
    jourName,
    modeJournee,
    marge = 0
) {
    let startHourMFM = 7 * 60
    let endHourMFM = 19 * 60

    if (modeJournee === "24H") {
        startHourMFM = 0
        endHourMFM = 23 * 60 + 59
    } else if (modeJournee === "EDT_HORAIRES") {
        const { heureDebut, heureFin } = getHeuresStrOuvertureEtab(
            etabHoraires,
            jourName
        )
        startHourMFM = heureStrToMFM(heureDebut)
        endHourMFM = heureStrToMFM(heureFin)
    }

    startHourMFM -= marge
    endHourMFM += marge

    // ////////////////// startHourMFM = startHourMFM < 0 ? 0 : startHourMFM
    // ////////////////// endHourMFM = endHourMFM > 24 * 60 ? 24 * 60 : endHourMFM

    return {
        startHourMFM,
        endHourMFM,
        durationInMinutes: endHourMFM - startHourMFM,
    }
}
*/

/**
 * converti une heure en string ("08:50") en nombre de minutes depuis minuit
 * ex : "08:50"		==>		8x60+50
 * @param 		{string} heureStr - ("08:50")
 * @returns 	{int}	 530   (8x60+50)
 */
function heureStrToMFM(heureStr) {
    const h = parseInt(heureStr.split(":")[0], 10)
    const m = parseInt(heureStr.split(":")[1], 10)
    const minutesFromMidnight = h * 60 + m
    return minutesFromMidnight
}

/*
/**
 * converti un nombre de minutes depuis minuit en string ("08:50")
 * ex :  8x60+50 	==>		"08:50"
 * @param 		{int}	 530   (8x60+50)
 * @returns 	{string} heureStr - ("08:50")
 * /
 function heureMFMToStr(heureMFM) {
    let min = heureMFM % 60
    let heure = Math.floor(heureMFM / 60)

    heure = heure < 10 ? "0" + heure : heure
    min = min < 10 ? "0" + min : min

    return heure + "h" + min
}
*/
