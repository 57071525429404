/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import Button from "react-bootstrap/Button"
import { useAppSelector, useAppDispatch } from "utils/store"
import { setShowOptions } from "features/timetable/utils/timetableReducer"

import { IcoOutil } from "styles/icones"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function ButtonShowOptions({ className }) {
    const { showOptions } = useAppSelector((state) => state.timetable)
    const dispatch = useAppDispatch()

    return (
        <Button
            className={className + " btn-sm ms-2 no-print"}
            variant={showOptions ? "danger" : "warning"}
            title="Afficher les options"
            onClick={() => dispatch(setShowOptions(!showOptions))}
        >
            {showOptions ? "Fermer" : <IcoOutil />}
        </Button>
    )
}
