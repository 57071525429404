/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { useAppSelector, useAppDispatch } from "utils/store"
import { setShowHoraires } from "features/timetable/utils/timetableReducer"
import { ZFieldCheckbox } from "uiZ/zForm"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function FilterShowHoraires() {
    const { showHoraires, modeHoraires } = useAppSelector(
        (state) => state.timetable
    )
    const dispatch = useAppDispatch()
    if (modeHoraires) {
        return null
    }

    return (
        <ZFieldCheckbox
            name="hideHoraires"
            label="Masquer les horaires"
            // type: string
            // hint="hint"
            // title="title"
            fieldClassName="ms-2"
            //  inputClassName?: string | null
            value={!showHoraires}
            handleInputChange={() => dispatch(setShowHoraires(!showHoraires))}
            error={null}
            fieldStyle="SWITCH"
        />
    )
}
