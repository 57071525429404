/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { Navbar, Container, Nav } from "react-bootstrap"

import { useAppSelector } from "utils/store"
import styled from "styled-components"
import { dev, useLocal, modeVideo } from "utils/constants"
import { theme } from "styles/theme"
import { Link, LinkMesEtabs, LinkEtabShow } from "./Links"
import DropdownConnectedUser from "./DropdownConnectedUser"
import DropdownAdmin from "./DropdownAdmin"
import DropdownAbout from "./DropdownAbout"
import SubMenuWorkspace from "./SubMenuWorkspace"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function HeaderMenu({ workspace = null, etab = null }) {
    const { userIsWebmaster } = useAppSelector((state) => state.connectedUser)

    const isLocalServer =
        window.location.hostname === "localhost" ||
        window.location.hostname === "127.0.0.1" ||
        window.location.hostname === ""

    return (
        <StyledNavbar
            expand="lg"
            className="app-navbar navbar-expand-lg navbar-dark  bg-gradient no-print "
            user_is_admin={userIsWebmaster ? 1 : 0}
            is_local_server={isLocalServer ? 1 : 0}
            mode_video={modeVideo ? 1 : 0}
        >
            <Container fluid>
                <Navbar.Brand className="align-self-start">
                    <Link to="/" title="Accueil de cmzEDT">
                        <img src="/img/app-logo/app-logo-32.png" alt="cmz" />
                        <div className="app-title me-2">cmzEDT</div>
                    </Link>
                </Navbar.Brand>

                <Navbar.Toggle aria-controls="basic-navbar-nav" />

                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className=" container">
                        <LinkMesEtabs />

                        {etab && <LinkEtabShow id={etab.id} nom={etab.nom} />}

                        {workspace && (
                            <>
                                <LinkEtabShow
                                    id={workspace.etablissement.id}
                                    nom={workspace.etablissement.nom}
                                />
                                <div>
                                    <Link
                                        to={"/edt/" + workspace.id}
                                        className="pb-0"
                                    >
                                        &gt; <small>EDT: </small>
                                        <b>{workspace.nom}</b>
                                    </Link>

                                    <SubMenuWorkspace workspace={workspace} />
                                </div>
                            </>
                        )}
                        <div>
                            {dev && !modeVideo && (
                                <div className="badge bg-danger me-1">dev</div>
                            )}
                            {useLocal && !modeVideo && (
                                <div className="badge bg-danger">useLocal</div>
                            )}
                        </div>
                        <DropdownAbout />
                        <DropdownAdmin />
                        <DropdownConnectedUser />
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </StyledNavbar>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS

const StyledNavbar = styled(Navbar)`
    ${(props) =>
        props.is_local_server &&
        !props.mode_video &&
        (props.user_is_admin
            ? { backgroundColor: "orange" }
            : { backgroundColor: "green" })};

    ${(props) =>
        (!props.is_local_server || props.mode_video) &&
        (props.user_is_admin
            ? { backgroundColor: "#BB4444" }
            : { backgroundColor: theme.colors.c1 })};

    width: 100% !important;

    .navbar-brand {
        font-size: 1em;
        text-align: center;
        padding: 0px;
        a {
            padding: 0px;
            img {
                width: 30px;
                height: 30px;
            }
            .app-title {
                font-size: 0.8em !important;
                padding-left: 5px;
                color: white;
            }
        }
    }

    .navbar-collapse {
    }

    .container-fluid {
        width: 100% !important;
        margin-left: 0px;
        padding: 0px;
    }
    .container {
        width: 100% !important;
        padding: 0px;
        min-width: 100% !important;
        margin-left: 0px;
    }

    .nav-link {
        font-size: 0.9em !important;
    }

    #user-basic-nav-dropdown {
        margin-left: auto !important;
    }
`
