// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
// import styled from "styled-components"
// import { theme } from "styles/theme"

import LPSection from "./layout/LPSection"
import LPSide from "./layout/LPSide"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function SectionGratuit() {
    return (
        <LPSection bgPrimary className="SectionGratuit">
            <LPSide className=" col-md-4 col-12">
                <h2>Pourquoi c’est gratuit ?</h2>
            </LPSide>
            <LPSide className=" col-md-8 col-12">
                <p>
                    J&apos;ai développé ce site web pour le collège ou j'étais
                    AED/TICE.
                    <br />
                    Arrivé à la fin de mon contrat, je souhaite me reconvertir
                    dans le développement d&apos;applications Web.
                    <br />
                    <br />
                    Avoir développé une application utilisée par de nombreux
                    établissements scolaires, témoignerait de mes compétences
                    auprès d&apos;un hypothétique futur employeur.
                    <br />
                    C'est pourquoi, j&apos;ai décidé de rendre ce site
                    disponible gratuitement pour tous les établissements.
                </p>
            </LPSide>
        </LPSection>
    )
}
