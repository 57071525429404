// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { useAppSelector, useAppDispatch } from "utils/store"
import { useNavigate } from "react-router-dom"
import ZxModal from "ui/ZxModal"
import { ZForm } from "uiZ/zForm"
import EtabFormInner from "./EtabFormInner"

import {
    closeFormEtab,
    // etabUpdateSuccess,
    // etabDeleteSuccess,
} from "../utils/etabFormReducer"

import { updateEtabSuccess } from "../utils/etabShowReducer"

import {
    zApiEtabCreate,
    zApiEtabUpdate,
    zApiEtabDelete,
} from "../utils/apiEtabs"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

// todo : importer depuis hPSReducer
interface IFormValues {
    id: number | null
    nom: string
    genre: string | null
    Ville: string | null
    fonctionUserCreator: string | null
}

// eslint-disable-next-line no-unused-vars
export default function EtabForm() {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { etab } = useAppSelector((state) => state.etabForm)

    const customValidate = (values: IFormValues) => {
        let errors = {}
        if (values.nom.length < 4) {
            errors = {
                ...errors,
                nom: "Le nom doit contenir au moins 4 caractères !",
            }
        } else if (values.nom.length >= 30) {
            errors = {
                ...errors,
                nom: "Le nom doit contenir moins de 30 caractères !",
            }
        }
        return errors
    }

    if (!etab) {
        return null
    }

    const createSuccessFunction = (etabId: number) => {
        dispatch(closeFormEtab())
        navigate("/etab/" + etabId)
    }

    const deleteSuccessFunction = () => {
        dispatch(closeFormEtab())
        navigate("/mes-etabs")
    }

    return (
        <ZxModal styles={{ maxWidth: "460px" }}>
            <ZForm
                initialData={etab}
                customValidate={customValidate}
                closeForm={() => dispatch(closeFormEtab())}
                // *** Create:
                createTitle="Ajouter un établissement"
                createFunction={(args: any) => zApiEtabCreate(args)}
                createSuccessFunction={(result: any) =>
                    createSuccessFunction(result.etablissement.id)
                }
                // *** Update:
                updateTitle={`Modifier l'établissement: ${etab?.nom}`}
                updateFunction={zApiEtabUpdate}
                updateSuccessFunction={(result: any) =>
                    dispatch(updateEtabSuccess(result.etablissement))
                }
                // *** Delete:
                deleteTitle={`Supprimer l'établissement: ${etab?.nom}`}
                deleteConfirmText={`Je souhaite supprimer cet établissement, ainsi que son
                personnel, ses EDT, ses postes...`}
                deleteFunction={() => zApiEtabDelete(etab.id || 0)}
                deleteSuccessFunction={(_result: any) =>
                    deleteSuccessFunction()
                }
            >
                <EtabFormInner isUpdate={etab.id !== null} />
            </ZForm>
        </ZxModal>
    )
}
