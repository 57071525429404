/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { FaCheck, FaTimes } from "react-icons/fa"
import styled from "styled-components"
import { NavLink } from "react-router-dom"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function EdtCard({ workspace, className = "" }) {
    return (
        <StyledContainer
            className={"row col-sm-12 col-md-4 col-lg-3 col-xl-2 " + className}
            to={"/edt/" + workspace.id}
        >
            <div className="edtItem ">
                <NavLink className="card-headerX" to={"/edt/" + workspace.id}>
                    <h5>
                        {workspace.nom}{" "}
                        {workspace.actif === true && (
                            <FaCheck
                                className="text-success"
                                title="EDT actif"
                            />
                        )}
                        {workspace.actif === false && (
                            <span className="text-danger" title="EDT inactif">
                                EDT inactif <FaTimes />
                            </span>
                        )}
                    </h5>

                    {workspace.description && (
                        <div className="card-body">
                            <div className="card-text">
                                {workspace.description}
                            </div>
                        </div>
                    )}
                </NavLink>
            </div>
        </StyledContainer>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS

const StyledContainer = styled.div`
    padding: 5px;

    .edtItem {
        text-decoration: none;
        border: 1px solid #0b5b95;
        min-height: 60px;
        display: flex;
        text-align: center;
        padding: 5px;
        a {
            text-decoration: none;
            align-self: center;
            width: 100%;
        }
        &:hover {
            a {
                color: white;
            }

            background-color: #0b5b95;
        }

        .card-header {
            border-bottom: 1px solid #0b5b95;
        }
    }
`
