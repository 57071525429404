// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
// import styled from "styled-components"
// import { theme } from "styles/theme"

import LPSection from "./layout/LPSection"
import LPSide from "./layout/LPSide"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function SectionEssayez() {
    return (
        <LPSection reverse className="SectionEssayez">
            <LPSide className=" col-md-4 col-12">
                <h2>Essayez !</h2>
            </LPSide>
            <LPSide className=" col-md-8 col-12">
                <p>
                    Inscrivez vous pour ajouter votre établissement et créer
                    votre 1er EDT.
                    <br />
                    Vous pouvez aussi consulter un exemple d&apos;EDT pour
                    découvrir ce que verront vos AED.
                </p>
                <strong>Vous n'avez pas le temps ?</strong>
                <p>
                    Vous avez déjà créé vos EDT sur excel et vous n'avez pas le
                    temps de tout recopier sur cmzEDT… <br />
                    Vous pouvez demander à l'un de vos AED de s'en occuper.
                    Accordez lui des droits d'administrateur ou laissez le créé
                    l'établissement.
                </p>
            </LPSide>
        </LPSection>
    )
}
