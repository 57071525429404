/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { useAppSelector, useAppDispatch } from "utils/store"
import Button from "react-bootstrap/Button"
import styled from "styled-components"
import { setFormHoraireMembre } from "../../utils/membresReducer"
import UWHoraire from "./UWHoraire"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
export default function UWsHorairesTdHoraire({
    uw,
    horaires,
    // workspace,
    jour,
}) {
    const dispatch = useAppDispatch()

    const { locked } = useAppSelector((state) => state.timetable)

    function newHoraireClick() {
        const newHoraire = {
            id: null,
            uw,
            jour,
            heureDebut: "07:00",
            heureFin: "18:00",
            semaine: null,
            membreId: uw.id,
            uwPseudo: uw.pseudo,
        }
        dispatch(setFormHoraireMembre(newHoraire))
    }

    return (
        <StyledCell locked={locked}>
            <div className="float-start">
                {horaires.map((horaire) => (
                    <UWHoraire
                        uw={uw}
                        horaire={horaire}
                        key={"hor" + horaire.id}
                    />
                ))}
            </div>

            {!locked && (
                <Button
                    className="float-end bt-add-horaire btn btn-sm btn-primary  mt-1 p-0"
                    onClick={() => newHoraireClick(uw, jour)}
                    title={
                        "Ajouter un horaire pour " + uw.pseudo + " le " + jour
                    }
                >
                    +
                </Button>
            )}
        </StyledCell>
    )
}

const StyledCell = styled.div``
