/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import styled from "styled-components"
import { useAppSelector } from "utils/store"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function LayoutHeader({
    couleur = "yellow",
    children,
    ...props
}) {
    const { orientation, rowHeaderWidth, vHeaderHeight } = useAppSelector(
        (state) => state.timetable
    )
    return (
        <StyledLayoutHeader
            couleur={couleur}
            orientation={orientation}
            rowHeaderWidth={rowHeaderWidth}
            vHeaderHeight={vHeaderHeight}
            {...props}
        >
            {children}
        </StyledLayoutHeader>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS

const StyledLayoutHeader = styled.div`
    background-color: ${(props) => props.couleur};
    padding: 0px;
    position: sticky;
    display: inline-block;
    text-overflow: clip;
    z-index: 200;
    //  background-color: lime; // **** debugOnly
    //  padding: 10px; // **** debugOnly

    /*

	    border: 2px dotted red;
    */
    .pseudo {
        height: 50%;
        // background-color: cyan;
        text-overflow: hidden;
        // color: red;
        width: 100%;
        // font-weight: bold;
        font-size: 1em;
        white-space: nowrap;
    }

    .horaires {
        height: 50%;
        font-size: 0.6em;
        // background-color: yellow;
        white-space: nowrap;
        .badge-sem-A {
            background-color: green;
        }
        .badge-sem-B {
            background-color: blue;
        }
    }

    ${(props) =>
        props.orientation === "HORIZONTAL"
            ? `
        width: ${props.rowHeaderWidth + "px"};
        height : 100%;
        top: 0px;
    `
            : `
        width: 100%;
        height : ${props.vHeaderHeight + "px"}; 
        text-overflow: clip;
        top: 0;
        margin-top: 0px;
        overflow: hidden;
        text-align: center;


    `}
`
