/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { useAppDispatch } from "utils/store"
import Button from "react-bootstrap/Button"
import { IcoPrint } from "styles/icones"
import { setLocked } from "features/timetable/utils/timetableReducer"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function ButtonPrintOpenModal({ setModalPrintIsOpen }) {
    const dispatch = useAppDispatch()
    const onClick = () => {
        dispatch(setLocked(true))
        setModalPrintIsOpen(true)
    }
    return (
        <Button
            className="btn-sm me-2 no-print"
            title="Imprimer"
            onClick={onClick}
            variant="info"
        >
            <IcoPrint />
        </Button>
    )
}
