/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import styled from "styled-components"
import Button from "react-bootstrap/Button"
import { FaTrashAlt } from "react-icons/fa"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function ZFormHeader({
    title,
    editMode = true,
    isLoading = false,
    actionDelete,
    showDeleteButton,
}) {
    return (
        <StyledHeader className="zxFormHeader col-12">
            <span className="formTitle">{title}</span>

            {editMode && showDeleteButton && !isLoading && <ButtonDelete action={actionDelete} />}
        </StyledHeader>
    )
}

function ButtonDelete({ action }) {
    return (
        <Button className="btn-danger btn-sm float-end" onClick={action} tabIndex="0">
            <FaTrashAlt /> Supprimer
        </Button>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS

const StyledHeader = styled.div`
    /*background-color: yellow;*/
    width: 100%;
    margin-bottom: 20px;
    padding-bottom: 5px;
    border-bottom: 1px solid red;
    .formTitle {
        font-size: 1.3em;
        color: red;
    }
`
