/* eslint-disable react/no-unstable-nested-components */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import ZTable from "ui/zTable/ZTable"
import { NavLink } from "react-router-dom"
import { ZPageSection } from "uiZ/zPage"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
interface Etab {
    personnels: []
    edts: []
    useCmzEdt: boolean
    useCmzDp: boolean
}

type Props = {
    etabs: Etab[]
}
export default function DashboardEtabs({ etabs }: Props) {
    // const [formEditUserIsOpen, setFormEditUserIsOpen] = useState(false)

    const formatedEtabs = etabs.map((etab) => ({
        ...etab,
        personnelsCount: etab.personnels?.length,
        edtsCount: etab.edts?.length,
        problems:
            (etab.useCmzEdt ? "" : "[can't_useCmzEdt] ") +
            (etab.useCmzDp ? "[useCmzDP] " : ""),
    }))

    const etabsTableColumns = [
        {
            name: "edit",
            text: "Edit.",
            // eslint-disable-next-line react/no-unstable-nested-components
            // cellHtml: (eleve: any) => <ButtonEleveEdit eleve={eleve} />,
        },

        {
            name: "nomxx",
            text: "nomxx",
            cellHtml: (etab: any) => (
                <>
                    <NavLink className="card-headerX" to={"/etab/" + etab.id}>
                        {etab.nom}
                    </NavLink>
                    <small>
                        {etab.genre && " - " + etab.genre}
                        {etab.ville && " (" + etab.ville + ")"} #{etab.id}
                    </small>
                </>
            ),
        },
        /*        
        { name: "id", text: "ID" },
        {
            name: "nom",
            text: "nom",
            cellHtml: (etab: any) => (
                <NavLink className="card-headerX" to={"/etab/" + etab.id}>
                    {etab.nom}
                </NavLink>
            ),
        },
        { name: "genre", text: "genre" },
        { name: "ville", text: "ville" },
        */
        { name: "createdAt", text: "createdAt" },
        {
            name: "createdBy",
            text: "createdBy",
            cellHtml: (etab: any) => etab.createdBy.username,
        },
        { name: "personnelsCount", text: "personels Count" },
        { name: "edtsCount", text: "edt Count" },
        { name: "problems", text: "problems" },
    ]

    const etabsFuncRowClassName = (item: any) => {
        let className = ""
        if (item.xxxxxxxxxxxxxxx) {
            className += " bg-secondary "
        }
        return className
    }

    return (
        <ZPageSection>
            <h2>{etabs.length} établissement(s)</h2>

            <div className="zzAutoScrollX">
                <ZTable
                    className="table table-bordered table-sm table-striped table-hover"
                    columns={etabsTableColumns}
                    data={formatedEtabs}
                    funcRowClassName={etabsFuncRowClassName}
                />
            </div>
        </ZPageSection>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS
