/* eslint-disable arrow-body-style */
/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { useAppSelector, useAppDispatch } from "utils/store"
import { setJour } from "features/timetable/utils/timetableReducer"
// import ButtonFilter from "./ButtonFilter"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function FilterJours() {
    const { horaireEdts, jour } = useAppSelector((state) => state.timetable)
    // console.log("FilterJours - workspace", workspace)

    const dispatch = useAppDispatch()
    const jours = [
        "Lundi",
        "Mardi",
        "Mercredi",
        "Jeudi",
        "Vendredi",
        "Samedi",
        "Dimanche",
    ]
    return (
        <div>
            <select
                id="jour"
                name="jour"
                type="select"
                onChange={(ev) => dispatch(setJour(ev.target.value))}
                value={jour}
            >
                {jours.map((jr) => {
                    const jourOuvert =
                        horaireEdts?.filter((hor) => hor.jour === jr).length > 0
                    if (!jourOuvert) {
                        return null
                    }
                    return (
                        <option value={jr} key={`sel-jour-${jr}`}>
                            {jr}
                        </option>
                    )
                })}
            </select>
        </div>
    )
}
