/* eslint-disable no-use-before-define */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React, { useState } from "react"
import Form from "react-bootstrap/Form"
import FloatingLabel from "react-bootstrap/FloatingLabel"
import Button from "react-bootstrap/Button"
import styled from "styled-components"
import { Loading } from "ui"
import { apiRegister } from "../utils/apiAuthentification"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function FormRegister() {
    const [email, setEmail] = useState("")
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [password2, setPassword2] = useState("")

    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)

    function validateForm() {
        return (
            username.length > 0 && password.length > 0 && password === password2
        )
    }

    const handleSubmitClick = (event) => {
        event.preventDefault()
        setError(false)
        setSuccess(false)
        setLoading(true)

        apiRegister(username, email, password).then((reponse) => {
            console.log("apiRegister.reponse:", reponse)
            if (reponse.success) {
                setSuccess(true)
                // dispatch(login(email))
            } else if (reponse.error) {
                setError(reponse.error)
            }
            setLoading(false)
        })
    }

    if (success) {
        return (
            <h3 className="text-success">
                Votre inscription a bien été prise en compte.
                <br /> <br />
                Verifiez vos mails pour valider votre adresse email.
            </h3>
        )
    }

    return (
        <StyledLoginForm onSubmit={handleSubmitClick}>
            {/* //********************************* */}
            <FloatingLabel
                controlId="email"
                label="Adresse eMail"
                className="mb-3"
            >
                <Form.Control
                    autoFocus
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="name@example.com"
                />
                {/* <Form.Text>Celle du mail que vous avez reçu.</Form.Text> */}
            </FloatingLabel>
            {/* //********************************* */}
            <FloatingLabel controlId="username" label="Nom d'utilisateur">
                <Form.Control
                    type="username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />
                <Form.Text>
                    C&apos;est l&apos;identifiant qui vous permettra de vous
                    connecter.
                </Form.Text>
            </FloatingLabel>
            {/* //********************************* */}
            <FloatingLabel
                controlId="password"
                label="Mot de passe"
                className="mt-2"
            >
                <Form.Control
                    type="password"
                    value={password}
                    autoComplete="off"
                    onChange={(e) => setPassword(e.target.value)}
                />
            </FloatingLabel>
            {/* //********************************* */}
            <FloatingLabel
                controlId="password2"
                label="Confirmez le mot de passe"
                className="mt-2"
            >
                <Form.Control
                    type="password"
                    value={password2}
                    autoComplete="off"
                    onChange={(e) => setPassword2(e.target.value)}
                />
                {password2 !== "" && password2 !== password && (
                    <p className="text-danger">
                        Les 2 mots de passe sont différent.
                    </p>
                )}
            </FloatingLabel>
            {/* //********************************* */}

            {error && <div className="text-danger">{error}</div>}
            {loading && <Loading />}

            {!success && !loading && (
                <Button
                    block="true"
                    size="lg"
                    type="submit"
                    disabled={!validateForm()}
                    className="mt-2"
                >
                    Inscription
                </Button>
            )}
        </StyledLoginForm>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS

const StyledLoginForm = styled(Form)``
