/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
// import styled from "styled-components"

import { ZPage, ZPageContent, ZPageSection } from "uiZ/zPage"
import { NavLink } from "react-router-dom"
import { IcoDanger } from "styles/icones"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function Page404() {
    return (
        <ZPage documentTitle="404">
            <ZPageContent>
                <ZPageSection>
                    <h1 className="text-danger">
                        {" "}
                        <IcoDanger /> 404 - Cette page n&apos;exite pas !
                    </h1>
                    <div className="mt-4">
                        <NavLink to="/">Retour à l&apos;accueil</NavLink>
                    </div>
                </ZPageSection>
            </ZPageContent>
        </ZPage>
    )
}
/*
const StyledBadgePage = styled.div`
    background-color: white;
    border-radius: 25%;
    color: red;
    padding: 5px;
    padding-top: 0px;
`
*/
