/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import Button from "react-bootstrap/Button"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function FormFieldToggle({
    name,
    labelOn,
    labelOff,
    hint = null,
    formik,
    className = "",
    inputProps = null,
    ...props
}) {
    return (
        <div className={className + " row col-12 pb-4"} {...props}>
            <Button
                variant={formik.values[name] ? "primary" : "secondary"}
                id={name}
                name={name}
                onClick={() => formik.setFieldValue(name, !formik.values[name])}
                value={formik.values[name]}
                {...inputProps}
            >
                {formik.values[name] ? labelOn : labelOff}
            </Button>
            {hint ? <div className="text-info">{hint}</div> : null}
            {formik.errors[name] ? (
                <div className="text-danger">{formik.errors[name]}</div>
            ) : null}
        </div>
    )
}
