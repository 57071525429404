/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { useAppSelector } from "utils/store"
// import styled from 'styled-components'

import LayoutContent from "../layouts/LayoutContent"

import LayoutItem from "../layouts/LayoutItem"
// import LayoutItem from "../layouts/LayoutItem"
import ItemHoraire from "./ItemHoraire"
import ItemPostage from "./ItemPostage"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function EdtUserContent({ membre }) {
    const { jour, sonneries, showHoraires, showPostages } = useAppSelector(
        (state) => state.timetable
    )

    const separations =
        !sonneries || sonneries.length === 0
            ? [
                  { id: 31, heure: "01:00", nom: null },
                  { id: 32, heure: "02:00", nom: null },
                  { id: 33, heure: "03:00", nom: null },
                  { id: 34, heure: "07:00", nom: null },
                  { id: 35, heure: "05:00", nom: null },
                  { id: 36, heure: "06:00", nom: null },

                  { id: 1, heure: "07:00", nom: null },
                  { id: 2, heure: "08:00", nom: null },
                  { id: 3, heure: "09:00", nom: null },
                  { id: 4, heure: "10:00", nom: null },
                  { id: 5, heure: "11:00", nom: null },
                  { id: 6, heure: "12:00", nom: null },
                  { id: 7, heure: "13:00", nom: null },
                  { id: 8, heure: "14:00", nom: null },
                  { id: 9, heure: "15:00", nom: null },
                  { id: 10, heure: "16:00", nom: null },
                  { id: 11, heure: "17:00", nom: null },
                  { id: 12, heure: "18:00", nom: null },
                  { id: 13, heure: "19:00", nom: null },
                  { id: 14, heure: "20:00", nom: null },
                  { id: 15, heure: "21:00", nom: null },
                  { id: 16, heure: "22:00", nom: null },
                  { id: 17, heure: "23:00", nom: null },
              ]
            : sonneries

    return (
        <LayoutContent
            bg={membre.couleur}
            genre="USER"
            membreId={membre.id}
            membrePseudo={membre.pseudo}
        >
            {showHoraires &&
                membre?.horaires
                    ?.filter((hor) => hor.jour === jour)
                    // .sort((a,b) => (a.heureDebut > b.heureDebut) ? 1 : ((b.heureDebut > a.heureDebut) ? -1 : 0))
                    .map((hor) => (
                        <ItemHoraire
                            key={"postage-" + hor.id}
                            horaire={hor}
                            showText={!showPostages}
                            membreId={membre.id}
                            membrePseudo={membre.pseudo}
                        />
                    ))}

            {separations?.map((sonnerie) => (
                <LayoutItem
                    key={"per-" + sonnerie.id}
                    style={{ fontSize: "0.5em" }}
                    heureDebut={sonnerie.heure}
                    heureFin={sonnerie.heure}
                    styles={{
                        // backgroundColor: sonnerie.couleur,
                        border: "1px solid #DDDDDD",
                        overflow: "visible",
                    }}
                >
                    {/* {sonnerie.heure} */}
                    {/* : {sonnerie.nom}    */}
                </LayoutItem>
            ))}

            {showPostages &&
                membre?.postages
                    ?.filter((post) => post.jour === jour)
                    .map((postage) => (
                        <ItemPostage
                            key={"postage-" + postage.id}
                            style={{ fontSize: "0.6em" }}
                            postage={postage}
                            membre={membre}
                        />
                    ))}
        </LayoutContent>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS
