/* eslint-disable react/no-array-index-key */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
// import styled from "styled-components"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function AboutCardDone() {
    const doneItems = [
        {
            date: "2022/02/07",
            content: "Carole veut créer, editer et supprimer des postes.",
        },
        { date: "2022/01/29", content: "+ Scrum Project Management Board." },
        {
            date: "2022/??/??",
            content:
                "Carole veut se connecter et ne pas à avoir à taper son mot de passe à chaque connexion.",
        },
    ]

    return (
        <div className="card border-success">
            <h5 className="card-header bg-success text-white">
                Done <small>(journal des modifications)</small>
            </h5>
            <div className="card-body">
                <div className="card-text">
                    {doneItems.map((item, i) => (
                        <div
                            key={"done" + i}
                            className="card border-success mb-1"
                        >
                            <div className="card-body p-1">
                                <div className="card-text">
                                    <div className="content">
                                        <span className="date">
                                            {item.date}
                                        </span>
                                        {item.content}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS

// const StyledItem = styled.div``
