// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { useAppSelector, useAppDispatch } from "utils/store"
import ZxModal from "ui/ZxModal"
import { ZForm } from "uiZ/zForm"

import {
    IPersonnel,
    setFormPersonnel,
    personnelCreateSuccess,
    personnelUpdateSuccess,
    personnelDeleteSuccess,
} from "features/etabs/utils/etabShowReducer"
import {
    zApiPersonnelCreate,
    zApiPersonnelUpdate,
    zApiPersonnelDelete,
} from "features/personnels/utils/apiPersonnels"

import PersonnelFormInner from "./PersonnelFormInner"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function PersonnelForm() {
    const dispatch = useAppDispatch()
    const { formPersonnel } = useAppSelector((state) => state.etabShow)
    const etabId = useAppSelector((state) => state.etabShow.etab?.id)

    console.log("formPersonnel:::", formPersonnel)

    const customValidate = (values: IPersonnel) => {
        let errors = {}

        if (values.pseudo.length < 2) {
            errors = {
                ...errors,
                pseudo: "Le pseudo doit contenir au moins 2 caractères !",
            }
        } else if (values.pseudo.length >= 20) {
            errors = {
                ...errors,
                pseudo: "Le pseudo doit contenir moins de 20 caractères !",
            }
        }

        return errors
    }

    if (!formPersonnel) {
        return <h1>!formPersonnel</h1>
    }

    const createSuccessFunction = (personnel: IPersonnel) => {
        dispatch(personnelCreateSuccess(personnel))
    }

    const updateSuccessFunction = (personnel: IPersonnel) => {
        dispatch(personnelUpdateSuccess(personnel))
        // dispatch(updateMembreSuccess(result))
    }
    const deleteSuccessFunction = () => {
        dispatch(personnelDeleteSuccess(formPersonnel.id))
    }

    return (
        <ZxModal styles={{ maxWidth: "460px" }}>
            <ZForm
                initialData={formPersonnel}
                customValidate={customValidate}
                closeForm={() => dispatch(setFormPersonnel(null))}
                // *** Create:
                createTitle="Ajouter un personnel à l'établissement"
                createFunction={(args: any) =>
                    zApiPersonnelCreate(args, etabId || 0)
                }
                createSuccessFunction={(result: any) =>
                    createSuccessFunction(result.personnel)
                }
                // *** Update:
                updateTitle={`Modifier un personnel de l'établissement: ${formPersonnel?.pseudo}`}
                updateFunction={zApiPersonnelUpdate}
                updateSuccessFunction={(result: any) =>
                    updateSuccessFunction(result.personnel)
                }
                // *** Delete:
                deleteTitle={`Supprimer un personnel de l'établissement: ${formPersonnel?.pseudo}`}
                deleteConfirmText="Je souhaite supprimer ce personnel de l'EDT, ainsi que
                        son ses horaires et postages... Si la personne a quitté l'établissement, il faut la désactiver, mais pas la supprimer !"
                deleteFunction={() =>
                    zApiPersonnelDelete(formPersonnel.id || 0)
                }
                deleteSuccessFunction={(_result: any) =>
                    deleteSuccessFunction()
                }
            >
                <PersonnelFormInner />
            </ZForm>
        </ZxModal>
    )
}
