// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
// import styled from "styled-components"
// import { theme } from "styles/theme"

import LPSection from "./layout/LPSection"
import LPSide from "./layout/LPSide"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function SectionConsulter() {
    return (
        <LPSection bgPrimary className="SectionConsulter">
            <LPSide className="col-md-8 col-12">
                <h2>
                    Consultez les postages en un clin d'oeil
                    <br />
                    depuis n'importe où.
                </h2>
                <p>
                    Placez un favoris sur les <b>PC</b> de la vie sco et sur
                    votre <b>téléphone</b> pour consulter les postages du jour
                    en quelques secondes.
                </p>
            </LPSide>
            <LPSide className=" col-md-4 col-12">
                <div className=" col-12 text-center">
                    <img
                        style={{
                            height: "400px",
                            border: "6px solid black",
                            borderRadius: "15px",
                            transform: "rotate(4deg)",
                            boxShadow: "10px 10px 20px black",
                        }}
                        src="img/landing/gif-smartphone.gif"
                        alt="EDT"
                    />
                </div>
            </LPSide>
        </LPSection>
    )
}
