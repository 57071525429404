// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
// import styled from "styled-components"
// import { theme } from "styles/theme"

import LPSection from "./layout/LPSection"
import LPSide from "./layout/LPSide"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function SectionEdtSurMesure() {
    return (
        <LPSection className="SectionEdtSurMesure" reverse>
            <LPSide className="col-md-7 col-12">
                <h2>Créez des EDT sur mesure.</h2>
                <ul>
                    <li>
                        Personnalisez les <strong>horaires</strong> et{" "}
                        <strong>sonneries</strong> de l'EDT.
                    </li>
                    <li>
                        Gestion du <strong>temps de travail</strong> des AED.{" "}
                        <small>
                            (nombre d'heures, quotité, nuits, horaires...)
                        </small>
                    </li>
                    <li>
                        Personnalisez les <strong>Postes</strong>{" "}
                        <small>(Vie sco, Portail, Etude...)</small> avec des
                        couleurs et des déscriptions.
                    </li>
                    <li>
                        Prise en charge des établissements avec{" "}
                        <strong>internat</strong>.
                    </li>
                    <li>
                        Prise en charge des <strong>semaines A et B</strong>.
                    </li>
                </ul>
            </LPSide>
            <LPSide className=" col-md-5 col-12">
                <img
                    style={{ width: "100%" }}
                    // src="img/landing/postages.png"
                    src="img/landing/postages2-horizontal.png"
                    alt="EDT"
                />
            </LPSide>
        </LPSection>
    )
}
