/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import PersonnelsListe from "features/personnels/personnelsListe/PersonnelsListe"
import { ButtonPersonnelCreate } from "features/personnels"
import { ZPageSection } from "uiZ/zPage"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function EtabShowSectionPersonnels({ etab, userIsAdminEtab }) {
    return (
        <ZPageSection className="col-12 zzAutoScrollX">
            <div className="section-header">
                <h2 className="float-start">
                    Personnel de l&apos;établissement
                </h2>
                {userIsAdminEtab && (
                    <ButtonPersonnelCreate className="float-end" />
                )}
            </div>

            <p>Ajouter du personnel (AEC, SC, CPE) à cet établissement</p>

            <div className="zzAutoScrollX">
                <PersonnelsListe
                    personnels={etab.personnels}
                    userIsAdminEtab={userIsAdminEtab}
                />
            </div>
        </ZPageSection>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS
