/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
// import styled from "styled-components"
import Button from "react-bootstrap/Button"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function ButtonFilter({
    className,
    onClick,
    actif,
    children,
    ...props
}) {
    let classNameX = "ms-auto btn-sm " + className
    classNameX += actif ? " btn-primary " : " btn-light btn-outline-primary "

    return (
        <Button
            onClick={() => onClick()}
            className={classNameX + " no-print"}
            {...props}
            // style={{ padding: "1px" }}
        >
            {children}
        </Button>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS
