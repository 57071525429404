/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { Button } from "react-bootstrap"
import { useAppSelector, useAppDispatch } from "utils/store"
import { setFormPostage1stSemaine } from "features/timetable/utils/timetableReducer"

// import styled from "styled-components"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function PostageFormSemaines() {
    const { formPostage1stSemaine, modeHoraires } = useAppSelector(
        (state) => state.timetable
    )
    const dispatch = useAppDispatch()

    return (
        <div className={"no-print " + (modeHoraires ? "ms-2" : "")}>
            Semaine
            <Button
                variant={
                    formPostage1stSemaine !== "A" &&
                    formPostage1stSemaine !== "B"
                        ? "primary"
                        : "secondary"
                }
                className="m-1 btn-sm"
                onClick={() => setSemaine(null)}
            >
                A&B
            </Button>
            <Button
                variant={
                    formPostage1stSemaine === "A" ? "primary" : "secondary"
                }
                className="m-1 btn-sm"
                onClick={() => setSemaine("A")}
            >
                A
            </Button>
            <Button
                variant={
                    formPostage1stSemaine === "B" ? "primary" : "secondary"
                }
                className="m-1 btn-sm"
                onClick={() => setSemaine("B")}
            >
                B
            </Button>
        </div>
    )

    function setSemaine(sem) {
        dispatch(setFormPostage1stSemaine(sem))
    }
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS

// const StyledForm = styled.div``
