// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { ZField } from "uiZ/zForm"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
type Props = {
    isUpdate: boolean
}
export default function EtabFormInner({ isUpdate }: Props) {
    return (
        <div className="row col-12">
            {/* 
            <div className="text-danger">
                <b>Si vous avez reçu une invitation</b>, ce n'est pas la peine
                de créer un établissement{" "}
                <small>(sauf si vous voulez en créer un autre)</small>. Votre
                CPE va vous ajouter!
                <br />
                <br />
            </div>
            */}

            <ZField autoFocus name="nom" label="nom" type="text" />

            <ZField
                name="genre"
                label="Type d'établissement"
                type="select"
                selectOptions={[
                    { value: null, text: "- Indéterminé -" },
                    {
                        value: "École Élémentaire",
                        text: "École Élémentaire",
                    },
                    { value: "Collège", text: "Collège" },
                    { value: "Lycée", text: "Lycée" },
                ]}
            />
            <ZField name="ville" label="Ville (facultatif)" type="text" />
            {!isUpdate && (
                <ZField
                    name="fonctionUserCreator"
                    label="Votre fonction dans cet établissement"
                    type="select"
                    selectOptions={[
                        { value: null, text: "- Indéterminée -" },
                        { value: "CPE", text: "CPE" },
                        { value: "PR", text: "Pincipal(e)" },
                        { value: "PR-ADJ", text: "Pincipal(e) adjoint(e)" },
                        { value: "DIR", text: "Directeur/Directrice" },
                        { value: "AED", text: "AED" },
                        { value: "SC", text: "Service Civique" },
                    ]}
                />
            )}
        </div>
    )
}
