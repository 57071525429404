/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { useAppSelector, useAppDispatch } from "utils/store"
import styled from "styled-components"
import { setFormHoraireMembre } from "../../utils/membresReducer"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
export default function UWHoraire({ uw, horaire }) {
    const dispatch = useAppDispatch()

    const { locked } = useAppSelector((state) => state.timetable)
    return (
        <StyledHoraire
            locked={locked}
            key={"hhh" + horaire.id}
            className={"horaire " + (!locked && " unlocked")}
            onClick={() => editHoraireClick(horaire)}
            title={
                locked
                    ? "Cliquez sur le cadenas pour déverouiller"
                    : "Modifier l'horaire pour " + uw.pseudo
            }
        >
            {`${horaire.heureDebut} > ${horaire.heureFin}`}
            {horaire.semaine !== null && (
                <span
                    className={
                        "badge  badge-sem bg-warning sem-" + horaire.semaine
                    }
                >
                    {" "}
                    {horaire.semaine}
                </span>
            )}
        </StyledHoraire>
    )

    function editHoraireClick() {
        if (locked) {
            alert("Cliquez sur le cadenas pour deverouiller les horaires")
            return
        }
        dispatch(
            setFormHoraireMembre({
                ...horaire,
                membreId: uw.id,
                uwPseudo: uw.pseudo,
            })
        )
    }
}

const StyledHoraire = styled.div`
    ${(props) =>
        !props.locked
            ? `
                cursor: pointer;
                    color: blue;
                &:hover{
                    text-decoration: underline;
                }
            `
            : `
                color: black;
                &:hover{
                    text-decoration: underline;
                }
            `};

    .badge {
        font-weight: inherit;
        margin-left: 3px;
        &.sem-A {
            background-color: green !important;
        }
        &.sem-B {
            background-color: blue !important;
        }
    }
`
