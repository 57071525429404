/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import styled from "styled-components"
import PosteItem from "./PosteItem"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function PostesGroupe({ postesGroupe, setSelectedPoste }) {
    return (
        <StyledGroupe className="groupe  mb-4     col-6   col-md-4   col-lg-2">
            <h5>
                {postesGroupe.nom !== "" ? postesGroupe.nom : "Sans groupe"}
            </h5>

            <div className="postes row col-12">
                {postesGroupe.postes.map((poste) => (
                    <PosteItem
                        key={"poste" + poste.id}
                        poste={poste}
                        setSelectedPoste={setSelectedPoste}
                    />
                ))}
            </div>
        </StyledGroupe>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS

const StyledGroupe = styled.div`
    border-right: 1px solid black;
    padding: 5px;
`
