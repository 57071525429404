// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
// import styled from "styled-components"
// import { theme } from "styles/theme"

import LPSection from "./layout/LPSection"
import LPSide from "./layout/LPSide"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function SectionIntro() {
    return (
        <LPSection reverse className="SectionIntro">
            <LPSide className="col-md-7 col-12">
                <h2>
                    <img
                        style={{ height: "96px" }}
                        src="img/app-logo/app-logo-96.png"
                        alt="EDT"
                    />
                </h2>
                <div>
                    Développé par un AED/TICE, <strong>cmzEDT</strong> est un
                    outil en ligne <strong>gratuit</strong>, permettant de créer
                    et de consulter facilement{" "}
                    <h2>les postages de la vie scolaire.</h2>
                </div>
            </LPSide>
            <LPSide className=" col-md-5 col-12">
                <img
                    style={{ width: "100%" }}
                    //  src="img/landing/gif-postages-now.gif"
                    src="img/landing/postages2.png"
                    alt="EDT"
                />
            </LPSide>
        </LPSection>
    )
}
