// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React, { useState } from "react"
import styled from "styled-components"
import { Button } from "react-bootstrap"
import Logsjour from "./LogsJour"
import { Log } from "../DashboardLogs"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

type Props = {
    logs: Log[]
}
export default function LogsbyHour({ logs }: Props) {
    const [hideMe, setHideMe] = useState(true)

    const filteredLogs = logs.filter(
        (log) => !hideMe || (log.user && ![1, 4, 567].includes(log.user?.id))
    )

    const differentUsersIds = filteredLogs
        .map((item) => item.user?.id)
        .filter((value, index, self) => index && self.indexOf(value) === index) // "index &&...." pour enlever les logs sans user (loginFail)

    let currentDate = new Date(Date.now())
    const jours = []

    const lastDate = new Date("2022-09-08") // la date ou j'ai commencé à enregistrer les logs

    do {
        jours.push(jsDateToString(currentDate))
        currentDate = new Date(currentDate.getTime() - 1000 * 60 * 60 * 24)
    } while (jsDateToString(currentDate) >= jsDateToString(lastDate))

    const dateHier = new Date(Date.now() - 1000 * 60 * 60 * 24)

    console.log("------------------------")
    console.log("lastDate", jsDateToString(lastDate))
    console.log("currentDate", jsDateToString(currentDate))
    console.log("dateHier", jsDateToString(dateHier))

    function jsDateToString(jsDate: Date) {
        const month = jsDate.getMonth() + 1
        const monthWithZero = month < 10 ? "0" + month : month
        const dayWithZero =
            jsDate.getDate() < 10 ? "0" + jsDate.getDate() : jsDate.getDate()
        return jsDate.getFullYear() + "/" + monthWithZero + "/" + dayWithZero
    }

    return (
        <>
            <div className="row col-12">
                <h2>DashboardLogs: By Day And Hour</h2>

                <div>
                    <b>{filteredLogs.length}</b> logs depuis le 2022/09/08
                </div>
                <div className="ms-auto">
                    <b>{differentUsersIds.length}</b> utilisateurs differents
                </div>
            </div>
            <Button
                onClick={() => setHideMe(!hideMe)}
                variant={hideMe ? "danger" : "primary"}
            >
                HideMe
            </Button>
            <br />

            <StyledContainer className="row mb-2">
                {jours.map((jr) => (
                    <Logsjour logs={filteredLogs} jour={jr} key={"jour" + jr} />
                ))}
            </StyledContainer>
        </>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS

const StyledContainer = styled.div`
    // background-color: orange;
    padding: 5px;
`
