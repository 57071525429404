// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { ZPageContent } from "uiZ/zPage"

import { navHeaderPublicHeight } from "ui/headerMenu/HeaderMenuPublic"
import SectionIntro from "./Section02Intro"
// import SectionAuthentification from "./Section01Authentification"
import SectionConsulter from "./Section03Consulter"
import SectionEdtSurMesure from "./Section04EdtSurMesure"
import SectionSimpleEtIntuitif from "./Section05SimpleEtIntuitif"
import SectionFonctionnalites from "./Section06Fonctionnalites"
import SectionEssayez from "./Section08Essayez"
import SectionGratuit from "./Section07Gratuit"

export default function LandingPage2() {
    // console.log("------LP----------")
    return (
        <ZPageContent style={{ paddingTop: navHeaderPublicHeight + "px" }}>
            {/*  <SectionAuthentification /> */}
            <SectionIntro />
            <SectionConsulter />
            <SectionEdtSurMesure />
            <SectionSimpleEtIntuitif />
            qqqqqssqqqqcqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqssssssssss ss
            s sq qq qqq qqqqqssqqqqcqqq qfsdfq dsf qq qq qq qq qq qq qq qq qq qq
            qq qq qq qqq
            <SectionFonctionnalites />
            <SectionGratuit />
            <SectionEssayez />
        </ZPageContent>
    )
}
