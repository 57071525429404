/* eslint-disable no-unused-vars */ // !!!!!!!!!!!!!!!
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React, { useState } from "react"
import { useAppSelector } from "utils/store"
import Button from "react-bootstrap/Button"
import HoraireJour from "./HoraireJour"
import HoraireForm from "./form/HoraireForm"

// import FormHoraireEtab from './FormHoraireEtab';

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
export default function CardHoraires() {
    const { workspace, horaireForm } = useAppSelector((state) => state.hPS)
    const horaires = useAppSelector((state) => state.hPS.workspace.horaireEdts)
    const jours = [
        "Lundi",
        "Mardi",
        "Mercredi",
        "Jeudi",
        "Vendredi",
        "Samedi",
        "Dimanche",
    ]

    const [selectedHoraire, setSelectedHoraire] = useState(null)

    function selectionnerHoraire(hor) {
        setSelectedHoraire(hor)
    }

    function AddHoraireClick(jour) {
        console.log("AddHoraireClick:" + jour)
        const newHoraire = {
            id: null,
            jour,
            heureDebut: "07:00",
            heureFin: "18:00",
        }

        setSelectedHoraire(newHoraire)
    }

    return (
        <div className="card etab-card-horaires">
            {horaireForm && <HoraireForm edtId={workspace.id} />}

            <div className="card-header">
                <h5 className="card-title">Horaires</h5>
            </div>
            <div className="card-body">
                <div className="card-text">
                    <div className="horaires row col-12 mt-3">
                        {jours.map((jour) => {
                            const horairesJour = horaires.filter(
                                (hor) => hor.jour === jour
                            )
                            return (
                                <HoraireJour
                                    key={"hj_" + jour}
                                    horairesJour={horairesJour}
                                    jour={jour}
                                />
                            )
                        })}
                    </div>
                </div>
            </div>

            <div className="card-footer">
                <div className="infos">
                    * Ce sont les horaires affichés dans l&apos;EDT.
                    <br />
                    {/* 
                    * Note importante pour les internats.
                    <br />
                    */}
                    * Si une journée n&apos;a pas d&apos;horaire, elle
                    n&apos;apparaitra pas dans les postage.
                </div>
            </div>
        </div>
    )
}
