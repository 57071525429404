/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { Navbar, Nav } from "react-bootstrap"

import styled from "styled-components"
import { dev, useLocal } from "utils/constants"
import { theme } from "styles/theme"
import { Link /* , LinkMesEtabs, LinkEtabShow */ } from "./Links"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export const navHeaderPublicHeight = 70

export default function HeaderMenuPublic({ variant }) {
    let extraClassName = variant + " "
    extraClassName += variant === "toLight" ? " navbar-light" : " navbar-dark "

    return (
        <StyledNavbar
            className={
                extraClassName + " app-navbar fixed-top navbar-expand no-print "
            }
        >
            <div id="navbarcontainer" className="row col-12">
                <div id="navbarBrand">
                    <Link to="/" className="float-start">
                        <img src="/img/app-logo/app-logo-32.png" alt="cmz" />
                        <span className="site-title me-2">
                            cmzEDT.zedixi.com
                        </span>
                    </Link>

                    <div className="float-end">
                        {dev && <div className="badge bg-danger me-1">dev</div>}
                        {useLocal && (
                            <div className="badge bg-danger">useLocal</div>
                        )}
                    </div>
                </div>

                <Nav id="navbarNavs">
                    <Link to="/login">Connexion</Link>{" "}
                    <Link to="/register">Inscription</Link>{" "}
                    <Link to="/exemple/89">Démo</Link>
                    <Link to="/docs">Documentation</Link>
                    {/* 
                        <DropdownAdmin user={user} />
                        <DropdownConnectedUser user={user} />
                        */}
                </Nav>
            </div>
        </StyledNavbar>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS

const StyledNavbar = styled(Navbar)`
    // border: 3px solid red;
    background-color: ${theme.colors.c1};
    width: 100% !important;
    padding: 0px;

    &.primary {
        background-color: ${theme.colors.c1};
        color: rgba(255, 255, 255, 0.55);

        a,
        .nav-link,
        .site-title {
            background-color: ${theme.colors.c1};
            color: rgba(255, 255, 255, 0.55);
        }
    }

    &.toPrimary {
        background-color: ${theme.colors.c1};
        color: rgba(255, 255, 255, 0.55);
        animation: lightToPrimary 600ms;

        a,
        .nav-link,
        .site-title {
            animation: lightToPrimary 600ms;
            color: rgba(255, 255, 255, 0.55);
            /*
            color: white;
            &.active {
                color: white;
            }
            */
        }
    }

    &.toLight {
        background-color: white;
        color: ${theme.colors.c1};
        animation: primaryToLight 600ms;

        a,
        .nav-link,
        .site-title {
            animation: primaryToLight 600ms;
            color: ${theme.colors.c1};
            &.active {
                color: ${theme.colors.c1};
            }
        }
    }

    @keyframes primaryToLight {
        from {
            background-color: ${theme.colors.c1};
            color: rgba(255, 255, 255, 0.55);
            a,
            .nav-link {
                //  color: white;
                color: red;
            }
        }
        to {
            background-color: white;
            color: ${theme.colors.c1};
            a {
                color: ${theme.colors.c1};
            }
        }
    }

    @keyframes lightToPrimary {
        from {
            background-color: white;
            color: ${theme.colors.c1};
            a {
                color: ${theme.colors.c1};
            }
        }
        to {
            background-color: ${theme.colors.c1};
            color: rgba(255, 255, 255, 0.55);
            a {
                color: rgba(255, 255, 255, 0.55);
            }
        }
    }

    #navbarcontainer {
        padding: 0px;
        margin-left: 0px;
        height: ${navHeaderPublicHeight}px !important; // si on modifie cette taille : LPSection > navHeaderHeight  +++

        #navbarBrand {
            // background-color: blue;
            height: 40px;
            font-size: 1em;
            text-align: center;
            padding: 5px;
            a {
                padding: 0px;
                img {
                    width: 40px;
                    height: 40px;
                }
                .site-title {
                    font-size: 1em !important;
                    padding-left: 5px;
                }
            }
        }
        #navbarNavs {
            // background-color: orange;
            height: 30px;
            display: flex;
            justify-content: center;

            .nav-link {
                font-size: 1em !important;
                // background-color: lime;
                padding-top: 0px;
                padding-top: 0px;
            }
        }
    }
`
