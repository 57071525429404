/* eslint-disable no-unsafe-finally */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import { API_BASE_URL } from "utils/constants"
// TODO import { poste } from "store/PosteFormReducer" !!!!!!!!!!!!!
import { requestOptionsHeaders } from "utils/api"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

// ■■ // ■■ // ■■ // ■■ // ■■ // ■■ // A MODIFIER POUR REDUX TOOLKIT

export async function apiFetchWorkspacePostes(workspaceId: number) {
    let edt = null
    let error = null
    const url = API_BASE_URL + "/postes/edt/" + workspaceId
    console.log("url:", url)

    const requestOptions = {
        method: "GET",
        headers: requestOptionsHeaders(),
        // body: JSON.stringify({ })
    }

    try {
        const response = await fetch(url, requestOptions)
        const rep = await response.json()
        if (rep.apixError) {
            error = rep.apixError
        } else if (rep.edt) {
            edt = rep.edt
        } else {
            console.log(rep)
            error = "Erreur de connexion inconnue"
        }
    } catch (err) {
        error = "Catch: " + err
    } finally {
        // eslint-disable-next-line no-unsafe-finally
        return {
            edt,
            error,
        }
    }
}

export async function apiPosteNew(workspaceId: number, posteForm: any) {
    let poste = null
    let error = null
    const url = API_BASE_URL + "/postes/new/edt/" + workspaceId
    console.log("url:", url)
    console.log("posteForm", posteForm)

    const requestOptions = {
        method: "POST",
        headers: requestOptionsHeaders(),
        body: JSON.stringify({
            nom: posteForm.nom,
            groupe: posteForm.groupe,
            couleur: posteForm.couleur,
            description: posteForm.description,
        }),
    }

    try {
        const response = await fetch(url, requestOptions)
        const rep = await response.json()
        if (rep.apixError) {
            error = rep.apixError
        } else if (rep.poste) {
            poste = rep.poste
        } else {
            console.log(rep)
            error = "Erreur inconnue"
        }
    } catch (err) {
        error = "Catch: " + err
    } finally {
        // eslint-disable-next-line no-unsafe-finally
        return {
            poste,
            error,
        }
    }
}

export async function apiPosteEdit(posteForm: any) {
    let poste = null
    let error = null
    const url = API_BASE_URL + "/postes/" + posteForm.id + "/edit"
    console.log("url:", url)
    console.log("posteForm", posteForm)

    const requestOptions = {
        method: "POST",
        headers: requestOptionsHeaders(),
        body: JSON.stringify({
            nom: posteForm.nom,
            groupe: posteForm.groupe,
            couleur: posteForm.couleur,
            description: posteForm.description,
        }),
    }

    try {
        const response = await fetch(url, requestOptions)
        const rep = await response.json()
        if (rep.apixError) {
            error = rep.apixError
        } else if (rep.poste) {
            poste = rep.poste
        } else {
            console.log(rep)
            error = "Erreur inconnue"
        }
    } catch (err) {
        error = "Catch: " + err
    } finally {
        // eslint-disable-next-line no-unsafe-finally
        return {
            poste,
            error,
        }
    }
}

export async function apiPosteDelete(posteId: number) {
    let reponse = null
    let error = null
    const url = API_BASE_URL + "/postes/" + posteId + "/delete"
    console.log("url:", url)
    console.log("posteId", posteId)

    const requestOptions = {
        method: "DELETE",
        headers: requestOptionsHeaders(),
        // body: JSON.stringify({ })
    }

    try {
        const fetchResponse = await fetch(url, requestOptions)
        const rep = await fetchResponse.json()
        if (rep.apixError) {
            error = rep.apixError
        } else if (rep.message) {
            reponse = rep.message
        } else {
            console.log(rep)
            error = "Erreur inconnue"
        }
    } catch (err) {
        error = "Catch: " + err
    } finally {
        // eslint-disable-next-line no-unsafe-finally
        return {
            reponse,
            error,
        }
    }
}
