/* eslint-disable react/require-default-props */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import styled, { CSSProperties } from "styled-components"
import { theme } from "styles/theme"
import { navHeaderPublicHeight } from "ui/headerMenu/HeaderMenuPublic"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

type Props = {
    children: React.ReactNode
    className?: String | null
    style?: CSSProperties
    isPublic?: boolean
}

export default function ZPageHeader({
    children,
    className = "",
    style = {},
    isPublic = false,
}: Props) {
    return (
        <StyledPageHeader
            className={className + " row col-12 "}
            style={style}
            isPublic={isPublic}
        >
            {children}
        </StyledPageHeader>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS
const StyledPageHeader = styled.div<{
    style: CSSProperties
    isPublic: boolean
}>`
    background-color: white;
    border-bottom: 1px solid ${theme.colors.c1};
    padding: 10px;
    padding-top: ${(props) =>
        props.isPublic ? navHeaderPublicHeight + 10 : 10}px;
`
