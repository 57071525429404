/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { useAppSelector } from "utils/store"
import ButtonUWUpdate from "../ButtonUWUpdate"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function UWsTableTr({ uw }) {
    const { locked } = useAppSelector((state) => state.timetable)

    return (
        <tr>
            {!locked && (
                <td className="p-0">
                    <ButtonUWUpdate uw={uw} />
                </td>
            )}
            {/*
            <td>{uw.id}</td>
             */}
            <td style={{ backgroundColor: uw.couleur }}>{uw.pseudo}</td>
            <td>{uw.fonction}</td>
            <td>{uw.quotite}</td>
            <td>{uw.nbreHeuresHebdo}</td>
            <td>{uw.nbreNuitsHebdo}</td>
        </tr>
    )
}
