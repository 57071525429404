// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
// import React from "react"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export const status = {
    pending: "PENDING",
    canceled: "CANCELED",
    processing: "PROCESSING",
    checking: "CHECKING",
    done: "DONE",
}

export const progressItemsEdtShow = [
    {
        page: "EDT Show",
        actions: [
            {
                title: "Afficher les infos de l'EDT",
                status: status.done,
            },
            {
                title: "Modifier les infos de l'EDT",
                status: status.done,
            },
            {
                title: "Supprimer l'EDT",
                status: status.done,
            },
            {
                title: "Dupliquer l'EDT",
                status: status.pending,
            },
        ],
    },
]
