/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { useAppSelector } from "utils/store"

import LayoutSection from "../layouts/LayoutSection"
import EdtPeriodesHeader from "./EdtPeriodesHeader"
import EdtPeriodesContent from "./EdtPeriodesContent"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function EdtPeriodes({ periodes }) {
    if (!periodes || periodes.length === 0) {
        return null
    }
    const { orientation } = useAppSelector((state) => state.timetable)
    const styles =
        orientation === "HORIZONTAL"
            ? {
                  backgroundColor: "white",
                  minHeight: "40px",
                  height: "40px",
              }
            : {
                  backgroundColor: "white",
                  minWidth: "40px",
                  width: "40px",
              }
    return (
        <LayoutSection styles={styles}>
            <EdtPeriodesHeader
            // uw={uw}
            />

            <EdtPeriodesContent
                // uw={uw}
                periodes={periodes}
            />
        </LayoutSection>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS
