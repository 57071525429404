/* eslint-disable import/no-extraneous-dependencies */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import { combineReducers } from "redux"
import { configureStore } from "@reduxjs/toolkit"
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux"

import uWReducer from "features/membres/utils/uWReducer"
import hPSReducer from "features/horairesPeriodesSonneries/utils/hPSReducer"
import connectedUserReducer from "features/users/utils/connectedUserReducer"
import timetableReducer from "features/timetable/utils/timetableReducer"
import mesEtabsReducer from "features/etabs/utils/mesEtabsReducer"
import EtabShowReducer from "features/etabs/utils/etabShowReducer"
import etabFormReducer from "features/etabs/utils/etabFormReducer"
import edtFormReducer from "features/edts/utils/edtFormReducer"
import membresReducer from "features/membres/utils/membresReducer"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

const reducer = combineReducers({
    connectedUser: connectedUserReducer,
    mesEtabs: mesEtabsReducer,
    etabForm: etabFormReducer,
    timetable: timetableReducer,
    uW: uWReducer,
    etabShow: EtabShowReducer,
    hPS: hPSReducer,
    edtForm: edtFormReducer,
    membres: membresReducer,
})

const store = configureStore({ reducer })

// Infer the `RootState` and `AppDispatch` types from the store itself
type RootState = ReturnType<typeof store.getState>
type AppDispatch = typeof store.dispatch

export default store

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

// Anciennement dans "storeHooks.ts"
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
