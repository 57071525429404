/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/button-has-type */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import styled from "styled-components"
//
import {
    ZPage,
    ZPageHeader,
    ZPageTitle,
    ZPageContent,
    ZPageSection,
    ZYoutube,
} from "uiZ/zPage"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function PageAboutDocs() {
    return (
        <ZPage documentTitle="Documentation">
            <ZPageHeader isPublic>
                <ZPageTitle>
                    Documentation (Etapes de création d&apos;un EDT)
                </ZPageTitle>
                <p>
                    cmzEDT est un outil en ligne <b>gratuit</b> permettant de
                    créer et de consulter facilement <b>les postages</b> de la
                    vie scolaire.
                </p>
            </ZPageHeader>

            <ZPageContent className="row col-12">
                <StylePageSection className="col-12 col-md-6 col-lg-4">
                    <h2>Etape 1 : Créez un établissement.</h2>
                    <p>
                        Vous pouvez en créer plus, si vous travaillez sur
                        plusieurs établissements .
                    </p>
                </StylePageSection>

                <StylePageSection className="col-12 col-md-6 col-lg-4">
                    <h2>Etape 2 : Ajoutez du personnel à cet établissement.</h2>
                    <p>
                        Adjouter les AED, Services Civiques (SC), CPE...
                        <br />
                        <small>
                            Vous pouvez saisir leur adresses eMail pour leur
                            envoyer une invitation afin qu'ils se crée un
                            compte.
                        </small>
                    </p>
                </StylePageSection>

                <StylePageSection className="col-12 col-md-6 col-lg-4">
                    <h2>Etape 3 : Créez un 1er EDT.</h2>
                    <p>
                        Vous avez la possibilité de créer plusieurs EDT.{" "}
                        <small>
                            (Année suivante, semaine de pré-rentrée,
                            modification en cours d'année, faire un EDT à part
                            pour les SC…)
                        </small>
                    </p>
                </StylePageSection>

                <StylePageSection className="col-12 col-md-6 col-lg-4">
                    <h2>
                        Etape 4 : Définissez les horaires, sonneries et période
                        de cet EDT.
                    </h2>
                    <p>
                        <ZYoutube
                            src="https://www.youtube.com/embed/H5h5Nev_g2Y"
                            title="cmzEDT: Définissez les horaires et les sonneries de l'EDT"
                        />
                    </p>
                </StylePageSection>

                <StylePageSection className="col-12 col-md-6 col-lg-4">
                    <h2>
                        Etape 5 : Ajouter du personnel de l'établissement à cet
                        EDT.
                    </h2>
                    <p>
                        Vous séléctionnez le personnel de l'établissement qui
                        doit figurer sur cet EDT et vous définissez le nombre
                        d'heures (et de nuits pour les internats) par semaine
                    </p>
                    <p>
                        <ZYoutube
                            src="https://www.youtube.com/embed/t1a2V1At76I"
                            title="cmzEDT: Ajouter du personnel à l'EDT"
                        />
                    </p>
                </StylePageSection>

                <StylePageSection className="col-12 col-md-6 col-lg-4">
                    <h2>
                        Etape 6 : Définissez les horaires de travail du
                        personnel de l'EDT.
                    </h2>

                    <p>
                        <ZYoutube
                            src="https://www.youtube.com/embed/uYBr0n94hbs"
                            title="cmzEDT: Définissez les horaires du personnel de l'EDT"
                        />
                    </p>
                </StylePageSection>

                <StylePageSection className="col-12 col-md-6 col-lg-4">
                    <h2>Etape 7 : Editez la liste des postes de cet EDT.</h2>
                    <p>
                        Vie Sco, Etude, Portail, Cour, Retenue...
                        <ZYoutube
                            src="https://www.youtube.com/embed/IOYOdFV4sf0"
                            title="cmzEDT: Editez la liste des postes de l'EDT"
                        />
                    </p>
                </StylePageSection>

                <StylePageSection className="col-12 col-md-6 col-lg-4">
                    <h2>Etape 8 : Placer les postages.</h2>
                    <p>
                        <ZYoutube
                            src="https://www.youtube.com/embed/60yQvOqVMHs"
                            title="cmzEDT: Placez les postages"
                        />
                    </p>
                </StylePageSection>

                <StylePageSection className="col-12 col-md-6 col-lg-4">
                    <h2>Consultez les postages.</h2>
                    <p>
                        Vous et le personnel de cet EDT peuvent consulter les
                        postages depuis n'importe quel ordinateur ou depuis leur
                        téléphone.
                    </p>
                </StylePageSection>
            </ZPageContent>
        </ZPage>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS

const StylePageSection = styled(ZPageSection)`
    p {
        margin-bottom: 15px;
        padding: 10px;
    }
    h2 {
        font-size: 1.1em;
    }
`
