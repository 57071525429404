/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
// import styled from 'styled-components'

import LayoutContent from "../layouts/LayoutContent"
import LayoutItem from "../layouts/LayoutItem"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function EdtSonneriesContent({ sonneries }) {
    return (
        <LayoutContent bg="pink" showText genre="SONNERIES">
            {sonneries.map((sonnerie) => (
                <LayoutItem
                    key={"per-" + sonnerie.id}
                    style={{ fontSize: "0.5em" }}
                    heureDebut={sonnerie.heure}
                    heureFin={sonnerie.heure}
                    styles={{
                        // backgroundColor: sonnerie.couleur,
                        overflow: "visible",
                    }}
                >
                    {sonnerie.heure}
                    {/* : {sonnerie.nom}    */}
                </LayoutItem>
            ))}
        </LayoutContent>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS
