/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
/**
 *  Afficher et editer la liste des postes d'un workspace
 *
 *
 *
 */

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import PostesGroupe from "./PostesGroupe"
import { getGroupesFromPostes } from "../utils/postesHelpers"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function PostesListe({ postes, setSelectedPoste }) {
    const sortedPostes = postes
        .slice()
        .sort((a, b) => (a.nom > b.nom ? 1 : b.nom > a.nom ? -1 : 0))

    const groupes = getGroupesFromPostes(sortedPostes)

    return (
        <div className="groupes row col-12">
            {groupes &&
                groupes.map((groupe, g) => (
                    <PostesGroupe
                        key={"groupe_" + g}
                        postesGroupe={groupe}
                        setSelectedPoste={setSelectedPoste}
                    />
                ))}
        </div>
    )
}
