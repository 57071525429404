/* eslint-disable no-alert */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import { addMembreToEdtSuccess } from "features/timetable/utils/timetableReducer" // !!!!!!!!! whyyyyyyyyyyyy
import { createSlice } from "@reduxjs/toolkit"
import { apiAddPersonnelToEdt } from "./apiMembres"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
/*
 *      Reducer pour la page "PageWorkspaceUsers"
 *
 */

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

interface UWHoraire {
    id: number | null
    jour: string //             ex : "Mardi"
    semaine: string | null //   "A" | "B"
    heureDebut: string //       ex : "14:55"
    heureFin: string //         ex : "16:10"
    infos: string | null
    membreId: number | null //  seulement pour le form UWHoraire
    uwPseudo: string | null //  seulement pour le form UWHoraire
    fetchStatus: null | "FETCHING" | "SUCCESS" | "ERROR"
}

interface User {
    id: string
    pseudo: string // pseudo = etabUser.pseudo
}

export interface IMembreForm {
    id: number | null
    pseudo: string
    couleur: string
    fonction: string | null
    horaires: Array<UWHoraire>
    nbreHeuresHebdo: number
    nbreNuitsHebdo: number
    quotite: number
    fetchStatus: null | "FETCHING" | "SUCCESS" | "ERROR"
    user?: User
}

interface Workspace {
    id: number
    nom: string
    actif: boolean
    nbreHeuresHebdoTempsPlein: number | null
    etablissement: { id: number; nom: string; etablissementUsers: Array<{}> } // ????
    membres: Array<IMembreForm>
}

interface InitialState {
    workspace: Workspace | null
    formAddUsersToWorkspaceStatus: null | string // null | "OPEN" | "ADDING" | "ERROR"
    formUWHoraire: UWHoraire | null // ????????????? OK
    formUWHoraireStatus: null | "FETCHING" | "ERROR" // | "SUCCESS"
}

// ■   ■   ■   ■   ■   ■   ■   ■   ■   ■   ■   ■   ■   ■   ■   ■   ■   ■   ■

const initialState: InitialState = {
    workspace: null,
    formAddUsersToWorkspaceStatus: null,
    formUWHoraire: null, // UserWorkspaceHoraire pour le form
    formUWHoraireStatus: null,
}

// ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■

const slice = createSlice({
    name: "uw",
    initialState,
    reducers: {
        setFormAddUsersToEdtStatus: (state, action) => {
            state.formAddUsersToWorkspaceStatus = action.payload
        },

        addUWStart: (state) => {
            state.formAddUsersToWorkspaceStatus = "ADDING"
        },

        setFormHoraireMembre: (state, action) => {
            state.formUWHoraire = action.payload
        },
    },
})

export default slice.reducer

// ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■

const { addUWStart, setFormHoraireMembre, setFormAddUsersToEdtStatus } =
    slice.actions

export { setFormHoraireMembre, setFormAddUsersToEdtStatus }

// *==========================================================

export const addPersonnelToWorkspace =
    (personnelId: number, workspaceId: number) => async (dispatch: any) => {
        // alert("Ajouter l'EU #" + etabUserId + " au worksapce #" + workspaceId)
        dispatch(addUWStart())
        apiAddPersonnelToEdt(personnelId, workspaceId).then((reponse) => {
            if (reponse.uW) {
                // depreciated:::: dispatch(addUserToWorkspaceSuccess(reponse.uW))
                dispatch(addMembreToEdtSuccess(reponse.uW))
            } else if (reponse.error) {
                alert("addUserToWorkspace error")
                // dispatch(fetchError(reponse.error))
            }
        })
    }
