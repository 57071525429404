/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { useFormik } from "formik"

import ZxModal from "ui/ZxModal"
import FormField from "ui/form/FormField"
import FormFieldToggle from "ui/form/FormFieldToggle"

import ButtonAnnuler from "ui/form/ButtonAnnuler"
import ButtonSubmit from "ui/form/ButtonSubmit"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function FormUser({ user, handleCloseForm }) {
    const formEtabUserStatus = "DEBUUUUUUUUUUUUGGGGGGGGGGGGG"
    const validate = (values) => {
        console.log("validate")
        const errors = {}
        if (!values.pseudo) {
            errors.pseudo = "Vous devez saisir un pseudo !"
        }

        return errors
    }
    const onSubmit = (values) => {
        alert(values)
        //  dispatch(createOrUpdateEtabUser(values, etab.id))
    }

    const formik = useFormik({
        initialValues: user,
        validate,
        onSubmit,
    })

    return (
        <ZxModal styles={{ maxWidth: "600px" }}>
            <form
                onSubmit={formik.handleSubmit}
                className="col-12 border border-primary p-2"
            >
                <h4 className="mb-4">
                    {formik.values.id ? "Modifier" : "Ajouter"} un utilisateur.
                </h4>
                <FormField
                    name="username"
                    label="username"
                    type="text"
                    formik={formik}
                    inputProps={{ autoComplete: "off" }}
                />
                <FormField
                    name="email"
                    label="email"
                    type="text"
                    formik={formik}
                    inputProps={{ autoComplete: "off" }}
                />
                <FormField
                    name="pseudo"
                    label="Pseudo"
                    type="text"
                    formik={formik}
                    inputProps={{ autoComplete: "off" }}
                />
                <FormField
                    name="nom"
                    label="nom"
                    type="text"
                    formik={formik}
                    inputProps={{ autoComplete: "off" }}
                />
                <div className="row border border-danger p-3 mb-2">
                    <div className="col-6">
                        <FormFieldToggle
                            name="actif"
                            labelOn="actif"
                            labelOff="inactif"
                            formik={formik}
                            inputProps={{ autoComplete: "off" }}
                        />
                    </div>

                    <div className="col-6">
                        <FormFieldToggle
                            name="emailVerified"
                            labelOn="email Verified"
                            labelOff="email non Verified"
                            formik={formik}
                            inputProps={{ autoComplete: "off" }}
                        />
                    </div>
                </div>
                {formEtabUserStatus === "LOADING" ? (
                    <h1>Loading</h1>
                ) : (
                    <>
                        <ButtonSubmit edit={formik.values.id !== null} />
                        <ButtonAnnuler
                            onClick={() => handleCloseForm()}
                            className=" float-end"
                        />
                    </>
                )}
            </form>
        </ZxModal>
    )
}
