/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
// import styled from 'styled-components'

import LayoutContent from "../layouts/LayoutContent"
import LayoutItem from "../layouts/LayoutItem"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function EdtPeriodesContent({ periodes }) {
    return (
        <LayoutContent bg="pink">
            {periodes.map((periode) => (
                <LayoutItem
                    key={"per-" + periode.id}
                    style={{
                        backgroundColor: periode.couleur,
                        fontSize: "0.5em",
                    }}
                    heureDebut={periode.heureDebut}
                    heureFin={periode.heureFin}
                    styles={{ backgroundColor: periode.couleur }}
                >
                    {periode.heureDebut}
                    {/* - {periode.heureFin} : {periode.nom}     */}
                </LayoutItem>
            ))}
        </LayoutContent>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS
