// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { ZField } from "uiZ/zForm"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function EdtFormInner() {
    return (
        <div className="row col-12">
            <ZField
                autoFocus
                name="nom"
                label='Nom (ex: "2022/2023")'
                type="text"
                // fieldStyle="SIMPLE"
            />
            <ZField
                name="description"
                label="Description (facultatif)"
                type="text"
            />
            <ZField
                name="commentaire"
                label="Commentaire (facultatif)"
                type="text"
            />
            <ZField
                name="nbreHeuresHebdoTempsPlein"
                label="Nombre d'heures Hebdo pour les temps-plein"
                type="number"
                hint="Laissez à 0 si vous ne savez pas"
            />
            <ZField
                name="isInternat"
                label="Internat"
                type="checkbox"
                hint={
                    <>Si vous voulez placer des horaires et postages de nuit.</>
                }
                fieldStyle="SWITCH"
            />
            <ZField
                name="actif"
                label="EDT actif"
                type="checkbox"
                hint="Décochez si EDT de l'année précédente, en cour
                d'edition,..."
                fieldStyle="SWITCH"
            />
            <ZField
                name="isPublished"
                label="Publié l'EDT"
                type="checkbox"
                hint={
                    <>
                        Décochez si en cour d&apos;édition.{" "}
                        <del>Le personnel non-admin ne peut pas le voir</del>
                    </>
                }
                fieldStyle="SWITCH"
            />
        </div>
    )
}
