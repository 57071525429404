/* eslint-disable arrow-body-style */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import styled from "styled-components"
import { ButtonCreateUser } from "features/users"
import UsersAdminTr from "./UsersAdminTr"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function UsersAdminTable({
    users,
    setSelectedUser,
    handleAddUserClick,
    setFormPassword,
}) {
    users.sort((a, b) =>
        a.username.toLowerCase() > b.username.toLowerCase()
            ? 1
            : b.username.toLowerCase() > a.username.toLowerCase()
            ? -1
            : 0
    )

    return (
        <StyledTable className="tableUsers zxTable table table-sm table-bordered">
            <thead>
                <tr>
                    <th colSpan="11">
                        Liste des utilisateurs
                        <div className="float-end">
                            <ButtonCreateUser
                                handleAddUserClick={handleAddUserClick}
                                className="btn-sm"
                            />
                        </div>
                    </th>
                </tr>
                <tr className="zxTrColumnsHeader">
                    <th>Edit</th>
                    <th>Id</th>
                    <th>Username</th>
                    <th>eMail</th>
                    <th>nom</th>
                    <th>prenom</th>
                    <th>pseudo</th>
                    <th>sexe</th>
                    <th>date Inscription</th>
                    <th>date Naissance</th>
                    <th>Actif</th>
                    <th>email Verified</th>
                    <th>roles</th>
                    <th>etab-Users</th>
                    <th>user-Workspaces</th>
                    <th>Password</th>
                </tr>
            </thead>
            <tbody>
                {users &&
                    users.map((user) => {
                        return (
                            <UsersAdminTr
                                key={"user-" + user.id}
                                user={user}
                                setSelectedUser={setSelectedUser}
                                setFormPassword={setFormPassword}
                            />
                        )
                    })}
            </tbody>

            <tfoot>
                <tr>
                    <td colSpan="10">Footer</td>
                </tr>
            </tfoot>
        </StyledTable>
    )
}

// █████████████████████████████████████████████████████████████	StyledComponents

const StyledTable = styled.table`
    tbody {
        td {
            font-size: 0.7em;
        }
        .tr-inactif {
            background-color: rgb(150, 150, 150);
        }
    }
`
