/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import styled, { keyframes } from "styled-components"
import { useAppSelector } from "utils/store"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function LineNow({ showText = true }) {
    const { orientation, heureNow, jour, jourNow } = useAppSelector(
        (state) => state.timetable
    )

    if (jourNow !== jour) {
        return null
    }
    if (
        heureNow === null ||
        heureNow.percent > 100
        // rPostages.selectedJour !== getDayNameForToday(false)
    ) {
        return null
    }

    return (
        <StyledLineNow
            orientation={orientation}
            nowPercent={heureNow.percent}
            showText
        >
            {showText && (
                <>
                    {heureNow.hours < 10 && "0"}
                    {heureNow.hours}:{heureNow.minutes < 10 && "0"}
                    {heureNow.minutes}
                </>
            )}
        </StyledLineNow>
    )
}

const styledLineNowAnimation = keyframes`
	0% 	 {	opacity: 1;	 border-color: blue;	 color: blue;	}
	20%  {	opacity: 1;	 border-color: blue;	 color: blue;	}
	40%  {	opacity: 0;		}
	60%  {	opacity: 0;		}
	80%  {	opacity: 1;	 border-color: red;	 	color: red;	}
	100% {	opacity: 1;	 border-color: red;	 	color: red;	}
`

const StyledLineNow = styled.div`
    // background-color: lime;
    position: absolute;
    font-size: 0.5em;
    margin: 0%;
    padding: 0px;
    z-index: 2300;

    animation-name: ${styledLineNowAnimation};
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-direction: alternate;

    ${(props) =>
        props.orientation === "HORIZONTAL"
            ? `
			left: ${props.nowPercent}%;
			height: 100%;
			/*width: 3px;*/
			border-left: 3px solid lime;
			`
            : `
			top: ${props.nowPercent}%;
			width: 100%;
			/*height: 3px;*/
			border-top: 3px solid lime;
			`}
    ${(props) =>
        props.showText &&
        `
		background-color: white;
		`}
`
