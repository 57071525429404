// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"

import { dev } from "utils/constants"
import ButtonShowOptions from "features/timetable/options/ButtonShowOptions"

import { ZPageSectionTodo } from "uiZ/zPage"
import ZxModal from "ui/ZxModal"
// import DateHeure from "./options/DateHeure"
import FilterShowHoraires from "./options/FilterShowHoraires"
// import FilterShowPostages from "./options/FilterShowPostages"
import FilterFormPostage1st from "./options/FilterFormPostage1st"
import FilterOrientation from "./options/FilterOrientation"
import FilterModeJournee from "./options/FilterModeJournee"
import FilterShowAllUsers from "./options/FilterShowAllUsers"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
/*
 *  TimetableHeader
 *  ( JourSelect, date, zoom, Lock, (Bouton)Options )
 *
 *
 *
 */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
export default function TimetableModalOptions() {
    return (
        <ZxModal styles={{ maxWidth: "750px" }}>
            <h2 className=" col-12 mb-4 pb-2 border-bottom border-primary">
                Options de la timetable:
                <ButtonShowOptions className="float-end" />
            </h2>
            <div className="d-flex ms-2 mb-3">
                Orientation:&nbsp;&nbsp;
                <FilterOrientation />
                <small className="ms-1 text-info">
                    (il y a quelques pb d'affichage avec le mode horizontal,
                    mais ça fonctionne)
                </small>
            </div>
            <div className="d-flex ms-2 mb-3">
                Horaires affichés:&nbsp;&nbsp;
                <FilterModeJournee />
            </div>
            <div className="d-flex mb-3">
                <FilterShowAllUsers />
            </div>
            <div className="d-flex mb-3">
                <FilterShowHoraires />
            </div>
            {/*
            <div className="d-flex mb-3">
                <FilterShowPostages />
            </div>
             */}

            <FilterFormPostage1st />
            {dev && (
                <ZPageSectionTodo className="col-12">
                    <h2>Dev:</h2>
                    <ul>
                        <li>
                            {" "}
                            Postages transparants{" "}
                            <small>
                                (pour verifier qu'il n'y ait pas de postages qui
                                se chevauchent)
                            </small>
                        </li>
                        <li>Afficher/masquer colonne sonneries, heures</li>
                        <li>xxxxxxxxxx</li>
                        <li>xxxxxxxxxx</li>
                        <li>xxxxxxxxxx</li>
                        <li>xxxxxxxxxx</li>
                    </ul>
                </ZPageSectionTodo>
            )}
        </ZxModal>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS
/*
const StyledTimetableHeader = styled.div`
    // display: flex;
    // flex-flow: row;
    // background-color: green; // debug only
    margin: 0px;
    // padding: 5px; // debug only
    // flex: 0 1 auto;
`
*/
