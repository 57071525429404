/* eslint-disable no-alert */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { useAppSelector, useAppDispatch } from "utils/store"
import {
    setFormAddUsersToEdtStatus,
    addPersonnelToWorkspace,
} from "features/membres/utils/uWReducer"
import { dev } from "utils/constants"
import Button from "react-bootstrap/Button"
import ZxModal from "ui/ZxModal"
import { ZPageSectionTodo } from "uiZ/zPage"
import { NavLink } from "react-router-dom"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function FormAddMembres() {
    const dispatch = useAppDispatch()
    const { formAddUsersToWorkspaceStatus } = useAppSelector(
        (state) => state.uW
    )
    const { edt, membres } = useAppSelector((state) => state.timetable)

    const etabUsersToAdd = edt?.etablissement?.personnels.filter(
        (etabPerso) =>
            membres.filter(
                // le point d'interogation ne devrait pas y etre mais ya un bug dans la bdd
                (edtPerso) => edtPerso.personnel?.id === etabPerso.id
            ).length === 0
    )
    if (!edt) {
        return <h3 className="text-danger">Erreur interne : !edt</h3>
    }

    function handleClick(personnelId) {
        // alert("Ajouter le personnel #" +personnelId +" au worksapce #" +workspace.id)

        dispatch(addPersonnelToWorkspace(personnelId, edt.id))
    }

    return (
        <ZxModal styles={{ width: "500px" }}>
            <h1>Ajouter le personnel de l&apos;établissement à cet EDT.</h1>

            <div className="m-4">
                {etabUsersToAdd?.map((eu) => (
                    <div key={"eu-" + eu.id} className="mb-2">
                        <b>{eu.pseudo}</b>
                        <Button
                            onClick={() => handleClick(eu.id)}
                            className="btn-sm ms-2"
                            variant={
                                formAddUsersToWorkspaceStatus === "ADDING"
                                    ? "secondary"
                                    : "primary"
                            }
                        >
                            Ajouter à l&apos;EDT
                        </Button>
                    </div>
                ))}
            </div>

            <p className="m-4">
                Pour créer un nouveau personnel, allez sur{" "}
                <NavLink to={`/etab/${edt.etablissement.id}`}>
                    la page de l&apos;établissement
                </NavLink>{" "}
                avant de pouvoir l&apos;ajouter à un EDT !
            </p>

            <Button
                onClick={() => dispatch(setFormAddUsersToEdtStatus(null))}
                variant="danger"
            >
                Annuler
            </Button>
            {dev && (
                <ZPageSectionTodo className=" col-11">
                    <h1>todo</h1>
                    <ul>
                        <li>Afficher pseudo user/etabuser si different</li>
                        <li>Afficher fonction</li>
                    </ul>
                </ZPageSectionTodo>
            )}
        </ZxModal>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS
