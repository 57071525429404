/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import Button from "react-bootstrap/Button"
import { useAppSelector, useAppDispatch } from "utils/store"
import { setLocked } from "features/timetable/utils/timetableReducer"
import { IcoLocked, IcoUnlocked } from "styles/icones"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function ButtonLocked() {
    const { locked } = useAppSelector((state) => state.timetable)
    const dispatch = useAppDispatch()

    return (
        <Button
            className="btn-sm ms-2 no-print"
            variant={locked ? "primary" : "danger"}
            title={
                (locked ? "Déverouiller pour modifier" : "Verouiller") +
                " l'EDT"
            }
            onClick={() => dispatch(setLocked(!locked))}
        >
            {locked ? <IcoLocked /> : <IcoUnlocked />}
        </Button>
    )
}
