// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { ZPageSectionHelp, ZYoutube } from "uiZ/zPage"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function SectionHelpHorairesMembres() {
    return (
        <ZPageSectionHelp className="row col-12 mb-4 mt-4">
            <div className="row col-12 ">
                <div className=" col-12 col-lg-6">
                    <h2>
                        Aide: Definir les horaires de travail des membres de
                        l'EDT
                    </h2>
                    <div>
                        <ul>
                            <li>
                                Cliquez sur le cadenas pour déverouiller,
                                séléctionnez la semaine (A, B ou les 2) , puis
                                peignez les horaires sur l&apos;EDT.
                            </li>
                            <li>
                                Choisissez entre l'affichage sous forme
                                <ul>
                                    <li>
                                        <b>Graphique :</b> Vous "peignez" les
                                        horaires sur la grille. (Cliquez sur le
                                        bouton "? Aide" pour plus d'explication)
                                    </li>
                                    <li>
                                        <b>Tableau :</b> Vous éditez les
                                        horaires à l'aide d'un formulaire.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                Le nombre d'heures hebdomadaire se met à jour
                                automatiquement.
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="  col-12 col-lg-6">
                    <ZYoutube
                        src="https://www.youtube.com/embed/uYBr0n94hbs"
                        title="cmzEDT: Définissez les horaires du personnel de l'EDT"
                    />
                </div>
            </div>
        </ZPageSectionHelp>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS
