/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React, { useState, useRef } from "react"
import styled from "styled-components"
import { useAppSelector } from "utils/store"
import { theme } from "styles/theme"
import { debugTimetable } from "utils/constants"
import PostageForm from "features/postages/form/PostageForm"

import FormPostage1st from "features/postages/formPostage1st/FormPostage1st"
import HoraireMembreForm from "features/membres/formHoraireMembre/HoraireMembreForm"

import TimetableHeader from "./TimetableHeader"
import TimetableModalPrint from "./TimetableModalPrint"
import TimetableContainer from "./TimetableContainer"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
/*

 *
 *
 *
 */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
export default function TimetableGlobalContainer() {
    const {
        postageForm,
        modeHoraires,
        formPostage1stPosition,
        isFormPostage1st,
        locked,
        // ****** OPTIONS SIZE :
        globalContainerHeight,
    } = useAppSelector((state) => state.timetable)

    const componentRef = useRef<HTMLHeadingElement>(null)

    const { formHoraireMembre } = useAppSelector((state) => state.membres)

    const [modalPrintIsOpen, setModalPrintIsOpen] = useState(false)
    // console.log("RENDER - Timetable")

    return (
        <StyledTTGlobalContainer
            ref={componentRef}
            modeHoraires={modeHoraires}
            globalContainerHeight={globalContainerHeight}
        >
            {postageForm && <PostageForm />}
            {formHoraireMembre && <HoraireMembreForm />}
            {modalPrintIsOpen && (
                <TimetableModalPrint
                    setModalPrintIsOpen={setModalPrintIsOpen}
                    componentRefCurrent={componentRef.current || null}
                />
            )}
            <TimetableHeader setModalPrintIsOpen={setModalPrintIsOpen} />
            <StyledFormAndContainer
                formPostage1stPosition={formPostage1stPosition}
            >
                {!locked && !modeHoraires && isFormPostage1st && (
                    <StyledForm formPostage1stPosition={formPostage1stPosition}>
                        <div id="formPostage1stContainer">
                            <FormPostage1st />
                        </div>
                    </StyledForm>
                )}
                <TimetableContainer />
            </StyledFormAndContainer>
        </StyledTTGlobalContainer>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS

const StyledTTGlobalContainer = styled.div<{
    modeHoraires: boolean
    globalContainerHeight: number
    ref: any
}>`
    background-color: ${theme.colors.c1};
    display: flex;
    flex-flow: column;
    flex: 1 1 auto;
    border: 1px solid black;
    padding: 5px;

    ${(props) =>
        props.modeHoraires
            ? " height: " + props.globalContainerHeight + "px;"
            : " height: 100%;"}

    @media print {
        background-color: white;
        .item-horaire-membre {
            background-color: #aaaaaa !important;
        }
    }

    ${debugTimetable && "padding: 10px; background-color:red;"}
`

const StyledFormAndContainer = styled.div<{
    formPostage1stPosition: string
}>`
    // background-color: purple; // debug only
    // padding: 10px; // debug only
    height: 100%;
    // min-height: 200px;
    display: flex;
    // flex: 1 1 1;

    ${(props) => props.formPostage1stPosition === "TOP" && "flex-flow: column;"}
    ${(props) =>
        props.formPostage1stPosition === "BOTTOM" &&
        "flex-flow: column-reverse;"}
    ${(props) => props.formPostage1stPosition === "LEFT" && "flex-flow: row;"}
    ${(props) =>
        props.formPostage1stPosition === "RIGHT" && "flex-flow: row-reverse;"}

    #formPostage1stContainer {
        // background-color: lime; // debug only
        padding: 0px; // debug only
        // flex: auto;
        overflow-y: auto;
    }
    ${debugTimetable && "padding: 10px; background-color:lime;"}
`

const StyledForm = styled.div<{
    formPostage1stPosition: string
}>`
    display: flex;
    flex-flow: row;

    // background-color: pink; // debug only

    margin: 0px;
    padding: 0px;
    //  flex: 1 1 auto;
    // overflow-y: auto;

    ${(props) =>
        (props.formPostage1stPosition === "LEFT" ||
            props.formPostage1stPosition === "RIGHT") &&
        `
        // min-width:200px;
         max-width:250px; 
        `}
`
