/* eslint-disable react/no-unstable-nested-components */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import styled from "styled-components"
// import { theme } from "styles/theme"
import { modeVideo } from "utils/constants"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

type Props = {
    children?: React.ReactNode
    className?: String | null
}

ZPageSectionTodo.defaultProps = {
    children: null,
    className: "",
}

export default function ZPageSectionTodo({ children, className }: Props) {
    if (modeVideo) {
        return null
    }
    return (
        <StyledZPageSectionTodo className={className + " col-12"}>
            <section>{children}</section>
        </StyledZPageSectionTodo>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS

const StyledZPageSectionTodo = styled.div`
    padding: 10px;
    section {
        background-color: rgb(255, 230, 230);
        border: 1px solid red;
        font-size: 0.8em;
        padding: 10px;
        h2 {
            color: red;
            font-size: 1.2em;
        }
        p {
            // margin-left: 10px;
        }
    }
`
