/* eslint-disable react/no-unstable-nested-components */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React, { useState } from "react"
import { FaBan } from "react-icons/fa"
import { ZPage, ZPageHeader, ZPageTitle, ZPageContent } from "uiZ/zPage"
import { apiFetchDashboard } from "features/admin/utils/apiAdmin"
// import DashboardUsers from "features/users/DashboardUsers"
// import DashboardEtabs from "features/etabs/DashboardEtabs"
// import DashboardLogs from "features/admin/Logs/DashboardLogs"
// import DashboardLogsOld from "features/admin/Logs/DashboardLogsOld"
import AdminDashboard from "features/admin/_dashboard/AdminDashboard"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function PageAdminDashboard() {
    const [users, setUsers] = useState<[] | null>(null)
    const [etabs, setEtabs] = useState<[] | null>(null)
    const [logs, setLogs] = useState<[] | null>(null)

    const fetchSuccessFunction = (result: any) => {
        setUsers(result.users)
        setEtabs(result.etabs)
        setLogs(result.logs)
    }

    return (
        <ZPage
            documentTitle="Admin Dashboard"
            fetchFunction={() => apiFetchDashboard()}
            fetchSuccessFunction={(result: any) => fetchSuccessFunction(result)}
        >
            <ZPageHeader>
                <ZPageTitle>
                    <FaBan /> Admin: Dashboard
                </ZPageTitle>
            </ZPageHeader>

            {etabs && users && (
                <ZPageContent>
                    {etabs && logs && users && (
                        <AdminDashboard
                            etabs={etabs}
                            logs={logs}
                            users={users}
                        />
                    )}
                </ZPageContent>
            )}
        </ZPage>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS
