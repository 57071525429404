// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
// import React from "react"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export const status = {
    pending: "PENDING",
    canceled: "CANCELED",
    processing: "PROCESSING",
    checking: "CHECKING",
    done: "DONE",
}

export const progressItemsPostes = [
    {
        page: "Postes",
        actions: [
            {
                title: "Afficher les postes du workspace",
                status: status.done,
            },
            {
                title: "Ajouter un poste",
                status: status.done,
            },
            {
                title: "Modifier un poste",
                status: status.done,
            },
            {
                title: "Supprimer un poste",
                status: status.done,
            },
            {
                title: "Meilleur gestion groupes",
                status: status.pending,
            },
            {
                title: "dupliquer poste",
                status: status.pending,
            },
            {
                title: "Importer depuis un autre EDT ou depuis une liste par defaut",
                status: status.pending,
            },
            {
                title: "Color picker dans le form",
                status: status.done,
            },
        ],
    },
]
