/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import styled from "styled-components"
import { useAppSelector } from "utils/store"

import {
    calcDureeMinutesFromStrHeureDebutToStrHeureFin,
    getArrayJoursFromHoraireEdts,
} from "utils/fonctions"
import ButtonToggleEdtLocked from "features/membres/ButtonToggleEdtLocked"
import UWsHorairesTableTr from "./UWsHorairesTableTr"
// import ButtonCreateUser from './ButtonCreateUser'
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function UWsHorairesTable() {
    const { edt, membres } = useAppSelector((state) => state.timetable)

    const uWCount = membres.length

    const jours = getArrayJoursFromHoraireEdts(edt.horaireEdts)

    membres
        .slice()
        .sort((a, b) =>
            a.pseudo.toLowerCase() > b.pseudo.toLowerCase()
                ? 1
                : b.pseudo.toLowerCase() > a.pseudo.toLowerCase()
                ? -1
                : 0
        )

    return (
        <StyledTable className="table table-sm table-striped table-bordered mb-5">
            <thead>
                <tr>
                    <th colSpan="13">
                        Horaires du personnel pour l&apos;EDT{" "}
                        <i>
                            <u>{edt.nom}</u>
                        </i>
                        <ButtonToggleEdtLocked className="float-end" />
                    </th>
                </tr>
                <tr className="zxTrColumnsHeader">
                    {/*
                    <th>Id</th>
                     */}
                    <th>Pseudo</th>
                    {/*
                    <th>Fonction</th>
                    <th style={{ width: "70px" }}>Quotité</th>
                    <th style={{ width: "70px" }}>
                        Heures
                        <br />
                        /sem.
                    </th>
                    <th style={{ width: "70px" }}>
                        Nuits
                        <br />
                        /sem.
                    </th>
                     */}
                    {jours.map((jr) => (
                        <th
                            key={"jour" + jr}
                            // onMouseEnter={()=>setJour(jr)}
                        >
                            {jr}
                        </th>
                    ))}
                    <th>Total Heures</th>
                </tr>
            </thead>
            <tbody>
                {membres?.map((uw) => {
                    /*
                    const user = userWorkspace.user
                 */
                    const dureeTotaleMinutes = uw.horaires.reduce(
                        (memo, hor) => {
                            let duree =
                                calcDureeMinutesFromStrHeureDebutToStrHeureFin(
                                    hor.heureDebut,
                                    hor.heureFin
                                )
                            if (hor.semaine !== null && hor.semaine !== "") {
                                duree /= 2
                            }
                            return memo + duree
                        },
                        0
                    )

                    return (
                        <UWsHorairesTableTr
                            key={"uw-" + uw.id}
                            uw={uw}
                            jours={jours}
                            dureeTotaleMinutes={dureeTotaleMinutes}
                            // setFormPassword={setFormPassword}
                        />
                    )
                })}
            </tbody>

            <tfoot>
                <tr>
                    <td colSpan="13">
                        {uWCount} personne{uWCount > 1 && "s"} sur cet EDT.
                    </td>
                </tr>
            </tfoot>
        </StyledTable>
    )
}

// █████████████████████████████████████████████████████████████	StyledComponents

const StyledTable = styled.table`
    // max-width: 700px;
    tbody {
        td {
            font-size: 1em;
        }
        .tr-inactif {
            background-color: rgb(150, 150, 150);
        }
        tr:nth-child(odd) {
            background-color: rgb(233, 233, 255);
        }
    }
`
