// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { useAppSelector } from "utils/store"
import MesEtabsListeItem from "./MesEtabsListeItem"
import MesEtabsListeItemCreate from "./MesEtabsListeItemCreate"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function MesEtabsListe() {
    const { etabs } = useAppSelector((state) => state.mesEtabs)

    if (etabs.length === 0) {
        return (
            <div className="col-12 row">
                <p>
                    Vous n&apos;appartenez à aucun établissement.
                    <br />
                    <br />
                    Cliquez sur le bouton &quot;Ajouter un établissement&quot;
                    ou demandez à votre CPE de vous ajouter avec votre adresse
                    mail.
                </p>
                <MesEtabsListeItemCreate />
            </div>
        )
    }
    return (
        <>
            {etabs.length > 0 ? (
                <p>
                    Sélectionnez l&apos;établissement sur lequel vous voulez
                    travailler:
                </p>
            ) : (
                <p>
                    Vous n&apos;avez pas encore crée d&apos;établissement.
                    <br />
                    Cliquez sur le bouton &quot;Ajouter un établissement&quot;
                    ou, si vous êtes AED, demandez à votre CPE de vous ajouter
                    avec votre adresse mail.
                </p>
            )}
            <div className="col-12 row">
                {etabs?.map((etab) => (
                    <MesEtabsListeItem key={"li-etab-" + etab.id} etab={etab} />
                ))}
                <MesEtabsListeItemCreate />
            </div>
            {/* 
            <div className="col-12 mt-4">
                Si vous êtes AED et que vous avez reçu une invitation, ce n'est
                pas la peine de créer un établissement ! Votre CPE va vous
                ajouter...
            </div>
            */}
        </>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS
