/* eslint-disable no-undef */
/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import styled from "styled-components"
import { LinkContainer } from "react-router-bootstrap"
import { Nav } from "react-bootstrap"
import { FaHome } from "react-icons/fa"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export function LinkMesEtabs({ className = "" }: { className: string }) {
    return (
        <Link to="/mes-etabs" className={className}>
            <FaHome /> Mes établissements
        </Link>
    )
}

export function LinkEtabShow({
    id,
    nom,
    className = "",
}: {
    id: number
    nom: string
    className: string
}) {
    return (
        <Link to={"/etab/" + id} className={className}>
            &gt; {nom}
        </Link>
    )
}

// ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■

interface Props {
    to: string
    children: React.ReactNode
    className: string
}

export function Link({ to, children, className = "" }: Props) {
    return (
        <StyledNavbar to={to} className={className}>
            <Nav.Link>{children}</Nav.Link>
        </StyledNavbar>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS
const StyledNavbar = styled(LinkContainer)`
    // background-color: red;
    // border: 2px solid red;
`
