/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { ZPageSectionHelp } from "uiZ/zPage"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function EtabShowSectionHelp({ className }) {
    return (
        <ZPageSectionHelp className={className}>
            <h2>Astuce:</h2>
            <p>
                Vous pouvez aussi créer un compte commun
                &quot;Surveillants&quot; avec l&apos;adresse mail de la vie sco.
                <br />
                <small>
                    (A utiliser sur les PC de la vie sco ou pour les AED qui
                    n&apos;ont pas crée de compte)
                </small>
            </p>
        </ZPageSectionHelp>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS
