/* eslint-disable react/no-array-index-key */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
// import styled from "styled-components"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function AboutCardDevelopment() {
    const sprintItems = [
        {
            date: "2022/02/22",
            content:
                "Carole veut créer, editer et supprimer des établissements.",
        },
    ]

    return (
        <div className="card border-warning">
            <h5 className="card-header bg-warning">
                Development <small>(sprint en cours)</small>
            </h5>
            <div className="card-body">
                <div className="card-text">
                    {sprintItems.map((item, i) => (
                        <div
                            key={"sprint" + i}
                            className="card border-warning mb-1"
                        >
                            <div className="card-body p-1">
                                <p className="card-text">{item.content}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS

// const StyledItem = styled.div``
