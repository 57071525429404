/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React, { useState } from "react"
import { useAppSelector, useAppDispatch } from "utils/store"
// import styled from 'styled-components'
import { setFormHoraireMembre } from "features/membres/utils/membresReducer"
import { Button } from "react-bootstrap"
import { IcoEdit } from "styles/icones"
import LayoutItem from "../layouts/LayoutItem"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function ItemHoraire({
    horaire,
    membreId,
    membrePseudo,
    showText = true,
}) {
    const dispatch = useAppDispatch()
    const { modeHoraires, orientation, locked } = useAppSelector(
        (state) => state.timetable
    )

    // const marginLeft = horaire.semaine === "B" ? "50%" : "0%"
    //  const width = horaire.semaine ? "50%" : "100%"

    const styles =
        orientation === "HORIZONTAL"
            ? {
                  backgroundColor: "white",
                  top: horaire.semaine === "B" ? "50%" : "0%",
                  height: horaire.semaine ? "50%" : "100%",
              }
            : {
                  backgroundColor: "white",
                  marginLeft: horaire.semaine === "B" ? "50%" : "0%",
                  width: horaire.semaine ? "50%" : "100%",
              }

    const [isOver, setIsOver] = useState(false)

    function handleOnClick() {
        console.log("handleOnClick")
        /*
        const editablePostage = {
            ...postage,
            posteId: postage.poste.id,
            userWorkspaceId: membre.id,
            userWorkspacePseudo: membre.pseudo,
        }
        console.log("editablePostage", editablePostage)
        // dispatch(setPostageForm(editablePostage))
        */
        console.log("horaire", horaire)
        dispatch(
            setFormHoraireMembre({
                ...horaire,
                membreId,
                membrePseudo,
            })
        )
    }

    function handleOnMouseMove(ev) {
        // console.log("handleOnMouseMove", ev)
        // ev.preventDefault() // ne sert a rien
        if (modeHoraires) {
            ev.stopPropagation() // ne sert a rien
            // setItemOvered(true)
            setIsOver(true)
        }
    }

    return (
        <LayoutItem
            key={"postage-" + horaire.id}
            style={{ fontSize: "0.6em" }}
            heureDebut={horaire.heureDebut}
            heureFin={horaire.heureFin}
            styles={styles}
            title={horaire.heureDebut + " - " + horaire.heureFin}
            isLoading={horaire.isLoading}
            onMouseMove={(ev) => handleOnMouseMove(ev)}
            onMouseLeave={() => setIsOver(false)}
            className="item-horaire-membre"
            // onMouseMove={(ev)=>ev.preventDefault()}
        >
            {(showText || modeHoraires) && (
                <>
                    {horaire.heureDebut}
                    <br />
                    {horaire.heureFin}
                </>
            )}

            {modeHoraires && !locked && isOver && (
                <Button
                    variant="warning"
                    className="btn-sm "
                    onClick={() => handleOnClick()}
                    title="Modifier/Supprimer cet horaire"
                >
                    <IcoEdit />
                </Button>
            )}
        </LayoutItem>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS
