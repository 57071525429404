// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { useAppDispatch } from "utils/store"
import { ZPage } from "uiZ/zPage"
import {
    MesEtabs,
    zApiFetchMesEtabs,
    mesEtabsFetchSuccess,
} from "features/etabs"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function PageMesEtabs() {
    const dispatch = useAppDispatch()

    const fetchSuccessFunction = (result: any) => {
        // * zApiFetchMesEtabs renvoi "etablissements" OU zError
        dispatch(mesEtabsFetchSuccess(result.etablissements))
    }

    return (
        <ZPage
            documentTitle="Mes établissements"
            fetchFunction={() => zApiFetchMesEtabs()}
            fetchSuccessFunction={(result: any) => fetchSuccessFunction(result)}
        >
            <MesEtabs />
        </ZPage>
    )
}
