/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { useAppDispatch } from "utils/store"
import styled from "styled-components"
import { theme } from "styles/theme"
import { setHoraireForm } from "../utils/hPSReducer"
// import FormHoraireEtab from './FormHoraireEtab';

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function HoraireItem({ jour, horaire }) {
    const dispatch = useAppDispatch()

    function handleHoraireClick() {
        dispatch(setHoraireForm(horaire))
    }

    return (
        <StyledItem
            className="horaireEtab col-12"
            role="button"
            key={"hor-" + horaire.id}
            onClick={() => handleHoraireClick()}
            title={"modifier l'horaire du " + jour}
            tabIndex="0"
        >
            de <b>{horaire.heureDebut}</b> à <b>{horaire.heureFin}</b>
        </StyledItem>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS

const StyledItem = styled.div`
    color: ${theme.colors.c1};
    &:hover {
        color: ${theme.colors.c1l2};
    }
`
