/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { FaEdit, FaKey } from "react-icons/fa"
import { NavLink } from "react-router-dom"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function UsersAdminTr({
    user,
    setSelectedUser,
    setFormPassword,
}) {
    const dateInscriptionStr = user.dateInscription
        ? new Date(user.dateInscription).toLocaleString("fr-FR", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
          })
        : null
    const dateNaissanceStr = user.dateNaissance
        ? new Date(user.dateNaissance).toLocaleString("fr-FR", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
          })
        : null

    let trClassName = "  "

    if (!user.actif) {
        trClassName += " tr-inactif "
    }

    return (
        <tr className={trClassName}>
            <td
                className="btn-warning text-center"
                onClick={() => setSelectedUser(user)}
                title="Modifier cet ami"
            >
                <FaEdit />
            </td>

            <td>{user.id}</td>
            <td>
                <NavLink to={"/user/" + user.id}>{user.username}</NavLink>
            </td>

            <td>{user.email}xxxxxxxxx</td>
            <td>{user.nom}</td>
            <td>{user.prenom}</td>
            <td>{user.pseudo}</td>
            <td>{user.sexe}</td>
            <td>{dateInscriptionStr}</td>
            <td>{dateNaissanceStr}</td>
            <td>{user.actif ? "Oui" : "Non"}</td>
            <td>{user.emailVerified ? "Oui" : "Non"}</td>

            <td>{user.roles}</td>
            <td>
                <ul>
                    {user.personnels?.map((eu) => (
                        <li key={`etabUser-${eu.id}`}>
                            <b>{eu.etablissement.nom}</b> ({eu.pseudo})
                        </li>
                    ))}
                </ul>
            </td>
            <td>
                {" "}
                <ul>
                    {user.membres?.map((uw) => (
                        <li key={`uw-${uw.id}`}>
                            <b>{uw.edt.nom}</b>
                            <div>{uw.horaires.length} horaire(s)</div>
                            <div>{uw.postages.length} postage(s)</div>
                        </li>
                    ))}
                </ul>
            </td>

            <td
                className="btn-warning text-center"
                onClick={() =>
                    setFormPassword({
                        friendId: user.id,
                    })
                }
                title="Modifier cet ami"
            >
                <FaKey />
            </td>
        </tr>
    )
}
