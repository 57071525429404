/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React, { useContext } from "react"
import { FormContext } from "ui/ZForm/ZForm"
// import styled from "styled-components"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function PostageFormHeures() {
    const { form, handleFormChange } = useContext(FormContext)
    return (
        <>
            Le <b>{form.jour}</b> de{" "}
            <input
                id="heureDebut"
                name="heureDebut"
                type="time"
                onChange={(e) => handleOnClick(e.target)}
                value={form.heureDebut}
            />{" "}
            à{" "}
            <input
                id="heureFin"
                name="heureFin"
                type="time"
                onChange={(e) => handleOnClick(e.target)}
                value={form.heureFin}
            />
        </>
    )

    function handleOnClick(evTarget) {
        handleFormChange({
            target: { name: evTarget.name, value: evTarget.value },
        })
    }
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS

// const StyledForm = styled.div``
