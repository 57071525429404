/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import Button from "react-bootstrap/Button"
import { IcoAdd } from "styles/icones"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function ButtonSonnerieCreate({ setFormSonnerie }) {
    return (
        <Button
            title="Ajouter une sonnerie"
            className="btn-sm float-end"
            onClick={() =>
                setFormSonnerie(() =>
                    setFormSonnerie({ heure: "08:00", nom: null })
                )
            }
        >
            <IcoAdd />
        </Button>
    )
}
