// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import styled from "styled-components"
import {
    ZPage,
    ZPageHeader,
    ZPageTitle,
    ZPageContent,
    ZPageSection,
    // ZPageSectionHelp,
} from "uiZ/zPage"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function PageAboutFaq() {
    return (
        <ZPage documentTitle="FAQ">
            <ZPageHeader>
                <ZPageTitle>Aide (FAQ)</ZPageTitle>
            </ZPageHeader>

            <ZPageContent className="row col-12">
                <StylePageSection className="col-12 col-lg-6">
                    <h2> Compte commun</h2>
                    <p>
                        Vous pouvez ajouter un personnel de l'établissement avec
                        l'adresse mail de la vie sco.
                        <br />
                        Les AED qui n'ont pas encore crée de compte peuvent
                        l'utiliser.
                        <br />
                        Ce compte peut aussi resté connecté sur tous les PC de
                        la vie sco.
                    </p>
                </StylePageSection>
                <StylePageSection className="col-12 col-lg-6">
                    <h2>Favoris / Raccourci</h2>
                    <p>
                        Pour consulter rapidement les postages:
                        <br />
                        <b>Sur un ordinateur</b>, ajoutez ce site dans la barre
                        des favoris de votre navigateur.
                        <br />
                        <br />
                        <b>Sur un téléphone</b>, rendez vous sur cmz, et dans le
                        menu de votre navigateur (chrome, firefox, safari...),
                        il y a une option "Ajouter à l'écran d'accueil".
                    </p>
                </StylePageSection>
                {/* 
                <StylePageSection className="col-12 col-lg-6">
                    <h2>Imprimer un EDT</h2>
                    <p>
                        Bien qu'avec cmzEDT vous n'ayez plus besoin d'imprimer
                        les EDT, il est tout à fait possible de le faire.
                        <br />
                        <ZPageSectionHelp>
                            Quand vous êtes sur la page des postages, appuyez
                            sur [ Control + P ]
                            <br />
                            Dans la fenêtre qui s’ouvre, réglez les options
                            comme bon vous semble:
                            <br />
                            <ul>
                                <li>
                                    Orientation : <b>Paysage</b>
                                </li>
                                <li>
                                    <b>Couleur</b> ou <b>Noir et blanc</b>
                                </li>
                            </ul>
                            Dans “plus d’options”, vous pouvez également :.
                            <ul>
                                <li>
                                    Décocher <b>"En-tête et pieds de page”</b>
                                </li>
                                <li>
                                    Cocher ou décocher <b>“arrière plan”</b>
                                </li>
                            </ul>
                            Dans le choix de l'imprimante, vous pouvez choisir "
                            <b>pdf</b>" pour exporter en pdf.
                        </ZPageSectionHelp>
                        <br />
                        Vous pouvez essayer sur l'EDT exemple (dans le menu "a
                        propos") <br />
                        <br />
                        Cette fonctionnalité est perfectible: Je prévois de
                        mettre au point un vrai système d’impression et d’export
                        des EDT en PDF ou au format Excel.
                    </p>
                </StylePageSection>
                */}
                <StylePageSection className="col-12 col-lg-6">
                    <h2>Notes pour les internats</h2>
                    <h3>Activez le mode internat</h3>
                    <p>
                        Quand vous créez un EDT, cochez la case "Internat".
                        (Vous pouvez toujours éditer l'EDT, si vous ne l'avez
                        pas fait).
                        <br />
                        Sinon, certaines fonctionnalités n'apparaissent pas
                        (nombre de nuit, mode "journée",...)
                    </p>
                    <h3>Horaires de l'EDT</h3>
                    <p>
                        Renseignez des horaires de journée (07:30 - 19:00) puis,
                        pour éditer les postages de nuit, passez en mode "24H"
                    </p>
                    <h3>Horaires du personnel de l'EDT</h3>
                    <p>
                        Exemple d'horaire pour un AED qui travail de nuit du
                        lundi 17:00 au mardi à 09:00 :
                        <br />
                        - Lundi : de 17:00 à 00:00
                        <br />- Mardi : de 00:00 à 09:00
                    </p>
                    <h3>
                        Affichage des postages : mode 24h ou horaires de l'EDT
                    </h3>
                    <p>
                        Cliquez sur le bouton <b>options</b> au dessus de la
                        grille des postages, et basculez entre les modes{" "}
                        <b>24h</b> (minuit-minuit){" "}
                        {/* , <b>Journée</b> (7:00-19:00) */}
                        ou <b>Horaires de l'EDT</b>, pour placer ou consulter
                        les postages de la journée plus facilement.
                    </p>
                </StylePageSection>
            </ZPageContent>
        </ZPage>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS

const StylePageSection = styled(ZPageSection)`
    p {
        padding: 10px;
    }
    h2 {
        font-size: 1.1em;
        padding-bottom: 10px;
    }
    h3 {
        font-size: 1em;
    }
`
