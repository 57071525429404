/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { useAppSelector } from "utils/store"
import { LinkContainer } from "react-router-bootstrap"
import { NavDropdown } from "react-bootstrap"
import { FaUserFriends, FaRoute, FaQuestion, FaBan } from "react-icons/fa"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function DropdownAdmin() {
    const { userIsWebmaster } = useAppSelector((state) => state.connectedUser)

    if (!userIsWebmaster) {
        return null
    }
    return (
        <NavDropdown
            title={
                <>
                    <FaBan /> Admin
                </>
            }
            id="basic-nav-dropdown"
            className="ms-auto"
        >
            <LinkContainer to="/admin">
                <NavDropdown.Item>
                    <FaUserFriends /> Dashboard
                </NavDropdown.Item>
            </LinkContainer>
            <LinkContainer to="/admin/users">
                <NavDropdown.Item>
                    <FaUserFriends /> Gestion des utilisateurs
                </NavDropdown.Item>
            </LinkContainer>
            <LinkContainer to="/admin/etablissements" disabled>
                <NavDropdown.Item>
                    <FaUserFriends /> Gestion des établissements
                </NavDropdown.Item>
            </LinkContainer>
            <NavDropdown.Divider />
            <NavDropdown.Item href="/admin/todo">
                <FaQuestion /> Todo
            </NavDropdown.Item>
            <NavDropdown.Item href="/dev/test">
                <FaRoute /> Test
            </NavDropdown.Item>
            <NavDropdown.Item href="#action/3.4" disabled>
                <FaRoute /> disabled
            </NavDropdown.Item>
        </NavDropdown>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS
