/* eslint-disable no-nested-ternary */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { useAppSelector, useAppDispatch } from "utils/store"
import { setVSrollablePercentHeight } from "features/timetable/utils/timetableReducer"
import { IcoZoomOut, IcoZoomIn } from "styles/icones"
import ButtonFilter from "./ButtonFilter"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function FilterVHeight() {
    const { vSrollablePercentHeight } = useAppSelector(
        (state) => state.timetable
    )
    const dispatch = useAppDispatch()
    const minH = 99
    const maxH = 400

    return (
        <div className="ms-4  no-print" style={{ whiteSpace: "nowrap" }}>
            <ButtonFilter
                disabled={vSrollablePercentHeight <= minH}
                className="me-1"
                title="Dézoomer"
                onClick={() => validateNewHeight(vSrollablePercentHeight / 1.2)}
            >
                <IcoZoomOut />
            </ButtonFilter>

            <ButtonFilter
                disabled={vSrollablePercentHeight >= maxH}
                title="Zoomer"
                onClick={() => validateNewHeight(vSrollablePercentHeight * 1.2)}
            >
                <IcoZoomIn />
            </ButtonFilter>
        </div>
    )

    function validateNewHeight(newH) {
        newH = Math.floor(newH)
        newH = newH < minH ? minH : newH > maxH ? maxH : newH
        dispatch(setVSrollablePercentHeight(newH))
    }
}
