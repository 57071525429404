/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"

import EtabUserTr from "./PersonnelsTableTr"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function PersonnelsListe({
    personnels,
    userIsAdminEtab,
    showInactifs,
}) {
    return (
        <table className="table table-striped table-bordered table-hover table-sm mb-5">
            {/* 
            <caption>Personnel de l&apos;établisssement</caption>
            */}
            <thead>
                <tr>
                    {userIsAdminEtab && <th>Edit</th>}
                    <th>Pseudo</th>
                    {/* <th>email</th> */}

                    <th>fonction</th>
                    <th>
                        Admin
                        <br />
                        Etab
                    </th>

                    {/*
                    {userIsAdminEtab && (
                        <th>
                            Demande
                            <br />
                            Acceptee
                        </th>
                    )}
                    */}
                    {userIsAdminEtab && <th>Compte Utilisateur</th>}
                </tr>
            </thead>
            <tbody>
                {personnels
                    .filter((perso) => showInactifs || perso.isActif)
                    .map((pers) => (
                        <EtabUserTr
                            personnel={pers}
                            key={"perskey-" + pers.id}
                            userIsAdminEtab={userIsAdminEtab}
                        />
                    ))}
            </tbody>

            {false && (
                <tfoot>
                    <tr>
                        <td colSpan="3">Total: xxxxxxxxxxxxxx</td>
                    </tr>
                </tfoot>
            )}
        </table>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS
