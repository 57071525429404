/* eslint-disable no-nested-ternary */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-unsafe-finally */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import { API_BASE_URL } from "utils/constants"
import { requestOptionsHeaders } from "utils/api"
import { errorManager } from "utils/api/functionsApi"
import { IEdtForm } from "features/edts/utils/edtFormReducer"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

//* ███████████████████████████████████████████████████████████████████████  zAPI - New version with errorManager OK

export async function zApiFetchMesEdts() {
    // * Renvoi :  reponse.edt  ou zError
    const url = API_BASE_URL + "/edts/mes_edts"
    console.group("🟨  zApiFetchMesEdts")
    console.log("➤➤ url:", url)
    const requestOptions = {
        method: "GET",
        headers: requestOptionsHeaders(),
        // body: JSON.stringify({ })
    }

    try {
        const response = await fetch(url, requestOptions)
        const rep = await response.json()
        if (rep.edts) {
            console.log("✔️ SUCCESS: edts: ", rep.edts)
            console.groupEnd()
            return { edts: rep.edts }
        }
        console.groupEnd()
        return { zError: errorManager(rep) }
    } catch (err) {
        console.groupEnd()
        return { zError: errorManager(err) }
    }
}

export async function zApiFetchEdt(edtId: number) {
    const url = API_BASE_URL + "/edts/" + edtId
    console.group("🟨  zApiFetchMesEdts")
    console.log("➤➤ url:", url)
    const requestOptions = {
        method: "GET",
        headers: requestOptionsHeaders(),
        // body: JSON.stringify({ })
    }

    try {
        const response = await fetch(url, requestOptions)
        const rep = await response.json()
        if (rep.edt) {
            console.log("✔️ SUCCESS: edt: ", rep.edt)
            console.groupEnd()
            return { edt: rep.edt }
        }
        console.groupEnd()
        return { zError: errorManager(rep) }
    } catch (err) {
        console.groupEnd()
        return { zError: errorManager(err) }
    }
}

export async function zApiEdtCreate(edt: IEdtForm) {
    // * Renvoi :  etablissement  ou zError
    const url = API_BASE_URL + "/edts/new/etab/" + edt.etabId
    console.group("🟨  zApiEtabCreate")
    console.log("➤➤ url:", url)
    const requestOptions = {
        method: "POST",
        headers: requestOptionsHeaders(),
        body: JSON.stringify(edt),
    }
    try {
        const response = await fetch(url, requestOptions)
        const rep = await response.json()
        if (rep.edt) {
            console.log("✔️ SUCCESS: edt: ", rep.edt)
            console.groupEnd()
            return { edt: rep.edt }
        }
        console.groupEnd()
        return { zError: errorManager(rep) }
    } catch (err) {
        console.groupEnd()
        return { zError: errorManager(err) }
    }
}

export async function zApiEdtUpdate(edt: IEdtForm) {
    // * Renvoi :  etablissement  ou zError
    const url = API_BASE_URL + "/edts/" + edt.id + "/update"
    console.group("🟨  zApiEdtUpdate")
    console.log("➤➤ url:", url)
    const requestOptions = {
        method: "POST",
        headers: requestOptionsHeaders(),
        body: JSON.stringify(edt),
    }
    try {
        const response = await fetch(url, requestOptions)
        const rep = await response.json()
        if (rep.edt) {
            console.log("✔️ SUCCESS: edt: ", rep.edt)
            console.groupEnd()
            return { edt: rep.edt }
        }
        console.groupEnd()
        return { zError: errorManager(rep) }
    } catch (err) {
        console.groupEnd()
        return { zError: errorManager(err) }
    }
}

export async function zApiEdtDelete(edtId: number) {
    // * Renvoi :  "SUCCESS"  ou zError

    const url = API_BASE_URL + "/edts/" + edtId + "/delete"
    console.group("🟨  zApiEdtDelete")
    console.log("➤➤ url:", url)

    const requestOptions = {
        method: "DELETE",
        headers: requestOptionsHeaders(),
    }

    try {
        const response = await fetch(url, requestOptions)
        const rep = await response.json()
        if (rep.message && rep.message === "SUCCESS") {
            console.log("✔️ SUCCESS: zApiEdtDelete")
            console.groupEnd()
            return "SUCCESS"
        }
        console.groupEnd()
        return { zError: errorManager(rep) }
    } catch (err) {
        console.groupEnd()
        return { zError: errorManager(err) }
    }
}

//* ███████████████████████████████████████████████████████████████████████
