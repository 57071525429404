// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"

import {
    ZPage,
    ZPageHeader,
    ZPageTitle,
    ZPageContent,
    ZPageSection,
} from "uiZ/zPage"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

/**
 * * ccccccc
 * * fccccccccccc
 * * S'iccccccccccccc
 * TODO : vvvvvvvvvvvvvvte)
 * TODO : ivvvvvvvvvvvvvvvvfs
 *
 */

export default function PageErrorAlreadyConnected() {
    // const navigate = useNavigate()

    return (
        <ZPage documentTitle="Déjà connecté">
            <ZPageHeader>
                <ZPageTitle>Erreur</ZPageTitle>
            </ZPageHeader>

            <ZPageContent>
                <ZPageSection className="col-12">
                    <h1 className="text-danger">
                        Vous êtes déjà connecté (avec un autre compte ?)
                    </h1>
                    <p>Déconnectez vous et recommencez !</p>
                </ZPageSection>
            </ZPageContent>
        </ZPage>
    )
}
