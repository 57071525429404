/* eslint-disable no-use-before-define */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"

import styled from "styled-components"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function PasswordForgottenSuccessMessage({
    email,
}: {
    email: string
}) {
    return (
        <StyledMessage className=" col-12">
            Un mail vous été envoyé à l'adresse <u>{email}</u> pour
            réinitialiser votre mot de passe.
        </StyledMessage>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS

const StyledMessage = styled.div`
    margin: 20px;
    padding: 10px;
    border: 1px solid green;
`
