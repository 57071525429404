// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { useAppSelector, useAppDispatch } from "utils/store"
import { initTimetableReducer } from "features/timetable/utils/timetableReducer"
import { useParams } from "react-router-dom"

import { zApiFetchMembres } from "features/membres/utils/apiMembres"

import { ZPage } from "uiZ/zPage"

import Membres from "../features/membres/_membres/Membres"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function PageMembres() {
    const dispatch = useAppDispatch()
    const edtId = Number(useParams().id) || 0
    if (edtId <= 0) {
        return <h1 className="text-danger">Erreur url</h1>
    }

    const { edt } = useAppSelector((state) => state.timetable)

    const fetchSuccessFunction = (result: any) => {
        // dispatch(fetchHPSSuccess(result.edt))
        dispatch(
            initTimetableReducer({
                edt: result.edt,
                postes: [],
                modeHoraires: true,
                modeHPS: false,
                demo: false,
            })
        )
    }

    return (
        <ZPage
            documentTitle="Personnel de l'EDT"
            edt={edt}
            fetchFunction={() => zApiFetchMembres(edtId)}
            fetchSuccessFunction={(result: any) => fetchSuccessFunction(result)}
        >
            <Membres />
        </ZPage>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS
