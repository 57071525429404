/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { ZPageSectionTodo } from "uiZ/zPage"
import { dev } from "utils/constants"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function EtabShowSectionTodo({ className }) {
    return (
        <ZPageSectionTodo className={className}>
            <h2>Améliorations à venir:</h2>
            <ul>
                <li>Dupliquer un EDT.</li>
                <li>Améliorer le formulaire de création du personnel.</li>
            </ul>
            {dev && (
                <>
                    <h2>Dev:</h2>
                    <ul>
                        <li>
                            Personnel : Demande Acceptee ( Ca marche ? C'est
                            util ? )
                        </li>
                        <li>Personnel.isCompteCommun</li>
                        <li>EDT.isInternat ne sert à rien pour l'instant</li>
                        <li>Personnel inactif</li>
                        <li>xxxxxxxx</li>
                        <li>xxxxxxxx</li>
                    </ul>
                </>
            )}
        </ZPageSectionTodo>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS
