/* eslint-disable no-alert */
/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import Button from "react-bootstrap/Button"
import { useAppSelector, useAppDispatch } from "utils/store"
import { setFormAddUsersToEdtStatus } from "features/membres/utils/uWReducer"
import { IcoAdd } from "styles/icones"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function ButtonUWCreate({ className = "" }) {
    const dispatch = useAppDispatch()
    const { locked } = useAppSelector((state) => state.timetable)

    function handleClick() {
        if (locked) {
            alert("Cliquez sur le cadenas pour deverouiller les horaires")
            return
        }

        dispatch(setFormAddUsersToEdtStatus("OPEN"))
    }

    return (
        <Button
            className={" " + className}
            variant="primary"
            title="Ajouter un utilsateur sur cet EDT"
            onClick={() => handleClick()}
        >
            <IcoAdd />
        </Button>
    )
}
