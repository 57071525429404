/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { useAppSelector } from "utils/store"
import { ZPageSection, ZPageContent } from "uiZ/zPage"
import UWsTable from "features/membres/membresList/table/UWsTable"
import HorairesMembresTable from "features/membres/horairesMembres/table/HorairesMembresTable"
import Timetable from "features/timetable/Timetable"
import SectionHelpAddMembres from "./SectionHelpAddMembres"
import SectionHelpHorairesMembres from "./SectionHelpHorairesMembres"
import SectionTodoMembres from "./SectionTodoMembres"
import ModeTableOrTimetable from "./ModeTableOrTimetable"
import HorairesMembresTitmetableWindowHeader from "./HorairesMembresTitmetableWindowHeader"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function MembresContent({
    horaireModeTimetable,
    setHoraireModeTimetable,
    setTimetableFullWindow,
}) {
    const { edt, membres } = useAppSelector((state) => state.timetable)

    return (
        <ZPageContent>
            {edt && membres && (
                <>
                    {/* //******      Personnel qui figure sur cet EDT           ***** */}
                    <ZPageSection className="row col-12">
                        <div className="row col-12">
                            <div className="col-12  col-lg-6 zzAutoSdivcrollX">
                                <UWsTable />
                            </div>
                            <SectionHelpAddMembres />
                        </div>
                    </ZPageSection>

                    {/* //******      Horaires du personnel de l'EDT           ***** */}
                    {membres && membres.length > 0 && (
                        <ZPageSection>
                            <h3>
                                Horaires du personnel pour l&apos;EDT{" "}
                                <i>
                                    <u>{edt.nom}</u>
                                </i>
                            </h3>

                            <ModeTableOrTimetable
                                horaireModeTimetable={horaireModeTimetable}
                                setHoraireModeTimetable={
                                    setHoraireModeTimetable
                                }
                            />

                            {horaireModeTimetable ? (
                                <div className="row col-12">
                                    <HorairesMembresTitmetableWindowHeader
                                        timetableFullWindow={false}
                                        setTimetableFullWindow={
                                            setTimetableFullWindow
                                        }
                                    />
                                    <div className="col-12">
                                        <Timetable />
                                    </div>
                                </div>
                            ) : (
                                <div className="row col-12 zzAutoScrollX">
                                    <HorairesMembresTable />
                                </div>
                            )}
                            <SectionHelpHorairesMembres />
                        </ZPageSection>
                    )}
                </>
            )}
            <SectionTodoMembres />
        </ZPageContent>
    )
}
