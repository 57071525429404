/*
 *       COPIE DE POSTAGE A MODIFIER
 */

/* eslint-disable no-unsafe-finally */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import { API_BASE_URL } from "utils/constants"
import { requestOptionsHeaders } from "utils/api"
import { errorManager } from "utils/api/functionsApi"
import { IMembreForm, IHoraireMembre } from "./membresReducer"
// import { Edt } from "features/timetable/utils/timetableReducer"

//* ███████████████████████████████████████████████████████████████████████  zAPI - New version with errorManager OK

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
export async function zApiFetchMembres(edtId: number) {
    // * Renvoi :  reponse.edt  ou zError

    const url = API_BASE_URL + "/membres/edt/" + edtId
    console.group("🟨  zApiFetchMembres")
    console.log("➤➤ url:", url)

    const requestOptions = {
        method: "GET",
        headers: requestOptionsHeaders(),
        // body: JSON.stringify({ })
    }

    try {
        const response = await fetch(url, requestOptions)
        const rep = await response.json()
        if (rep.edt) {
            console.log("✔️ SUCCESS: edt: ", rep.edt)
            console.groupEnd()
            return { edt: rep.edt }
        }
        console.groupEnd()
        return { zError: errorManager(rep) }
    } catch (err) {
        console.groupEnd()
        return { zError: errorManager(err) }
    }
}

export async function zApiMembreUpdate(membre: IMembreForm) {
    // * Renvoi :  membre  ou zError

    const url = API_BASE_URL + "/membres/" + membre.id + "/edit"
    console.group("🟨  zApiMembreUpdate")
    console.log("➤➤ url:", url)

    const requestOptions = {
        method: "POST",
        headers: requestOptionsHeaders(),
        body: JSON.stringify(membre),
    }

    try {
        const response = await fetch(url, requestOptions)
        const rep = await response.json()
        if (rep.membre) {
            console.log("✔️ SUCCESS: zApiEdtDelete")
            console.groupEnd()
            return rep.membre
        }
        console.groupEnd()
        return { zError: errorManager(rep) }
    } catch (err) {
        console.groupEnd()
        return { zError: errorManager(err) }
    }
}

export async function zApiMembreDelete(membreId: number) {
    // * Renvoi :  "SUCCESS"  ou zError

    const url = API_BASE_URL + "/membres/" + membreId + "/delete"
    console.group("🟨  zApiMembreDelete")
    console.log("➤➤ url:", url)

    const requestOptions = {
        method: "DELETE",
        headers: requestOptionsHeaders(),
    }

    try {
        const response = await fetch(url, requestOptions)
        const rep = await response.json()
        if (rep.message && rep.message === "SUCCESS") {
            console.log("✔️ SUCCESS: zApiEdtDelete")
            console.groupEnd()
            return "SUCCESS"
        }
        console.groupEnd()
        return { zError: errorManager(rep) }
    } catch (err) {
        console.groupEnd()
        return { zError: errorManager(err) }
    }
}

export async function zApiHoraireMembreCreate(horaireMembre: IHoraireMembre) {
    // * Renvoi :  horaireMembre  ou zError

    const url =
        API_BASE_URL + "/membres/" + horaireMembre.membreId + "/horaire/new"
    console.group("🟨  zApiHoraireMembreCreate")
    console.log("➤➤ url:", url)

    const requestOptions = {
        method: "POST",
        headers: requestOptionsHeaders(),
        body: JSON.stringify(horaireMembre),
    }

    try {
        const response = await fetch(url, requestOptions)
        const rep = await response.json()
        if (rep.horaireMembre) {
            console.log("✔️ SUCCESS: zApiHoraireMembreUpdate")
            console.groupEnd()
            return { horaireMembre: rep.horaireMembre }
        }
        console.groupEnd()
        return { zError: errorManager(rep) }
    } catch (err) {
        console.groupEnd()
        return { zError: errorManager(err) }
    }
}

export async function zApiHoraireMembreUpdate(horaireMembre: IHoraireMembre) {
    // * Renvoi :  horaireMembre  ou zError

    const url = API_BASE_URL + "/membres/horaire/" + horaireMembre.id + "/edit"
    console.group("🟨  zApiHoraireMembreUpdate")
    console.log("➤➤ url:", url)

    const requestOptions = {
        method: "POST",
        headers: requestOptionsHeaders(),
        body: JSON.stringify(horaireMembre),
    }

    try {
        const response = await fetch(url, requestOptions)
        const rep = await response.json()
        if (rep.horaireMembre) {
            console.log("✔️ SUCCESS: zApiHoraireMembreUpdate")
            console.groupEnd()
            return rep.horaireMembre // !!!! ou          { horaireMembre: rep.horaireMembre }
        }
        console.groupEnd()
        return { zError: errorManager(rep) }
    } catch (err) {
        console.groupEnd()
        return { zError: errorManager(err) }
    }
}

export async function zApiHoraireMembreDelete(horaireMembreId: number) {
    // * Renvoi :  "SUCCESS"  ou zError

    const url = API_BASE_URL + "/membres/horaire/" + horaireMembreId + "/delete"
    console.group("🟨  zApiHoraireMembreDelete")
    console.log("➤➤ url:", url)

    const requestOptions = {
        method: "DELETE",
        headers: requestOptionsHeaders(),
    }

    try {
        const response = await fetch(url, requestOptions)
        const rep = await response.json()
        if (rep.message && rep.message === "SUCCESS") {
            console.log("✔️ SUCCESS: zApiHoraireMembreDelete")
            console.groupEnd()
            return "SUCCESS"
        }
        console.groupEnd()
        return { zError: errorManager(rep) }
    } catch (err) {
        console.groupEnd()
        return { zError: errorManager(err) }
    }
}

//* ███████████████████████████████████████████████████████████████████████

export async function apiAddPersonnelToEdt(personnelId: number, edtId: number) {
    let uW = null
    let error = null
    const url =
        API_BASE_URL + "/membres/edt/" + edtId + "/add_personnel/" + personnelId
    console.log("url:", url)

    const requestOptions = {
        method: "POST",
        headers: requestOptionsHeaders(),
        // body: JSON.stringify(),
    }

    try {
        const response = await fetch(url, requestOptions)
        const rep = await response.json()
        if (rep.apixError) {
            error = rep.apixError
        } else if (rep.uw) {
            console.log("rep.uW::", rep.uw)
            uW = rep.uw
        } else {
            console.log("erreur - rep : ", rep)
            error = "Erreur inconnue"
        }
    } catch (err) {
        error = "Catch: " + err
    } finally {
        // eslint-disable-next-line no-unsafe-finally
        return {
            uW,
            error,
        }
    }
}

// ■■ // ■■ // ■■ // ■■ // ■■ // ■■ // EDT-PERSONNEL - HORAIRE

export async function apiHoraireMembreCreate(uWHoraireForm: any) {
    let uWHoraire = null
    let error = null
    const url =
        API_BASE_URL + "/membres/" + uWHoraireForm.membreId + "/horaire/new"
    console.log("url:", url)

    const requestOptions = {
        method: "POST",
        headers: requestOptionsHeaders(),
        body: JSON.stringify({
            jour: uWHoraireForm.jour,
            semaine: uWHoraireForm.semaine,
            heureDebut: uWHoraireForm.heureDebut,
            heureFin: uWHoraireForm.heureFin,
            // membreId: uWHoraireForm.membreId,
            infos: uWHoraireForm.infos,
        }),
    }

    try {
        const response = await fetch(url, requestOptions)
        const rep = await response.json()
        if (rep.apixError) {
            error = rep.apixError
        } else if (rep.uWHoraire) {
            uWHoraire = rep.uWHoraire
        } else {
            console.log("erreur - rep : ", rep)
            error = "Erreur inconnue"
        }
    } catch (err) {
        error = "Catch: " + err
    } finally {
        // eslint-disable-next-line no-unsafe-finally
        return {
            uWHoraire,
            error,
        }
    }
}
