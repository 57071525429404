/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { useAppSelector } from "utils/store"
import { connectedUserIsAdminEtab } from "utils/fonctions"
import {
    ZPageContent,
    ZPageSectionHelp,
    ZPageHeader,
    ZPageTitle,
} from "uiZ/zPage"

import EdtForm from "features/edts/form/EdtForm"
// import PersonnelForm from "features/personnels/crud/PersonnelFormOld"
import PersonnelForm from "features/personnels/form/PersonnelForm"

import ButtonEtabEdit from "../crud/ButtonEtabEdit"
import EtabShowSectionEdts from "./EtabShowSectionEdts"
import EtabShowSectionPersonnels from "./EtabShowSectionPersonnels"
import EtabShowSectionTodo from "./EtabShowSectionTodo"
import EtabShowSectionHelp from "./EtabShowSectionHelp"
import EtabForm from "../form/EtabForm"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function EtabShow() {
    const {
        etab,
        // formEdt,
        // formWorkspaceStatus,
        formPersonnel,
        // personnelToDelete,
    } = useAppSelector((state) => state.etabShow)

    const userIsAdminEtab = connectedUserIsAdminEtab(etab)

    const etabFormReducer = useAppSelector((state) => state.etabForm)
    const { edtForm } = useAppSelector((state) => state.edtForm)

    if (!etab || !etab.personnels || !etab.edts) {
        return <h1>!etab</h1>
    }

    return (
        <>
            <ZPageHeader>
                <ZPageTitle>
                    {etab.genre}&nbsp;<b>{etab.nom} </b>{" "}
                    {etab.ville && <>&nbsp;({etab.ville}) </>}
                    {userIsAdminEtab && (
                        <div className="ms-auto">
                            <ButtonEtabEdit etab={etab} />
                        </div>
                    )}
                </ZPageTitle>
            </ZPageHeader>
            <ZPageContent>
                {/* // *** FORMS *** // */}
                {userIsAdminEtab && etabFormReducer.etab && <EtabForm />}
                {userIsAdminEtab && edtForm && <EdtForm />}

                {userIsAdminEtab && formPersonnel && <PersonnelForm />}

                {/* // *** XXXXXXXXXXXXXXXXX *** // */}

                {(etab.personnels?.length < 4 || etab.edts?.length < 1) && (
                    <ZPageSectionHelp>
                        <h2>Assistant de création d&apos;EDT</h2>
                        <p>
                            Commencez par ajouter du personnel à cet
                            établissement.
                            <br />
                            Ensuite, créez un 1er EDT.
                        </p>
                    </ZPageSectionHelp>
                )}

                <EtabShowSectionEdts
                    etab={etab}
                    className="col-12"
                    userIsAdminEtab={userIsAdminEtab}
                />

                <EtabShowSectionPersonnels
                    etab={etab}
                    userIsAdminEtab={userIsAdminEtab}
                    className="col-12"
                />

                <div className="col-12 row">
                    <EtabShowSectionHelp className="col-12 col-lg-6" />
                    <EtabShowSectionTodo className="col-12 col-lg-6" />
                </div>
            </ZPageContent>{" "}
        </>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS
