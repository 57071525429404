/* eslint-disable react/no-unstable-nested-components */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"

import { ZPageSection } from "uiZ/zPage"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

interface Log {
    createdAt: string
    event: string
}
interface User {
    id: number
    logs: Log[]
    username: string
}

type Props = {
    users: User[]
}
export default function DashboardLogsOld({ users }: Props) {
    // const [formEditUserIsOpen, setFormEditUserIsOpen] = useState(false)

    /*
    const formatedUsers = users.map((user) => ({
        ...user,
        lastLogAt:
            user.logs.length > 0
                ? user.logs[0].createdAt + " : " + user.logs[0].event
                : "",
    }))
    */
    return (
        <ZPageSection>
            <h3>Users sans log:</h3>

            {users
                .filter((user) => user.logs.length === 0)
                .map((user) => (
                    <span key={"user-a-" + user.id}>{user.username}, </span>
                ))}
        </ZPageSection>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS
