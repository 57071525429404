// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import styled from "styled-components"
import {
    ZPage,
    ZPageHeader,
    ZPageTitle,
    ZPageContent,
    ZPageSection,
} from "uiZ/zPage"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function PageAboutChangeLog() {
    return (
        <ZPage documentTitle="FAQ">
            <ZPageHeader>
                <ZPageTitle>Changelog (historique des mises à jour)</ZPageTitle>
            </ZPageHeader>

            <ZPageContent className="row col-12">
                <StylePageSection className="col-12">
                    <h2>Dernières mises à jour:</h2>
                    <ul>
                        <li>
                            14/09/2022 - Impression des EDT (postages ou
                            horaires)
                        </li>{" "}
                        <li>
                            Une marge de 10 minutes s'ajoute quand on
                            déveerouille la timetable.
                            <br />
                            <small>
                                Avant, le curseur s'annulait quand on arrivait à
                                la limite de l'horaire
                            </small>
                        </li>
                        <li>
                            Invitations simplifiées.
                            <br />
                            <small>
                                La personne reçoit une invitation par mail. Elle
                                s'inscrit <i>normalement</i>.<br />
                                Apres inscription, elle doit valider son adresse
                                mail, puis elle peut se connecter et appartient
                                automatiquement à l'établissement.
                            </small>
                        </li>
                        <li>Vidéos explicatives Youtube</li>
                        <li>
                            Internat: Possibilité de définir un horaire jusqu'à
                            minuit pour un personnel.
                            <br />
                            <small>Avant, la limite etait 23:59</small>
                        </li>
                        <li>
                            Résolution du problème qui affichait "Token invalid"
                            pour les utilisateur avec un accent dans leur nom
                        </li>
                        <li>
                            Mode 24H pour afficher les EDT de minuit à minuit
                            pour les internats
                        </li>
                        <li>
                            Affichage des sonneries (ou des heures) sur la{" "}
                            <i>timetable</i> quand on place les postages ou les
                            horaires.
                        </li>
                    </ul>
                </StylePageSection>
            </ZPageContent>
        </ZPage>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS

const StylePageSection = styled(ZPageSection)`
    p {
        padding: 10px;
    }
    h2 {
        font-size: 1.1em;
        padding-bottom: 10px;
    }
    h3 {
        font-size: 1em;
    }
`
