/* eslint-disable no-nested-ternary */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-unsafe-finally */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import { API_BASE_URL } from "utils/constants"
import { requestOptionsHeadersPublic } from "utils/api"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export async function apiRegisterMailValidation(email: string, token: string) {
    let success = false
    let error = null
    const url = API_BASE_URL + "/authentification/register/mail/validation"
    console.log("apiRegisterMailValidation - url:", url)

    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
        body: JSON.stringify({ email, token }),
    }

    try {
        const response = await fetch(url, requestOptions)
        const rep = await response.json()
        console.log("AAAAAA.rep", rep)
        if (rep.message && rep.message === "SUCCESS") {
            success = true
        } else if (rep.error) {
            if (rep.error === "ERR_EMAIL_MISMATCH") {
                error = "L'adresse email ne correspond pas."
            } else if (rep.error === "ERR_TOKEN_MISMATCH") {
                error =
                    "Le token ne correspond pas. Si vous avez fait plusieurs demandes de réinitialisation, verifiez que vous avez cliquez sur le dernier mail reçu. Sinon, recommencez."
            } else if (rep.error === "ERR_REGISTRATION_ALREADY_VALIDATE") {
                error =
                    "Votre inscription à déjà été validée. Vous pouvez vous connecter."
            } else {
                error = "Erreur inconnue."
            }
        } else {
            console.log("rep--------------------:" + rep)
            error = "Erreur inconnue..."
        }
    } catch (err) {
        error = "CatchError: " + err
    } finally {
        return {
            success,
            error,
        }
    }
}
export async function apiPasswordReset(
    userId: string,
    token: string,
    email: string,
    password: string
) {
    let success = false
    let error = null
    const url = API_BASE_URL + "/authentification/password_reset/" + userId
    console.log("apiPasswordReset - url:", url)

    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
        body: JSON.stringify({ token, email, password }),
    }

    try {
        const response = await fetch(url, requestOptions)
        const rep = await response.json()
        console.log("AAAAAA.rep", rep)
        if (rep.message && rep.message === "SUCCESS") {
            success = true
        } else if (rep.error) {
            error = rep.error
            /*
            if (rep.error === "ERR_EMAIL_MISMATCH") {
                error = "L'adresse email ne correspond pas."
            } else if (rep.error === "ERR_TOKEN_MISMATCH") {
                error =
                    "Le token ne correspond pas. Si vous avez fait plusieurs demandes de réinitialisation, verifiez que vous avez cliquez sur le dernier mail reçu. Sinon, recommencez."
            } else {
                error = "Erreur inconnue."
            }
            */
        } else {
            console.log("rep--------------------:" + rep)
            error = "Erreur inconnue..."
        }
    } catch (err) {
        error = "CatchError: " + err
    } finally {
        return {
            success,
            error,
        }
    }
}
export async function apiRegister(
    username: string,
    email: string,
    password: string
) {
    let success = false
    let error = null
    const url = API_BASE_URL + "/authentification/register"
    console.log("apiPasswordReset - url:", url)

    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
        body: JSON.stringify({ username, email, password }),
    }

    try {
        const response = await fetch(url, requestOptions)
        const rep = await response.json()
        console.log("AAAAAA.rep", rep)
        if (rep.message && rep.message === "SUCCESS") {
            success = true
        } else if (rep.error) {
            if (rep.error === "ERR_EMAIL_EXISTING") {
                error = "Cette adresse email existe déjà."
            } else if (rep.error === "ERR_USERNAME_EXISTING") {
                error = "Ce nom d'utilisateur existe déjà."
            } else {
                error = "Erreur inconnue."
            }
        } else {
            console.log("rep--------------------:" + rep)
            error = "Erreur inconnue..."
        }
    } catch (err) {
        error = "CatchError: " + err
    } finally {
        return {
            success,
            error,
        }
    }
}

export async function apiPassWordForgottenSendMail(email: string) {
    let success = false
    let error = null
    const url = API_BASE_URL + "/authentification/password_forgotten/send_mail"
    console.log("apiPassWordForgottenSendMail - url:", url)

    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
        body: JSON.stringify({ email }),
    }

    try {
        const response = await fetch(url, requestOptions)
        const rep = await response.json()
        // console.log("AAAAAA.rep", rep)
        if (rep.message && rep.message === "SUCCESS") {
            success = true
        } else if (rep.error) {
            if (rep.error === "ERR_EMAIL_NOT_FOUND") {
                error = "Cette adresse ne correspond à aucun utilisateur"
            } else {
                error = "Erreur inconnue."
            }
        } else {
            console.log("rep--------------------:" + rep)
            error = "Erreur inconnue..."
        }
    } catch (err) {
        error = "CatchError: " + err
    } finally {
        return {
            success,
            error,
        }
    }
}

export async function apiValidateMailPersonnel(
    personnelId: number,
    email: string,
    username: string,
    password: string
) {
    /**
     * Quand on clique sur "Envoyer un mail d'invitation" à un personnel de l'etab
     * L'utilisateur recoit un mail
     * il choisit son pseudo et mot de passe
     * Back-end : Un user est crée...................
     *
     */
    let personnel = null
    let error = null
    const url =
        API_BASE_URL + "/authentification/invitation/register/" + personnelId
    console.log("url:", url)

    const requestOptions = {
        method: "POST",
        headers: requestOptionsHeadersPublic(),
        body: JSON.stringify({ email, username, password }),
    }

    try {
        const response = await fetch(url, requestOptions)
        const rep = await response.json()
        if (rep.apiZError) {
            error = rep.apiZError
        } else if (rep.personnel) {
            console.log("+++++++++++++++++rep", rep)
            personnel = rep.personnel
        } else {
            console.log(rep)
            error = "Erreur de connexion inconnue"
        }
    } catch (err) {
        error = "Catch: " + err
    } finally {
        return {
            personnel,
            error,
        }
    }
}

export async function apiLogin(username: string, password: string) {
    let user = null
    let error = null
    const url = API_BASE_URL + "/authentification/login"
    console.log("apiLogin - url:", url)

    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
        body: JSON.stringify({
            username,
            password,
        }),
    }

    try {
        const response = await fetch(url, requestOptions)
        const rep = await response.json()
        if (rep.user) {
            console.log("apiLogin", rep.user)
            user = rep.user
        } else if (rep.error) {
            error = rep.error
        } else {
            console.log("apiLogin - error > rep:" + rep)
            error = "Erreur de connexion inconnue"
        }
    } catch (err) {
        error = "Catch: " + err
    } finally {
        return {
            user,
            error,
        }
    }
}

// ■■ // ■■ // ■■ // ■■ // ■■ // ■■ // A MODIFIER POUR REDUX TOOLKIT
