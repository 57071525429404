// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { useAppSelector, useAppDispatch } from "utils/store"
import ZxModal from "ui/ZxModal"
import { ZForm } from "uiZ/zForm"
import HoraireFormInner from "./HoraireFormInner"
import {
    setHoraireForm,
    horaireCreateSuccess,
    horaireUpdateSuccess,
    horaireDeleteSuccess,
} from "../../utils/hPSReducer"

import {
    zApiHoraireCreate,
    zApiHoraireUpdate,
    zApiHoraireDelete,
} from "../../utils/apiHPS"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

// todo : importer depuis hPSReducer
interface IFormValues {
    id: number | null
    jour: string
    heureDebut: string
    heureFin: string

    infos: string | null // !!! obligatoire dans hPSReducer
    fetchStatus: null | "FETCHING" | "SUCCESS" | "ERROR" // !!! obligatoire dans hPSReducer
    workspaceId: number | null // pour create horaire   // !!! obligatoire dans hPSReducer
    semaine: string | null //   "A" | "B"   // !!! obligatoire dans hPSReducer
}

type Props = {
    // closeForm: any
    edtId: number | null // null si edit ???
}

// eslint-disable-next-line no-unused-vars
export default function HoraireForm({ edtId }: Props) {
    const dispatch = useAppDispatch()
    const { horaireForm } = useAppSelector((state) => state.hPS)

    const customValidate = (values: IFormValues) => {
        let errors = {}

        if (values.heureDebut >= values.heureFin) {
            errors = {
                ...errors,
                heureDebut:
                    "L'heure de fin doit être superieure à l'heure de début !",
            }
        }
        return errors
    }

    if (!horaireForm) {
        return null
    }

    return (
        <ZxModal styles={{ maxWidth: "460px" }}>
            <ZForm
                initialData={horaireForm}
                customValidate={customValidate}
                closeForm={() => dispatch(setHoraireForm(null))}
                // *** Create:
                createTitle={`Ajouter un horaire pour le ${horaireForm?.jour}`}
                createFunction={(args: any) =>
                    zApiHoraireCreate(edtId || 0, args)
                }
                createSuccessFunction={(result: any) =>
                    dispatch(horaireCreateSuccess(result))
                }
                // *** Update:
                updateTitle={`Modifier l'horaire pour le ${horaireForm?.jour}`}
                updateFunction={zApiHoraireUpdate}
                updateSuccessFunction={(result: any) =>
                    dispatch(horaireUpdateSuccess(result))
                }
                // *** Delete:
                deleteTitle={`Supprimer l'horaire du ${horaireForm?.jour}`}
                deleteConfirmText=""
                deleteFunction={() => zApiHoraireDelete(horaireForm.id || 0)}
                deleteSuccessFunction={(_result: any) =>
                    dispatch(horaireDeleteSuccess(horaireForm.id))
                }
            >
                <HoraireFormInner />
                <div className="text-info mb-4">
                    Pour les internats, vous pouvez mettre de "00:00" à "23:59"
                </div>
            </ZForm>
        </ZxModal>
    )
}
