/* eslint-disable no-nested-ternary */
/* eslint-disable import/prefer-default-export */
/**
 *
 * param : postes[]
 * return : goupes[{nom, postes}]
 *
 */
export function getGroupesFromPostes(postes) {
    const groupeNames = []
    const groupes = []

    postes.forEach((poste) => {
        const tempGroupeName =
            poste.groupe !== null && poste.groupe !== "" ? poste.groupe : "_"

        if (!groupeNames.includes(tempGroupeName)) {
            groupeNames.push(tempGroupeName)
        }
    })

    const sortedGroupeNames = groupeNames.sort((a, b) =>
        a.toLowerCase() > b.toLowerCase()
            ? 1
            : b.toLowerCase() > a.toLowerCase()
            ? -1
            : 0
    )

    let groupeId = 100

    sortedGroupeNames.forEach((groupeName) => {
        let groupe
        if (groupeName === "_") {
            groupe = {
                id: groupeId,
                nom: "",
                postes: postes.filter(
                    (poste) => poste.groupe === "" || poste.groupe === null
                ),
            }
        } else {
            groupe = {
                id: groupeId,
                nom: groupeName,
                postes: postes.filter((poste) => poste.groupe === groupeName),
            }
        }
        groupes.push(groupe)
        groupeId += 1
    })

    return groupes
}
