// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import styled from "styled-components"
// import LogsUser from "./LogsUser"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

interface User {
    id: number
    username: string
}

interface Log {
    id: number
    createdAt: string
    event: string
    user?: User
}

type Props = {
    logs: Log[]
    minDate: string
    maxDate: string
    className: string
}
export default function LogsHeure({
    logs,
    minDate,
    maxDate,
    className,
}: Props) {
    const filteredLogs = logs.filter(
        (log) => log.createdAt >= minDate && log.createdAt <= maxDate // && log.user?.id !== 1 && log.user?.id !== 4
    )

    const differentUsersIds = filteredLogs
        .map((item) => item.user?.id)
        .filter((value, index, self) => index && self.indexOf(value) === index) // "index &&...." pour enlever les logs sans user (loginFail)
    // console.log("differentUsersIds", differentUsersIds)

    const maxUsersByOur = 10
    const thisHeightPercent = (differentUsersIds.length * 100) / maxUsersByOur

    let details2 = ""

    differentUsersIds.forEach((userId) => {
        const userLogs = filteredLogs.filter((log) => log.user?.id === userId)
        const { user } = userLogs[0]
        if (user && userLogs) {
            details2 += user.username + ","
        }
    })

    return (
        <StyledHour className={" border align-bottom  " + className}>
            <i>{minDate.slice(12, 15)}</i>
            <br />
            <div className="barContainer align-bottom">
                <StyledBar
                    style={{ height: thisHeightPercent + "%" }}
                    title={details2}
                >
                    {differentUsersIds.length > 0 && differentUsersIds.length}
                </StyledBar>
            </div>

            {/* 
            {differentUsersIds.map((userId) => {
                const userLogs = filteredLogs.filter(
                    (log) => log.user?.id === userId
                )
                const { user } = userLogs[0]
                if (user && userLogs) {
                    return (
                        <LogsUser
                            key={"log-diff-userId-" + userId}
                            user={user}
                            userLogs={userLogs}
                        />
                    )
                }
                return null
            })}
            */}
        </StyledHour>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS
const StyledHour = styled.div`
    background-color: gray;
    padding: 2px;
    width: 4.1666%;
    display: inline-block;
    text-align: center;

    .barContainer {
        height: 100px;
        background-color: white;
        display: flex;
    }
`
const StyledBar = styled.div`
    background-color: blue;

    width: 100%;
    color: white;
    display: inline-block;
    align-self: flex-end;
    //  height: $ {(props) => props.percentheight};
`
