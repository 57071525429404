/* eslint-disable react/prop-types */
/* eslint-disable no-use-before-define */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React, { useState } from "react"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import styled from "styled-components"
import { Loading } from "ui"
import { FieldEmail, FieldPassword, FieldPassword2 } from "../common/formFields"
import { apiPasswordReset } from "../utils/apiAuthentification"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function FormPasswordReset({ userId, token }) {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [password2, setPassword2] = useState("")

    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)

    function validateForm() {
        // todo: verifier mail valide !!!
        return email.length > 8 && password.length > 5 && password === password2
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        setError(false)
        setSuccess(false)
        setLoading(true)

        apiPasswordReset(userId, token, email, password).then((reponse) => {
            console.log("apiPassWordForgottenSendMail.reponse:", reponse)
            if (reponse.success) {
                setSuccess(true)

                // dispatch(login(email))
            } else if (reponse.error) {
                setError(reponse.error)
            }
            setLoading(false)
        })
    }

    return (
        <StyledLoginForm onSubmit={handleSubmit}>
            <FieldEmail email={email} setEmail={setEmail} />
            <FieldPassword password={password} setPassword={setPassword} />
            <FieldPassword2 password2={password2} setPassword2={setPassword2} />

            {success && <h1 className="text-success">success</h1>}
            {error && <div className="text-danger">{error}</div>}

            {loading && <Loading />}

            {!success && !loading && (
                <Button
                    block="true"
                    size="lg"
                    type="submit"
                    disabled={!validateForm()}
                    className="mt-2"
                >
                    Valider
                </Button>
            )}
        </StyledLoginForm>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS

const StyledLoginForm = styled(Form)``
