/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import styled from "styled-components"
import { useAppSelector } from "utils/store"

import ButtonToggleEdtLocked from "features/membres/ButtonToggleEdtLocked"
import UWsTableTr from "./UWsTableTr"
import ButtonUWCreate from "../ButtonUWCreate"

// import ButtonCreateUser from './ButtonCreateUser'
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function UWsTable() {
    const { membres, locked } = useAppSelector((state) => state.timetable)

    const membresount = membres.length
    const nombreColonnes = locked ? 5 : 8

    // console.log("UWsTable - membres", membres)

    membres
        .slice()
        .sort((a, b) =>
            a.pseudo.toLowerCase() > b.pseudo.toLowerCase()
                ? 1
                : b.pseudo.toLowerCase() > a.pseudo.toLowerCase()
                ? -1
                : 0
        )

    return (
        <StyledTable className="table table-sm table-striped table-bordered mb-5">
            <thead>
                <tr>
                    <th colSpan={nombreColonnes}>
                        Personnel qui figure sur cet EDT
                        <ButtonToggleEdtLocked className="float-end" />
                        {!locked && (
                            <ButtonUWCreate className="float-end me-2" />
                        )}
                    </th>
                </tr>
                <tr className="zxTrColumnsHeader">
                    {!locked && <th>Edit</th>}

                    {/*
                    <th>Id</th>
                     */}
                    <th>Pseudo</th>
                    <th>Fonction</th>
                    <th style={{ width: "70px" }}>Quotité</th>
                    <th style={{ width: "60" }}>Heures /sem.</th>
                    <th style={{ width: "60" }}>Nuits /sem.</th>
                </tr>
            </thead>
            <tbody>
                {membres.map((uw) => (
                    <UWsTableTr key={"uw-" + uw.id} uw={uw} />
                ))}
            </tbody>

            <tfoot>
                <tr>
                    <td colSpan={nombreColonnes}>
                        {membresount} personne{membresount > 1 && "s"} sur cet
                        EDT.
                    </td>
                </tr>
            </tfoot>
        </StyledTable>
    )
}

// █████████████████████████████████████████████████████████████	StyledComponents

const StyledTable = styled.table`
    max-width: 700px;
    tbody {
        td {
            font-size: 1em;
        }
        .tr-inactif {
            background-color: rgb(150, 150, 150);
        }
        tr:nth-child(odd) {
            background-color: rgb(200, 233, 255);
        }
    }
`
