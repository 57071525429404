/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import Button from "react-bootstrap/Button"
import { IcoQuestion } from "styles/icones"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function ButtonShowHelp({ showHelp, setShowHelp }) {
    return (
        <Button
            className="btn-sm no-print"
            variant={showHelp ? "danger" : "info"}
            title="Afficher l'aide"
            onClick={() => setShowHelp(!showHelp)}
        >
            {showHelp ? "Fermer" : <IcoQuestion />}
        </Button>
    )
}
