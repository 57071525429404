/* eslint-disable no-use-before-define */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React, { useState } from "react"
import { useAppSelector, useAppDispatch } from "utils/store"
import { useNavigate } from "react-router-dom"
import { dev } from "utils/constants"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import styled from "styled-components"
import Loading from "ui/Loading"
import ErrorMessage from "ui/ErrorMessage"
// import { login } from "features/users/utils/connectedUserReducer"
import {
    loginStart,
    loginSuccess,
    loginError,
} from "features/users/utils/connectedUserReducer"
import { apiLogin } from "features/authentification/utils/apiAuthentification"
import { FieldUsername, FieldPassword } from "../common/formFields"
import DebugSetIdentifiants from "./DebugSetIdentifiants"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function FormLogin() {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const userReducer = useAppSelector((state) => state.connectedUser)
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")

    function validateForm() {
        return username.length > 0 && password.length > 0
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        dispatch(loginStart())
        apiLogin(username, password).then((reponse) => {
            if (reponse.user) {
                navigate("/")
                dispatch(loginSuccess(reponse.user))
            } else if (reponse.error) {
                dispatch(loginError(reponse.error))
            }
        })
    }

    return (
        <StyledLoginForm onSubmit={handleSubmit}>
            <FieldUsername username={username} setUsername={setUsername} />

            <FieldPassword password={password} setPassword={setPassword} />

            {userReducer.loginError && (
                <ErrorMessage
                    className="mb-2 mt-1"
                    errorMessage={userReducer.loginError}
                />
            )}

            {userReducer.userStatus === "LOGGING" ? (
                <Loading />
            ) : (
                <Button
                    block="true"
                    size="lg"
                    type="submit"
                    disabled={!validateForm()}
                    className="mt-2"
                >
                    Connexion
                </Button>
            )}

            {dev && (
                <DebugSetIdentifiants
                    setUsername={setUsername}
                    setPassword={setPassword}
                />
            )}
        </StyledLoginForm>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS

const StyledLoginForm = styled(Form)``
