/* eslint-disable import/prefer-default-export */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-unsafe-finally */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import { API_BASE_URL } from "utils/constants"
import { requestOptionsHeaders } from "utils/api"
import { IPersonnel } from "features/etabs/utils/etabShowReducer"
import { errorManager } from "utils/api/functionsApi"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

//* ███████████████████████████████████████████████████████████████████████  zAPI - New version with errorManager OK

export async function zApiPersonnelCreate(
    personnel: IPersonnel,
    etabId: number
) {
    // * Renvoi :  etablissement  ou zError
    const url = API_BASE_URL + "/personnels/new/etab/" + etabId
    console.group("🟨  zApiPersonnelCreate")
    console.log("➤➤ url:", url)
    const requestOptions = {
        method: "POST",
        headers: requestOptionsHeaders(),
        body: JSON.stringify(personnel),
    }
    try {
        const response = await fetch(url, requestOptions)
        const rep = await response.json()
        if (rep.personnel) {
            console.log("✔️ SUCCESS: personnel: ", rep.personnel)
            console.groupEnd()
            return { personnel: rep.personnel }
        }
        console.groupEnd()
        return { zError: errorManager(rep) }
    } catch (err) {
        console.groupEnd()
        return { zError: errorManager(err) }
    }
}

export async function zApiPersonnelUpdate(personnel: IPersonnel) {
    // * Renvoi :  etablissement  ou zError
    const url = API_BASE_URL + "/personnels/" + personnel.id + "/edit"
    console.group("🟨  zApiPersonnelUpdate")
    console.log("➤➤ url:", url)
    const requestOptions = {
        method: "POST",
        headers: requestOptionsHeaders(),
        body: JSON.stringify(personnel),
    }
    try {
        const response = await fetch(url, requestOptions)
        const rep = await response.json()
        if (rep.personnel) {
            console.log("✔️ SUCCESS: personnel: ", rep.personnel)
            console.groupEnd()
            return { personnel: rep.personnel }
        }
        console.groupEnd()
        return { zError: errorManager(rep) }
    } catch (err) {
        console.groupEnd()
        return { zError: errorManager(err) }
    }
}

export async function zApiPersonnelDelete(personnelId: number) {
    // * Renvoi :  "SUCCESS"  ou zError

    const url = API_BASE_URL + "/personnels/" + personnelId + "/delete"
    console.group("🟨  zApiPersonnelDelete")
    console.log("➤➤ url:", url)

    const requestOptions = {
        method: "DELETE",
        headers: requestOptionsHeaders(),
    }

    try {
        const response = await fetch(url, requestOptions)
        const rep = await response.json()
        if (rep.message && rep.message === "SUCCESS") {
            console.log("✔️ SUCCESS: zApiPersonnelDelete")
            console.groupEnd()
            return "SUCCESS"
        }
        console.groupEnd()
        return { zError: errorManager(rep) }
    } catch (err) {
        console.groupEnd()
        return { zError: errorManager(err) }
    }
}

//* ███████████████████████████████████████████████████████████████████████

export async function apiEtabUserSendMail(eu: any) {
    let personnel = null
    let error = null
    const url = API_BASE_URL + "/personnels/" + eu.id + "/send_mail"
    console.log(">>>>apiEtabUserSendMail url:", url)

    const requestOptions = {
        method: "POST",
        headers: requestOptionsHeaders(),
        // body: JSON.stringify(eu),
    }

    try {
        const response = await fetch(url, requestOptions)
        const rep = await response.json()
        if (rep.error) {
            console.log("repppppp:", rep)
            error = "Erreur: " + rep.apixError
        } else if (rep.personnel) {
            console.log("repppppp:", rep)
            personnel = rep.personnel
        } else {
            console.log("repppppp:", rep)
            console.log("rep--------------------:" + rep)
            error = "Erreur de connexion inconnue"
        }
    } catch (err) {
        console.log("err:", err)
        error = "Catch: " + err
    } finally {
        return {
            personnel,
            error,
        }
    }
}
