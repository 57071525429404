// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

type Props = {
    horaireModeTimetable: boolean
    setHoraireModeTimetable: (_mode: boolean) => any
}

export default function ModeTableOrTimetable({
    horaireModeTimetable,
    setHoraireModeTimetable,
}: Props) {
    return (
        <div className=" col-12 mb-3">
            Afficher les horaires sous forme de{" "}
            <strong
                className={!horaireModeTimetable ? "clickText" : ""}
                role="button"
                onClick={() => setHoraireModeTimetable(true)}
                aria-hidden="true"
            >
                Graphique (grille)
            </strong>
            {" / "}
            <strong
                className={horaireModeTimetable ? "clickText" : ""}
                role="button"
                onClick={() => setHoraireModeTimetable(false)}
                aria-hidden="true"
            >
                Tableau
            </strong>
        </div>
    )
}
