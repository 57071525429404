/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React, { useState } from "react"
import { Button } from "react-bootstrap"
import PersonnelsTable from "./PersonnelsTable"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function PersonnelsListe({ personnels, userIsAdminEtab }) {
    // console.log("etabUsers", etabUsers)
    const sortedPersonnels = personnels
        .slice() // pour faire une copie du tableau
        .sort((a, b) =>
            a.pseudo > b.pseudo ? 1 : b.pseudo > a.pseudo ? -1 : 0
        )

    const [showInactifs, setShowInactifs] = useState(false)

    const persosInactifsCount = sortedPersonnels.filter(
        (pers) => !pers.isActif
    ).length

    return (
        <>
            {persosInactifsCount > 0 && (
                <Button
                    onClick={() => setShowInactifs(!showInactifs)}
                    className="btn-sm"
                    variant="info"
                >
                    {showInactifs ? "Masquer " : "Afficher "}
                    {persosInactifsCount} personnel
                    {persosInactifsCount > 0 && "s"} inactif
                    {persosInactifsCount > 0 && "s"}
                </Button>
            )}
            <PersonnelsTable
                personnels={sortedPersonnels}
                userIsAdminEtab={userIsAdminEtab}
                showInactifs={showInactifs}
            />
        </>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS
