/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { NavLink } from "react-router-dom"
import {
    ZPageSectionTodo,
    ZPageHeader,
    ZPageTitle,
    ZPageContent,
    ZPageSection,
} from "uiZ/zPage"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function UserShow({ user }) {
    console.log("user", user)
    return (
        <>
            <ZPageHeader className=" col-12">
                <ZPageTitle className="page-title float-start">
                    Utilisateur: <b>{user.username}</b>
                </ZPageTitle>
            </ZPageHeader>

            <ZPageContent className="page-content  col-12">
                <ZPageSection>
                    id: <b>{user.id}</b>
                    <br />
                    actif: <b>{user.actif ? "Oui" : "non"}</b>
                    <br />
                    couleur: <b>{user.couleur}</b>
                    <br />
                    dateEntree: <b>{user.dateEntree}</b>
                    <br />
                    dateSortie: <b>{user.dateSortie}</b>
                    <br />
                    email: <b>{user.email}</b>
                    <br />
                    nom: <b>{user.nom}</b>
                    <br />
                    prenom: <b>{user.prenom}</b>
                    <br />
                    pseudo: <b>{user.pseudo}</b>
                    <br />
                    username: <b>{user.username}</b>
                    <br />
                    <br />
                    <h3>Les établissements de {user.username}:</h3>
                    {user.personnels?.map((eU) => (
                        <div key={"eu-" + eU.id}>
                            <NavLink to={"/etab/" + eU.etablissement.id}>
                                - {eU.etablissement.nom}
                            </NavLink>
                        </div>
                    ))}
                </ZPageSection>
                <ZPageSectionTodo className="col-12 col-md-6">
                    <h2>Améliorations à venir:</h2>
                    <ul>
                        <li>Modifier mon mot de passe, mon pseudo</li>
                        <li>
                            Afficher la liste de mes établissement et de mes EDT
                        </li>
                        <li>Me déconnecter de tous les appareils</li>
                        <li>Option (que faire à l&apos;accueil)</li>
                    </ul>
                </ZPageSectionTodo>
            </ZPageContent>
        </>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS
