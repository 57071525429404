/* eslint-disable no-use-before-define */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React, { useState } from "react"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import styled from "styled-components"
import { Loading } from "ui"
import { FieldEmail } from "../common/formFields"
import PasswordForgottenSuccessMessage from "../common/PasswordForgottenSuccessMessage"
import { apiPassWordForgottenSendMail } from "../utils/apiAuthentification"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function FormPasswordReset() {
    const [email, setEmail] = useState("")
    const [error, setError] = useState(null)
    const [success, setSuccess] = useState(false)
    const [loading, setLoading] = useState(false)

    function validateForm() {
        // todo: verifier mail valide !!!
        return email.length > 8
    }

    const handleSubmit = (event) => {
        event.preventDefault()

        setLoading(true)

        apiPassWordForgottenSendMail(email).then((reponse) => {
            console.log("apiPassWordForgottenSendMail.reponse:", reponse)
            if (reponse.success) {
                setSuccess(true)
                // dispatch(login(email))
            } else if (reponse.error) {
                setError(reponse.error)
            }
            setLoading(false)
        })
    }

    if (success) {
        return <PasswordForgottenSuccessMessage email={email} />
    }

    return (
        <StyledLoginForm onSubmit={handleSubmit}>
            <FieldEmail email={email} setEmail={setEmail} />

            {error && <div className="text-danger">{error}</div>}
            {loading ? (
                <Loading />
            ) : (
                <Button
                    block="true"
                    size="lg"
                    type="submit"
                    disabled={!validateForm()}
                    className="mt-2"
                >
                    Valider
                </Button>
            )}
        </StyledLoginForm>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS

const StyledLoginForm = styled(Form)``
