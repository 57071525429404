// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
// import React from "react"
import { progressItemsMesEtabs } from "./aboutCardProgressData_MesEtabs"
import { progressItemsEtabShow } from "./aboutCardProgressData_EtabShow"
import { progressItemsEdtShow } from "./aboutCardProgressData_EdtShow"
import { progressItemsEdtHPS } from "./aboutCardProgressData_EdtHPS"
import { progressItemsEdtMembres } from "./aboutCardProgressData_EdtMembres"
import { progressItemsPostes } from "./aboutCardProgressData_Postes"
import { progressItemsPostages } from "./aboutCardProgressData_Postages"
import { progressItemsDivers } from "./aboutCardProgressData_Divers"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export const status = {
    pending: "PENDING",
    canceled: "CANCELED",
    processing: "PROCESSING",
    checking: "CHECKING",
    done: "DONE",
}

export const progressItems = [].concat(
    progressItemsMesEtabs,
    progressItemsEtabShow,
    progressItemsEdtShow,
    progressItemsEdtHPS,
    progressItemsEdtMembres,
    progressItemsPostes,
    progressItemsPostages,
    progressItemsDivers
)
