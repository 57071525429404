// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { useAppSelector } from "utils/store"
import { NavLink } from "react-router-dom" // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
import { ZPageSectionHelp, ZYoutube } from "uiZ/zPage"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function SectionHelpAddMembres() {
    const { edt } = useAppSelector((state) => state.timetable)

    return (
        <ZPageSectionHelp className="col-12  col-lg-6 ">
            <h2>Aide: Ajouter du personnel à l'EDT</h2>
            <ul>
                <li>
                    Si vous ne l'avez pas déjà fait, commencez par créer du
                    personnel sur la{" "}
                    <NavLink to={"/etab/" + (edt ? edt.etablissement.id : "")}>
                        page de l'établissement
                    </NavLink>
                    .
                </li>
                <li>Cliquez sur le cadenas pour déverouiller.</li>
                <li>
                    Cliquez sur le bouton + pour ajouter le personnel de votre
                    établissement qui doit figurer sur l&apos;EDT
                </li>
                <li>
                    Vous pouvez ensuite editer cette liste pour modifier le
                    temps de travail hebdomadaire, le nombre de nuit...
                </li>
            </ul>

            <ZYoutube
                src="https://www.youtube.com/embed/t1a2V1At76I"
                title="cmzEDT: Ajouter du personnel à l'EDT"
            />
        </ZPageSectionHelp>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS
