// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import styled from "styled-components"
// import { theme } from "styles/theme"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
LPSide.defaultProps = {
    //  bgPrimary: false,
    className: "",
}
type Props = {
    //  bgPrimary?: boolean
    className?: string
    children: React.ReactNode
}
export default function LPSide({
    //  bgPrimary,
    className,
    children,
}: Props) {
    return (
        <StyleSide
            className={className + " lp-side"}
            //  bgPrimary={bgPrimary}
        >
            <div className="verical-middle ">
                <div>{children}</div>
            </div>
        </StyleSide>
    )
}
// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS

const StyleSide = styled.div`
    h2 {
        font-size: 1.8em;
        font-weight: bold;
        padding-bottom: 15px;
    }
    .verical-middle {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        div {
            width: 100%;
        }
    }
    p {
        margin-bottom: 15px;
        padding: 10px;
    }
`
