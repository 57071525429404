/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import Button from "react-bootstrap/Button"
import { IcoAdd } from "styles/icones"
import { randomHexColor } from "utils/fonctions"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function ButtonPeriodeCreate({ setFormPeriode }) {
    const newPeriode = {
        nom: "",
        heureDebut: "08:00",
        heureFin: "09:00",
        infos: null,
        couleur: randomHexColor(),
    }

    return (
        <Button
            title="Ajouter une periode"
            className="btn-sm float-end"
            onClick={() => setFormPeriode(() => setFormPeriode(newPeriode))}
            disabled
        >
            <IcoAdd />
        </Button>
    )
}
