/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React, { useContext } from "react"
import { FormContext } from "ui/ZForm/ZForm"
import { getGroupesFromPostes } from "features/postes/utils/postesHelpers"
import styled from "styled-components"
// import PostesGroupe from "features/postes/PostesGroupe"
// import styled from "styled-components"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function PostageFormPostes({ postes /* , handleSubmit */ }) {
    if (!postes || postes.length === 0) {
        return (
            <h5 className="text-danger m-5">
                Vous devez d&apos;abord créer des postes !
            </h5>
        )
    }
    const { form, handleFormChange } = useContext(FormContext)
    const groupes = getGroupesFromPostes(postes)
    return (
        <div>
            Poste:
            <div className="groupes row col-12">
                {groupes &&
                    groupes.map((groupe) => (
                        <div key={`groupe-` + groupe.nom}>
                            {/* <h3>{groupe.nom}</h3> */}
                            {groupe.postes.map((poste) => (
                                <StyledPoste
                                    key={`poste-${poste.slug}`}
                                    couleur={poste.couleur}
                                    selected={poste.id === form.posteId}
                                    className="btn-sm m-1"
                                    onClick={() => handleOnClick(poste.id)}
                                >
                                    {poste.slug}
                                </StyledPoste>
                            ))}
                        </div>
                    ))}
            </div>
        </div>
    )

    function handleOnClick(posteId) {
        handleFormChange({
            target: { name: "posteId", value: posteId },
        })
        // handleSubmit() // c'est le submit du form
    }
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS

const StyledPoste = styled.div`
    border: 1px solid black;
    padding: 3px;
    font-size: 0.7em;
    margin-bottom: 3px;
    display: inline-block;
    background-color: ${(props) => props.couleur};

    ${(props) => props.selected && `font-size: 1.5em;`};

    &:hover {
        cursor: pointer;
        // color: darkorange;
        border: 1px solid blue;
        box-shadow: 2px 2px 2px black;
    }

    .poste-description {
        background-color: white;
        font-size: 0.7em;
        padding: 2px;
    }
`
