/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import Button from "react-bootstrap/Button"
import { IcoEdit } from "styles/icones"
import { useAppDispatch } from "utils/store"
import { setFormPersonnel } from "features/etabs/utils/etabShowReducer"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function ButtonPersonnelEdit({ etabUser, className = "" }) {
    const dispatch = useAppDispatch()

    // const { workspace } = useAppSelector((state) => state.hPS)

    function handleClick() {
        dispatch(setFormPersonnel(etabUser))
    }

    return (
        <Button
            className={"btn-sm p-1 " + className}
            variant="warning"
            title="Modifier xxxxxxxxxxxxxxxxxxxx "
            onClick={() => handleClick()}
        >
            <IcoEdit />
        </Button>
    )
}
