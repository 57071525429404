/* eslint-disable react/prop-types */
/* eslint-disable react/self-closing-comp */

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { useAppDispatch } from "utils/store"
import styled from "styled-components"
import { ZErrorInterface } from "uiZ/zError"
import { dev } from "utils/constants"
import { useNavigate, NavLink } from "react-router-dom"
import { logout } from "features/users/utils/connectedUserReducer"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
type Props = {
    zError: ZErrorInterface
}
export default function ZError({ zError }: Props) {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    //  let userErrorMessage = null

    /*
    switch (errorMessage) {
        case "CANT_USE_CMZ_EDT":
            console.log("bbbbbbbbbb")
            userErrorMessage = "Vous n'êtes pas autorisé à utiliser cmzEDT."
            break
        case "WRONG_PASSWORD":
            userErrorMessage = "Mauvais mot de passe."
            break
        case "UNKNOWN_USERNAME":
            userErrorMessage = "Nom d'utilisateur inconnu."
            break
        default:
            userErrorMessage = errorMessage
    }
    console.log("userErrorMessage", userErrorMessage)
    */

    function deconnexion() {
        navigate("/")
        dispatch(logout())
    }

    if (zError.status === 404 || zError.status === 405) {
        zError.customContent = (
            <>
                Actualisez (F5) ou retournez sur la page{" "}
                <NavLink className="card-headerX" to="/mes-etabs">
                    Mes établissements
                </NavLink>{" "}
                ou sur{" "}
                <NavLink className="card-headerX" to="/">
                    la page d'accueil
                </NavLink>
            </>
        )
    } else if (zError.detail === "INVALID_TOKEN") {
        zError.customContent = (
            <>
                <b>Token Invalide !</b>
                <br />
                Actualisez (F5) ou{" "}
                <NavLink to="#" onClick={() => deconnexion()}>
                    Déconnectez-vous
                </NavLink>{" "}
                !
                <br />
                Si le problème persiste, contactez le webmaster.
            </>
        )
    } else if (zError.detail === "INVALID_TOKENcccccccccccccccccccccccc") {
        zError.customContent = (
            <>
                <b>Token Invalide !</b>
                <br />
                Actualisez (F5) ou{" "}
                <NavLink to="#" onClick={() => deconnexion()}>
                    Déconnectez-vous
                </NavLink>{" "}
                !
                <br />
                Si le problème persiste, contactez le webmaster.
            </>
        )
    }

    return (
        <StyledZError className="row col-12 text-center">
            <section>
                <h3>Une erreur est survenue.</h3>

                {zError.userMessage && <h2>{zError.userMessage}</h2>}

                {zError.customContent && (
                    <div className="customContent">{zError.customContent}</div>
                )}

                {dev && (
                    <div className="dev">
                        <h4>devOnly:</h4>
                        <div>
                            <b>status:</b> {zError.status}
                        </div>
                        <div>
                            <b>detail:</b> {zError.detail}
                        </div>
                        <div>
                            <b>devMessage:</b> {zError.devMessage}
                        </div>
                    </div>
                )}
            </section>
        </StyledZError>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS

const StyledZError = styled.div`
    padding: 10px !important;
    section {
        padding: 20px;
        border: 1px solid red;
        h3 {
            color: red;
        }
        h2 {
            font-size: 1.2em;
            margin-top: 20px;
            margin-bottom: 20px;
        }
        .customContent {
            border-top: 1px solid red;
            margin-top: 20px;
            padding-top: 20px;
        }
        .dev {
            padding: 5px;
            border: 1px solid red;
            background-color: rgb(255, 230, 230);
            overflow-y: auto;
            text-align: left;
            margin-top: 30px;
        }
    }
    /*background-color: $ {theme.colors.c1} !important; */
    // background-color: lime;

    /*
    height: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0px !important;
    width: 100% !important;
    .btn {
        cursor: pointer;
    }
    .row {
        margin: 0px !important;
    }
    */
`
