/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import Button from "react-bootstrap/Button"
import { useAppSelector, useAppDispatch } from "utils/store"
import { setLocked } from "features/timetable/utils/timetableReducer"

import { IcoLocked, IcoUnlocked } from "styles/icones"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function ButtonToggleEdtLocked({ className = "" }) {
    const { locked } = useAppSelector((state) => state.timetable)
    const dispatch = useAppDispatch()
    return (
        <Button
            className={className}
            variant={locked ? "primary" : "danger"}
            onClick={() => dispatch(setLocked(!locked))}
            title={locked ? "Déverouiller l'EDT" : "Verrouiller l'EDT"}
        >
            {locked ? <IcoLocked /> : <IcoUnlocked />}
        </Button>
    )
}
