/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React, { useState } from "react"

import ZFormHeader from "./ZFormHeader"
import ZFormFooter from "./ZFormFooter"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export const FormContext = React.createContext({
    form: {},
})

export default function Form({
    initialValues,
    title,
    children,
    isLoading,
    handleSubmit,
    handleCancel,
    handleDelete,
    showDeleteButton = true,
}) {
    const [form, setForm] = useState(initialValues)
    const editMode = form.id !== null

    return (
        <form className="Form">
            <ZFormHeader
                title={title}
                editMode={editMode}
                isLoading={isLoading}
                actionDelete={() => handleDelete(form)}
                showDeleteButton={showDeleteButton}
            />

            <FormContext.Provider value={{ form, handleFormChange }}>
                {children}
            </FormContext.Provider>

            <ZFormFooter
                isLoading={isLoading}
                editMode={editMode}
                handleSubmit={() => handleSubmit(form)}
                handleCancel={handleCancel}
            />
        </form>
    )

    function handleFormChange(event) {
        // console.log("handleFormChange", event)
        const { name, value } = event.target
        setForm({ ...form, [name]: value })
        // console.log(form)
    }
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS
