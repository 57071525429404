/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React, { useContext } from "react"
import { Button } from "react-bootstrap"
import { FormContext } from "ui/ZForm/ZForm"
// import styled from "styled-components"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function PostageFormSemaines() {
    const { form, handleFormChange } = useContext(FormContext)
    return (
        <>
            Semaine
            <Button
                variant={
                    form.semaine !== "A" && form.semaine !== "B"
                        ? "primary"
                        : "secondary"
                }
                className="ms-2"
                onClick={() => handleOnClick("")}
            >
                A&B
            </Button>
            <Button
                variant={form.semaine === "A" ? "primary" : "secondary"}
                onClick={() => handleOnClick("A")}
                className="ms-2"
            >
                A
            </Button>
            <Button
                variant={form.semaine === "B" ? "primary" : "secondary"}
                onClick={() => handleOnClick("B")}
                className="ms-2"
            >
                B
            </Button>
        </>
    )

    function handleOnClick(sem) {
        console.log("handleOnClick")
        handleFormChange({
            target: { name: "semaine", value: sem },
        })
    }
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS

// const StyledForm = styled.div``
