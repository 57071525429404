/* eslint-disable no-alert */
/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { useAppSelector, useAppDispatch } from "utils/store"
import Button from "react-bootstrap/Button"
import { IcoAdd } from "styles/icones"
import { setHoraireForm } from "../utils/hPSReducer"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function ButtonHoraireCreate({ jour, className = "" }) {
    const dispatch = useAppDispatch()

    const { workspace } = useAppSelector((state) => state.hPS)

    function handleClick() {
        const newHoraire = {
            id: null,
            jour,
            heureDebut: "07:00",
            heureFin: "18:00",
            workspaceId: workspace.id,
        }
        dispatch(setHoraireForm(newHoraire))
    }

    return (
        <Button
            className={"btn-sm p-1 " + className}
            variant="primary"
            title={"Ajouter un horaire pour le " + jour}
            onClick={() => handleClick()}
        >
            <IcoAdd />
        </Button>
    )
}
