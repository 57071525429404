/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { LinkContainer } from "react-router-bootstrap"
import { Nav } from "react-bootstrap"

import { IcoArrowDownRight } from "styles/icones"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function SubMenuWorkspace({ workspace = null }) {
    return (
        <div className="d-flex ms-5  text-light">
            <IcoArrowDownRight className="align-self-center" />

            <SubMenuItem to={`/edt/${workspace.id}/horaires`} text="Horaires" />
            <SubMenuSeparator />
            <SubMenuItem
                to={`/edt/${workspace.id}/membres`}
                text="Personnel de l'EDT"
            />
            <SubMenuSeparator />
            <SubMenuItem to={`/edt/${workspace.id}/postes`} text="Postes" />
            <SubMenuSeparator />
            <SubMenuItem to={`/edt/${workspace.id}/postages`} text="Postages" />
        </div>
    )
}

function SubMenuSeparator() {
    return <div>|</div>
}

function SubMenuItem({ to, text }) {
    return (
        <LinkContainer to={to} className="pt-0 pb-0">
            <Nav.Link>{text}</Nav.Link>
        </LinkContainer>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS
