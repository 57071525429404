/* eslint-disable react/no-array-index-key */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React, { useState } from "react"
import Button from "react-bootstrap/Button"

// import styled from "styled-components"
import { ZPage, ZPageHeader, ZPageTitle, ZPageContent } from "uiZ/zPage"
import Scrum from "features/admin/todo/todo_scrum/Scrum"
import AboutCardProgress from "features/admin/todo/todo_progress/AboutCardProgress"
import AboutCardReducers from "features/admin/todo/AboutCardReducers"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function PageTodo() {
    const [module, setModule] = useState("PROGRESS")
    return (
        <ZPage>
            <ZPageHeader documentTitle="Déjà connecté">
                <ZPageTitle>
                    About cmzEdt.zedixi / Scrum Project Management Board
                </ZPageTitle>
                <div className="float-end">
                    <Button
                        variant={module === "SCRUM" ? "primary" : "secondary"}
                        onClick={() => setModule("SCRUM")}
                    >
                        Scrum
                    </Button>{" "}
                    <Button
                        variant={
                            module === "PROGRESS" ? "primary" : "secondary"
                        }
                        onClick={() => setModule("PROGRESS")}
                    >
                        Progress
                    </Button>{" "}
                    <Button
                        variant={
                            module === "REDUCERS" ? "primary" : "secondary"
                        }
                        onClick={() => setModule("REDUCERS")}
                    >
                        Reducers
                    </Button>
                </div>
            </ZPageHeader>

            <ZPageContent>
                {module === "REDUCERS" && <AboutCardReducers />}
                {module === "PROGRESS" && <AboutCardProgress />}
                {module === "SCRUM" && <Scrum />}
            </ZPageContent>
        </ZPage>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS
/*
const StyledItem = styled.div`
    /*background-color: green;* /
    margin: 4px;
    padding: 10px;
    border-right: 1px solid gray;
    border-bottom: 1px solid gray;
    li {
        margin: 0px;
    }
    .date {
        color: blue;
    }
`

const StyledChangelogItem = styled(StyledItem)`
    border-color: blue;
    .date {
        color: blue;
    }
`
const StyledTodoItem = styled(StyledItem)`
    border-color: red;
`
const StyledBacklogItems = styled(StyledItem)`
    border-color: green;
`
*/
