// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { useAppDispatch } from "utils/store"
import ZxModal from "ui/ZxModal"
import { ZForm } from "uiZ/zForm"
import {
    zApiSonnerieCreate,
    zApiSonnerieUpdate,
    zApiSonnerieDelete,
} from "../utils/apiHPS"
import {
    sonnerieUpdateSuccess,
    sonnerieCreateSuccess,
    sonnerieDeleteSuccess,
} from "../utils/hPSReducer"
import SonnerieFormInner from "./SonnerieFormInner"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

// todo : importer depuis hPSReducer
interface IFormValues {
    id: number
    nom: string | undefined
    heure: string
}

type Props = {
    sonnerie: IFormValues
    closeForm: any
    edtId: number
}

export default function SonnerieForm({ edtId, sonnerie, closeForm }: Props) {
    const dispatch = useAppDispatch()

    const customValidate = (values: IFormValues) => {
        let errors = {}
        // eslint-disable-next-line no-constant-condition
        if (!values.nom && false && false && false && false && false) {
            errors = {
                ...errors,
                nom: "test- mank nom",
            }
        }
        return errors
    }

    return (
        <ZxModal styles={{ maxWidth: "400px" }}>
            <ZForm
                initialData={sonnerie}
                closeForm={closeForm}
                customValidate={customValidate}
                // *** Create:
                createTitle="Ajouter une sonnerie"
                createFunction={(args: any) => zApiSonnerieCreate(edtId, args)}
                createSuccessFunction={(result: any) =>
                    dispatch(sonnerieCreateSuccess(result))
                }
                // *** Update:
                updateTitle="Modifier la sonnerie"
                updateFunction={zApiSonnerieUpdate}
                updateSuccessFunction={(result: any) =>
                    dispatch(sonnerieUpdateSuccess(result))
                }
                // *** Delete:
                deleteTitle={`Supprimer la sonnerie de ${sonnerie?.heure}`}
                deleteConfirmText=""
                deleteFunction={() => zApiSonnerieDelete(sonnerie.id)}
                deleteSuccessFunction={(_result: any) =>
                    dispatch(sonnerieDeleteSuccess(sonnerie.id))
                }
            >
                <SonnerieFormInner />
            </ZForm>
        </ZxModal>
    )
}
