// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React, { useState, useEffect } from "react"
import { useAppSelector, useAppDispatch } from "utils/store"
import { useParams } from "react-router-dom"
import styled from "styled-components"
import { initTimetableReducer } from "features/timetable/utils/timetableReducer"
import { apiFetchPostages } from "features/postages/utils/apiPostages"
import Timetable from "features/timetable/Timetable"
import HeaderMenu from "ui/headerMenu/HeaderMenu"
import { Loading } from "ui"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
// TODO *   ZPage
// TODO *
// TODO *
// TODO *
// TODO *
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function PagePostages() {
    const dispatch = useAppDispatch()
    const { id } = useParams()
    const edtId = parseInt(id, 10).isNaN ? 0 : parseInt(id, 10)

    const { edt } = useAppSelector((state) => state.timetable)

    // const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null)

    useEffect(() => {
        if (edtId > 0) {
            // setIsLoading(true)
            apiFetchPostages(edtId).then((reponse) => {
                if (reponse.edt && reponse.postes) {
                    console.log("reponse", reponse)
                    dispatch(
                        initTimetableReducer({
                            edt: reponse.edt,
                            postes: reponse.postes,
                            modeHoraires: false,
                            modeHPS: false,
                            demo: false,
                        })
                    )
                } else if (reponse.error) {
                    setError(reponse.error)
                }
                // setIsLoading(false)
            })
        }
    }, [edtId])

    if (error) {
        return (
            <>
                <HeaderMenu />
                <h4 className="text-danger">Une erreur s&apos;est produite</h4>
                {error && <p className="text-danger">{error}</p>}
                <p>
                    Actualisez la page ou cliquez sur le menu &quot;Mes
                    établissements&quot;
                </p>
            </>
        )
    }

    // if (isLoading) {
    if (!edt) {
        return (
            <>
                <HeaderMenu />
                <Loading />
            </>
        )
    }

    return (
        <StyledPagePostage>
            <div id="top">
                <HeaderMenu workspace={edt} mode="POSTAGES" />
            </div>

            <Timetable />
        </StyledPagePostage>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS

/**
 *  La page entiere (headerMenu + date/heure + Filters + Edt)
 */
const StyledPagePostage = styled.div`
    display: flex;
    flex-flow: column;
    // background-color: lime;
    height: 100%;
    margin: 0px;

    #top {
        // background-color: orange;
        flex: 0 1 auto;
    }

    #reste {
        // background-color: cyan;
        flex: 1 1 auto;
        overflow-y: auto;
    }
`
