// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React, { useState, useEffect } from "react"
import { useAppSelector } from "utils/store"
import { ZErrorInterface, ZError } from "uiZ/zError"
import styled from "styled-components"
import { theme } from "styles/theme"
import HeaderMenu from "ui/headerMenu/HeaderMenu"
import HeaderMenuPublic from "ui/headerMenu/HeaderMenuPublic"
import Loading from "ui/Loading"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

/*
 *  ZPage = ZPageMenu + ZPageHeader + ZPageContent
 *
 *
 *
 *
 */

type Props = {
    documentTitle: string
    children?: React.ReactNode
    className?: String | null
    // * pour le menu::
    edt?: any | null
    etab?: any | null
    navHeaderVariant?: string // "light" | "primary"
    // * option 1:
    fetchFunction?: (() => any) | null
    fetchSuccessFunction?: ((_result: any) => any) | null
    // * option 2:
    isLoading?: boolean
    error?: String | null
}

ZPage.defaultProps = {
    edt: null,
    etab: null,
    children: null,
    className: "",
    fetchFunction: null,
    fetchSuccessFunction: null,
    isLoading: false,
    error: null,
    navHeaderVariant: "primary",
}

export default function ZPage({
    children,
    edt,
    etab,
    className,
    documentTitle,
    fetchFunction,
    fetchSuccessFunction,
    isLoading,
    error,
    navHeaderVariant,
}: Props) {
    const [isFetching, setIsFetching] = useState(false)
    const [fetchError, setFetchError] = useState<String | null>(error || null)
    const [zError, setZError] = useState<ZErrorInterface | null>(null)

    const userId: string | null = useAppSelector(
        (state) => state.connectedUser.userId
    )

    useEffect(() => {
        document.title =
            documentTitle === "" ? "cmzEDT" : "cmzEDT: " + documentTitle

        setFetchError(null)
        if (fetchFunction) {
            setIsFetching(true)
            fetchFunction().then((response: any) => {
                if (response.zError) {
                    setZError(response.zError)
                } else if (response.error) {
                    setFetchError(response.error)
                } else if (fetchSuccessFunction) {
                    fetchSuccessFunction(response)
                }
                setIsFetching(false)
            })
        }
    }, [])

    useEffect(() => {
        document.title =
            documentTitle === "" ? "cmzEDT" : "cmzEDT: " + documentTitle
    }, [documentTitle])

    return (
        <StyledZPage className={className + " col-12"}>
            {userId ? (
                <HeaderMenu etab={etab} workspace={edt} />
            ) : (
                <HeaderMenuPublic variant={navHeaderVariant} />
            )}

            {isFetching && <Loading />}
            {isLoading && <Loading />}
            {fetchError && <h3>Erreur: {fetchError}</h3>}
            {zError && <ZError zError={zError} />}
            {error && <h3>Erreur: {error}</h3>}
            {!isFetching &&
                !isLoading &&
                !fetchError &&
                !zError &&
                !error &&
                children}
        </StyledZPage>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS

const StyledZPage = styled.div`
    background-color: ${theme.colors.c1l4};
    // background-color: lime;
    height: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0px !important;
    width: 100% !important;
    .btn {
        cursor: pointer;
    }
    .row {
        margin: 0px !important;
    }
    padding-top: 270px;
`
