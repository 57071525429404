/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { useAppSelector } from "utils/store"

import LayoutSection from "../layouts/LayoutSection"
import EdtSonneriesHeader from "./EdtSonneriesHeader"
import EdtSonneriesContent from "./EdtSonneriesContent"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function EdtSonneries({ sonneries, side = "" }) {
    const { orientation } = useAppSelector((state) => state.timetable)
    /*
    if (!sonneries || sonneries.length === 0) {
        return null
    }
    */

    if (!sonneries || sonneries.length === 0) {
        sonneries = [
            { id: 31, heure: "01:00", nom: null },
            { id: 32, heure: "02:00", nom: null },
            { id: 33, heure: "03:00", nom: null },
            { id: 34, heure: "07:00", nom: null },
            { id: 35, heure: "05:00", nom: null },
            { id: 36, heure: "06:00", nom: null },

            { id: 1, heure: "07:00", nom: null },
            { id: 2, heure: "08:00", nom: null },
            { id: 3, heure: "09:00", nom: null },
            { id: 4, heure: "10:00", nom: null },
            { id: 5, heure: "11:00", nom: null },
            { id: 6, heure: "12:00", nom: null },
            { id: 7, heure: "13:00", nom: null },
            { id: 8, heure: "14:00", nom: null },
            { id: 9, heure: "15:00", nom: null },
            { id: 10, heure: "16:00", nom: null },
            { id: 11, heure: "17:00", nom: null },
            { id: 12, heure: "18:00", nom: null },
            { id: 13, heure: "19:00", nom: null },
            { id: 14, heure: "20:00", nom: null },
            { id: 15, heure: "21:00", nom: null },
            { id: 16, heure: "22:00", nom: null },
            { id: 17, heure: "23:00", nom: null },
        ]
    }
    const styles =
        orientation === "HORIZONTAL"
            ? {
                  backgroundColor: "white",
                  minHeight: "40px",
                  height: "40px",
              }
            : {
                  backgroundColor: "white",
                  minWidth: "40px",
                  width: "40px",
                  // border: "4px solid red",
                  position: "sticky",
                  left: side === "LEFT" ? "0" : "inherit",
                  right: side === "RIGHT" ? "0" : "inherit",
                  zIndex: "999",
                  // height: vContentHeight - 10 + "px",
              }
    return (
        <LayoutSection bgColor="yellow" styles={styles}>
            <EdtSonneriesHeader
            // uw={uw}
            />

            <EdtSonneriesContent
                // uw={uw}
                sonneries={sonneries}
            />
        </LayoutSection>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS
