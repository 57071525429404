/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { minutesToStringHeuresMinutes } from "utils/fonctions"

import { IcoDanger } from "styles/icones"
import UWsTableTdHoraire from "./UWsHorairesTdHoraire"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function UWsTableTr({ uw, jours, dureeTotaleMinutes }) {
    return (
        <tr>
            {/*
            <td>{uw.id}</td>
             */}
            <td style={{ backgroundColor: uw.couleur }}>{uw.pseudo}</td>
            {/*
            <td>{uw.fonction}</td>
            <td>{uw.quotite}</td>
            <td>{uw.nbreHeuresHebdo}</td>
            <td>{uw.nbreNuitsHebdo}</td>
            */}
            {jours.map((jr, j) => (
                <td key={"td-" + j}>
                    <UWsTableTdHoraire
                        uw={uw}
                        horaires={uw.horaires.filter((hor) => hor.jour === jr)}
                        // workspace={workspace}
                        jour={jr}
                    />
                </td>
            ))}
            <td className="tdHeures" style={{ backgroundColor: uw.couleur }}>
                {minutesToStringHeuresMinutes(dureeTotaleMinutes, "h", true)}
                {uw.nbreHeuresHebdo && (
                    <>
                        <span className="nbreHeuresHebdoTheo ">
                            /{uw.nbreHeuresHebdo}
                        </span>
                        {dureeTotaleMinutes !== uw.nbreHeuresHebdo * 60 && (
                            <IcoDanger className="text-danger" />
                        )}
                    </>
                )}
            </td>
        </tr>
    )
}
