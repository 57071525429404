// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"

import { useParams } from "react-router-dom"
import { ZPage } from "uiZ/zPage"
import { useAppSelector, useAppDispatch } from "utils/store"
import { fetchEtabShowSuccess, zApiFetchEtab, EtabShow } from "features/etabs"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function PageEtabShow() {
    const etabId = Number(useParams().id) || 0
    if (etabId <= 0) {
        return <h1 className="text-danger">Erreur url</h1>
    }

    const dispatch = useAppDispatch()
    const { etab } = useAppSelector((state) => state.etabShow)

    const fetchSuccessFunction = (result: any) => {
        dispatch(fetchEtabShowSuccess(result.etablissement))
    }

    return (
        <ZPage
            etab={etab}
            documentTitle={etab?.nom || "Etablissement"}
            fetchFunction={() => zApiFetchEtab(etabId)}
            fetchSuccessFunction={(result: any) => fetchSuccessFunction(result)}
        >
            <EtabShow />
        </ZPage>
    )
}
