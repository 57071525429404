/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React, { useState } from "react"
import { useAppSelector } from "utils/store"
// //////////////// import PeriodeForm from "./PeriodeForm"
import PeriodeForm from "./form/PeriodeForm"
import ButtonPeriodeCreate from "./ButtonPeriodeCreate"
// import { useState } from 'react'
// import Button from 'react-bootstrap/Button';
// import FormHoraireEtab from './FormHoraireEtab';
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function Periodes({ edtId }) {
    const periodes = useAppSelector((state) => state.hPS.workspace.periodes)
    const [formPeriode, setFormPeriode] = useState(null)
    const sortedPeriodes = periodes
        .slice()
        .sort((a, b) =>
            a.heureDebut > b.heureDebut
                ? 1
                : b.heureDebut > a.heureDebut
                ? -1
                : 0
        )

    return (
        <div className="card etab-card-periodes">
            {formPeriode && (
                <PeriodeForm
                    periode={formPeriode}
                    closeForm={() => setFormPeriode(null)}
                    edtId={edtId}
                />
            )}

            <div className="card-header">
                <h5 className="card-title">
                    Periodes <small>(facultatif)</small>
                    <ButtonPeriodeCreate setFormPeriode={setFormPeriode} />
                </h5>
                <small className="text-danger">
                    Vous ne pouvez pas éditer les périodes (pour le moment)
                </small>
            </div>
            <div className="card-body">
                <div className="card-text">
                    {sortedPeriodes.map((periode) => {
                        const periodeclasName = "aa" // ///////// hoveredPeriodeId === periode.id ? " hovered " : ""
                        const periodeBgColor = periode.couleur
                            ? periode.couleur
                            : "white"
                        return (
                            <div
                                key={"periode" + periode.id}
                                className={
                                    "periode row col-12" + periodeclasName
                                }
                                title="Modifier/supprimer cette période"
                                // onClick={() => setSelectedPeriode(periode)}
                                style={{ backgroundColor: periodeBgColor }}
                                // onMouseOver={()=>setHoveredPeriodeId(periode.id)}
                                // onMouseLeave={()=>setHoveredPeriodeId(null)}
                            >
                                <div className="nom col-8">{periode.nom}</div>
                                <div className="col-2">
                                    {periode.heureDebut}
                                </div>
                                <div className="col-2">{periode.heureFin}</div>
                            </div>
                        )
                    })}
                </div>
            </div>

            <div className="card-footer">
                <div className="infos">
                    <i>Facultatif:</i> Vous pouvez definir des periodes
                    (interclasse, cours, récré...) pour pouvoir, ensuite, placer
                    les postages plus facilement.
                </div>
            </div>
        </div>
    )
}
