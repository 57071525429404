// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import { createSlice } from "@reduxjs/toolkit"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export interface Horaire {
    id: number | null
    jour: string //             ex : "Mardi"
    semaine: string | null //   "A" | "B"
    heureDebut: string //       ex : "14:55"
    heureFin: string //         ex : "16:10"
    infos: string | null
    workspaceId: number | null // pour create horaire
}
interface Periode {
    id: number | null
    nom: string
    heureDebut: string //       ex : "14:55"
    heureFin: string //         ex : "16:10"
    infos: string | null
    couleur: string
}
interface Sonnerie {
    id: number | null
    nom: string
    heure: string //       ex : "14:55"
}

interface Workspace {
    id: number
    nom: string
    actif: boolean
    etablissement: { id: number; nom: string }
    horaireEdts: Array<Horaire>
    periodes: Array<Periode>
    sonneries: Array<Sonnerie> | null // TODO
}

interface InitialState {
    workspace: Workspace | null
    horaireForm: Horaire | null
    periodeForm: Periode | null
    sonnerieForm: Sonnerie | null
}

// ■   ■   ■   ■   ■   ■   ■   ■   ■   ■   ■   ■   ■   ■   ■   ■   ■   ■   ■

const initialState: InitialState = {
    workspace: null,
    horaireForm: null,
    periodeForm: null,
    sonnerieForm: null,
}

// ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■

const slice = createSlice({
    name: "uw",
    initialState,
    reducers: {
        // *==============================  Fetch

        fetchHPSSuccess: (state, action) => {
            state.workspace = { ...action.payload }
        },
        // *==============================  Horaire
        setHoraireForm: (state, action) => {
            state.horaireForm = action.payload
        },

        horaireCreateSuccess: (state, action) => {
            state.horaireForm = null
            if (state.workspace) {
                state.workspace.horaireEdts = [
                    ...state.workspace.horaireEdts,
                    action.payload,
                ]
            }
        },
        horaireUpdateSuccess: (state, action) => {
            state.horaireForm = null
            if (state.workspace) {
                const newHoraires = state.workspace.horaireEdts.map((hor) =>
                    hor.id === action.payload.id ? action.payload : hor
                )
                state.workspace.horaireEdts = newHoraires
            }
        },
        horaireDeleteSuccess: (state, action) => {
            state.horaireForm = null

            if (state.workspace) {
                const newHoraires = state.workspace.horaireEdts.filter(
                    (hor) => hor.id !== action.payload
                )
                state.workspace.horaireEdts = newHoraires
            }
        },

        // *==============================  Sonnerie

        sonnerieCreateSuccess: (state, action) => {
            if (state.workspace && state.workspace.sonneries) {
                state.workspace.sonneries = [
                    ...state.workspace.sonneries,
                    action.payload,
                ]
            }
        },
        sonnerieUpdateSuccess: (state, action) => {
            if (state.workspace && state.workspace.sonneries) {
                const newSonneries = state.workspace.sonneries?.map((sonn) =>
                    sonn.id === action.payload.id ? action.payload : sonn
                )
                state.workspace.sonneries = newSonneries
            }
        },
        sonnerieDeleteSuccess: (state, action) => {
            if (state.workspace && state.workspace.sonneries) {
                state.workspace.sonneries = state.workspace.sonneries.filter(
                    (sonn) => sonn.id !== action.payload
                )
            }
        },

        // TODO
    },
})

export default slice.reducer

// ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■

const {
    fetchHPSSuccess,
    setHoraireForm,
    horaireCreateSuccess,
    horaireUpdateSuccess,
    horaireDeleteSuccess,
    sonnerieUpdateSuccess,
    sonnerieCreateSuccess,
    sonnerieDeleteSuccess,
} = slice.actions

export {
    // ******* export OK pour new version : ******
    fetchHPSSuccess,
    sonnerieCreateSuccess,
    sonnerieUpdateSuccess,
    sonnerieDeleteSuccess,
    horaireCreateSuccess,
    horaireUpdateSuccess,
    horaireDeleteSuccess,
    // ******* OLD / A MODIFIER : ******
    setHoraireForm,
}
