/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import {
    IcoTrue,
    IcoFalse,
    IcoDelete,
    IcoSearch,
    IcoTriangleRight,
} from "styles/icones"
import styled from "styled-components"
import { status } from "./data/aboutCardProgressData"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function AboutCardProgressItemAction({ action }) {
    return (
        <StyledActionLi
            status={action.status}
            className="mb-1 border-bottom border-primary "
        >
            <div className="actionContent">
                {action.status === status.done && <IcoTrue className="me-2" />}
                {action.status === status.pending && (
                    <IcoFalse className="me-2" />
                )}
                {action.status === status.canceled && (
                    <IcoDelete className="me-2" />
                )}{" "}
                {action.status === status.checking && (
                    <IcoSearch className="me-2" />
                )}{" "}
                {action.status === status.processing && (
                    <IcoTriangleRight className="me-2" />
                )}
                {action.title}
                {action.content && (
                    <div className="actionContent">( {action.content} )</div>
                )}
            </div>

            {action.name && (
                <div className="actionName">--- [ {action.name} ]</div>
            )}
            {action.endpoint && (
                <div className="actionEndpoint">+++ [ {action.endpoint} ]</div>
            )}
        </StyledActionLi>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS

const StyledActionLi = styled.li`
    // background-color: orange;
    text-decoration: none;
    ${(props) => props.status === status.done && `color:green;`}
    ${(props) => props.status === status.pending && `color:red;`}
    ${(props) => props.status === status.processing && `color:blue;`}
    ${(props) =>
        props.status === status.canceled && `text-decoration: line-through;`}



    padding-left: 0px;
    margin-left: 0px;

    .actionName {
        font-size: 0.8em;
        padding-left: 4em;
    }
    .actionContent {
        font-size: 0.8em;
        padding-left: 4em;
    }
    .actionEndpoint {
        font-size: 0.8em;
        padding-left: 4em;
    }
`
