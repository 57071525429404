/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React, { useState } from "react"
import styled from "styled-components"
import { useAppSelector } from "utils/store"
import { debugTimetable } from "utils/constants"
// import { dev } from "utils/constants"
import ButtonShowOptions from "features/timetable/options/ButtonShowOptions"
import ButtonShowHelp from "features/timetable/options/ButtonShowHelp"
import FormPostage1stSemaines from "features/postages/formPostage1st/FormPostage1stSemaines"
import ButtonLocked from "./options/ButtonLocked"
import ButtonPrintOpenModal from "./options/ButtonPrintOpenModal"
import FilterJours from "./options/FilterJours"
import FilterVHeight from "./options/FilterVHeight"
import DateHeure from "./options/DateHeure"
// !! in TimetableModalOptions //         import ZxModal from "ui/ZxModal"
// !! in TimetableModalOptions //         import FilterShowHoraires from "./options/FilterShowHoraires"
// !! in TimetableModalOptions //         import FilterShowPostages from "./options/FilterShowPostages"
// !! in TimetableModalOptions //         import FilterFormPostage1st from "./options/FilterFormPostage1st"
import FilterOrientation from "./options/FilterOrientation"
import FilterShowAllUsers from "./options/FilterShowAllUsers"
import FilterModeJournee from "./options/FilterModeJournee"
// import FilterGlobalContainerHeight from "./options/FilterGlobalContainerHeight"
import TimetableModalOptions from "./TimetableModalOptions"
import TimetableModalHelp from "./TimetableModalHelp"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
/*
 *  TimetableHeader
 *  ( JourSelect, date, zoom, Lock, (Bouton)Options )
 *
 *
 *
 */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
export default function TimetableHeader({ setModalPrintIsOpen }) {
    const {
        edt,
        jour,
        showOptions,
        modeHoraires,
        locked,
        modeDemo,
        orientation,
    } = useAppSelector((state) => state.timetable)
    const [showHelp, setShowHelp] = useState(false)

    return (
        <StyledTimetableHeader className="border border-primary p-1">
            {showOptions && !modeHoraires && <TimetableModalOptions />}
            {showHelp && (
                <TimetableModalHelp
                    modeHoraires={modeHoraires}
                    handleClose={() => setShowHelp(false)}
                />
            )}
            <div className="print-only col-12">
                <h2 className="float-start">{jour}</h2>
                <h3 className="float-end">{edt.nom}</h3>
            </div>
            <div className="d-flex flex-wrap col-12 no-print">
                {modeDemo && <p>Ceci est une démo&nbsp;&nbsp;</p>}
                <FilterJours />
                {!locked && modeHoraires && <FormPostage1stSemaines />}
                <DateHeure />
                {orientation !== "HORIZONTAL" && <FilterVHeight />}
                {/* 
                <FilterGlobalContainerHeight />
                */}
                {modeHoraires && (
                    <>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <FilterOrientation />
                        <div className="d-flex ms-4 mb-2">
                            <FilterModeJournee />
                        </div>
                        <FilterShowAllUsers />
                    </>
                )}
                <div className="ms-auto">
                    <ButtonPrintOpenModal
                        setModalPrintIsOpen={setModalPrintIsOpen}
                    />
                    <ButtonShowHelp
                        showHelp={showHelp}
                        setShowHelp={setShowHelp}
                    />
                    {!modeHoraires && !modeDemo && !showOptions && (
                        <ButtonShowOptions />
                    )}
                    {!modeDemo && <ButtonLocked />}
                </div>
            </div>
        </StyledTimetableHeader>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS

const StyledTimetableHeader = styled.div`
    // display: flex;
    // flex-flow: row;
    background-color: white;
    margin: 0px;
    // padding: 5px; // debug only
    // flex: 0 1 auto;

    .print-only {
        display: none;
        @media print {
            display: inherit;
        }
    }

    ${debugTimetable && "padding: 10px; background-color:yellow;"}
`
