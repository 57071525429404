// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import styled from "styled-components"
import {
    ZPage,
    ZPageHeader,
    ZPageTitle,
    ZPageContent,
    ZPageSection,
} from "uiZ/zPage"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function PageAbout() {
    return (
        <ZPage documentTitle="A propos">
            <ZPageHeader>
                <ZPageTitle>
                    A propos de&nbsp;<b> cmzEDT.zedixi.com</b>
                </ZPageTitle>
            </ZPageHeader>

            <ZPageContent>
                <StylePageSection>
                    <div>
                        cmzEDT est un outil en ligne <b>gratuit</b> permettant
                        de créer et de consulter facilement <b>les postages</b>{" "}
                        de la vie scolaire.
                    </div>
                    <div>
                        <h2>cmzEDT.zedixi.com</h2>
                        J&apos;ai développé un site web que nous utilisons
                        depuis plusieurs années au collège où je suis AED/TICE.
                        <br />
                        Pour la rentrée 2022/2023, j&apos;ai décidé de rendre ce
                        site disponible pour tous les établissements scolaires.
                        <br />
                        cmzEDT reprend, entre autres, les fonctionnalités que
                        vous pouvez trouver dans EDT-CPE, mais avec une
                        interface qui peut être plus pratique et plus intuitive.
                    </div>
                    <div>
                        <h2>Les fonctionnalités de cmzEDT</h2>
                        <ul>
                            <li>
                                Postages consultables rapidement depuis
                                n&apos;importe où.{" "}
                                <small>
                                    (Placez un favoris sur votre PC et raccourci
                                    sur votre téléphone).
                                </small>
                            </li>
                            <li>
                                Possibilité de créer <b>plusieurs EDT</b> par
                                établissement.{" "}
                                <small>
                                    (Année suivante, semaine de pré-rentrée,
                                    modification en cours d&apos;année…)
                                </small>
                            </li>
                            <li>
                                Si vous faîtes des modifications sur un EDT, les
                                AED les verront automatiquement.{" "}
                                <small>
                                    (Plus besoin de leur donner le nouveau
                                    fichier excel ou de le réimprimer)
                                </small>
                            </li>
                            <li>
                                Personnalisation des postes, horaires, sonneries
                                et périodes pour chaque EDT.
                            </li>
                            <li>
                                Gestion du temps de travail des AED.{" "}
                                <small>
                                    (Nombre d&apos;heures, quotité, nombre de
                                    nuits, horaires...)
                                </small>
                            </li>
                            <li>Prise en charge des semaines A et B.</li>
                            <li>
                                Une CPE ou un AED peut être sur plusieurs
                                établissements.
                            </li>
                            <li>
                                Prise en charge des établissements avec{" "}
                                <b>internat</b>.
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h2>Essayez !</h2>
                        Vous pouvez d&apos;ores et déjà vous inscrire pour
                        tester l&apos;application. Vous pouvez aussi consulter
                        un exemple d&apos;EDT pour découvrir ce que verront vos
                        AED.
                    </div>
                    <div>
                        <h2>Pourquoi c’est gratuit ?</h2>
                        Arrivant à la fin de mon contrat d&apos;AED/TICE, je
                        souhaite me reconvertir dans le développement
                        d&apos;applications Web.
                        <br />
                        Avoir développé une application utilisée par de nombreux
                        établissements scolaires, témoignerait de mes
                        compétences auprès d&apos;un hypothétique futur
                        employeur.
                    </div>
                    cmzedt@zedixi.com
                    {/* 
                    <div>
                        <h2>cmzEdt</h2>
                        Vous n&apos;avez pas le temps de tout lire ? Cliquez sur ce
                        lien pour avoir une démonstration de ce que vos AED
                        pourront voir si vous décidez d&apos;utiliser cmzEDT.
                        (Essayez aussi depuis un téléphone)
                    </div>
                    */}
                </StylePageSection>
            </ZPageContent>
        </ZPage>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS

const StylePageSection = styled(ZPageSection)`
    div {
        margin-bottom: 30px;
        padding: 10px;
    }
    h2 {
        font-size: 1.1em;
    }
`
