/* eslint-disable no-unsafe-finally */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import { API_BASE_URL } from "utils/constants"
// TODO import { postage } from "store/PostageFormReducer" !!!!!!!!!!!!!
import { requestOptionsHeaders } from "../../../utils/api"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export async function apiFetchPostages(workspaceId: number) {
    let edt = null
    let postes = []
    let error = null
    const url = API_BASE_URL + "/postages/edt/" + workspaceId
    console.log("url:", url)

    const requestOptions = {
        method: "POST",
        headers: requestOptionsHeaders(),
        // body: JSON.stringify({ })
    }

    try {
        const response = await fetch(url, requestOptions)
        const rep = await response.json()
        if (rep.apixError) {
            error = rep.apixError
        } else if (rep.edt && rep.postes) {
            console.log(rep)
            edt = rep.edt
            postes = rep.postes
        } else if (rep.error) {
            console.log(rep)
            error = rep.error
        } else {
            console.log(rep)
            error = "Erreur de connexion inconnue"
        }
    } catch (err) {
        error = "Catch: " + err
    } finally {
        return {
            edt,
            postes,
            error,
        }
    }
}

export async function apiFetchPostagesExemple(workspaceId: number) {
    let edt = null
    let postes = []
    let error = null
    const url = API_BASE_URL + "/exemple/edt/" + workspaceId
    console.log("url:", url)

    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
        // body: JSON.stringify({ })
    }

    try {
        const response = await fetch(url, requestOptions)
        const rep = await response.json()
        if (rep.apixError) {
            error = rep.apixError
        } else if (rep.edt && rep.postes) {
            console.log(rep)
            edt = rep.edt
            postes = rep.postes
        } else if (rep.error) {
            console.log(rep)
            error = rep.error
        } else {
            console.log(rep)
            error = "Erreur de connexion inconnue"
        }
    } catch (err) {
        error = "Catch: " + err
    } finally {
        return {
            edt,
            postes,
            error,
        }
    }
}

export async function apiPostageUpdate(postageForm: any) {
    let postage = null
    let error = null
    const url = API_BASE_URL + "/postages/" + postageForm.id + "/edit"
    console.log("url:", url)
    // console.log("postageForm", postageForm)
    // console.log("■■■■ apiPostageUpdate - .posteId", postageForm.posteId)

    const requestOptions = {
        method: "POST",
        headers: requestOptionsHeaders(),
        body: JSON.stringify({
            jour: postageForm.jour,
            semaine: postageForm.semaine,
            heureDebut: postageForm.heureDebut,
            heureFin: postageForm.heureFin,
            userWorkspaceId: postageForm.userWorkspaceId,
            posteId: postageForm.posteId,
            commentaire: postageForm.commentaire,
        }),
    }

    try {
        const response = await fetch(url, requestOptions)
        const rep = await response.json()

        console.log("rep", rep)

        if (rep.apixError) {
            error = rep.apixError
        } else if (rep.postage) {
            console.log(
                "■■■■ apiPostageUpdate rep.postage.poste.id",
                rep.postage.poste.id
            )
            postage = rep.postage
        } else {
            // console.log(rep)
            error = "Erreur inconnue"
        }
    } catch (err) {
        error = "Catch: " + err
    } finally {
        // eslint-disable-next-line no-unsafe-finally
        return {
            postage,
            error,
        }
    }
}

export async function apiPostageCreate(postageForm: any) {
    let postage = null
    let error = null
    const url = API_BASE_URL + "/postages/new"
    console.log("url:", url)
    console.log("###### postage à creer::", postageForm)

    const requestOptions = {
        method: "POST",
        headers: requestOptionsHeaders(),
        body: JSON.stringify({
            jour: postageForm.jour,
            semaine: postageForm.semaine,
            heureDebut: postageForm.heureDebut,
            heureFin: postageForm.heureFin,
            membreId: postageForm.membreId,
            posteId: postageForm.posteId,
            commentaire: postageForm.commentaire,
        }),
    }

    try {
        const response = await fetch(url, requestOptions)
        const rep = await response.json()
        if (rep.apixError) {
            error = rep.apixError
        } else if (rep.postage) {
            console.log("********************rep.postage::", rep.postage)
            postage = rep.postage
        } else {
            console.log("erreur - rep : ", rep)
            error = "Erreur inconnue"
        }
    } catch (err) {
        error = "Catch: " + err
    } finally {
        // eslint-disable-next-line no-unsafe-finally
        return {
            postage,
            error,
        }
    }
}

// ■■ // ■■ // ■■ // ■■ // ■■ // ■■ // A MODIFIER POUR REDUX TOOLKIT

export async function apiPostageDelete(postageId: number) {
    let reponse = null
    let error = null
    const url = API_BASE_URL + "/postages/" + postageId + "/delete"
    console.log("url:", url)
    console.log("postageId", postageId)

    const requestOptions = {
        method: "DELETE",
        headers: requestOptionsHeaders(),
        // body: JSON.stringify({ })
    }

    try {
        const fetchResponse = await fetch(url, requestOptions)
        const rep = await fetchResponse.json()
        if (rep.apixError) {
            error = rep.apixError
        } else if (rep.message) {
            reponse = rep.message
        } else {
            console.log(rep)
            error = "Erreur inconnue"
        }
    } catch (err) {
        error = "Catch: " + err
    } finally {
        // eslint-disable-next-line no-unsafe-finally
        return {
            reponse,
            error,
        }
    }
}
