// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { ZField } from "uiZ/zForm"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function PeriodeFormInner() {
    return (
        <div className="row col-12">
            <ZField
                autoFocus
                name="heureDebut"
                label="Début"
                type="time"
                fieldClassName="col-6"
            />
            <ZField
                name="heureFin"
                label="Fin"
                type="time"
                hint='Pour les internat, vous pouvez mettre "00:00"'
                fieldClassName="col-6"
            />
            <ZField
                name="nom"
                label="Nom (facultatif)"
                type="text"
                hint="ex: Interclasse, Récré, A1"
            />
            <ZField name="infos" label="infos (facultatif)" type="text" />
            <ZField name="couleur" label="couleur" type="color" />
        </div>
    )
}
