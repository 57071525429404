// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React, { useState } from "react"
import { useParams } from "react-router-dom"
import { useAppSelector } from "utils/store"

import { ZPage, ZPageHeader, ZPageTitle, ZPageContent } from "uiZ/zPage"
import {
    EdtShowSectionTodo,
    ButtonEdtUpdate,
    zApiFetchEdt,
    EdtShow,
} from "features/edts"
import EdtForm from "features/edts/form/EdtForm"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

interface Edt {
    id: number
    nom: String
    etablissement: { id: number }
}
export default function PageEdtShow() {
    const edtId = Number(useParams().id) || 0
    if (edtId <= 0) {
        return <h1 className="text-danger">Erreur url</h1>
    }

    const [edt, setEdt] = useState<Edt | null>(null)
    const { edtForm } = useAppSelector((state) => state.edtForm)

    const fetchSuccessFunction = (result: any) => {
        console.log("result.edt-----", result.edt)
        setEdt(result.edt)
    }

    return (
        <ZPage
            documentTitle="EDT"
            edt={edt}
            fetchFunction={() => zApiFetchEdt(edtId)}
            fetchSuccessFunction={(result: any) => fetchSuccessFunction(result)}
        >
            {edtForm && <EdtForm setEdt={setEdt} />}
            {edt && (
                <>
                    <ZPageHeader className="row col-12">
                        <ZPageTitle className="col-12">
                            <small>EDT: </small>
                            {edt.nom}

                            <ButtonEdtUpdate className="ms-auto" edt={edt} />
                        </ZPageTitle>
                    </ZPageHeader>

                    <ZPageContent className="row">
                        {edt && <EdtShow edt={edt} />}
                        <EdtShowSectionTodo />
                    </ZPageContent>
                </>
            )}
        </ZPage>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS
