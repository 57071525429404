// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { useAppSelector, useAppDispatch } from "utils/store"
import ZxModal from "ui/ZxModal"
import { ZForm } from "uiZ/zForm"
import {
    updateMembreSuccess,
    deleteMembreSuccess,
} from "features/timetable/utils/timetableReducer"
import {
    IMembreForm,
    setFormMembre,
} from "features/membres/utils/membresReducer"
import {
    zApiMembreUpdate,
    zApiMembreDelete,
} from "features/membres/utils/apiMembres"
import MembreFormInner from "./MembreFormInner"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function MembreForm() {
    const dispatch = useAppDispatch()
    const { formMembre } = useAppSelector((state) => state.membres)

    const customValidate = (values: IMembreForm) => {
        let errors = {}

        if (values.pseudo.length < 2) {
            errors = {
                ...errors,
                pseudo: "Le pseudo doit contenir au moins 2 caractères !",
            }
        } else if (values.pseudo.length >= 20) {
            errors = {
                ...errors,
                pseudo: "Le pseudo doit contenir moins de 20 caractères !",
            }
        }

        return errors
    }

    if (!formMembre) {
        return <h1>!formMembre</h1>
    }

    /*
    // On ne crée pas de membre avec ce formulaire, mais en ajouter du personnel
    const createSuccessFunction = (edtId: number) => {  
        dispatch(setFormMembre(null))
    }
    */

    const updateSuccessFunction = (result: any) => {
        dispatch(updateMembreSuccess(result))
    }
    const deleteSuccessFunction = () => {
        dispatch(deleteMembreSuccess(formMembre.id))
        dispatch(setFormMembre(null))
    }

    console.log("formMembre", formMembre)

    return (
        <ZxModal styles={{ maxWidth: "460px" }}>
            <ZForm
                initialData={formMembre}
                customValidate={customValidate}
                closeForm={() => dispatch(setFormMembre(null))}
                // *** Create:
                createTitle="Ajouter un membre UNNNNNNNNNUUUUUUUSED"
                //  createFunction={(args: any) => zApiMembreCreate(args)}
                //  createSuccessFunction={(result: any) => createSuccessFunction(result.edt.id) }
                // *** Update:
                updateTitle={`Modifier un personnel de l'EDT: ${formMembre?.pseudo}`}
                updateFunction={zApiMembreUpdate}
                updateSuccessFunction={(result: any) =>
                    updateSuccessFunction(result)
                }
                // *** Delete:
                deleteTitle={`Supprimer un personnel de l'EDT: ${formMembre?.pseudo}`}
                deleteConfirmText={`Je souhaite supprimer ce personnel de l'EDT, ainsi que son
                ses horaires et postages...`}
                deleteFunction={() => zApiMembreDelete(formMembre.id || 0)}
                deleteSuccessFunction={(_result: any) =>
                    deleteSuccessFunction()
                }
            >
                <MembreFormInner />
            </ZForm>
        </ZxModal>
    )
}
