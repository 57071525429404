// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React, { useState } from "react"
import { zApiFetchMesEdts } from "features/edts/utils/apiEdt"
import { useNavigate, NavLink } from "react-router-dom"

import {
    ZPage,
    ZPageHeader,
    ZPageTitle,
    ZPageContent,
    ZPageSection,
} from "uiZ/zPage"
import EdtCardFull from "features/edts/EdtCardFull"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

interface Edt {
    id: number
    actif: boolean
}
/**
 * * Page d'accueil
 * * fetch: charge la liste des EDT de l'utilisateur
 *
 * * S'il n'y a qu'un seul EDT actif, on redirige vers les postages de cette EDT
 * * S'il n'y aucun EDT (actif ou inactif), on redirige vers mes etabs
 * * Sinon, on affiche la liste des EDT
 *
 * TODO : mettre une options (pour etre rediriger ou pour voir la liste)
 *
 */

export default function PageHomeLandingPage() {
    const navigate = useNavigate()
    const [edts, setEdts] = useState<null | Edt[]>(null)

    const [showInactifs, setShowInactifs] = useState(false)

    const fetchSuccess = (result: any) => {
        setEdts(result.edt)

        if (result.edts) {
            if (result.edts.filter((e: Edt) => e.actif).length === 1) {
                navigate(
                    "/edt/" +
                        result.edts.filter((e: Edt) => e.actif)[0].id +
                        "/postages"
                )
            } else if (result.edts.length === 0) {
                navigate("/mes-etabs")
            }

            setEdts(result.edts)
        }
    }

    return (
        <ZPage
            documentTitle="Mes EDT"
            fetchFunction={() => zApiFetchMesEdts()}
            fetchSuccessFunction={(result: any) => fetchSuccess(result)}
        >
            <ZPageHeader>
                <ZPageTitle>Accueil (mes EDT)</ZPageTitle>
            </ZPageHeader>

            <ZPageContent>
                <ZPageSection className="col-12">
                    {edts && edts.length === 0 && (
                        <div className="col-12 ">
                            Vous ne figurez sur aucun EDT.
                            <br />
                            <br />
                            Cliquez sur{" "}
                            <NavLink to="/mes-etabs">
                                Mes établissements
                            </NavLink>{" "}
                            pour créer un établissement ou demandez à votre CPE
                            d&apos;ajouter votre adresse email et de vous
                            inviter.
                        </div>
                    )}
                    <div className="col-12 row">
                        {edts && edts.length > 1 && (
                            <div className="mb-5">
                                Séléctionnez l&apos;EDT pour lequel vous voulez
                                consulter les postages:
                            </div>
                        )}

                        {edts?.map((edt) => {
                            if (!edt.actif && !showInactifs) {
                                return null
                            }
                            return (
                                <EdtCardFull
                                    key={"edt-" + edt.id}
                                    edt={edt}
                                    className="row col-sm-12 col-md-4 col-lg-3 col-xl-2"
                                />
                            )
                        })}

                        {edts && edts.filter((e: Edt) => !e.actif).length > 0 && (
                            <div
                                className="mt-4 clickText"
                                onClick={() => setShowInactifs(!showInactifs)}
                                role="presentation"
                            >
                                {showInactifs ? "Masquer " : "Afficher "}les{" "}
                                {edts.filter((e: Edt) => !e.actif).length} EDT
                                inactif(s).
                            </div>
                        )}

                        <div className="mt-5">
                            <u>Note</u>: Si vous ne figurez que sur un seul EDT
                            actif, en arrivant sur cette page d&apos;accueil,
                            vous êtes automatiquement redirigé vers les postages
                            de cet EDT.
                        </div>
                    </div>
                </ZPageSection>
            </ZPageContent>
        </ZPage>
    )
}
