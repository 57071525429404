/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import styled from "styled-components"
import { useAppDispatch } from "utils/store"
import { openFormEtabCreate } from "features/etabs/utils/etabFormReducer"
import { IcoAdd } from "styles/icones"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
// **** ATTENTION :
// **** StyledContainer reutilisé dans EtabItemCreate / EtabItem
// **** DOUBLONS avec  ButtonEtabCreate
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function MesEtabsListeItemCreate() {
    const dispatch = useAppDispatch()
    return (
        <StyledContainer className="row col-sm-12 col-md-4 col-lg-3 col-xl-2">
            <div
                className="etabItem   bg-primary "
                onClick={() => dispatch(openFormEtabCreate())}
                title="Ajouter un nouvel établissement"
            >
                <div className="d-block text-light">
                    <IcoAdd /> Ajouter un établissement
                </div>
            </div>
        </StyledContainer>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS

const StyledContainer = styled.div`
    padding: 5px;

    .etabItem {
        border: 1px solid #0b5b95;
        min-height: 60px;
        display: flex;
        text-align: center;
        padding: 5px;
        cursor: pointer;
        * {
            text-decoration: none;
            align-self: center;
            width: 100%;
        }
        &:hover {
            * {
                color: #0b5b95 !important;
            }

            background-color: white !important;
        }

        .card-header {
            border-bottom: 1px solid #0b5b95;
        }
    }
`
