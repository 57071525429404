/* eslint-disable no-unsafe-finally */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import { API_BASE_URL } from "utils/constants"
import { requestOptionsHeaders } from "utils/api"
import { Horaire } from "./hPSReducer"
import { errorManager } from "utils/api/functionsApi"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

type Sonnerie = { id: number | null; heure: string; nom?: string }

//* ███████████████████████████████████████████████████████████████████████  zAPI - New version with errorManager OK

export async function zApiFetchEdtHoraires(edtId: number) {
    // * Renvoi :  reponse.edt  ou zError
    const url = API_BASE_URL + "/hps/" + edtId
    console.group("🟨  zApiFetchWorkspaceHoraires")
    console.log("➤➤ url:", url)
    const requestOptions = {
        method: "GET",
        headers: requestOptionsHeaders(),
        // body: JSON.stringify({ })
    }

    try {
        const response = await fetch(url, requestOptions)
        const rep = await response.json()
        if (rep.edt) {
            console.log("✔️ SUCCESS: edt: ", rep.edt)
            console.groupEnd()
            return { edt: rep.edt }
        }
        console.groupEnd()
        return { zError: errorManager(rep) }
    } catch (err) {
        console.groupEnd()
        return { zError: errorManager(err) }
    }
}

//* █████████ Sonneries

export async function zApiSonnerieCreate(
    edtId: number,
    sonnerieForm: Sonnerie
) {
    // * Renvoi :  sonnerie  ou zError

    const url = API_BASE_URL + "/sonneries/new/edt/" + edtId
    console.group("🟨  zApiSonnerieCreate")
    console.log("➤➤ url:", url)

    const requestOptions = {
        method: "POST",
        headers: requestOptionsHeaders(),
        body: JSON.stringify(sonnerieForm),
    }

    try {
        const response = await fetch(url, requestOptions)
        const rep = await response.json()
        if (rep.sonnerie) {
            console.log("✔️ SUCCESS: sonnerie: ", rep.sonnerie)
            console.groupEnd()
            return rep.sonnerie
        }
        console.groupEnd()
        return { zError: errorManager(rep) }
    } catch (err) {
        console.groupEnd()
        return { zError: errorManager(err) }
    }
}

export async function zApiSonnerieUpdate(sonnerieForm: Sonnerie) {
    // * Renvoi :  sonnerie  ou zError

    const url = API_BASE_URL + "/sonneries/" + sonnerieForm.id + "/edit"
    console.group("🟨  zApiSonnerieUpdate")
    console.log("➤➤ url:", url)

    const requestOptions = {
        method: "POST",
        headers: requestOptionsHeaders(),
        body: JSON.stringify(sonnerieForm),
    }

    try {
        const response = await fetch(url, requestOptions)
        const rep = await response.json()
        if (rep.sonnerie) {
            console.log("✔️ SUCCESS: sonnerie: ", rep.sonnerie)
            console.groupEnd()
            return rep.sonnerie
        }
        console.groupEnd()
        return { zError: errorManager(rep) }
    } catch (err) {
        console.groupEnd()
        return { zError: errorManager(err) }
    }
}

export async function zApiSonnerieDelete(sonnerieId: number) {
    // * Renvoi :  "SUCCESS"  ou zError

    const url = API_BASE_URL + "/sonneries/" + sonnerieId + "/delete"
    console.group("🟨  zApiSonnerieDelete")
    console.log("➤➤ url:", url)

    const requestOptions = {
        method: "DELETE",
        headers: requestOptionsHeaders(),
    }

    try {
        const response = await fetch(url, requestOptions)
        const rep = await response.json()
        if (rep.message && rep.message === "SUCCESS") {
            console.log("✔️ SUCCESS: delete sonnerie")
            console.groupEnd()
            return "SUCCESS"
        }
        console.groupEnd()
        return { zError: errorManager(rep) }
    } catch (err) {
        console.groupEnd()
        return { zError: errorManager(err) }
    }
}

//* █████████ Horaires

export async function zApiHoraireCreate(edtId: number, horaire: Horaire) {
    // * Renvoi :  horaire  ou zError

    const url = API_BASE_URL + "/hps/horaires/new/edt/" + edtId
    console.log("url:", url)
    console.group("🟨  zApiHoraireCreate")
    console.log("➤➤ url:", url)

    const requestOptions = {
        method: "POST",
        headers: requestOptionsHeaders(),
        body: JSON.stringify(horaire),
    }

    try {
        const response = await fetch(url, requestOptions)
        const rep = await response.json()
        if (rep.horaire) {
            console.log("✔️ SUCCESS: horaire: ", rep.horaire)
            console.groupEnd()
            return rep.horaire
        }
        console.groupEnd()
        return { zError: errorManager(rep) }
    } catch (err) {
        console.groupEnd()
        return { zError: errorManager(err) }
    }
}

export async function zApiHoraireUpdate(horaire: Horaire) {
    // * Renvoi :  sonnerie  ou zError

    const url = API_BASE_URL + "/hps/horaires/" + horaire.id + "/update"
    console.group("🟨  zApiHoraireUpdate")
    console.log("➤➤ url:", url)

    const requestOptions = {
        method: "POST",
        headers: requestOptionsHeaders(),
        body: JSON.stringify(horaire),
    }

    try {
        const response = await fetch(url, requestOptions)
        const rep = await response.json()
        if (rep.horaire) {
            console.log("✔️ SUCCESS: horaire: ", rep.horaire)
            console.groupEnd()
            return rep.horaire
        }
        console.groupEnd()
        return { zError: errorManager(rep) }
    } catch (err) {
        console.groupEnd()
        return { zError: errorManager(err) }
    }
}

export async function zApiHoraireDelete(horaireId: number) {
    // * Renvoi :  "SUCCESS"  ou zError

    const url = API_BASE_URL + "/hps/horaires/" + horaireId + "/delete"
    console.group("🟨  zApiHoraireDelete")
    console.log("➤➤ url:", url)

    const requestOptions = {
        method: "DELETE",
        headers: requestOptionsHeaders(),
    }

    try {
        const response = await fetch(url, requestOptions)
        const rep = await response.json()
        if (rep.message && rep.message === "SUCCESS") {
            console.log("✔️ SUCCESS: horaireId sonnerie")
            console.groupEnd()
            return "SUCCESS"
        }
        console.groupEnd()
        return { zError: errorManager(rep) }
    } catch (err) {
        console.groupEnd()
        return { zError: errorManager(err) }
    }
}

//* ███████████████████████████████████████████████████████████████████████
