/* eslint-disable react/no-array-index-key */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
// import Button from "react-bootstrap/Button"
// import styled from "styled-components"
import { ZPageSection } from "uiZ/zPage"
import AboutCardPersonnas from "./AboutCardPersonas"
import AboutCardDone from "./AboutCardDone"
import AboutCardDevelopment from "./AboutCardDevelopment"
import AboutCardBacklog from "./AboutCardBacklog"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function Scrum() {
    return (
        <ZPageSection className="row col-12">
            <div className="col-md-12 col-12 mb-4">
                <AboutCardPersonnas />
            </div>
            <div className="col-md-6 col-12 mb-4">
                <AboutCardDone />
            </div>
            <div className="col-md-6 col-12 mb-4">
                <AboutCardDevelopment />
            </div>
            <div className="col-md-12 col-12 mb-4">
                <AboutCardBacklog />
            </div>
        </ZPageSection>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS
