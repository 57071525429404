/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React, { useState } from "react"
import { useAppSelector } from "utils/store"
// importfrom 'react'
// import Button from 'react-bootstrap/Button';
// import FormHoraireEtab from './FormHoraireEtab';
import SonnerieItem from "./SonnerieItem"
import SonnerieForm from "./SonnerieForm"
import ButtonSonnerieCreate from "./ButtonSonnerieCreate"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function Sonneries({ edtId }) {
    const sonneries = useAppSelector((state) => state.hPS.workspace.sonneries)
    const [formSonnerie, setFormSonnerie] = useState(null)

    const sortedSonneries = sonneries
        .slice()
        .sort((a, b) => (a.heure > b.heure ? 1 : b.heure > a.heure ? -1 : 0))

    return (
        <>
            {formSonnerie && (
                <SonnerieForm
                    sonnerie={formSonnerie}
                    closeForm={() => setFormSonnerie(null)}
                    edtId={edtId}
                />
            )}

            <div className="card etab-card-periodes">
                <div className="card-header">
                    <h5 className="card-title">
                        Sonneries <small>(facultatif)</small>
                        <ButtonSonnerieCreate
                            setFormSonnerie={setFormSonnerie}
                        />
                    </h5>
                </div>
                <div className="card-body">
                    <div className="card-text">
                        {sortedSonneries.map((sonnerie) => (
                            <SonnerieItem
                                key={"sonnerie-" + sonnerie.id}
                                sonnerie={sonnerie}
                                setFormSonnerie={setFormSonnerie}
                            />
                        ))}
                    </div>
                </div>

                <div className="card-footer">
                    <div className="infos">
                        Les sonneries sont affichées dans l&apos;EDT et peuvent
                        vous aider à placer les postages plus facilement.
                    </div>
                </div>
            </div>
        </>
    )
}
