/* eslint-disable jsx-a11y/click-events-have-key-events */ // !!!!!!!
/* eslint-disable jsx-a11y/no-static-element-interactions */ // !!!!!!!!
/* eslint-disable react/no-unstable-nested-components */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React, { useState } from "react"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

interface User {
    id: number
    username: string
}

interface Log {
    id: number
    createdAt: string
    event: string
    user?: User
}

type Props = {
    user: User
    userLogs: Log[]
    // maxDate: string
}
export default function LogsUser({ user, userLogs }: Props) {
    const [showLogs, setShowLogs] = useState(false)
    return (
        <div
            key={"log-diff-userId-" + user.id}
            className={
                "col-12 " + ([1, 4, 567].includes(user.id) && "text-danger")
            }
            onClick={() => setShowLogs(!showLogs)}
        >
            <div>
                <small>{user?.username}</small>
                <div className="float-end">x {userLogs.length}</div>
            </div>
            {showLogs && (
                <small className="text-success">
                    {userLogs.map((log) => (
                        <div key={"log-user-" + user.id + "-" + log.id}>
                            {log.createdAt}: {log.event}
                        </div>
                    ))}
                </small>
            )}
        </div>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS
