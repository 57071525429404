// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import { createSlice } from "@reduxjs/toolkit"
import {
    zApiHoraireMembreCreate,
    zApiHoraireMembreUpdate,
} from "features/membres/utils/apiMembres"
import {
    apiPostageUpdate,
    apiPostageCreate,
    apiPostageDelete,
} from "features/postages/utils/apiPostages"
import { getDayNameForToday, getHeuresMFMOuvertureEtab } from "utils/fonctions"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

interface HoraireMembre {
    id: number | null
    jour: string //             ex : "Mardi"
    semaine: string | null //   "A" | "B"
    heureDebut: string //       ex : "14:55"
    heureFin: string //         ex : "16:10"
    infos: string | null
    membreId: number | null //  seulement pour le form UWHoraire
    uwPseudo: string | null //  seulement pour le form UWHoraire
    fetchStatus: null | "FETCHING" | "SUCCESS" | "ERROR"
}

interface Membre {
    id: number | null
    pseudo: string
    couleur: string
    fonction: string | null
    horaires: Array<HoraireMembre>
    nbreHeuresHebdo: number
    nbreNuitsHebdo: number
    quotite: number
    fetchStatus: null | "FETCHING" | "SUCCESS" | "ERROR"
    postages: Array<{
        id: number
        heureDebut: string
        heureFin: string
        semaine: string | null
        jour: string
    }>
    // user?: User
}
interface Edt {
    id: number | null
    nom: string
    actif: boolean
    etablissement: { id: number; etablissementNom: string }
    horaireEdts: []
    membres: Array<Membre>
}
interface Poste {
    id: number
    nom: string
    slug: string
    color: string
    description: null | string
}

interface Postage {
    id: number | null
    jour: string //       ex : "Mardi"
    semaine: string | null
    heureDebut: string // ex : "14:55"
    heureFin: string //   ex : "16:10"
    poste: { id: number }
    commentaire: string | null
    posteId: number | null
    userWorkspaceId: number | null
    membreId: number | null
    isLoading: boolean | null
}

interface InitialState {
    // fetchStatus: null | "FETCHING" | "SUCCESS" | "ERROR"
    // fetchErrorMessage: string | null

    edt: Edt | null
    postes: Array<Poste> | null
    horaireEdts: []
    periodes: []
    sonneries: []
    membres: Array<Membre>

    showOptions: boolean
    postageForm: Postage | null

    isFormPostage1st: boolean
    formPostage1stPosition: "TOP" | "BOTTOM" | "LEFT" | "RIGHT"
    formPostage1stSemaine: "A" | "B" | null
    formPostage1stPoste: Poste | null

    locked: boolean

    heureNow: any

    jour: string // todo : "Lundi"| "Mardi"| "Mercredi"| "Jeudi"| "Vendredi"| "Samedi"| "Dimanche"
    jourNow: string // todo : "Lundi"| "Mardi"| "Mercredi"| "Jeudi"| "Vendredi"| "Samedi"| "Dimanche"
    orientation: "HORIZONTAL" | "VERTICAL"
    startMFM: number
    endMFM: number
    durationInMinutes: number

    vSrollablePercentHeight: number
    vHeaderHeight: number
    vContentHeight: number
    hSectionWidth: number
    hSectionHeight: number
    rowHeaderWidth: number
    containerHeight: number // seulement pour orientation.VERTICAL

    showAllUsers: boolean
    showHoraires: boolean
    showPostages: boolean

    // todo : modePage: "HORAIRES_MEMBRES" | "POSTAGES_EDT" | "POSTAGES_MEMBRE"
    modeHoraires: boolean // true : affichage pour la page horairesMembres
    modeHPS: boolean // true : affichage pour la page horairesMembres
    modeDemo: boolean
    modeJournee: "EDT_HORAIRES" | "JOURNEE" | "24H"
    /*
    vSectionWidth: 150,  // Nop; flex
    headerHorizontalHeight: 80,
    rowHeight   : 80,
    rowWidth: 1700,					// modifié dans useEffect()
    rowWidthAuto: 2000,				// modifié dans useEffect()
    rowContentWidth: 2000-110,		// modifié dans useEffect()
    rowContentWidthAuto: 2000-110,	// modifié dans useEffect()
    columnWidth: 100,
    columnHeight: (endHourMFM-startHourMFM)*2,
    columnContentHeight: (endHourMFM-startHourMFM)*2 - 70,

    whiteHoraires: true,
    postageOpacity: 1,		
    showAllUsers: false,
    locked: true,
    */
    // **************************************** OK
    minClickMFM: number
    maxClickMFM: number
    // ****** OPTIONS SIZE :
    globalContainerHeight: number // la hauteur en px (pour les mode postages, height: 100%)
    // ****************************************
}

// ■   ■   ■   ■   ■   ■   ■   ■   ■   ■   ■   ■   ■   ■   ■   ■   ■   ■   ■

const initialState: InitialState = {
    // fetchStatus: null,
    // fetchErrorMessage: null,

    edt: null,
    postes: null,
    horaireEdts: [],
    periodes: [],
    sonneries: [],
    membres: [],
    showOptions: false,
    postageForm: null,

    isFormPostage1st: true,
    formPostage1stPosition: "LEFT", // "TOP" | "BOTTOM" | "LEFT" | "RIGHT"
    formPostage1stSemaine: null, // "A" | "B" | null
    formPostage1stPoste: null,

    heureNow: null,
    jour: getDayNameForToday(true),
    jourNow: getDayNameForToday(false),

    locked: true,
    orientation: "VERTICAL",

    vSrollablePercentHeight: 99,
    vHeaderHeight: 60,
    vContentHeight: 800,
    hSectionWidth: 110,
    hSectionHeight: 100,
    rowHeaderWidth: 100,
    containerHeight: 550, // seulement pour orientation.VERTICAL

    showAllUsers: false,
    showHoraires: true,
    showPostages: true,

    modeHoraires: false,
    modeHPS: false,
    modeDemo: false,
    modeJournee: "EDT_HORAIRES",
    // *################################## OK
    startMFM: 5 * 60,
    endMFM: 20 * 60,
    durationInMinutes: 20 * 60 - 5 * 60,
    minClickMFM: 0,
    maxClickMFM: 24 * 60,
    // *################ OPTIONS SIZE :
    globalContainerHeight: 700,
    // *##################################
}

function getStartEndDurationMinMaxMFM(options: {
    horaireEdts: []
    jourName: string
    modeJournee: string
    locked: boolean
}) {
    const { horaireEdts, jourName, modeJournee, locked } = options
    let { startHourMFM, endHourMFM } = getHeuresMFMOuvertureEtab(
        horaireEdts,
        jourName
    )
    const minClickMFM = modeJournee === "24H" ? 0 : startHourMFM
    const maxClickMFM = modeJournee === "24H" ? 24 * 60 : endHourMFM

    if (modeJournee === "24H") {
        startHourMFM = 0
        endHourMFM = 23 * 60 + 59
    }

    if (!locked) {
        startHourMFM -= 10
        endHourMFM += 10
    }

    return {
        startHourMFM,
        endHourMFM,
        durationInMinutes: endHourMFM - startHourMFM,
        minClickMFM,
        maxClickMFM,
    }
}

// ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■

const slice = createSlice({
    name: "timetable",
    initialState,
    reducers: {
        // **** Fetch
        /*
        fetchStart: (state) => {
            state.edt = null
            state.fetchStatus = "FETCHING"
            state.fetchErrorMessage = null
        },
        */
        initTimetableReducer: (state, action) => {
            state.postes = action.payload.postes
            state.modeDemo = action.payload.demo

            state.edt = {
                id: action.payload.edt.id,
                nom: action.payload.edt.nom,
                actif: action.payload.edt.actif,
                etablissement: action.payload.edt.etablissement,
                horaireEdts: action.payload.edt.horaireEdts,
                membres: [], // action.payload.edt.membres, // !!!! mais on utilise pas celui la !!!
            }
            state.horaireEdts = action.payload.edt.horaireEdts
            state.periodes = action.payload.edt.periodes
            state.sonneries = action.payload.edt.sonneries
            state.membres = action.payload.edt.membres

            state.showOptions = false

            state.modeHoraires = action.payload.modeHoraires
            state.modeHPS = action.payload.modeHPS
            state.modeJournee = "EDT_HORAIRES"

            state.showHoraires = true
            state.showPostages = !action.payload.modeHoraires
            state.showAllUsers = action.payload.modeHoraires // ***********************************************************************************
            state.orientation = action.payload.modeHPS
                ? "HORIZONTAL"
                : "VERTICAL"

            // ** !*!*!*!*!
            const {
                startHourMFM,
                endHourMFM,
                durationInMinutes,
                minClickMFM,
                maxClickMFM,
            } = getStartEndDurationMinMaxMFM({
                horaireEdts: action.payload.edt.horaireEdts,
                jourName: state.jour,
                modeJournee: "EDT_HORAIRES",
                locked: true,
            })
            state.startMFM = startHourMFM
            state.endMFM = endHourMFM
            state.durationInMinutes = durationInMinutes
            state.minClickMFM = minClickMFM
            state.maxClickMFM = maxClickMFM
            // ** !*!*!*!*!

            state.locked = true
            /*
             state.isFormPostage1st = true
            state.formPostage1stPosition = "LEFT"
            state.formPostage1stSemaine = null
            state.formPostage1stPoste = null

            if (action.payload.modeHoraires) {
                state.showAllUsers = true
            } else {
                state.showAllUsers = false
            }
            if (action.payload.modeHPS) {
            }
            */
        },

        // **** Options
        setModeJournee: (state, action) => {
            state.modeJournee = action.payload

            const {
                startHourMFM,
                endHourMFM,
                durationInMinutes,
                minClickMFM,
                maxClickMFM,
            } = getStartEndDurationMinMaxMFM({
                horaireEdts: state.horaireEdts,
                jourName: state.jour,
                modeJournee: action.payload,
                locked: state.locked,
            })
            state.startMFM = startHourMFM
            state.endMFM = endHourMFM
            state.durationInMinutes = durationInMinutes
            state.minClickMFM = minClickMFM
            state.maxClickMFM = maxClickMFM
        },
        setOrientation: (state, action) => {
            state.orientation = action.payload
        },
        setVSrollablePercentHeight: (state, action) => {
            state.vSrollablePercentHeight = action.payload
        },

        updateHeureNow: (state) => {
            const d = new Date()
            const nowMFM = d.getHours() * 60 + d.getMinutes()
            const nowPercent =
                ((nowMFM - state.startMFM) * 100) / state.durationInMinutes

            state.heureNow = {
                hours: d.getHours(),
                minutes: d.getMinutes(),
                secondes: d.getSeconds(),
                percent: nowPercent,
            }
        },
        setShowAllUsers: (state, action) => {
            state.showAllUsers = action.payload
        },
        setShowHoraires: (state, action) => {
            state.showHoraires = action.payload
        },
        setShowPostages: (state, action) => {
            state.showPostages = action.payload
        },
        setLocked: (state, action) => {
            state.locked = action.payload
            state.formPostage1stPoste = null
            state.formPostage1stSemaine = null
            // ** !*!*!*!*!
            const {
                startHourMFM,
                endHourMFM,
                durationInMinutes,
                minClickMFM,
                maxClickMFM,
            } = getStartEndDurationMinMaxMFM({
                horaireEdts: state.horaireEdts,
                jourName: state.jour,
                modeJournee: state.modeJournee,
                locked: action.payload,
            })
            state.startMFM = startHourMFM
            state.endMFM = endHourMFM
            state.durationInMinutes = durationInMinutes
            state.minClickMFM = minClickMFM
            state.maxClickMFM = maxClickMFM
            // ** !*!*!*!*!
        },
        setJour: (state, action) => {
            state.jour = action.payload

            // ** !*!*!*!*!
            const {
                startHourMFM,
                endHourMFM,
                durationInMinutes,
                minClickMFM,
                maxClickMFM,
            } = getStartEndDurationMinMaxMFM({
                horaireEdts: state.horaireEdts,
                jourName: action.payload,
                modeJournee: state.modeJournee,
                locked: state.locked,
            })
            state.startMFM = startHourMFM
            state.endMFM = endHourMFM
            state.durationInMinutes = durationInMinutes
            state.minClickMFM = minClickMFM
            state.maxClickMFM = maxClickMFM
            // ** !*!*!*!*!
        },
        // **** CRUD Postage

        setPostageForm: (state, action) => {
            state.postageForm = action.payload
        },
        updatePostageStart: (state, action) => {
            // console.log("updatePostageStart", action)
            const editablePostage = action.payload
            const uwId = editablePostage.membreId
            editablePostage.isLoading = true

            const newPostages = state.membres
                .filter((membre) => membre.id === uwId)[0]
                .postages.map((post) =>
                    post.id === editablePostage.id ? editablePostage : post
                )

            // console.log("newPostages", newPostages)

            state.membres.filter((uw) => uw.id === uwId)[0].postages =
                newPostages
        },
        updatePostageSuccess: (state, action) => {
            // console.log("actionnnnnnnnnnnn", action)
            const editablePostage = action.payload.postage
            const uwId = action.payload.userWorkspaceId
            // console.log("editablePostage", editablePostage)
            // console.log("uwId", uwId)

            const newPostages = state.membres
                .filter((uw) => uw.id === uwId)[0]
                .postages.map((post) =>
                    post.id === editablePostage.id ? editablePostage : post
                )

            // console.log("newPostages", newPostages)

            state.membres.filter((uw) => uw.id === uwId)[0].postages =
                newPostages
        },
        createPostageStart: (state, action) => {
            const newPostage = action.payload
            const uwId = newPostage.membreId
            newPostage.isLoading = true
            newPostage.poste = { id: newPostage.posteId }

            //  alert(newPostage.semaine)

            // Cloner les anciens postages de l'uw dans newPostages et ajouter newPostage
            const newPostages = [
                ...state.membres.filter((uw) => uw.id === uwId)[0].postages,
                newPostage,
            ]

            // console.log("****** newPostages", newPostages)

            state.membres.filter((uw) => uw.id === uwId)[0].postages =
                newPostages
        },

        createPostageSuccess: (state, action) => {
            // alert("success")
            const newPostage = action.payload.postage
            const uwId = action.payload.membreId

            // console.log("!!!!!!!!!newPostage::::", newPostage)
            // console.log("!!!!!!!!!uwId::::", uwId)

            const newPostages = state.membres
                .filter((uw) => uw.id === uwId)[0]
                .postages.map((post) =>
                    post.jour === newPostage.jour &&
                    post.heureDebut === newPostage.heureDebut &&
                    post.heureFin === newPostage.heureFin &&
                    post.semaine === newPostage.semaine // attention "" !=== null
                        ? newPostage
                        : post
                )

            // console.log("newPostages", newPostages)

            state.membres.filter((uw) => uw.id === uwId)[0].postages =
                newPostages
        },
        deletePostageStart: (state, action) => {
            const { postageId, userWorkspaceId } = action.payload

            const newPostages = state.membres
                .filter((uw) => uw.id === userWorkspaceId)[0]
                .postages.map((post) =>
                    post.id !== postageId ? post : { ...post, isLoading: true }
                )

            state.membres.filter(
                (uw) => uw.id === userWorkspaceId
            )[0].postages = newPostages
        },

        deletePostageSuccess: (state, action) => {
            //  console.log("deletePostageSuccess:: postageId:", action.payload)

            const { postageId, userWorkspaceId } = action.payload

            const newPostages = state.membres
                .filter((uw) => uw.id === userWorkspaceId)[0]
                .postages.filter((post) => post.id !== postageId)

            state.membres.filter(
                (uw) => uw.id === userWorkspaceId
            )[0].postages = newPostages
        },
        // **** CRUD HoraireMembre
        // depreciated
        addHoraireMembre: (state, action) => {
            //   console.log(                "**updateUWHoraireSuccess**: action.paylod",                action.payload            )

            const newUWHoraire = action.payload.uWHoraire
            const uwId = action.payload.userWorkspaceId

            if (state.edt) {
                const newUWHoraires = state.edt.membres
                    .filter((uw) => uw.id === uwId)[0]
                    .horaires.map((hor) =>
                        hor.id === newUWHoraire.id ? newUWHoraire : hor
                    )

                state.edt.membres.filter((uw) => uw.id === uwId)[0].horaires =
                    newUWHoraires
            }
        },

        // HM Create

        horaireMembreCreateStart4Timetable: (state, action) => {
            //   console.clear()
            //   console.log("@@@@@ horaireMembreAdd @@@@@")
            const newHoraireMembre = {
                ...action.payload.horaireMembre,
                isLoading: true,
            }
            const uwId = action.payload.membreId

            if (state.edt) {
                const newHorairesMembre = [
                    ...state.membres.filter((uw) => uw.id === uwId)[0].horaires,
                    newHoraireMembre,
                ]
                //   console.log("newHorairesMembre", newHorairesMembre)
                state.membres.filter((uw) => uw.id === uwId)[0].horaires =
                    newHorairesMembre
            }
        },

        horaireMembreCreateSuccess4Timetable: (state, action) => {
            console.log("@@@@@ horaireMembreEdit @@@@@")
            const newHoraireMembre = action.payload.horaireMembre
            const uwId = action.payload.membreId

            if (state.edt) {
                const newHorairesMembre = state.membres
                    .filter((uw) => uw.id === uwId)[0]
                    .horaires.map((hor) =>
                        hor.heureDebut === newHoraireMembre.heureDebut &&
                        hor.heureFin === newHoraireMembre.heureFin &&
                        hor.jour === newHoraireMembre.jour &&
                        hor.semaine === newHoraireMembre.semaine
                            ? newHoraireMembre
                            : hor
                    )
                console.log("newHorairesMembre", newHorairesMembre)
                state.membres.filter((uw) => uw.id === uwId)[0].horaires =
                    newHorairesMembre
            }
        },

        horaireMembreCreateSuccess4Table: (state, action) => {
            const { horaireMembre, membreId } = action.payload

            const newHorairesMembre = [
                ...state.membres.filter((mbr) => mbr.id === membreId)[0]
                    .horaires,
                horaireMembre,
            ]
            console.log("newHorairesMembre", newHorairesMembre)
            state.membres.filter((uw) => uw.id === membreId)[0].horaires =
                newHorairesMembre
        },

        // HM Update

        horaireMembreUpdateStart: (state, action) => {
            console.log("@@@@@ horaireMembreUpdateStart @@@@@")

            const { membreId } = action.payload
            const newHoraire = {
                ...action.payload.horaireMembre,
                isLoading: true,
            }

            if (state.edt) {
                const newHoraires = state.membres
                    .filter((mbr) => mbr.id === membreId)[0]
                    .horaires.map((hor) =>
                        hor.id === newHoraire.id ? newHoraire : hor
                    )

                console.log("newHorairesMembre", newHoraires)
                state.membres.filter((mbr) => mbr.id === membreId)[0].horaires =
                    newHoraires
            }
        },

        horaireMembreUpdateSuccess: (state, action) => {
            console.log("@@@@@ horaireMembreUpdateSuccess @@@@@")
            const { horaireMembre, membreId } = action.payload

            console.log("horaireMembre", horaireMembre)
            console.log("membreId", membreId)
            if (state.edt) {
                const newHorairesMembre = state.membres
                    .filter((uw) => uw.id === membreId)[0]
                    .horaires.map((hor) =>
                        hor.heureDebut === horaireMembre.heureDebut &&
                        hor.heureFin === horaireMembre.heureFin &&
                        hor.jour === horaireMembre.jour &&
                        hor.semaine === horaireMembre.semaine
                            ? horaireMembre
                            : hor
                    )
                console.log("newHorairesMembre", newHorairesMembre)
                state.membres.filter((uw) => uw.id === membreId)[0].horaires =
                    newHorairesMembre
            }
        },

        horaireMembreUpdateSuccessV2: (state, action) => {
            console.log("@@@@@ horaireMembreUpdateSuccessV2 @@@@@")
            const { horaireMembre, membreId } = action.payload

            console.log("horaireMembre", horaireMembre)
            console.log("membreId", membreId)
            if (state.edt) {
                const newHorairesMembre = state.membres
                    .filter((uw) => uw.id === membreId)[0]
                    .horaires.map((hor) =>
                        hor.id === horaireMembre.id ? horaireMembre : hor
                    )
                console.log("newHorairesMembre", newHorairesMembre)
                state.membres.filter((uw) => uw.id === membreId)[0].horaires =
                    newHorairesMembre
            }
        },

        // HM Delete

        deleteMembreHoraireSuccess: (state, action) => {
            const { membreId, membreHoraireId } = action.payload

            console.log("deleteMembreHoraireSuccess")
            console.log("membreId", membreId)
            console.log("membreHoraireId", membreHoraireId)
            console.log("action.payload", action.payload)

            if (state.edt) {
                const newUWHoraires = [
                    ...state.membres
                        .filter((memb) => memb.id === membreId)[0]
                        .horaires.filter((hor) => hor.id !== membreHoraireId),
                ]

                console.log("newUWHoraires", newUWHoraires)

                state.membres.filter(
                    (memb) => memb.id === membreId
                )[0].horaires = newUWHoraires
            }
        },

        // **** CRUD Membres
        addMembreToEdtSuccess: (state, action) => {
            const { membre } = action.payload
            console.log("addMembreToEdt>>>>>> membre", membre)

            if (state.membres) {
                state.membres = [...state.membres, membre]
            }
        },
        deleteMembreSuccess: (state, action) => {
            const membreId = action.payload

            if (state.membres) {
                state.membres = [
                    ...state.membres.filter((mbr) => mbr.id !== membreId),
                ]
            }
        },
        updateMembreSuccess: (state, action) => {
            if (state.membres) {
                // console.log("newUWs", newUWs)
                state.membres = [
                    ...state.membres.map((mbr) =>
                        mbr.id === action.payload.id ? action.payload : mbr
                    ),
                ]
            }
        },

        // ****
        setIsFormPostage1st: (state, action) => {
            state.isFormPostage1st = action.payload
            if (!action.payload) {
                state.formPostage1stPoste = null
                state.formPostage1stSemaine = null
            }
        },
        setFormPostage1stPosition: (state, action) => {
            state.formPostage1stPosition = action.payload
        },
        setFormPostage1stSemaine: (state, action) => {
            state.formPostage1stSemaine = action.payload
        },
        setFormPostage1stPoste: (state, action) => {
            state.formPostage1stPoste = action.payload
        },
        setShowOptions: (state, action) => {
            state.showOptions = action.payload
        },
        // *################ OPTIONS SIZE :
        // exporté
        setGlobalContainerHeight: (state, action) => {
            state.globalContainerHeight = action.payload
        },
    },
})

export default slice.reducer

// ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■

const {
    updatePostageSuccess,
    updatePostageStart,
    createPostageStart,
    createPostageSuccess,
    deletePostageStart,
    deletePostageSuccess,
    horaireMembreCreateStart4Timetable,
    horaireMembreUpdateStart,
} = slice.actions

export const {
    initTimetableReducer,
    setModeJournee,
    setOrientation,
    updateMembreSuccess,
    deleteMembreSuccess,
    setGlobalContainerHeight,
    horaireMembreCreateSuccess4Table,
    horaireMembreCreateSuccess4Timetable,
    horaireMembreUpdateSuccess,
    horaireMembreUpdateSuccessV2,
    deleteMembreHoraireSuccess,
    setVSrollablePercentHeight,
    setJour,
    updateHeureNow,
    setShowAllUsers,
    setShowHoraires,
    setShowPostages,
    setLocked,
    setPostageForm,
    setFormPostage1stPosition,
    setIsFormPostage1st,
    setFormPostage1stSemaine,
    setFormPostage1stPoste,
    setShowOptions,
    addMembreToEdtSuccess,
} = slice.actions

export const createOrUpdatePostage =
    (postage: Postage) => async (dispatch: any) => {
        // console.log("createOrUpdatePostage", postage)
        console.log("■■■■ createOrUpdatePostage - .postage", postage)
        // console.log(            "uwIdddddddddddddddddddddddddddddddd",            postage.userWorkspaceId        )
        if (postage.id) {
            dispatch(updatePostageStart(postage))
            apiPostageUpdate(postage).then((reponse) => {
                if (reponse.postage) {
                    console.log("■■ ■■ ■■ reponse.postage", reponse.postage)

                    dispatch(
                        updatePostageSuccess({
                            postage: reponse.postage,
                            userWorkspaceId: postage.membreId,
                        })
                    )
                } else if (reponse.error) {
                    alert("update postage error")
                    // dispatch(fetchError(reponse.error))
                }
            })
        } else {
            dispatch(createPostageStart(postage))
            apiPostageCreate(postage).then((reponse) => {
                if (reponse.postage) {
                    console.log("■■ ■■ ■■ reponse.postage", reponse.postage)

                    dispatch(
                        createPostageSuccess({
                            postage: reponse.postage,
                            membreId: postage.membreId,
                        })
                    )
                } else if (reponse.error) {
                    alert("update postage error")
                    // dispatch(fetchError(reponse.error))
                }
            })
        }
    }

export const deletePostage =
    (postageId: number, userWorkspaceId: number) => async (dispatch: any) => {
        // console.log("createOrUpdatePostage", postage)
        // console.log("■■■■ createOrUpdatePostage - .postage", postage)
        // console.log(            "uwIdddddddddddddddddddddddddddddddd",            postage.userWorkspaceId        )

        dispatch(deletePostageStart({ postageId, userWorkspaceId }))
        apiPostageDelete(postageId).then((reponse) => {
            if (reponse.reponse === "SUCCESS") {
                console.log("■■ ■■ ■■ reponse.postage", reponse.reponse)
                dispatch(deletePostageSuccess({ postageId, userWorkspaceId }))
            } else if (reponse.error) {
                alert("update postage error")
                // dispatch(fetchError(reponse.error))
            }
        })
    }

export const createOrUpdateHoraireMembre4Timetable =
    (horaireMembre: HoraireMembre) => async (dispatch: any) => {
        if (horaireMembre.semaine === "") {
            horaireMembre.semaine = null
        }
        if (horaireMembre.id) {
            dispatch(
                horaireMembreUpdateStart({
                    horaireMembre,
                    membreId: horaireMembre.membreId,
                })
            )

            zApiHoraireMembreUpdate(horaireMembre).then((reponse) => {
                console.log("QQQQQQQQQQQQQQQQQQ", horaireMembre)
                if (reponse.horaireMembre) {
                    dispatch(
                        horaireMembreUpdateSuccess({
                            horaireMembre: reponse.horaireMembre,
                            membreId: horaireMembre.membreId,
                        })
                    )
                } else if (reponse.error) {
                    alert("update  error")
                }
            })
        } else {
            dispatch(
                horaireMembreCreateStart4Timetable({
                    horaireMembre,
                    membreId: horaireMembre.membreId,
                })
            )
            zApiHoraireMembreCreate(horaireMembre).then((reponse) => {
                if (reponse.horaireMembre) {
                    dispatch(
                        horaireMembreCreateSuccess4Timetable({
                            horaireMembre: reponse.horaireMembre,
                            membreId: horaireMembre.membreId,
                        })
                    )
                } else if (reponse.zError) {
                    alert("create Error")
                    console.log("reponse.zError", reponse.zError)
                }
            })
        }
    }
