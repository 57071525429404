// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
// import React from "react"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export const status = {
    pending: "PENDING",
    canceled: "CANCELED",
    processing: "PROCESSING",
    checking: "CHECKING",
    done: "DONE",
}

export const progressItemsEdtHPS = [
    {
        page: "EDT_HPS",
        actions: [
            {
                title: "CRUD Horaire",
                status: status.done,
            },
            {
                title: "CRUD Sonnerie",
                status: status.done,
            },
            {
                title: "CRUD Periodes",
                status: status.pending,
            },
            /*
            {
                title: "Ajouter une periode",
                status: status.pending,
            },
            {
                title: "Modifier une periode",
                status: status.pending,
            },
            {
                title: "Supprimer une periode",
                status: status.pending,
            },
            */
            {
                title: "Cloner depuis un autre workspace ??",
                status: status.pending,
            },
        ],
    },
]
