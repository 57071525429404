// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React, { useState } from "react"
import { useAppSelector } from "utils/store"
import { ZPageHeader, ZPageTitle } from "uiZ/zPage"
import Timetable from "features/timetable/Timetable"
import FormAddMembres from "features/membres/formAddMembres/FormAddMembres"
import HorairesMembresTitmetableWindowHeader from "./HorairesMembresTitmetableWindowHeader"
import PageMembresContent from "./MembresContent"
import MembreForm from "../form/MembreForm"
import HoraireMembreForm from "../formHoraireMembre/HoraireMembreForm"
// import UWHoraireForm from "features/membres/horairesMembres/UWHoraireForm"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function Membres() {
    const [horaireModeTimetable, setHoraireModeTimetable] = useState(true) // ! debug

    const [timetableFullWindow, setTimetableFullWindow] = useState(false)

    const { formAddUsersToWorkspaceStatus } = useAppSelector(
        (state) => state.uW
    )

    const { formMembre, formHoraireMembre } = useAppSelector(
        (state) => state.membres
    )

    const { edt } = useAppSelector((state) => state.timetable)

    if (timetableFullWindow) {
        return (
            <>
                <HorairesMembresTitmetableWindowHeader
                    timetableFullWindow={timetableFullWindow}
                    setTimetableFullWindow={setTimetableFullWindow}
                />
                <Timetable />
            </>
        )
    }

    return (
        <>
            <ZPageHeader>
                <ZPageTitle>
                    Personnel de l&apos;EDT <small>(membres)</small>
                </ZPageTitle>
                <p>
                    Editer la liste du personnel de l'EDT et définissez leurs
                    horaires
                </p>
            </ZPageHeader>

            {formAddUsersToWorkspaceStatus && <FormAddMembres />}
            {formMembre && <MembreForm />}
            {
                // si horaireModeTimetable, le MembreForm est inclus dans timetable
                formHoraireMembre && !horaireModeTimetable && (
                    <HoraireMembreForm />
                )
            }
            {edt && (
                <PageMembresContent
                    horaireModeTimetable={horaireModeTimetable}
                    setHoraireModeTimetable={setHoraireModeTimetable}
                    setTimetableFullWindow={setTimetableFullWindow}
                />
            )}
        </>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS
