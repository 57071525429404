/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React, { useState, useRef } from "react"
import styled from "styled-components"
import { useAppSelector } from "utils/store"
import LineNow from "../LineNow"
import CursorLine from "../CursorLine"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function LayoutContent({
    showText = false,
    genre = null, // "USER" | "SONNERIES" | "PERIODES"
    membreId = null,
    membrePseudo = null,
    children,
}) {
    const {
        orientation,
        durationInMinutes,
        startMFM,
        locked,
        jour,
        modeHoraires,
        minClickMFM,
        maxClickMFM,
    } = useAppSelector((state) => state.timetable)

    const myRef = useRef(null)

    const [mouseCurrent, setMouseCurrent] = useState(null)
    const [mouseClick1, setMouseClick1] = useState(null)
    const [mouseClick2, setMouseClick2] = useState(null)
    // const [itemHovered, setItemHovered] = useState(false)

    function handleOnMouseMove(event) {
        // console.log(event)
        event.preventDefault()
        if (locked) {
            return
        }
        setMouseCurrent(getMouseFromEvent(event))
    }

    function handleClick(ev) {
        ev.stopPropagation()
        if (locked) {
            return
        }
        // console.log(ev)
        if (!mouseClick2) {
            if (mouseClick1) {
                console.log("mouseCurrent", mouseCurrent)
                console.log("mouseClick1", mouseClick1)
                if (mouseCurrent.percent <= mouseClick1.percent) {
                    console.log("return")
                    return
                }

                setMouseClick2(mouseCurrent)
                // ev.stopPropagation()
                // ev.preventDefault()
            } else {
                setMouseClick1(mouseCurrent)
                // ev.stopPropagation()
                // ev.preventDefault()
            }
        } else {
            setMouseClick1(null)
            setMouseClick2(null)
        }
    }
    function handleOnMouseLeave() {
        setMouseCurrent(null)
        setMouseClick1(null)
        setMouseClick2(null)
    }

    function mfmToStringHeure(mfm) {
        const h = Math.floor(mfm / 60)
        const m = mfm % 60
        return (h < 10 ? "0" : "") + h + ":" + (m < 10 ? "0" : "") + m
    }

    function getMouseFromEvent(event) {
        const minutesStep = 5

        let percent
        if (orientation === "HORIZONTAL") {
            const elemWidth = myRef.current.clientWidth
            const elemLeft = myRef.current.getBoundingClientRect().x
            const mouseX = event.nativeEvent.clientX - elemLeft
            percent = (mouseX * 100) / elemWidth
        } else {
            const elemHeight = myRef.current.clientHeight
            const elemTop = myRef.current.getBoundingClientRect().y
            const mouseY = event.nativeEvent.clientY - elemTop
            percent = (mouseY * 100) / elemHeight
        }
        /*
        const elemHeight = myRef.current.clientHeight
        const elemTop = myRef.current.getBoundingClientRect().y
        const mouseY = event.nativeEvent.clientY - elemTop
        const percent = (mouseY * 100) / elemHeight
        */
        if (percent && percent > 0) {
            const minutesFM = Math.round(
                (percent * durationInMinutes) / 100 + startMFM
            )
            let minutesFMWithStep =
                Math.round(minutesFM / minutesStep) * minutesStep

            if (minutesFMWithStep < minClickMFM) {
                minutesFMWithStep = minClickMFM
            }
            if (minutesFMWithStep > maxClickMFM) {
                minutesFMWithStep = maxClickMFM
            }
            let percentWithStep =
                ((minutesFMWithStep - startMFM) * 100) / durationInMinutes
            percentWithStep = Math.round(percentWithStep * 100) / 100

            //  console.log("minutesFMWithStep", minutesFMWithStep)

            return {
                percent: percentWithStep,
                heureStr: mfmToStringHeure(minutesFMWithStep),
            }
        }
        return null
    }

    return (
        <StyledLayoutContent
            ref={myRef}
            bg="red"
            orientation={orientation}
            className="flex-grow-1"
            onMouseMove={(ev) => handleOnMouseMove(ev)}
            onClick={(ev) => handleClick(ev)}
            onMouseLeave={() => handleOnMouseLeave()}
            genre={genre}
        >
            {/*
            {!locked && (
                <>
                    <b>mouseCurrent:</b>
                    <br />
                    {mouseCurrent?.percent} %<br />
                    {mouseCurrent?.heureStr}
                    <br />
                    <br />
                    <b>mouseClick1:</b>
                    <br />
                    {mouseClick1?.percent} %<br />
                    {mouseClick1?.heureStr}
                    <br />
                    <br />
                    <b>mouseClick2:</b>
                    <br />
                    {mouseClick2?.percent} %<br />
                    {mouseClick2?.heureStr}
                    <br />
                    <br />
                </>
            )}
             */}
            {/* {genre} */}
            {children}
            {!modeHoraires && locked && <LineNow showText={showText} />}
            <CursorLine
                mouseCurrent={mouseCurrent}
                mouseClick1={mouseClick1}
                mouseClick2={mouseClick2}
                membreId={membreId}
                membrePseudo={membrePseudo}
                jour={jour}
            />
        </StyledLayoutContent>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS

const StyledLayoutContent = styled.div`
    background-color: gray;
    ${(props) => props.genre === "SONNERIES" && `background-color:white;`}

    @media print {
        background-color: white;
    }
    z-index: 100;
    //  background-color: orange; // **** debugOnly
    //  border: 2px solid red; // **** debugOnly
    //   padding: 10px; // **** debugOnly
    display: inline-block;
    // min-height: 200px; // ******************** TEMPPPPPPPPPPPPPPPPPP
    // cursor: row-resize;
    /* 
         border: 4px dotted yellow;	
     */

    ${(props) =>
        props.orientation === "HORIZONTAL"
            ? `
            //	width:  90%; $ {props.hSectionWidth+'px'};
            // width: ${props.hSectionWidth + "px"};
            // width: 200px !important;
             // width: 2200px !important;
           // top: 0px;
			height: 100%;
           left: 0px;
            position: relative;
			`
            : `
			width: 100%;
			/*height:   $ {props.vContentHeight+"px"};*/
			top: 0px ; /* $ {props.vHeaderHeight+"px"}; */
            position: relative;
			`}
`
