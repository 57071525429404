// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React, { useEffect, useRef, useContext, useState } from "react"

import styled from "styled-components"
import { theme } from "styles/theme"
import { landingPageContext } from "pages/public/PageHomeLandingPage"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
LPSection.defaultProps = {
    bgPrimary: false,
    reverse: false,
    className: "",
}
type Props = {
    className?: string
    bgPrimary?: boolean
    reverse?: boolean
    children: React.ReactNode
}
export default function LPSection({
    className,
    reverse,
    bgPrimary,
    children,
}: Props) {
    const [isCurrent, setIsCurrent] = useState(false)

    const { currentVariant, updateCurrentVariant } =
        useContext(landingPageContext)

    const innerRef = useRef<HTMLHeadingElement>(null)
    const navHeaderHeight = 70

    useEffect(() => {
        const handleScroll = (_event: any) => {
            if (innerRef.current) {
                const { scrollY } = window

                if (scrollY < 200 && currentVariant !== "light") {
                    updateCurrentVariant("light")
                    console.log("<200")
                } else {
                    const { offsetHeight, offsetTop } = innerRef.current

                    const distTop = offsetTop - scrollY - navHeaderHeight
                    const distBottom =
                        offsetTop - scrollY - navHeaderHeight + offsetHeight

                    const isActive = distTop < 0 && distBottom > 0
                    setIsCurrent(isActive)
                }
            }
        }
        window.addEventListener("scroll", handleScroll)
        return () => {
            window.removeEventListener("scroll", handleScroll)
        }
    }, [])

    useEffect(() => {
        // console.log("***************" + (isCurrent ? "oui" : "non"))
        const newColor = bgPrimary ? "primary" : "light"
        if (isCurrent && currentVariant !== newColor) {
            updateCurrentVariant(newColor)
        }
    }, [isCurrent, currentVariant])

    return (
        <StyleSection
            className={
                className +
                " row col-12 " +
                (reverse ? " d-flex flex-row-reverse " : "  ")
            }
            bgPrimary={bgPrimary}
            reverse={reverse}
            ref={innerRef}
        >
            {children}
            {/* 
            <>---{currentVariant}---</>
            <>---{isCurrent ? "isCurrent" : "is NOT Current"}---</>
            */}
        </StyleSection>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS

const StyleSection = styled.div<{
    bgPrimary: boolean | undefined
    reverse: boolean | undefined
}>`
    ${(props) =>
        props.bgPrimary
            ? `
            background-color:${theme.colors.c1};
            color:white;`
            : `
            background-color: white;
            color:${theme.colors.c1};
            `}

    padding-top: 60px;
    padding-bottom: 60px;
    padding-left: 40px;
    padding-right: 40px;

    strong {
        font-size: 1.2em;
        // font-weight: inherit;
    }
    p {
        width: 100%;
    }

    ${(props) =>
        props.reverse
            ? `
            .lp-side:first-child {
               // border: 2px solid green;
               // color:green!important;
                text-align:left;
            }
            .lp-side:last-child {
               // border: 2px solid yellow;
               // color:yellow!important;
                text-align:right;
            }
            `
            : `
            .lp-side:first-child {
               // border: 2px solid red;
               // color:red!important;
                text-align:right;
            }
            .lp-side:last-child {
               // border: 2px solid orange;
               // color:orange!important;
                text-align:left;
            }
            `}
`

/*


max 200     100
x    100     75
mil   0      50
min -200    0    










*/
