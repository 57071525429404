/* eslint-disable react/prop-types */
/* eslint-disable react/self-closing-comp */

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"

// import styled from 'styled-components'
// import Button from "react-bootstrap/Button"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
/*
 *	props:
 *		-	showMessage	:	boulean		- defaut: true
 *		-	message	:		String		- defaut: "Chargement..."
 *
 */
export default function ErrorMessage({ className = "", errorMessage = null }) {
    console.log("errorMessage", errorMessage)
    let userErrorMessage = null

    switch (errorMessage) {
        case "CANT_USE_CMZ_EDT":
            console.log("bbbbbbbbbb")
            userErrorMessage =
                "Vous n'êtes pas autorisé à utiliser cmzEDT. Avez vous reçu le mail pour valider votre inscription ?"
            break
        case "WRONG_PASSWORD":
            userErrorMessage = "Mauvais mot de passe."
            break
        case "UNKNOWN_USERNAME":
            userErrorMessage = "Nom d'utilisateur inconnu."
            break
        default:
            userErrorMessage = errorMessage
    }
    console.log("userErrorMessage", userErrorMessage)
    return (
        <div className={className + " text-danger text-center"}>
            <h4>Une erreur est survenue.</h4>
            {userErrorMessage && (
                <div className="message">{userErrorMessage}</div>
            )}
            {/*
            <div className="mt-3">Appuyez sur F5 pour actualiser la page.</div>
             */}
        </div>
    )
}
