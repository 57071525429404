/* eslint-disable react/no-array-index-key */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import AboutCardReducersItem from "./AboutCardReducersItem"
// import styled from "styled-components"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function AboutCardReducers() {
    const reducerItems = [
        {
            name: "uW (page)",
            reducers: [
                {
                    name: "fetchStart",
                    status: "DONE",
                },
                {
                    name: "fetchSuccess",
                    status: "DONE",
                },
                {
                    name: "fetchError",
                    status: "DONE",
                },
                {
                    name: "__[formMembre]__setFormMembre",
                    status: "DONE",
                },
                {
                    name: "__[formMembre]__updateUWStart",
                    status: "DONE",
                },
                {
                    name: "__[formMembre]__updateUWSuccess",
                    status: "DONE",
                },
                {
                    name: "__[formMembre]__createUWStart",
                    status: "DONE",
                },
                {
                    name: "__[formMembre]__createUWSuccess",
                    status: "DONE",
                },
                {
                    name: "__[formMembre]__setFormDeleteMembre",
                    status: "DONE",
                },
                {
                    name: "__[formMembre]__deleteUWStart",
                    status: "DONE",
                },
                {
                    name: "__[formMembre]__deleteUWSuccess",
                    status: "DONE",
                },
                {
                    name: "__[formMembre]__setFormAddUsersToEdtStatus",
                    status: "DONE",
                },
                {
                    name: "__[formMembre]__addUserToWorkspaceSuccess",
                    status: "DONE",
                },
                {
                    name: "__[formUWHoraire]__setFormHoraireMembre",
                    status: "DONE",
                },
                {
                    name: "__[formUWHoraire]__updateUWHoraireStart",
                    status: "DONE",
                },
                {
                    name: "__[formUWHoraire]__updateUWHoraireSuccess",
                    status: "DONE",
                },
                {
                    name: "__[formUWHoraire]__createUWHoraireStart",
                    status: "DONE",
                },
                {
                    name: "__[formUWHoraire]__createUWHoraireSuccess",
                    status: "DONE",
                },
                {
                    name: "toggleEdtLockedX",
                    status: "DONE",
                },
            ],
            actions: [
                {
                    name: "fetchWorkspaceForUW",
                    status: "DONE",
                },
                {
                    name: "toggleEdtLocked",
                    status: "DONE",
                },
                {
                    name: "setFormMembre",
                    status: "DONE",
                },
                {
                    name: "createOrUpdateUW",
                    status: "DONE",
                },
                {
                    name: "setFormDeleteMembre",
                    status: "DONE",
                },
                {
                    name: "deleteUW",
                    status: "DONE",
                },
                {
                    name: "setFormAddUsersToEdtStatus",
                    status: "DONE",
                },
                {
                    name: "addUserToWorkspace",
                    status: "DONE",
                },
            ],
        },
        {
            name: "etabShow (page)",
            reducers: [
                {
                    name: "fetchEtabStart",
                    status: "DONE",
                },
                {
                    name: "fetchEtabSuccess",
                    status: "DONE",
                },
                {
                    name: "fetchEtabError",
                    status: "DONE",
                },
                {
                    name: "__[formEtab]__updateEtabStart",
                    status: "PENDING",
                },
                {
                    name: "__[formEtab]__updateEtabSuccess",
                    status: "DONE",
                },
                {
                    name: "__[formEtab]__updateEtabError",
                    status: "PENDING",
                },
                {
                    name: "__[formWorkspace]__setFormWorkspaceX",
                    status: "DONE",
                },
                {
                    name: "__[formWorkspace]__createWorkspaceStart",
                    status: "DONE",
                },
                {
                    name: "__[formWorkspace]__createWorkspaceSuccess",
                    status: "DONE",
                },
                {
                    name: "__[formWorkspace]__createWorkspaceError",
                    status: "PENDING",
                },
                {
                    name: "xxxxxxxxxxxxxxxxxxxxx",
                    status: "DONE",
                },
                {
                    name: "xxxxxxxxxxxxxxxxxxxxx",
                    status: "DONE",
                },
            ],
            actions: [
                {
                    name: "fetchEtab",
                    status: "DONE",
                },
                {
                    name: "addEtabToMesEtabs",
                    status: "DONE",
                },
                {
                    name: "setFormWorkspace",
                    status: "DONE",
                },
                {
                    name: "createOrUpdateWorkspace",
                    status: "DONE",
                },
                {
                    name: "xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx",
                    status: "DONE",
                },
                {
                    name: "xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx",
                    status: "DONE",
                },
                {
                    name: "xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx",
                    status: "DONE",
                },
                {
                    name: "xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx",
                    status: "DONE",
                },
                {
                    name: "xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx",
                    status: "DONE",
                },
                {
                    name: "xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx",
                    status: "DONE",
                },
                {
                    name: "xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx",
                    status: "DONE",
                },
                {
                    name: "xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx",
                    status: "DONE",
                },
                {
                    name: "xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx",
                    status: "DONE",
                },
                {
                    name: "xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx",
                    status: "DONE",
                },
                {
                    name: "xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx",
                    status: "DONE",
                },
            ],
        },
        {
            name: "formMembre",
            actions: [
                {
                    name: "rrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr",
                    status: "DONE",
                },
                {
                    name: "rrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr",
                    status: "PENDING",
                },
            ],
            reducers: [
                {
                    name: "aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa",
                    status: "DONE",
                },
                {
                    name: "aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa",
                    status: "PENDING",
                },
            ],
        },
        {
            name: "etabUser",
            actions: [
                {
                    name: "rrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr",
                    status: "DONE",
                },
                {
                    name: "rrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr",
                    status: "PENDING",
                },
            ],
            reducers: [
                {
                    name: "aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa",
                    status: "DONE",
                },
                {
                    name: "aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa",
                    status: "PENDING",
                },
            ],
        },
        {
            name: "formUWHoraire",
            actions: [
                {
                    name: "rrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr",
                    status: "DONE",
                },
                {
                    name: "rrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr",
                    status: "PENDING",
                },
            ],
            reducers: [
                {
                    name: "aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa",
                    status: "DONE",
                },
                {
                    name: "aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa",
                    status: "PENDING",
                },
            ],
        },
        {
            name: "formWorkspace",
            actions: [
                {
                    name: "rrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr",
                    status: "DONE",
                },
                {
                    name: "rrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr",
                    status: "PENDING",
                },
            ],
            reducers: [
                {
                    name: "aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa",
                    status: "DONE",
                },
                {
                    name: "aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa",
                    status: "PENDING",
                },
            ],
        },
        {
            name: "formEtab",
            actions: [
                {
                    name: "rrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr",
                    status: "DONE",
                },
                {
                    name: "rrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr",
                    status: "PENDING",
                },
            ],
            reducers: [
                {
                    name: "aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa",
                    status: "DONE",
                },
                {
                    name: "aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa",
                    status: "PENDING",
                },
            ],
        },
        {
            name: "mesEtabs (page)",
            actions: [
                {
                    name: "rrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr",
                    status: "DONE",
                },
                {
                    name: "rrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr",
                    status: "PENDING",
                },
            ],
            reducers: [
                {
                    name: "aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa",
                    status: "DONE",
                },
                {
                    name: "aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa",
                    status: "PENDING",
                },
            ],
        },
        {
            name: "workspaceHoraires (page)",
            actions: [
                {
                    name: "rrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr",
                    status: "DONE",
                },
                {
                    name: "rrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr",
                    status: "PENDING",
                },
            ],
            reducers: [
                {
                    name: "aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa",
                    status: "DONE",
                },
                {
                    name: "aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa",
                    status: "PENDING",
                },
            ],
        },
    ]

    return (
        <div className="card border-primary">
            <h5 className="card-header bg-info">Reducers/Actions</h5>
            <div className="card-body">
                {reducerItems.map((item, i) => (
                    <div
                        key={"progress" + i}
                        item={item}
                        className="mb-1 col-4 "
                    >
                        {item.name}
                    </div>
                ))}
                <div className="card-text row">
                    {reducerItems.map((item, i) => (
                        <AboutCardReducersItem
                            key={"progress" + i}
                            item={item}
                            className="card border-primary mb-1 col-4 "
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS

// const StyledItem = styled.div``
