/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { LinkContainer } from "react-router-bootstrap"
import { NavDropdown } from "react-bootstrap"
import { FaQuestionCircle } from "react-icons/fa"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function DropdownAbout() {
    return (
        <NavDropdown
            title={
                <>
                    <FaQuestionCircle /> A Propos/Aide
                </>
            }
            id="basic-nav-dropdown"
            className="ms-auto"
        >
            <LinkContainer to="/about">
                <NavDropdown.Item>
                    <FaQuestionCircle /> A propos de cmzEdt
                </NavDropdown.Item>
            </LinkContainer>
            <LinkContainer to="/about/docs">
                <NavDropdown.Item>
                    <FaQuestionCircle /> Aide: Etapes de création d&apos;un EDT
                </NavDropdown.Item>
            </LinkContainer>{" "}
            <LinkContainer to="/about/faq">
                <NavDropdown.Item>
                    <FaQuestionCircle /> Aide: FAQ
                </NavDropdown.Item>
            </LinkContainer>
            {/*
            <LinkContainer to="/about/xxxxxxxxxxxx">
                <NavDropdown.Item disabled>
                    <FaQuestionCircle /> ChangeLog / Todo (historique des mises
                    à jour)
                </NavDropdown.Item>
            </LinkContainer>
            */}
            <LinkContainer to="/about/exemple/89">
                <NavDropdown.Item>
                    <FaQuestionCircle /> Exemple de postages
                </NavDropdown.Item>
            </LinkContainer>
            <LinkContainer to="/about/changelog">
                <NavDropdown.Item>
                    <FaQuestionCircle /> Hitorique des mises à jours
                </NavDropdown.Item>
            </LinkContainer>
            <NavDropdown.Divider />{" "}
            <a
                href="https://www.youtube.com/channel/UCKskN6Vjzs_5xC9_hRKjBCQ"
                target="_blank"
                rel="noreferrer"
                style={{
                    color: "black",
                    textDecoration: "none",
                    marginLeft: "16px",
                }}
            >
                <FaQuestionCircle /> Chaîne Youtube (tuto)
            </a>
            <NavDropdown.Item disabled>
                Contact: <b>cmzedt@zedixi.com</b>
            </NavDropdown.Item>
        </NavDropdown>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS
