// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { useAppSelector, useAppDispatch } from "utils/store"
import { useNavigate } from "react-router-dom"
import ZxModal from "ui/ZxModal"
import { ZForm } from "uiZ/zForm"
// import { closeFormEdt } from "features/etabs/utils/etabShowReducer"
import { formEdtClose, IEdtForm } from "features/edts/utils/edtFormReducer"
import {
    zApiEdtCreate,
    zApiEdtUpdate,
    zApiEdtDelete,
} from "features/edts/utils/apiEdt"
import EdtFormInner from "./EdtFormInner"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
type Props = {
    setEdt?: (_args: any) => any | null
}
EdtForm.defaultProps = {
    setEdt: null,
}
export default function EdtForm({ setEdt }: Props) {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { edtForm } = useAppSelector((state) => state.edtForm)

    const customValidate = (values: IEdtForm) => {
        let errors = {}

        if (values.nom.length < 4) {
            errors = {
                ...errors,
                nom: "Le nom doit contenir au moins 4 caractères !",
            }
        } else if (values.nom.length >= 30) {
            errors = {
                ...errors,
                nom: "Le nom doit contenir moins de 30 caractères !",
            }
        }

        return errors
    }

    if (!edtForm) {
        return <h1>!formEdt</h1>
    }

    const createSuccessFunction = (edtId: number) => {
        dispatch(formEdtClose())
        navigate("/edt/" + edtId)
    }

    const updateSuccessFunction = (result: any) => {
        if (setEdt) {
            setEdt(result.edt)
        }
    }
    const deleteSuccessFunction = (etabId: number) => {
        dispatch(formEdtClose())
        navigate("/etab/" + etabId)
    }

    console.log("edtForm", edtForm)

    return (
        <ZxModal styles={{ maxWidth: "460px" }}>
            <ZForm
                initialData={edtForm}
                customValidate={customValidate}
                closeForm={() => dispatch(formEdtClose())}
                // *** Create:
                createTitle="Ajouter un EDT"
                createFunction={(args: any) => zApiEdtCreate(args)}
                createSuccessFunction={(result: any) =>
                    createSuccessFunction(result.edt.id)
                }
                // *** Update:
                updateTitle={`Modifier l'EDT: ${edtForm?.nom}`}
                updateFunction={zApiEdtUpdate}
                updateSuccessFunction={
                    (result: any) => updateSuccessFunction(result) //  dispatch(updateEtabSuccess(result.etablissement))
                }
                // *** Delete:
                deleteTitle={`Supprimer l'EDT: ${edtForm?.nom}`}
                deleteConfirmText={`Je souhaite supprimer cet EDT, ainsi que son
                personnel, ses postes, ses postages...`}
                deleteFunction={() => zApiEdtDelete(edtForm.id || 0)}
                deleteSuccessFunction={(_result: any) =>
                    deleteSuccessFunction(edtForm.etablissement.id)
                }
            >
                <EdtFormInner />
            </ZForm>
        </ZxModal>
    )
}
