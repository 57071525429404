/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React, { useState } from "react"
import Button from "react-bootstrap/Button"
import { etabUpdateAfterMailPersonnel } from "features/etabs/utils/etabShowReducer"
import { useAppDispatch } from "utils/store"
import { IcoMail } from "styles/icones"
import Modal from "react-bootstrap/Modal"
import { apiEtabUserSendMail } from "../utils/apiPersonnels"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function ModalEtabUserMail({ eu, handleClose }) {
    const dispatch = useAppDispatch()
    const [isLoading, setIsLoading] = useState(false)

    function handleOnClick() {
        console.log("+++handleOnClick+++")
        setIsLoading(true)
        apiEtabUserSendMail(eu).then(
            (result) => {
                if (result.personnel) {
                    dispatch(etabUpdateAfterMailPersonnel(result.personnel))
                    handleClose()
                } else {
                    alert("erreur")
                    console.log("error:::result", result)
                }
                setIsLoading(false)
            },
            (error) => {
                // TODO
                console.log("error:::", error)
                setIsLoading(false)
            }
        )
    }

    return (
        <Modal show onHide={handleClose} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Envoyer un mail d&apos;invitation à {eu.pseudo}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Un e-mail sera envoyé à <b>{eu.pseudo}</b> (<u>{eu.email}</u>)
                pour l&apos;inviter à utiliser cette application.
                <br />
                <br />
                Il devra personnaliser son mot de passe et pourra ensuite se
                connecter pour consulter ses postages.
            </Modal.Body>

            <Modal.Footer>
                {isLoading ? (
                    <>Loading</>
                ) : (
                    <>
                        <Button
                            variant="secondary"
                            onClick={() => handleClose()}
                        >
                            Annuler
                        </Button>
                        <Button
                            variant="primary"
                            onClick={() => handleOnClick()}
                        >
                            <IcoMail /> Envoyer
                        </Button>
                    </>
                )}
            </Modal.Footer>
        </Modal>
    )
}
