/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React, { useState } from "react"
import styled from "styled-components"

import ZxModal from "ui/ZxModal"
import ZForm from "ui/ZForm/ZForm"
import ZFormInput from "ui/ZForm/ZFormInput"
import { apiPosteNew, apiPosteEdit, apiPosteDelete } from "./utils/apiPostes"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function PosteForm({
    poste,
    handleCancel,
    workspace,
    setWorkspace,
}) {
    const [isLoading, setIsLoading] = useState(false)

    return (
        <ZxModal styles={{ maxWidth: "500px" }}>
            <StyledForm>
                <ZForm
                    initialValues={poste}
                    handleSubmit={handleSubmit}
                    handleCancel={handleCancel}
                    handleDelete={handleDelete}
                    title={
                        poste.id
                            ? "Modifier le poste " + poste.nom
                            : "Ajouter un poste"
                    }
                    isLoading={isLoading}
                >
                    <ZFormInput label="Nom" name="nom" required />
                    <ZFormInput label="Groupe" name="groupe" />
                    <p>
                        Les &quot;groupes&quot; sont facultatifs et servent à
                        trier les postes.
                    </p>
                    <ZFormInput label="Couleur" type="color" name="couleur" />
                    <p>
                        Pour plus de lisibilité, évitez les couleurs trop
                        foncées.
                    </p>
                    <ZFormInput
                        label="Description"
                        // type="password"
                        name="description"
                    />
                </ZForm>
            </StyledForm>
        </ZxModal>
    )

    function handleSubmit(poste) {
        setIsLoading(true)
        if (poste.id === null) {
            apiPosteNew(workspace.id, poste).then(
                (result) => {
                    console.log("result:", result)
                    if (result.poste) {
                        setWorkspace({
                            ...workspace,
                            postes: [...workspace.postes, result.poste],
                        })
                        handleCancel()
                    }
                    setIsLoading(false)
                },
                (error) => {
                    // TODO
                    setIsLoading(false)
                    console.log("error")
                    console.log(error)
                }
            )
        } else {
            apiPosteEdit(poste).then(
                (result) => {
                    console.log("result:", result)
                    if (result.poste) {
                        const newPostes = workspace.postes.map((pst) =>
                            pst.id === result.poste.id ? result.poste : pst
                        )
                        setWorkspace({ ...workspace, postes: newPostes })
                        handleCancel()
                    }
                    setIsLoading(false)
                },
                (error) => {
                    // TODO
                    setIsLoading(false)
                    console.log("error")
                    console.log(error)
                }
            )
        }
    }

    function handleDelete(poste) {
        if (
            !window.confirm("Voulez-vous définitivement supprimer ce Poste ?")
        ) {
            return
        }
        console.log("poste", poste)
        setIsLoading(true)
        apiPosteDelete(poste.id).then(
            (result) => {
                if (result.reponse && result.reponse === "SUCCESS") {
                    const newPostes = workspace.postes.filter(
                        (pst) => pst.id !== poste.id
                    )
                    setWorkspace({ ...workspace, postes: newPostes })
                }
                setIsLoading(false)
                handleCancel()
            },
            (error) => {
                // TODO
                setIsLoading(false)
                console.log("error")
                console.log(error)
            }
        )
    }
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS

const StyledForm = styled.div``
