/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import Button from "react-bootstrap/Button"
import { IcoFalse } from "styles/icones"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function ButtonAnnuler({ onClick, className = "", ...props }) {
    return (
        <Button
            className={"btn-danger btn-sm " + className}
            onClick={onClick}
            title="Annuler"
            {...props}
        >
            <IcoFalse /> Annuler
        </Button>
    )
}
