/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { useAppSelector, useAppDispatch } from "utils/store"
import { NavDropdown } from "react-bootstrap"
import { logout } from "features/users/utils/connectedUserReducer"
import { IcoUser } from "styles/icones"
import { FaSignOutAlt } from "react-icons/fa"
import { useNavigate } from "react-router-dom"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function DropdownConnectedUser() {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { userId, username } = useAppSelector((state) => state.connectedUser)
    if (!userId) {
        return null
    }

    return (
        <NavDropdown
            title={
                <>
                    <IcoUser /> {username}
                </>
            }
            id="user-basic-nav-dropdown"
            className="ms-auto"
            align="end"
        >
            <NavDropdown.Item onClick={() => btLogoutClick()}>
                <FaSignOutAlt /> Déconnexion
            </NavDropdown.Item>
            <NavDropdown.Item href="#todo" disabled>
                <FaSignOutAlt /> Déconnecter de tous les appareils
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href={"/user/" + userId}>
                Mon profil
            </NavDropdown.Item>
        </NavDropdown>
    )

    function btLogoutClick() {
        dispatch(logout())
        navigate("/")
    }
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS
