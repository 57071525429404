// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { ZField } from "uiZ/zForm"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function SonnerieFormInner() {
    return (
        <div className="row col-12">
            <ZField
                autoFocus
                name="heure"
                label="Heure"
                type="time"
                // fieldClassName="col-6"
            />
            <ZField
                name="nom"
                label="Description (facultatif)"
                type="text"
                // hint='Pour les internat, vous pouvez mettre "00:00"'
                // fieldClassName="col-6"
            />
        </div>
    )
}
