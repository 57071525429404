/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React, { useState } from "react"
import Button from "react-bootstrap/Button"
import { IcoMail } from "styles/icones"
import ModalEtabUserMail from "./ModalEtabUserMail"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function ButtonEtabUserMail({
    eu,
    className = "",
    sendAgain = false,
}) {
    const [showModal, setShowModal] = useState(false)

    return (
        <>
            <Button
                className={`btn-primary btn-sm  ${className}`}
                onClick={() => setShowModal(true)}
                title="Ajouter un personnel"
            >
                <IcoMail />{" "}
                {sendAgain ? (
                    "Envoyer de nouveau"
                ) : (
                    <>
                        Envoyer une invitation à <u>{eu.email}</u>
                    </>
                )}
            </Button>
            {showModal && (
                <ModalEtabUserMail
                    eu={eu}
                    handleClose={() => setShowModal(false)}
                />
            )}
        </>
    )
}
