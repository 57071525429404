// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
// import { randomHexColorLight } from "utils/fonctions"
import { ZPage } from "uiZ/zPage"
import { Postes, apiFetchWorkspacePostes } from "features/postes"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

type Edt = {
    id: number | null
    nom: String
    groupe: String
    couleur: String
    description: String
    etablissementId: number
    etablissement: { id: number }
    postes: any
}
/*
type Poste = {
    id: null
    nom: string
    groupe: string
    couleur: string
    description: string
    etablissementId: number | undefined
}
*/
export default function PagePostes() {
    const edtId = Number(useParams().id) || 0
    if (edtId <= 0) {
        return <h1 className="text-danger">Erreur url</h1>
    }

    const [edt, setEdt] = useState<Edt | null>(null)
    const [fetchError, setFetchError] = useState(null)

    useEffect(() => {
        document.title = "cmzEDT: Postes"
        if (edtId > 0) {
            apiFetchWorkspacePostes(edtId).then(
                (result) => {
                    console.log("useEffect.apiFetchWorkspace.result", result)
                    if (result.edt) {
                        setEdt(result.edt)
                        document.title =
                            "cmzEDT: Postes (" + result.edt.nom + ")"
                    } else if (result.error) {
                        setFetchError(result.error)
                    }
                },
                (error) => {
                    console.log("error", error)
                }
            )
        }
    }, [edtId])

    return (
        <ZPage
            documentTitle="Postes"
            isLoading={edt === null}
            error={fetchError}
            edt={edt}
        >
            <Postes edt={edt} setEdt={setEdt} />
        </ZPage>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS
