// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
// import React from "react"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export const status = {
    pending: "PENDING",
    canceled: "CANCELED",
    processing: "PROCESSING",
    checking: "CHECKING",
    done: "DONE",
}

export const progressItemsPostages = [
    {
        page: "Postages",
        actions: [
            {
                title: "Afficher les postages",
                status: status.done,
            },
            {
                title: "Regler les filtres (jours, taille...)",
                status: status.checking,
            },
            {
                title: "Ajouter un postage",
                status: status.done,
            },
            {
                title: "Modifier un postage",
                status: status.done,
            },
            {
                title: "Supprimer un postage",
                status: status.done,
            },
            {
                title: "Cloner des postages ??",
                status: status.pending,
            },
        ],
    },
]
