// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
// import React from "react"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export const status = {
    pending: "PENDING",
    canceled: "CANCELED",
    processing: "PROCESSING",
    checking: "CHECKING",
    done: "DONE",
}

export const progressItemsEdtMembres = [
    {
        page: "Edt_Membres",
        actions: [
            {
                title: "Afficher les membres de l'EDT et leurs horaires",
                status: status.done,
            },
            {
                title: "Verrouiller/Déverouiller EDT",
                status: status.done,
            },
            {
                title: "Creer un membre (Ajouter un personel de l'étab à l'EDT)",
                content: "ok mais pb d'actualisation",
                status: status.done,
            },
            {
                title: "Modifier UW",
                status: status.done,
            },
            {
                title: "Supprimer UW",
                status: status.done,
            },
            {
                title: "Cloner un membres (cad un personnel de l'EDT) (avec horaires et postages) ??",
                status: status.pending,
            },
            {
                title: "Verifier que les horaires des AED ne dépassent pas des horaires de l'EDT.",
                status: status.pending,
            },
            {
                title: "Creer un UW Horaire",
                status: status.done,
            },
            {
                title: "Modifier UW Horaire",
                status: status.done,
            },
            {
                title: "Supprimer UW Horaire",
                status: status.done,
            },
            {
                title: "N'afficher que les jours ouvrés",
                status: status.done,
            },
            {
                title: "Afficher EDT (graphique) des horaires (sans les postages)",
                status: status.done,
            },
            {
                title: "formUWHoraire : Semaine Radio",
                status: status.done,
            },
            {
                title: "horaire membre : Mettre des contrainte ",
                content: "ex: travail au lycée le Lundi et le mercredi",
                status: status.pending,
            },
        ],
    },
]
