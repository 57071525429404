/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { IcoTrue, IcoFalse } from "styles/icones"
import styled from "styled-components"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function AboutCardReducersItem({ item, className }) {
    return (
        <StyledItem className={className}>
            <h4>{item.name}</h4>

            <h6>reducers:</h6>
            <ul>
                {item.reducers.map((reducer, r) => (
                    <StyledActionLi
                        key={"reducer" + r}
                        status={reducer.status}
                        className="mb-1 "
                    >
                        <div className="actionContent">
                            {reducer.status === "DONE" && (
                                <IcoTrue className="text-success me-2" />
                            )}
                            {reducer.status === "PENDING" && (
                                <IcoFalse className="text-danger me-2" />
                            )}
                            {reducer.name}
                        </div>
                    </StyledActionLi>
                ))}
            </ul>
            <h6>actions:</h6>
            <ul>
                {item.actions.map((action, a) => (
                    <StyledActionLi
                        key={"action" + a}
                        status={action.status}
                        className="mb-1 "
                    >
                        <div className="actionContent">
                            {action.status === "DONE" && (
                                <IcoTrue className="text-success me-2" />
                            )}
                            {action.status === "PENDING" && (
                                <IcoFalse className="text-danger me-2" />
                            )}
                            {action.name}
                        </div>
                    </StyledActionLi>
                ))}
            </ul>
        </StyledItem>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS

const StyledItem = styled.div`
    // background-color: yellow;
`

// eslint-disable-next-line no-unused-vars
const StyledActionLi = styled.li`
    // background-color: orange;
    ${(props) => props.status === "DONE" && `color:green;`}
    ${(props) => props.status === "PENDING" && `color:red;`}
    .actionName {
        font-size: 0.8em;
        padding-left: 4em;
    }
    .actionEndpoint {
        font-size: 0.8em;
        padding-left: 4em;
    }
`
