// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

const forceDev = true // ********* A modifier pour forcer le mode dev (en prod, dev=false)
const forceUseLocal = true // **** A modifier pour forcer l'utilisation de la BDD local (en useLocal, dev=false)
const forceDebugTimetable = false
export const modeVideo = false // **** A modifier pour supprimer des trucs

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export const debugTimetable =
    process.env.NODE_ENV === "production" ? false : forceDebugTimetable

export const dev = process.env.NODE_ENV === "production" ? false : forceDev

export const useLocal =
    process.env.NODE_ENV === "production" ? false : forceUseLocal

export const API_BASE_URL = useLocal
    ? "http://cmzapi.zedixi.local/apiedt"
    : "https://cmzapi.zedixi.com/apiedt"
