/* eslint-disable react/jsx-no-constructed-context-values */ // !!!!!!!!!!!!!!!!
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React, { useState /* useState, useRef,  useEffect */ } from "react"
import { ZPage } from "uiZ/zPage"
import LandingPage from "features/about/_landingPage2/LandingPage"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export const landingPageContext = React.createContext({
    currentVariant: "",
    updateCurrentVariant: (_s: string) => {},
})

export default function PageHomeLandingPage() {
    const [currentVariant, setCurrentVariant] = useState("light")

    const updateCurrentVariant = (str: string) => {
        // console.log("change :: " + currentVariant + " > " + str)
        if (str === "primary" || str === "light") {
            setCurrentVariant(str)
        }
    }

    console.log(currentVariant)

    return (
        <landingPageContext.Provider
            value={{ currentVariant, updateCurrentVariant }}
        >
            <ZPage
                documentTitle="Edition et consulation des Postages pour la vie scolaire"
                navHeaderVariant={
                    currentVariant === "primary" ? "toLight" : "toPrimary"
                }
            >
                <LandingPage />
            </ZPage>
        </landingPageContext.Provider>
    )
}
