/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
// import styled from "styled-components"

import Button from "react-bootstrap/Button"
import { dev } from "utils/constants"
import { ZPageSectionTodo, ZYoutube /* , SectionTodo */ } from "uiZ/zPage"
// import ButtonShowHelp from "features/timetable/options/ButtonShowHelp"
// import PostageForm from "features/postages/form/PostageForm"
// import UWHoraireForm from "features/membres/horairesMembres/UWHoraireForm"
import ZxModal from "ui/ZxModal"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
/*
 *  TimetableHeader
 *  ( JourSelect, date, zoom, Lock, (Bouton)Options )
 *
 *
 *
 */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
export default function TimetableModalOptions({ modeHoraires, handleClose }) {
    /*
    const { showOptions, modeHoraires, locked, modeDemo } = useAppSelector(
        (state) => state.timetable
    )
*/

    return (
        <ZxModal styles={{ maxWidth: "600px" }}>
            <div className="row col-12">
                <h2 className=" col-12">
                    Aide
                    <Button
                        onClick={handleClose}
                        variant="danger"
                        className="float-end"
                    >
                        Fermer
                    </Button>
                </h2>

                <div className="xxxxxxxxxxx">
                    - Cliquez sur le cadenas pour déverouiller.
                    <br />
                    {!modeHoraires && (
                        <>
                            - Dans le menu, sélectionnez un poste (et une
                            semaine).
                            <br />
                        </>
                    )}
                    - Passez la souris sur la grille et cliquez pour marquer
                    l&apos;heure de début.
                    <br />- Descendez jusqu&apos;à l&apos;heure de fin et
                    cliquez de nouveau.
                    <br />- Cliquez sur
                    {modeHoraires ? " l&apos;horaire" : " le postage"} que vous
                    venez de créer pour l&apos;enregistrer ou cliquez en dehors
                    pour annuler.
                    <br />
                    <br />
                </div>
                {!modeHoraires && (
                    <ZYoutube
                        src="https://www.youtube.com/embed/60yQvOqVMHs"
                        title="cmzEDT: Placez les postages"
                    />
                )}

                <ZPageSectionTodo className="col-12">
                    <h2>Améliorations à venir:</h2>
                    <ul>
                        <li>
                            <li>
                                Affichage horizontal des AED: Il y a quelques
                                bugs d'affichage, mais ça fonctionne.
                            </li>
                            <li>
                                Le dernier click pour enregistrer est-il
                                vraiment util ?
                            </li>
                        </li>
                    </ul>

                    {dev && (
                        <>
                            <h2>Dev:</h2>
                            <ul>
                                <li>
                                    Loading quand on change page postage/horaire
                                </li>
                                <li>
                                    postage &quot;aucun AED ne travail le lundi,
                                    ajoutez des horaires&quot;
                                </li>
                                <li>
                                    message si pas d&apos;horaire, de poste ou
                                    de membre
                                </li>
                                <li>
                                    Mode jour/nuit pour afficher les postages
                                    des internats (heure limite de bascule)
                                </li>
                                <li>afficher heure si pas de sonnerie</li>
                                <li>
                                    afficher le nom de l&apos;etab, de
                                    l&apos;edt J&apos;y et s&apos;il est inactif
                                </li>
                                <li>
                                    Header en flex (une seule ligne sur mobile)
                                </li>
                                <li>
                                    Pour les horaire: bt maximiser taille
                                    timetable
                                </li>
                                <li>
                                    ajouter 5 min aux horaires pour faciliter
                                    les placement aux bords (options)
                                </li>
                                <li>améliorer Line now (reRender)</li>
                                <li>
                                    Form edit postage : heureFin sup HeureDeb
                                    dans un alert (mettre message)
                                </li>
                            </ul>
                        </>
                    )}
                </ZPageSectionTodo>
            </div>
        </ZxModal>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS
/*
const StyledTimetableHeader = styled.div`
    // display: flex;
    // flex-flow: row;
    // background-color: green; // debug only
    margin: 0px;
    // padding: 5px; // debug only
    // flex: 0 1 auto;
`
*/
