// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { Button } from "react-bootstrap"
import { IcoEdit, IcoAdd } from "styles/icones"
import { FormContext } from "./ZForm"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function ZFormButtonSubmit() {
    return (
        <FormContext.Consumer>
            {({ formErrors, updateMode }) => (
                <Button
                    type="submit"
                    disabled={Object.keys(formErrors).length > 0}
                    className="float-end"
                    variant="success"
                >
                    {updateMode ? (
                        <>
                            <IcoEdit /> Modifier
                        </>
                    ) : (
                        <>
                            <IcoAdd /> Ajouter
                        </>
                    )}
                </Button>
            )}
        </FormContext.Consumer>
    )
}
