/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
// import styled from "styled-components"
import ReactToPrint from "react-to-print"

import Button from "react-bootstrap/Button"

// import ButtonShowHelp from "features/timetable/options/ButtonShowHelp"
// import PostageForm from "features/postages/form/PostageForm"
// import UWHoraireForm from "features/membres/horairesMembres/UWHoraireForm"
import ZxModal from "ui/ZxModal"
import { IcoPrint } from "styles/icones"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
/*
 *  TimetableHeader
 *  ( JourSelect, date, zoom, Lock, (Bouton)Options )
 *
 *
 *
 */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
export default function TimetableModalPrint({
    componentRefCurrent,
    setModalPrintIsOpen,
}) {
    return (
        <ZxModal styles={{ maxWidth: "600px" }}>
            <h2>Imprimer ou exporter en PDF</h2>

            <div className=" col-12 mt-4 mb-5">
                Cliquez sur le bouton "Imprimer"
                <br />
                <br />
                Dans la fenêtre qui s'ouvre, réglez les options suivantes:
                <br />- Destination : <b>Imprimante</b> ou <b>PDF</b>
                <br />- Mise en page : <b>Paysage</b> (recommandé) ou Portrait
                <br />- Dans &quot;<i>Plus de paramètres</i>&quot;, vous pouvez
                regler les <b>marges</b> (minimum) et décocher les{" "}
                <b>En-têtes et pieds de page</b>
                <br />
                <br />
                <p className="text-danger">
                    <b>IMPORTANT</b>
                    <br />
                    Si l'affichage n'est pas complet (à cause d'un trop grand
                    nombre d'AED):
                    <br />- Dans &quot;<i>Plus de paramètres</i>&quot;,
                    choisissez <b>Echelle personnalisée</b> et réduisez le
                    pourcentage jusqu'à ce que tous les AED soient affichés.
                </p>
                <div className=" col-12 pt-4 border-top border-primary">
                    Je vous recommande de faire une sauvegarde de vos EDT en
                    PDF. (Comme tout les serveurs, il peut arrivé que celui de
                    cmzEDT tombe en panne)
                </div>
            </div>
            <div className=" col-12">
                <Button
                    className=""
                    title="Fermer"
                    variant="danger"
                    onClick={() => setModalPrintIsOpen(false)}
                >
                    Fermer
                </Button>
                <ReactToPrint
                    trigger={() => functionTrigger()}
                    content={() => componentRefCurrent}
                />
            </div>
        </ZxModal>
    )
}

const functionTrigger = () => (
    <Button className=" float-end me-2 no-print" title="Imprimer">
        <IcoPrint /> Imprimer
    </Button>
)

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS
/*
const StyledTimetableHeader = styled.div`
    // display: flex;
    // flex-flow: row;
    // background-color: green; // debug only
    margin: 0px;
    // padding: 5px; // debug only
    // flex: 0 1 auto;
`
*/
