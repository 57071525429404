/* eslint-disable no-nested-ternary */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React, { useState } from "react"
import ZTableHeadItem from "./ZTableHeadItem "
import ZTableRow from "./ZTableRow"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

interface Props {
    className?: string
    columns: {
        name: string
        text: string
        cellHtml?: any
        cellClassName?: any
    }[]
    data: any[] | null
    // eslint-disable-next-line react/require-default-props
    funcRowClassName?: any
}

ZTable.defaultProps = {
    className: null, // "bg-secondary",
}

export default function ZTable({
    columns,
    data,
    funcRowClassName,
    className,
}: Props) {
    const [sortBy, setSortBy] = useState("")
    const [sortOrderAsc, setSortOrderAsc] = useState(true)
    const sortedData = sortData()

    return (
        <table className={className}>
            <thead>
                <tr>
                    {columns.map((col) => (
                        <ZTableHeadItem
                            key={"th-" + col.name}
                            col={col}
                            sortBy={sortBy}
                            sortOrderAsc={sortOrderAsc}
                            // eslint-disable-next-line react/jsx-no-bind
                            handleSortByClick={handleSortByClick}
                        />
                    ))}
                </tr>
            </thead>
            <tbody>
                {sortedData?.map((item: any) => (
                    <ZTableRow
                        key={item.id}
                        item={item}
                        columns={columns}
                        funcRowClassName={funcRowClassName}
                    />
                ))}
            </tbody>
        </table>
    )

    function sortData() {
        const sortFactor = sortOrderAsc ? 1 : -1
        return data?.slice().sort((a, b) => sortPair(sortFactor, a, b))
    }

    function sortPair(sortFactor: number, a: any, b: any) {
        /*
        if (Array.isArray(a[sortBy])) {
            console.log(sortBy + "+++++++++++IS ARRAY")
        }
        console.log(typeof a[sortBy])
        */
        return a[sortBy] > b[sortBy]
            ? sortFactor
            : b[sortBy] > a[sortBy]
            ? -sortFactor
            : 0
    }

    function handleSortByClick(colName: string) {
        console.log("handleSortByClick " + colName)
        if (colName === sortBy) {
            setSortOrderAsc(!sortOrderAsc)
        } else {
            setSortOrderAsc(true)
            setSortBy(colName)
        }
    }
}
