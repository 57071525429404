// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React /* , { useEffect } */ from "react"
import { useAppSelector, useAppDispatch } from "utils/store"
import { useParams } from "react-router-dom"
import {
    ZPage,
    ZPageHeader,
    ZPageTitle,
    ZPageContent,
    ZPageSectionHelp,
    ZYoutube,
} from "uiZ/zPage"
import { initTimetableReducer } from "features/timetable/utils/timetableReducer"
import { dev } from "utils/constants"

import Timetable from "features/timetable/Timetable"
import {
    HPSSectionTodo,
    // HPSSectionHelp,
    CardHoraires,
    Periodes,
    Sonneries,
    zApiFetchEdtHoraires,
    fetchHPSSuccess,
} from "features/horairesPeriodesSonneries"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function PageHoraires() {
    const dispatch = useAppDispatch()
    const edtId = Number(useParams().id) || 0
    if (edtId <= 0) {
        return <h1 className="text-danger">Erreur url</h1>
    }
    const { workspace } = useAppSelector((state) => state.hPS)

    const fetchSuccessFunction = (result: any) => {
        dispatch(fetchHPSSuccess(result.edt))
        dispatch(
            initTimetableReducer({
                edt: result.edt,
                postes: [],
                modeHoraires: true,
                modeHPS: true,
                demo: false,
            })
        )
    }

    return (
        <ZPage
            documentTitle="Horaires"
            edt={workspace}
            fetchFunction={() => zApiFetchEdtHoraires(edtId)}
            fetchSuccessFunction={(result: any) => fetchSuccessFunction(result)}
        >
            <ZPageHeader>
                <ZPageTitle>
                    Horaires, périodes et sonneries de l&apos;EDT
                </ZPageTitle>
            </ZPageHeader>

            <ZPageContent className="row col-12">
                {workspace && (
                    <>
                        <div className="mt-4 col-12 col-md-6 col-lg-4">
                            <CardHoraires />
                        </div>

                        <div className="mt-4 col-12 col-md-6 col-lg-3">
                            <Sonneries edtId={workspace.id} />
                        </div>

                        <div className="mt-4 col-12 col-md-6 col-lg-5">
                            <Periodes edtId={workspace.id} />
                        </div>
                    </>
                )}
                {/* 
                <HPSSectionHelp />
                
                */}
                {dev && <Timetable />}

                <ZPageSectionHelp className="col-12 col-md-6 p-3">
                    <ZYoutube
                        src="https://www.youtube.com/embed/H5h5Nev_g2Y"
                        title="cmzEDT: Définissez les horaires et les sonneries de l'EDT"
                    />
                </ZPageSectionHelp>

                <HPSSectionTodo />
            </ZPageContent>
        </ZPage>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS
