// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
// import styled from "styled-components"
// import { theme } from "styles/theme"

import LPSection from "./layout/LPSection"
import LPSide from "./layout/LPSide"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function SectionSimpleEtIntuitif() {
    return (
        <LPSection bgPrimary className="SectionSimpleEtIntuitif">
            <LPSide className="col-md-7 col-12">
                <h2>Simple et intuitif.</h2>
                <p>
                    Placez les horaires et les postages en les "peignant" sur la
                    grille avec la souris.
                </p>
            </LPSide>
            <LPSide className="col-md-5 col-12">
                <img
                    style={{ width: "100%" }}
                    src="img/landing/gif-postages3.gif"
                    alt="EDT"
                />
            </LPSide>
        </LPSection>
    )
}
