// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { ZField } from "uiZ/zForm"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function HoraireMembreFormInner() {
    return (
        <div className="row col-12">
            <ZField
                name="semaine"
                label="semaine --- radio"
                type="select"
                selectOptions={[
                    { value: "", text: "A & B" },
                    { value: "A", text: "A" },
                    { value: "B", text: "B" },
                ]}
            />
            <ZField
                autoFocus
                name="heureDebut"
                label="heureDebut"
                type="time"
            />
            <ZField autoFocus name="heureFin" label="heureFin" type="time" />
            <ZField name="infos" label="infos" type="text" />
        </div>
    )
}
