/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import Button from "react-bootstrap/Button"
import { formEdtOpenCreate } from "features/edts/utils/edtFormReducer"
import { FaPlus } from "react-icons/fa"
import { useAppDispatch } from "utils/store"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function ButtonEdtCreate({ etabId, className = "" }) {
    const dispatch = useAppDispatch()

    const handleClick = () => {
        dispatch(formEdtOpenCreate(etabId))
    }
    return (
        <Button
            className={`btn-primary btn-sm ${className}`}
            onClick={() => handleClick()}
            title="Ajouter un EDT"
        >
            <FaPlus /> Ajouter un EDT
        </Button>
    )
}
