// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import { createSlice } from "@reduxjs/toolkit"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export interface Etab {
    id: number | null
    nom: string
    genre: string | null
    ville: string | null
    fonctionUserCreator?: string | null
}
interface EtabForm {
    etab: Etab | null
}
const initialState: EtabForm = {
    etab: null,
}

// ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■
// Slice

const slice = createSlice({
    name: "etabForm",
    initialState,
    reducers: {
        setEtab: (state, action) => {
            state.etab = action.payload
        },
    },
})
export default slice.reducer

// ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■

// Actions
const { setEtab } = slice.actions

export const openFormEtabCreate = () => async (dispatch: any) => {
    dispatch(
        setEtab({
            id: null,
            nom: "",
            genre: "",
            ville: "",
            fonctionUserCreator: "",
        })
    )
}
export const openFormEtabUpdate = (etab: Etab) => async (dispatch: any) => {
    dispatch(setEtab(etab))
}

export const closeFormEtab = () => async (dispatch: any) => {
    dispatch(setEtab(null))
}
