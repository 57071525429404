/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { useAppSelector, useAppDispatch } from "utils/store"
import { getGroupesFromPostes } from "features/postes/utils/postesHelpers"
import { setFormPostage1stPoste } from "features/timetable/utils/timetableReducer"
import styled from "styled-components"
// import PostesGroupe from "features/postes/PostesGroupe"
// import styled from "styled-components"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function PostageFormPostes() {
    const { postes, formPostage1stPoste, isFormPostage1st } = useAppSelector(
        (state) => state.timetable
    )

    const dispatch = useAppDispatch()
    //
    if (!postes || postes.length === 0) {
        return (
            <h5 className="text-danger m-5">
                Vous devez d&apos;abord créer des postes !
            </h5>
        )
    }
    const groupes = getGroupesFromPostes(postes)
    return (
        <div>
            Poste:
            {isFormPostage1st && !formPostage1stPoste && (
                <div className="clignote text-danger">
                    <b>Sélectionnez un poste !</b>
                </div>
            )}
            <div className="groupes row col-12">
                {groupes &&
                    groupes.map((groupe) => (
                        <div key={`groupe-` + groupe.nom}>
                            {/* <h3>{groupe.nom}</h3> */}
                            {groupe.postes.map((poste) => (
                                <StyledPoste
                                    key={`poste-${poste.id}`}
                                    couleur={poste.couleur}
                                    className="btn-sm"
                                    selected={
                                        formPostage1stPoste?.id === poste.id &&
                                        "text-danger"
                                    }
                                    onClick={() => handlePosteClick(poste)}
                                >
                                    {poste.slug}
                                </StyledPoste>
                            ))}
                        </div>
                    ))}
            </div>
        </div>
    )

    function handlePosteClick(poste) {
        console.log(poste)
        dispatch(setFormPostage1stPoste(poste))
    }
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS

const StyledPoste = styled.div`
    border: 1px solid black;
    padding: 2px;
    font-size: 0.7em;
    margin: 1px;
    display: inline-block;
    background-color: ${(props) => props.couleur};

    ${(props) => props.selected && `font-size: 1.5em;`};

    &:hover {
        cursor: pointer;
        // color: darkorange;
        border: 1px solid blue;
        box-shadow: 2px 2px 2px black;
    }
`
