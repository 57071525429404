// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import { createSlice } from "@reduxjs/toolkit"
// import { apiWorkspaceCreate } from "features/edts/utils/apiEdt"
// import {apiPersonnelEdit,apiPersonnelCreate} from "features/personnels/utils/apiPersonnels"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export interface IPersonnel {
    id: number
    pseudo: string
    couleur: string | null
    user: { id: number; username: string }
    actif: boolean
}
interface IEdt {
    id: number | null
    nom: string
    description: string | null
    commentaire: string | null
    actif: boolean
}

interface IEtab {
    id: number
    nom: string
    slug: string
    ville?: string
    adresse?: string
    genre: string | null
    edts: Array<IEdt>
    personnels: Array<IPersonnel>
}

interface EtabState {
    etab: IEtab | null
    formPersonnel: null | IPersonnel
    // formEtabUserStatus: null | string // ???????????? "HIDDEN" | "---OPEN" | "LOADING" | "---SUCCESS" | "ERROR"
    personnelToDelete: IPersonnel | null
}

const initialState: EtabState = {
    etab: null,
    //  formEtabUserStatus: null,
    formPersonnel: null,
    personnelToDelete: null,
}

// ■   ■   ■   ■   ■   ■   ■   ■   ■   ■   ■   ■   ■   ■   ■   ■   ■   ■   ■

// ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■

const slice = createSlice({
    name: "etabShow",
    initialState,
    reducers: {
        // *==========================================  OK
        fetchEtabShowSuccess: (state, action) => {
            console.log("fetchEtabShowSuccess", action.payload)
            state.etab = action.payload
        },

        updateEtabSuccess: (state, action) => {
            console.log("action.payload", action.payload)
            state.etab = action.payload
        },

        // *==========================================  Personnel
        setFormPersonnel: (state, action) => {
            state.formPersonnel = action.payload
        },
        personnelCreateSuccess: (state, action) => {
            if (state.etab) {
                const newEtabUsers = [...state.etab.personnels, action.payload]

                state.etab.personnels = newEtabUsers
                state.formPersonnel = null
            }
        },
        personnelUpdateSuccess: (state, action) => {
            if (state.etab) {
                const newEtabUsers = state.etab.personnels.map((eu) =>
                    eu.id === action.payload.id ? action.payload : eu
                )

                state.etab.personnels = newEtabUsers
                state.formPersonnel = null
            }
        },

        personnelDeleteSuccess: (state, action) => {
            if (state.etab) {
                const newPersonnels = state.etab.personnels.filter(
                    (pers) => pers.id !== action.payload
                )

                state.etab.personnels = newPersonnels
                state.formPersonnel = null
            }
        },
        // *==========================================  wwwwwwwwwwwwwwwww
    },
})

export default slice.reducer

// ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■

export const {
    fetchEtabShowSuccess,
    updateEtabSuccess,
    setFormPersonnel,
    personnelCreateSuccess,
    personnelUpdateSuccess,
    personnelDeleteSuccess,
} = slice.actions

export const etabUpdateAfterMailPersonnel =
    (etabUser: IPersonnel) => async (dispatch: any) => {
        console.log("updateAfterMailPersonnel:", etabUser)
        dispatch(personnelUpdateSuccess(etabUser))
    }
