/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
// import styled from "styled-components";
import Button from "react-bootstrap/Button"
import { IcoEdit } from "styles/icones"
import { useAppDispatch } from "utils/store"
import { openFormEtabUpdate } from "features/etabs/utils/etabFormReducer"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function ButtonEtabEdit({ etab }) {
    const dispatch = useAppDispatch()
    // console.log("---etab", etab)
    return (
        <Button
            variant="warning"
            className="btn-sm m-1"
            title="Modifier cet établissement"
            onClick={() => dispatch(openFormEtabUpdate(etab))}
        >
            <IcoEdit />
        </Button>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS
