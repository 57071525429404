// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { Button } from "react-bootstrap"
import FilterGlobalContainerHeight from "features/timetable/options/FilterGlobalContainerHeight"
import { IcoWindowMaximize, IcoWindowRestore } from "styles/icones"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

type Props = {
    timetableFullWindow: boolean
    setTimetableFullWindow: (_b: boolean) => any
}

export default function HorairesMembresTitmetableWindowHeader({
    timetableFullWindow,
    setTimetableFullWindow,
}: Props) {
    return (
        <div className="col-12 text-light ">
            {timetableFullWindow ? (
                <div className="d-flex bg-primary pt-1 pb-1 ps-4 pe-4 float-end rounded-top">
                    <div>Réduire la fenêtre :</div>
                    <Button
                        className="btn-sm ms-4 pt-0 pb-0"
                        onClick={() => setTimetableFullWindow(false)}
                        variant="light"
                        title="Réduire"
                    >
                        <IcoWindowRestore />
                    </Button>
                </div>
            ) : (
                <div className="d-flex bg-primary p-1 float-end rounded-top">
                    <div>Taille de la fenêtre :</div>
                    <FilterGlobalContainerHeight />
                    <Button
                        className="btn-sm ms-4"
                        onClick={() => setTimetableFullWindow(true)}
                        variant="light"
                        title="Agrandir"
                    >
                        <IcoWindowMaximize />
                    </Button>
                </div>
            )}
        </div>
    )
}
