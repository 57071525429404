/* eslint-disable react/prop-types */
/**
 * TODO : gestion message d'erreur
 *
 *
 *
 *
 */

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React, { useContext } from "react"
import { FormContext } from "./ZForm"
// import './ZFormInput.css';
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function FormInput(props) {
    const { label, type = "text", name, required = null } = props

    const { form, handleFormChange } = useContext(FormContext)

    // pour eviter ::: Warning: `value` prop on `input` should not be null
    const value = type === "text" && form[name] === null ? "" : form[name]

    return (
        <div className="mb-2">
            <label htmlFor={name}>{label}</label>
            <input
                type={type}
                id={name}
                name={name}
                value={value}
                onChange={handleFormChange}
            />
            {required && value === "" && (
                <div className="text-danger">
                    Ce champ ne peut pas être vide.
                </div>
            )}
        </div>
    )
}
