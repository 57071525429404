/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { ZPageSectionHelp } from "uiZ/zPage"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function MesEtabsHelp({ className }) {
    return (
        <ZPageSectionHelp className={className}>
            <h2>Infos:</h2>
            <p>
                Si vous travaillez sur plusieurs établissements, vous pouvez
                parler de ce site à vos CPE.
                <br />
                Vous pouvez aussi créer un autre établissement et éditer
                vous-même l'EDT.{" "}
                <small>(Demandez quand meme l'autorisation à votre CPE)</small>
            </p>
        </ZPageSectionHelp>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS
