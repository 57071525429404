/* eslint-disable react/no-array-index-key */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
// import styled from "styled-components"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function AboutCardPersonas() {
    const peronaItems = [
        {
            content: (
                <>
                    <b>Carole</b> est CPE au collège <i>Jean-Claude Van Gogh</i>{" "}
                    (JCVG). Jusqu&apos;à maintenant elle gérait les postages des
                    ses AED sur excel, mais elle cherche un outil plus pratique.
                    &quot;Les ordinateurs, ce n&apos;est pas <i>son</i>{" "}
                    truc&quot;.
                </>
            ),
        },
        {
            content: (
                <>
                    <b>Mathilda</b> est AED au collège <i>JCVG</i>. Elle doit
                    régulièrement consulter cette application pour connaitre ses
                    postages.
                </>
            ),
        },
        {
            content: (
                <>
                    <b>Christian</b> est AED au collège <i>JCVG</i>. Comme il
                    est &quot;un peu geek&quot;, Carole lui a demandé
                    l&apos;aider à réaliser les postages sur cmz. (il est{" "}
                    <i>adminPostages</i> de l&apos;établissement)
                </>
            ),
        },
    ]

    return (
        <div className="card border-info">
            <h5 className="card-header bg-info">
                Personas <small>(profils utilisateurs)</small>
            </h5>
            <div className="card-body">
                <div className="card-text">
                    {peronaItems.map((item, i) => (
                        <div
                            key={"persona" + i}
                            className="card border-info mb-1"
                        >
                            <div className="card-body p-1">
                                <p className="card-text">{item.content}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS

// const StyledItem = styled.div``
