/* eslint-disable no-alert */
/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import styled from "styled-components"
import { useAppSelector, useAppDispatch } from "utils/store"
import {
    setPostageForm,
    createOrUpdatePostage,
    createOrUpdateHoraireMembre4Timetable,
} from "features/timetable/utils/timetableReducer"
// import { setFormHoraireMembre } from "features/membres/utils/uWReducer"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function CursorLine({
    mouseCurrent,
    mouseClick1,
    mouseClick2,
    membreId,
    membrePseudo,
}) {
    const {
        orientation,
        jour,
        modeHoraires,
        isFormPostage1st,
        formPostage1stSemaine,
        formPostage1stPoste,
    } = useAppSelector((state) => state.timetable)
    const dispatch = useAppDispatch()

    if (!mouseCurrent && !mouseClick1 && !mouseClick2) {
        return null
    }

    if (!modeHoraires && isFormPostage1st && !formPostage1stPoste) {
        return null
    }

    //    console.log(mouseCurrent)

    // CURSEUR LIGNE (pas de click1)
    if (mouseCurrent && !mouseClick1 && !mouseClick2) {
        return (
            <StyledItem
                orientation={orientation}
                semaine={formPostage1stSemaine}
                percent={mouseCurrent.percent}
                hauteur={0}
                showText
            >
                {mouseCurrent.heureStr}

                {formPostage1stPoste && (
                    <>
                        <br />
                        {formPostage1stPoste.slug}
                    </>
                )}
            </StyledItem>
        )
    }
    // CURSEUR RECTANGLE (click1+mouseCurrent    ou  click1+click2)
    if (mouseClick1) {
        const secondClick = mouseClick2 || mouseCurrent
        const hauteur = secondClick.percent - mouseClick1.percent

        return (
            <StyledItem
                orientation={orientation}
                percent={mouseClick1.percent}
                hauteur={hauteur}
                showText
                onClick={() => handleClick()}
                finished={mouseClick2 !== null}
                modeHoraires={modeHoraires}
                semaine={formPostage1stSemaine}
                bgColor={formPostage1stPoste?.couleur || null}
            >
                {mouseClick1.heureStr}
                <br />
                {secondClick.heureStr}
                {mouseClick2 !== null && (
                    <small>
                        <br />
                        Cliquez pour ajouter un{" "}
                        {modeHoraires ? "horaire" : "postage"}
                        <br />
                        {formPostage1stPoste && (
                            <b>{formPostage1stPoste.slug}</b>
                        )}
                    </small>
                )}
            </StyledItem>
        )
    }

    function handleClick() {
        if (mouseClick1 && mouseClick2) {
            // alert(                `Ajouter un postage à #${userWorkspaceId} le ${jour} de ${mouseClick1.heureStr} à ${mouseClick2.heureStr}`            )

            if (!modeHoraires) {
                if (formPostage1stPoste) {
                    dispatch(
                        createOrUpdatePostage({
                            id: null,
                            heureDebut: mouseClick1.heureStr,
                            heureFin: mouseClick2.heureStr,
                            jour,
                            membreId,
                            semaine: formPostage1stSemaine,
                            posteId: formPostage1stPoste.id,
                        })
                    )
                } else {
                    dispatch(
                        setPostageForm({
                            id: null,
                            heureDebut: mouseClick1.heureStr,
                            heureFin: mouseClick2.heureStr,
                            jour,
                            membreId,
                            posteId: null,
                            semaine: null,
                        })
                    )
                }
            } else {
                // alert("Placer un horaire")
                const newHoraire = {
                    id: null,
                    uw: null, // /////////////////////////////////////////////
                    jour,
                    heureDebut: mouseClick1.heureStr,
                    heureFin: mouseClick2.heureStr,
                    semaine: formPostage1stSemaine,
                    membreId,
                    membrePseudo,
                }
                //   dispatch(setFormHoraireMembre(newHoraire))

                dispatch(createOrUpdateHoraireMembre4Timetable(newHoraire))
            }
        }
    }
}

const StyledItem = styled.div`
    // background-color: blue;
    position: absolute;
    font-size: 0.9em;
    margin: 0%;
    padding: 2px;
    z-index: 2300;
    text-align: center;

    ${(props) =>
        props.orientation === "HORIZONTAL"
            ? `
			    left: ${props.percent}%;
			    height: 100%;
                width: ${props.hauteur + "%"};
			     border-left: 4px dashed red;

                 ${
                     props.hauteur > 0
                         ? `
                            border: 4px dashed red;
                            animation: 1s linear itemAnimation infinite;
                            background-color:${
                                props.modeHoraires
                                    ? "yellow"
                                    : props.bgColor || "#70d4ff"
                            };

                    `
                         : `border-left: 4px dashed red;`
                 }
			`
            : `
			    top: ${props.percent}%;
			    width: ${props.semaine ? `45%` : `90%`};
                margin-left:  ${
                    props.semaine === "B" ? `50%` : `5%`
                };                
                height: ${props.hauteur + "%"};
			
                ${
                    props.hauteur > 0
                        ? `
                            border: 4px dashed red;
                            animation: 1s linear itemAnimation infinite;
                            background-color:${
                                props.modeHoraires
                                    ? "yellow"
                                    : props.bgColor || "#70d4ff"
                            };

                    `
                        : `border-top: 4px dashed red;`
                }
			`}

    ${(props) =>
        props.finished > 0 &&
        `
		    border-color: blue;
            cursor: pointer;
		`}




    @keyframes itemAnimation {
        0% {
            opacity: 1;
        }
        50% {
            opacity: 0.5;
        }
        100% {
            opacity: 1;
        }
    }
`
