/* eslint-disable react/no-unstable-nested-components */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"

import LogsPeriode from "./LogsPeriode"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

interface User {
    id: number
    username: string
}

interface Log {
    id: number
    createdAt: string
    event: string
    user?: User
}

type Props = {
    logs: Log[]
}
export default function DashboardLogsLastDays({ logs }: Props) {
    const minDate = "2022/09/08 - 00:00"
    const maxDate = "2022/09/08 - 23:59"

    const filteredLogs = logs.filter(
        (log) => log.createdAt >= minDate && log.createdAt <= maxDate // && log.user?.id !== 1 && log.user?.id !== 4
    )

    const differentUsersIds = filteredLogs
        .map((item) => item.user?.id)
        .filter((value, index, self) => index && self.indexOf(value) === index) // "index &&...." pour enlever les logs sans user (loginFail)
    // console.log("differentUsersIds", differentUsersIds)

    //  const now = new Date()
    // const current = now.getHours() + ":" + now.getMinutes()

    return (
        <>
            <h2>Logs: Les 6 derniers jours</h2>

            <div>
                <b>{filteredLogs.length}</b> logs sur la période
            </div>
            <div>
                <b>{differentUsersIds.length}</b> utilisateurs differents sur la
                période
            </div>
            <br />
            <br />

            <div className="row bg-info p-2">
                <h3>Derniers jours:</h3>
                <LogsPeriode
                    logs={logs}
                    minDate="2022/09/08 - 00:00"
                    maxDate="2022/09/08 - 23:59"
                    className=""
                />
                <LogsPeriode
                    logs={logs}
                    minDate="2022/09/09 - 00:00"
                    maxDate="2022/09/09 - 23:59"
                    className=""
                />
                <LogsPeriode
                    logs={logs}
                    minDate="2022/09/10 - 00:00"
                    maxDate="2022/09/10 - 23:59"
                    className=""
                />
                <LogsPeriode
                    logs={logs}
                    minDate="2022/09/11 - 00:00"
                    maxDate="2022/09/11 - 23:59"
                    className=""
                />
                <LogsPeriode
                    logs={logs}
                    minDate="2022/09/12 - 00:00"
                    maxDate="2022/09/12 - 23:59"
                    className=""
                />
                <LogsPeriode
                    logs={logs}
                    minDate="2022/09/13 - 00:00"
                    maxDate="2022/09/13 - 23:59"
                    className=""
                />
            </div>
        </>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS
