/* eslint-disable no-nested-ternary */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { NavLink } from "react-router-dom"
import styled from "styled-components"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
// ** Quand unxxxxxxxxxxxxxx
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

type Props = {
    className?: string
}

ButtonNavLinkRegister.defaultProps = { className: "" }
export function ButtonNavLinkRegister({ className }: Props) {
    return (
        <StyledButtonNavlink to="/register" className={className}>
            Inscription
        </StyledButtonNavlink>
    )
}

ButtonNavLinkLogin.defaultProps = { className: "" }
export function ButtonNavLinkLogin({ className }: Props) {
    return (
        <StyledButtonNavlink to="/login" className={className}>
            Connexion
        </StyledButtonNavlink>
    )
}

ButtonNavLinkPasswordForfotten.defaultProps = { className: "" }
export function ButtonNavLinkPasswordForfotten({ className }: Props) {
    return (
        <StyledButtonNavlink to="/password_forgotten" className={className}>
            Mot de passe oublié ?
        </StyledButtonNavlink>
    )
}

// ***************************************************************

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS

const StyledButtonNavlink = styled(NavLink)``
