/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { NavLink } from "react-router-dom"
import { ZPageSection } from "uiZ/zPage"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function EdtShow({ edt }) {
    return (
        <>
            <ZPageSection className="col-lg-6 col-12">
                <div>
                    <b>Nom : </b> {edt.nom}
                </div>
                <div>
                    <b>Etablissement : </b>
                    {edt.etablissement.nom}
                </div>
                <div>
                    <b>Description : </b>
                    {edt.description ? edt.description : "---"}
                </div>
                <div>
                    <b>commentaire : </b>
                    {edt.commentaire ? edt.commentaire : "---"}
                </div>
                <div>
                    <b>nbreHeuresHebdoTempsPlein : </b>
                    {edt.nbreHeuresHebdoTempsPlein !== null
                        ? edt.nbreHeuresHebdoTempsPlein
                        : "---"}
                </div>
                <div>
                    <b>du </b>
                    {edt.dateDebut ? edt.dateDebut : "---"}
                    <b> au </b>
                    {edt.dateFin ? edt.dateFin : "---"}
                </div>
                <div>
                    <b>Actif: </b> {edt.actif ? "Oui" : "Non"}
                </div>
                <div>
                    <b>Publié: </b> {edt.isPublished ? "Oui" : "Non"}
                </div>
                <div>
                    <b>Internat: </b> {edt.isInternat ? "Oui" : "Non"}
                </div>
            </ZPageSection>

            <ZPageSection className="col-lg-6 col-12">
                Vous avez créé l&apos;EDT &quot;<b>{edt.nom}</b>&quot;. Vous
                pouvez maintenant:
                <br />
                <br />
                <br />- Definir les{" "}
                <NavLink to={"/edt/" + edt.id + "/horaires"}>
                    horaires, sonneries et périodes.
                </NavLink>{" "}
                de l&apos;EDT pour chaque jour de la semaine.
                <br />
                <br />- Ajouter le{" "}
                <NavLink to={"/edt/" + edt.id + "/membres"}>
                    personnel
                </NavLink>{" "}
                de votre établissement à cet EDT et definir{" "}
                <NavLink to={"/edt/" + edt.id + "/membres"}>
                    le nombre d&apos;heures hebdomadaire et les horaires de
                    travail
                </NavLink>{" "}
                de chaque AED.
                <br />
                <br />- Créer des{" "}
                <NavLink to={"/edt/" + edt.id + "/postes"}>postes</NavLink> (ex:
                Vie Scolaires, Portail, Etude...)
                <br />
                <br />- Placer les{" "}
                <NavLink to={"/edt/" + edt.id + "/postages"}>
                    postages
                </NavLink>{" "}
                des AED (ex: Alice est en Etude le Lundi de 8h05 à 8h55)
            </ZPageSection>
        </>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS
