/* eslint-disable import/prefer-default-export */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unneeded-ternary */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

import { API_BASE_URL } from "utils/constants"
import { ZErrorInterface } from "uiZ/zError"
import { requestOptionsHeaders } from "."

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export function errorManager(reponse: any, requierdFields = []) {
    console.log("requierdFields", requierdFields)
    console.log("errorManager...reponse", reponse)
    let zError: ZErrorInterface

    if (reponse.status && reponse.detail) {
        zError = {
            status: reponse.status,
            detail: reponse.detail,
        }
        console.log("❌ errorManager - withStatusAndDetail: ", zError)
    } else if (reponse.apiError) {
        zError = {
            userMessage: reponse.apiError,
            devMessage: reponse.apiError,
        }
        console.log("❌ errorManager - apiError: ", zError)
    } else if (reponse.error) {
        zError = {
            userMessage: reponse.error,
            devMessage: reponse.error,
        }
        console.log("❌ errorManager - apiError: ", zError)
    } else if (reponse.apixError) {
        zError = {
            userMessage: reponse.apixError,
            devMessage: reponse.apixError,
        }
        console.log("❌ errorManager - apixError: ", zError)
    } else {
        zError = {
            userMessage: "Erreur Inconnu",
        }
    }
    return zError
}

export async function customApiFetchNOOOOTTTTWOOORKINGGG(
    urlShort: string,
    methode: string,
    elements: string[],
    body: any = null
) {
    const url = API_BASE_URL + urlShort

    let data: [] | null = null
    let error: any | null = null

    const requestOptions = {
        method: methode,
        headers: requestOptionsHeaders(),
        body: body ? JSON.stringify(body) : null,
    }

    console.log("🟨 customApiFetch")
    console.log("➤➤ url:", url)

    fetch(url, requestOptions)
        .then((response) => response.json())
        .then((response) => {
            console.log("➤➤ response:", response)
            const missingElements = [] as string[]
            elements.forEach((elem) => {
                if (!response[elem]) {
                    missingElements.push(elem)
                }
            })
            if (missingElements.length === 0) {
                data = response
                console.log("✔️ SUCCESS")
                console.log("data", data)
            } else {
                console.log("❌ missingElements", missingElements)

                const code = response.status || null
                const debugMessage = response.detail || null
                const userMessage = null // "Une erreur " + response.status + " est survenue.",

                error = { code, debugMessage, userMessage }
            }
            console.groupEnd()
        })
    console.log("return data", data)
    return { data, error }
}
