// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { ZField } from "uiZ/zForm"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function HoraireFormInner() {
    return (
        <div className="row col-12">
            {/* 
            <ZField
                autoFocus
                name="jour"
                label="Jour"
                type="text"
                errors={errors}
            />
            */}
            <ZField
                autoFocus
                name="heureDebut"
                label="Début"
                type="time"
                fieldClassName="col-6"
            />
            <ZField
                name="heureFin"
                label="Fin"
                type="time"
                // hint='Pour les internat, vous pouvez mettre "00:00"'
                fieldClassName="col-6"
            />
        </div>
    )
}
