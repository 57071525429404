/* eslint-disable react/no-unstable-nested-components */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React, { useState } from "react"
import { ZPageContent, ZPageSection } from "uiZ/zPage"
import { Button } from "react-bootstrap"
import DashboardUsers from "features/users/DashboardUsers"
import DashboardEtabs from "features/etabs/DashboardEtabs"
import DashboardLogs from "../Logs/DashboardLogs"
import DashboardLogsOld from "../Logs/DashboardLogsOld"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

type ModeDashboard = "LOGS" | "LOGS_OLD" | "USERS" | "ETAB"
type Props = {
    users: []
    etabs: []
    logs: []
}

export default function AdminDashboard({ users, etabs, logs }: Props) {
    const [mode, setMode] = useState<ModeDashboard>("LOGS")
    return (
        <ZPageContent>
            <ZPageSection>
                <Button
                    onClick={() => setMode("LOGS")}
                    variant={mode === "LOGS" ? "primary" : "secondary"}
                >
                    LOGS
                </Button>{" "}
                <Button
                    onClick={() => setMode("LOGS_OLD")}
                    variant={mode === "LOGS_OLD" ? "primary" : "secondary"}
                >
                    LOGS_OLD
                </Button>{" "}
                <Button
                    onClick={() => setMode("USERS")}
                    variant={mode === "USERS" ? "primary" : "secondary"}
                >
                    USERS
                </Button>{" "}
                <Button
                    onClick={() => setMode("ETAB")}
                    variant={mode === "ETAB" ? "primary" : "secondary"}
                >
                    ETAB
                </Button>
            </ZPageSection>
            {mode === "ETAB" && <DashboardEtabs etabs={etabs} />}
            {mode === "USERS" && <DashboardUsers users={users} />}
            {mode === "LOGS" && <DashboardLogs logs={logs} />}
            {mode === "LOGS_OLD" && <DashboardLogsOld users={users} />}
        </ZPageContent>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS
