/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import styled from "styled-components"
import { useAppSelector } from "utils/store"
// import { Textfit } from 'react-textfit';
// import { useFitText } from "helpers/useFitText"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function LayoutItem({
    heureDebut,
    heureFin,
    styles = {},
    title,
    onClick,
    onMouseMove = null,
    onMouseLeave = null,
    isLoading = false,
    className = null,
    children,
}) {
    const { orientation, startMFM, endMFM, durationInMinutes } = useAppSelector(
        (state) => state.timetable
    )

    const startHourMFM = calcMinutesFromMidnight(heureDebut)
    const endHourMFM = calcMinutesFromMidnight(heureFin)

    const startPercent = calcPercentDebut(startHourMFM)
    let durationPercent = calcPercentDuree(startHourMFM, endHourMFM)

    // console.log("LayoutItem", startPercent)
    // const { fontSize, ref } = useFitText(50,150);

    if (startHourMFM > endMFM) {
        return null
    }

    if (startPercent + durationPercent > 100) {
        durationPercent = 100 - startPercent
    }

    return (
        <StyledLayoutItem
            orientation={orientation}
            startPercent={startPercent}
            durationPercent={durationPercent}
            title={title}
            isLoading={isLoading}
            //  ref={ref} style={{...styles, fontSize  }}
            style={styles}
            onClick={onClick}
            onMouseMove={onMouseMove}
            onMouseLeave={onMouseLeave}
            className={className}
        >
            {/* fontSize */}
            {children}
        </StyledLayoutItem>
    )

    function calcPercentDebut(heureMFM) {
        const percent = ((heureMFM - startMFM) * 100) / durationInMinutes
        return percent
    }

    function calcPercentDuree(debutMFM, finMFM) {
        const percent = ((finMFM - debutMFM) * 100) / durationInMinutes
        return percent
    }

    /**
     *	Renvoi le nombre de minutes depuis minuit à partir d'une heure en string
     *	"08:50"		==>		8x60+50
     */
    function calcMinutesFromMidnight(heureStr) {
        if (!heureStr) {
            return 0
        }
        const h = parseInt(heureStr.split(":")[0], 10)
        const m = parseInt(heureStr.split(":")[1], 10)
        const minutesFromMidnight = h * 60 + m
        return minutesFromMidnight
    }
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS

const StyledLayoutItem = styled.div`
    border: 1px solid black; /*TEMP*/
    font-size: 0.6em;
    // z-index: 800;
    // background-color: rgba(200, 100, 50, 0.1) !important;

    .loadingText {
        font-weight: bold;
        font-size: 2em;
    }

    &:hover {
        overflow: visible;
        // z-index: 999;
    }

    ${(props) =>
        props.isLoading && `animation: 500ms linear loadingAnimation infinite;`}

    ${(props) =>
        props.orientation === "HORIZONTAL"
            ? `
                position: absolute;
                top:0px;
                overflow: hidden;
                left: ${props.startPercent + "%"};
                width:  ${props.durationPercent + "%"};
                height: 100%;
            `
            : `	
                position: absolute;
                overflow: hidden;
                top:  ${props.startPercent + "%"};
                height:  ${props.durationPercent + "%"};
                width: 100%;
            `}
 


    
    @keyframes loadingAnimation {
        0% {
            border: 5px dashed red;
        }
        50% {
            border: 5px dashed blue;
        }
        100% {
            border: 5px dashed red;
        }
    }
`
