// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { ZField } from "uiZ/zForm"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function PersonnelFormInner() {
    return (
        <div className="row col-12">
            <ZField autoFocus name="pseudo" label="Pseudo" type="text" />

            <ZField
                name="email"
                label="Adresse email (facultatif)"
                type="text"
            />
            <ZField
                name="fonction"
                label="fonction"
                type="select"
                selectOptions={[
                    { value: null, text: "- Indéterminée -" },
                    { value: "AED", text: "AED" },
                    { value: "SC", text: "Service Civique" },
                    { value: "CPE", text: "CPE" },
                    { value: "PR", text: "Pincipal(e)" },
                    { value: "PR-ADJ", text: "Pincipal(e) adjoint(e)" },
                    { value: "DIR", text: "Directeur/Directrice" },
                ]}
            />
            <ZField
                name="couleur"
                label="couleur : "
                type="color"
                fieldStyle="SIMPLE"
            />
            <ZField
                name="isActif"
                label="Actif"
                type="checkbox"
                hint="Désactivez le personnel s'il a quitté
                            l'établissement."
                fieldStyle="SWITCH"
            />
            <ZField
                name="isAdminEtablissement"
                label="Admin Etab"
                type="checkbox"
                hint="Peut modifier ou supprimer les infos de cet établissement"
                fieldStyle="SWITCH"
            />
        </div>
    )
}
