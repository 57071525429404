// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import styled from "styled-components"
import HeaderMenuPublic, {
    navHeaderPublicHeight,
} from "ui/headerMenu/HeaderMenuPublic"

import {
    ButtonNavLinkRegister,
    ButtonNavLinkPasswordForfotten,
} from "features/authentification/common/buttonsNavLink"
import FormLogin from "features/authentification/_login/FormLogin"
// import Presentation from "features/authentification/_login/Presentation"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function PageLogin() {
    return (
        <StyledPageLogin className="row col-12 m-1">
            <HeaderMenuPublic variant="primary" />

            <div className="row col-12 ">
                {/* ** LEFT ** */}
                <div className="left-side row col-md-12 col-12">
                    <StyledFormLogin>
                        <h1 className="app-title mb-4">cmzEDT.zedixi.com</h1>
                        <img
                            className="app-logo mb-4"
                            alt="cmzEDT"
                            src="/img/app-logo/app-logo-512.png"
                        />
                        <FormLogin />
                        <div className="mt-4">
                            <ButtonNavLinkRegister />
                        </div>
                        <div className="mt-4">
                            <ButtonNavLinkPasswordForfotten />
                        </div>
                    </StyledFormLogin>
                </div>
                {/* ** RIGHT ** */}
                {/* 
                <div className="right-side  col-md-6 col-12">
                    <Presentation />
                </div>
                */}
            </div>
        </StyledPageLogin>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS

const StyledPageLogin = styled.div`
    padding-top: ${navHeaderPublicHeight}px; // !!!  pour le header public
    /*color: $C1d1 !important;*/
    // background-color: red;
    margin: 0 auto;

    .left-side {
        //background-color: cyan;
    }
    .right-side {
        //background-color: lime;
        margin: auto;
    }
`
const StyledFormLogin = styled.div`
    /*color: $C1d1 !important;*/
    text-align: center;
    margin: 0 auto;

    @media all and (min-width: 320px) {
        padding: 60px 0;
        max-width: 320px;
        .app-title {
            font-size: 1.5em;
        }
        .app-logo {
            width: 150px;
        }
    }

    @media all and (max-width: 319px) {
        padding: 10px 0;
        .app-title {
            font-size: 1.1em;
        }
        .app-logo {
            width: 60%;
        }
    }
`
