// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

import React, { useState, useEffect } from "react"
import { FaBan } from "react-icons/fa"
import { ZPage } from "uiZ/zPage"
import { apiFetchAdminUsers } from "../features/users/utils/apiUsers"

import UsersAdminTable from "../features/users/_pageUsersAdmin/UsersAdminTable"
import FormUser from "../features/users/crud/FormUser"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function PageUsersAdmin() {
    // const [formEditUserIsOpen, setFormEditUserIsOpen] = useState(false)
    const [users, setUsers] = useState(null)
    const [selectedUser, setSelectedUser] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const [fetchError, setFetchError] = useState(null)

    useEffect(() => {
        document.title = "cmzEDT: Postes"
    }, [])

    useEffect(() => {
        setIsLoading(true)
        apiFetchAdminUsers().then(
            (result) => {
                console.log("useEffect.apiFetchAdminUsers.result", result)
                if (result.users) {
                    setUsers(result.users)
                } else if (result.error) {
                    setFetchError(result.error)
                }
                setIsLoading(false)
            },
            (error) => {
                setIsLoading(false)
                setFetchError(error)
                console.log("error", error)
            }
        )
    }, [])

    return (
        <ZPage
            documentTitle="Admin Users"
            isLoading={isLoading}
            error={fetchError}
        >
            {selectedUser && (
                <FormUser
                    user={selectedUser}
                    handleCloseForm={() => setSelectedUser(null)}
                    /* setWlSerie={setWlSerie} */
                />
            )}

            <div className="page-header row col-12">
                <div className="page-title">
                    <FaBan /> Admininistration des utilisateurs
                </div>
            </div>

            <div className="page-content row col-12">
                {users && (
                    <UsersAdminTable
                        users={users}
                        handleAddUserClick={() =>
                            alert("PageUsersAdmin.handleAddUserClick")
                        }
                        setFormPassword={() =>
                            alert("PageUsersAdmin.setFormPassword")
                        }
                        setSelectedUser={setSelectedUser}
                    />
                )}
            </div>
        </ZPage>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS
