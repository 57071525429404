/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import Button from "react-bootstrap/Button"
import { FaPlus } from "react-icons/fa"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function ButtonCreateUser({ handleAddUserClick }) {
    return (
        <Button
            className="btn-primary btn-sm"
            onClick={() => handleAddUserClick()}
            title="Ajouter un amis"
        >
            <FaPlus /> Ajouter un amis
        </Button>
    )
}
