/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import styled from "styled-components"
import { theme } from "styles/theme"
// import { useState } from 'react'
// import Button from 'react-bootstrap/Button';
// import FormHoraireEtab from './FormHoraireEtab';
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function SonnerieItem({ sonnerie, setFormSonnerie }) {
    return (
        <StyledItem
            className="sonnerie row col-12"
            title="Modifier/supprimer cette sonnerie"
            role="button"
            onClick={() => setFormSonnerie(sonnerie)}
        >
            <div className="col-3">{sonnerie.heure}</div>
            <div className="nom col-7">{sonnerie.nom}</div>
        </StyledItem>
    )
}
// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS

const StyledItem = styled.div`
    color: ${theme.colors.c1};
    &:hover {
        color: ${theme.colors.c1l2};
    }
`
