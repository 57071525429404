/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import styled from "styled-components"
import Button from "react-bootstrap/Button"
import { FaPlus, FaEdit, FaTimes } from "react-icons/fa"

import Loading from "ui/Loading"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function ZFormFooter({
    editMode = true,
    isLoading = false,
    handleSubmit,
    handleCancel,
}) {
    return (
        <StyledFooter>
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <Button variant="danger" onClick={() => handleCancel()}>
                        <FaTimes /> Annuler
                    </Button>
                    <Button
                        variant="success"
                        className="float-end"
                        onClick={() => handleSubmit()}
                    >
                        {editMode ? (
                            <>
                                <FaEdit /> Modifier
                            </>
                        ) : (
                            <>
                                <FaPlus /> Ajouter
                            </>
                        )}
                    </Button>
                </>
            )}
        </StyledFooter>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS

const StyledFooter = styled.div`
    /*background-color: pink;*/
    width: 100%;
    margin-top: 20px;
    padding-top: 10px;
    border-top: 1px solid green;
`
