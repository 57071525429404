/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { useAppSelector, useAppDispatch } from "utils/store"
import {
    setIsFormPostage1st,
    setFormPostage1stPosition,
} from "features/timetable/utils/timetableReducer"
import { ZFieldCheckbox } from "uiZ/zForm"

import ButtonFilter from "./ButtonFilter"
// import { IcoQuestion } from "styles/icones"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function FilterFormPostage1st() {
    const { isFormPostage1st, formPostage1stPosition } = useAppSelector(
        (state) => state.timetable
    )
    const dispatch = useAppDispatch()

    console.log("isFormPostage1st", isFormPostage1st)
    console.log("isFormPostage1st", isFormPostage1st)
    return (
        <div className="border-top border-primary p-1">
            <div>
                <b>Options pour l'édition des postages:</b>
                <br />
                <br />
            </div>
            <ZFieldCheckbox
                name="formPostage1st"
                label="Afficher le menu à côté de la grille (recommandé)"
                // type: string
                hint="Si vous désactivez cette option, vous &quot;peignez&quot; sur l'EDT et ensuite
                un menu flottant s'ouvre pour choisir le poste et la semaine."
                fieldClassName="ms-2"
                //  inputClassName?: string | null
                value={isFormPostage1st}
                handleInputChange={() =>
                    dispatch(setIsFormPostage1st(!isFormPostage1st))
                }
                error={null}
                fieldStyle="SWITCH"
            />

            {isFormPostage1st && (
                <div className="mt-3">
                    Position du menu:&nbsp;
                    <ButtonFilter
                        className="ml-2"
                        actif={formPostage1stPosition === "LEFT"}
                        onClick={() =>
                            dispatch(setFormPostage1stPosition("LEFT"))
                        }
                    >
                        Gauche
                    </ButtonFilter>
                    <ButtonFilter
                        className="ml-2"
                        actif={formPostage1stPosition === "TOP"}
                        onClick={() =>
                            dispatch(setFormPostage1stPosition("TOP"))
                        }
                    >
                        Haut
                    </ButtonFilter>
                    <ButtonFilter
                        className="ml-2"
                        actif={formPostage1stPosition === "RIGHT"}
                        onClick={() =>
                            dispatch(setFormPostage1stPosition("RIGHT"))
                        }
                    >
                        Droite
                    </ButtonFilter>
                    <ButtonFilter
                        className="ml-2"
                        actif={formPostage1stPosition === "BOTTOM"}
                        onClick={() =>
                            dispatch(setFormPostage1stPosition("BOTTOM"))
                        }
                    >
                        Bas
                    </ButtonFilter>
                </div>
            )}
        </div>
    )
}
