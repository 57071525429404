// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import ReactDOM from "react-dom/client"
import ReactGA from "react-ga4"
import { Provider } from "react-redux"
import { dev } from "utils/constants"
import ErrorBoundary from "utils/ErrorBoundary"
import store from "utils/store"
import App from "./App"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

// * replace console.* for disable log on production
if (process.env.NODE_ENV === "production" || !dev) {
    console.log = () => {}
    console.error = () => {}
    console.debug = () => {}
    console.group = () => {}
    console.groupCollapsed = () => {}
    console.groupEnd = () => {}
}

ReactGA.initialize("G-QFWWP7567J") // GA4
ReactGA.send("pageview")

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
    <Provider store={store}>
        {/* <React.StrictMode> */}
        <ErrorBoundary>
            <App />
        </ErrorBoundary>
        {/* </React.StrictMode> */}
    </Provider>
)
