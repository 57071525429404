// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import ZxModal from "ui/ZxModal"
import { ZForm } from "uiZ/zForm"
import PeriodeFormInner from "./PeriodeFormInner"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

interface IFormValues {
    id: number | null
    nom: string | null
    heureDebut: string
    heureFin: string
    infos: string | null
    couleur: string
}

type Props = {
    periode: IFormValues
    closeForm: any
    edtId: number | null // null si edit ???
}

// eslint-disable-next-line no-unused-vars
export default function PeriodeForm({ edtId, periode, closeForm }: Props) {
    /*
    const customOnSubmit = (ev: FormEvent<HTMLFormElement>) => {
        console.log("main - onSubmit", ev)
    }

    const customOnDelete = () => {
        alert("delete")
    }
    */
    const customValidate = (values: IFormValues) => {
        let errors = {}
        /*
        if (!values.nom || values.nom.length < 3) {
            errors = {
                ...errors,
                nom: "Le nom doit faire au moins 3 caractères !",
            }
        }
        */
        if (values.heureDebut >= values.heureFin) {
            errors = {
                ...errors,
                heureDebut:
                    "L'heure de fin doit être superieure à l'heure de début !",
            }
        }
        return errors
    }

    return (
        <ZxModal styles={{ maxWidth: "460px" }}>
            <ZForm
                initialData={periode}
                customValidate={customValidate}
                createTitle="Ajouter une période"
                updateTitle="Modifier une période"
                deleteTitle={`Supprimer la periode de ${periode?.heureDebut}-${periode?.heureFin}`}
                deleteConfirmText=""
                closeForm={closeForm}
            >
                <PeriodeFormInner />
            </ZForm>
        </ZxModal>
    )
}
