// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { useAppSelector } from "utils/store"
import { ZPageHeader, ZPageTitle, ZPageContent, ZPageSection } from "uiZ/zPage"
// import { EtabForm } from "features/etabs"
import MesEtabsHelp from "./MesEtabsHelp"
import MesEtabsTodo from "./MesEtabsTodo"
import MesEtabsListe from "./MesEtabsListe"
import EtabForm from "../form/EtabForm"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function MesEtabs() {
    const etabFormReducer = useAppSelector((state) => state.etabForm)

    return (
        <>
            {etabFormReducer.etab && <EtabForm />}

            <ZPageHeader>
                <ZPageTitle>Mes établissements</ZPageTitle>
            </ZPageHeader>

            <ZPageContent>
                <ZPageSection className="col-12">
                    <MesEtabsListe />
                </ZPageSection>

                <div className="row col-12">
                    <MesEtabsHelp className="col-12 col-md-6" />
                    <MesEtabsTodo className="col-12 col-md-6" />
                </div>
            </ZPageContent>
        </>
    )
}
