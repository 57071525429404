// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
// import styled from "styled-components"
// import { theme } from "styles/theme"

import LPSection from "./layout/LPSection"
import LPSide from "./layout/LPSide"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function SectionFonctionnalites() {
    return (
        <LPSection reverse className="SectionFonctionnalites">
            <LPSide className="col-md-7 col-12">
                <ul>
                    <li>Impression et export PDF.</li>{" "}
                    <li>
                        Une CPE ou un AED peut être sur plusieurs établissements
                    </li>
                    <li>
                        Pour chaque établissement, vous pouvez créer plusieurs
                        EDT.{" "}
                        <small>
                            (Année suivante, semaine de pré-rentrée,
                            modifications en cours d'année…)
                        </small>
                    </li>
                    <li>
                        Contrairement à un fichier excel, plusieurs utilisateurs
                        peuvent consulter ou éditer un EDT en même temps.
                    </li>
                    <li>
                        Les AED verront automatiquement les modifications que
                        vous apporterez à un EDT.
                        <br />
                        Vous n'avez plus besoin de le réimprimer
                        <small> (et La planète vous remerciera)</small>.
                    </li>
                    <li>
                        Envoi d'invitations par mail aux personnels pour qu'ils
                        se créent un compte.
                    </li>
                </ul>
            </LPSide>
            <LPSide className=" col-md-5 col-12">
                <h2>Autres fonctionnalités.</h2>
            </LPSide>
        </LPSection>
    )
}
