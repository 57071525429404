// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { ZPage } from "uiZ/zPage"
import { UserShow, apiFetchUser } from "features/users"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function PageUserShow() {
    const userId = Number(useParams().id) || 0
    if (userId <= 0) {
        return <h1 className="text-danger">Erreur url</h1>
    }

    const [user, setUser] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const [fetchError, setFetchError] = useState(null)

    useEffect(() => {
        if (userId > 0) {
            setIsLoading(true)
            apiFetchUser(userId).then(
                (result) => {
                    console.log("useEffect.apiFetchUser.result--", result)
                    if (result.user) {
                        setUser(result.user)
                    } else if (result.error) {
                        setFetchError(result.error)
                    }
                    setIsLoading(false)
                },
                (error) => {
                    setIsLoading(false)
                    setFetchError(error)
                    console.log("error", error)
                }
            )
        }
    }, [userId])

    return (
        <ZPage
            documentTitle="Utilisateur"
            isLoading={isLoading}
            error={fetchError}
        >
            <UserShow user={user} />
        </ZPage>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS
