/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React /* , { useState } */ from "react"
import styled from "styled-components"

import FormPostage1stSemaines from "./FormPostage1stSemaines"
import FormPostage1stPostes from "./FormPostage1stPostes"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function FormPostage1st() {
    return (
        <StyledForm className="border border-primary no-print">
            <div
                id="FormPostage1st-help"
                className="border-bottom border-primary"
            >
                Séléctionnez la semaine et le poste puis peignez sur l&apos;EDT.
            </div>
            <FormPostage1stSemaines />
            <FormPostage1stPostes />
        </StyledForm>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS

const StyledForm = styled.div`
    height: 100%;
    margin: 0px;
    padding: 5px;
    background-color: white;
    #FormPostage1st-help {
        font-size: 0.8em;
    }
`
