// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
// import React from "react"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export const status = {
    pending: "PENDING",
    canceled: "CANCELED",
    processing: "PROCESSING",
    checking: "CHECKING",
    done: "DONE",
}

export const progressItemsDivers = [
    {
        page: "Divers",
        actions: [
            {
                title: "Inscription",
                status: status.done,
            },
            {
                title: "Connexion",
                status: status.done,
            },
            {
                title: "deconexion",
                status: status.done,
            },
            {
                title: "user show",
                status: status.checking,
            },
            {
                title: "edit user password",
                status: status.done,
            },
            {
                title: "admin",
                status: status.checking,
            },
        ],
    },
]
