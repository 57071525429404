/* eslint-disable no-nested-ternary */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import styled from "styled-components"
import HeaderMenuPublic, {
    navHeaderPublicHeight,
} from "ui/headerMenu/HeaderMenuPublic"
import FormPasswordForgotten from "features/authentification/_passwordForgotten/FormPasswordForgotten"
import { ButtonNavLinkLogin } from "features/authentification/common/buttonsNavLink"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
// ** Quand un User clique sur "mot de passe oublié" sur la page Login
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function PagePasswordForgotten() {
    return (
        <div className="row col-12 m-1">
            <HeaderMenuPublic variant="primary" />
            <StyledPageLoginXXXXXXXXXX>
                <h1 className="app-title mb-4">cmzEDT.zedixi.com</h1>
                <img
                    className="app-logo mb-4"
                    alt="cmzEDT"
                    src="/img/app-logo/app-logo-512.png"
                />

                <h1 className="mb-2">Mot de passe oublié</h1>
                <p>Un mail vous sera envoyé pour le réinitialiser.</p>
                <FormPasswordForgotten />
                <br />
                <br />
                <ButtonNavLinkLogin />
            </StyledPageLoginXXXXXXXXXX>
        </div>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS

const StyledPageLoginXXXXXXXXXX = styled.div`
    /*color: $C1d1 !important;*/
    // padding-top: 70px; // !!!  pour le header public
    text-align: center;
    margin: 0 auto;

    @media all and (min-width: 320px) {
        padding: 60px 0;
        padding-top: ${navHeaderPublicHeight}px; // !!!  pour le header public
        max-width: 320px;
        .app-title {
            font-size: 1.5em;
        }
        .app-logo {
            width: 200px;
        }
    }

    @media all and (max-width: 319px) {
        padding: 10px 0;
        padding-top: ${navHeaderPublicHeight}px; // !!!  pour le header public
        .app-title {
            font-size: 1.1em;
        }
        .app-logo {
            width: 60%;
        }
    }
`
