/*
 *   Used in : PageEtabShow
 *
 *
 */

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import { createSlice } from "@reduxjs/toolkit"

// import { addEtabToMesEtabs } from "features/etabs/utils/mesEtabsReducer"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export interface IEdtForm {
    id: number | null
    nom: string
    description: string | null
    commentaire: string | null
    actif: boolean
    etabId: number | null
    etablissement: { id: number }
}
interface IEdtFormState {
    edtForm: IEdtForm | null
}

const initialState: IEdtFormState = {
    edtForm: null,
}

// ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■
// Slice

const slice = createSlice({
    name: "edtForm",
    initialState,
    reducers: {
        setEdtFormX: (state, action) => {
            console.log(action.payload)
            state.edtForm = action.payload
        },
    },
})
export default slice.reducer

// ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■

// Actions
const { setEdtFormX } = slice.actions

export const formEdtOpenCreate = (etabId: number) => async (dispatch: any) => {
    console.log("'aaaaaaaaaaaaaa")
    dispatch(
        setEdtFormX({
            id: null,
            nom: "",
            description: "",
            actif: true,
            etabId,
            commentaire: "",
            nbreHeuresHebdoTempsPlein: 0,
            isPublished: true,
            isInternat: false,
        })
    )
}
export const setformEdtToUpdate = (edt: IEdtForm) => async (dispatch: any) => {
    dispatch(setEdtFormX(edt))
}

export const formEdtClose = () => async (dispatch: any) => {
    dispatch(setEdtFormX(null))
}
