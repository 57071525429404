/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import Button from "react-bootstrap/Button"
import { IcoEdit, IcoAdd } from "styles/icones"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function ButtonSubmit({ title, edit = false, className = "" }) {
    return (
        <Button
            className={"btn-success btn-sm " + className}
            type="submit"
            title={title}
        >
            {edit ? (
                <>
                    <IcoEdit /> Modifier
                </>
            ) : (
                <>
                    <IcoAdd /> Ajouter
                </>
            )}
        </Button>
    )
}
